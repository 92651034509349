import { Injectable } from '@angular/core';
import { IOSBgSyncService } from '../offline/ios-bg-sync.service';
import { DeepLinksService } from './deep-links.service';
import { IOSVersionService } from './ios-version.service';

@Injectable({
  providedIn: 'root',
})
export class MobileServices {
  private initialized = false;

  constructor(
    private iosBgSyncService: IOSBgSyncService,
    private deepLinksService: DeepLinksService,
    private iosVersionService: IOSVersionService,
  ) {}

  public async initialize() {
    if (this.initialized) {
      return;
    }
    this.initialized = true;

    try {
      await this.iosBgSyncService.initialize();
      this.deepLinksService.initialize();
      await this.iosVersionService.initialize();
    } catch (error) {
      console.log('::: Error initializing mobile services', error);
    }
  }
}
