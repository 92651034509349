import { Injectable } from '@angular/core';
import { Router, Event, NavigationError } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ErrorsService {
  constructor(private router: Router) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationError) {
      }
    });
  }
}
