<div class="content-container" fxLayout="column" fxLayoutAlign="center center">
  <mat-dialog-content>
      <label>{{'VKIPopUp.StartDateMessage' | translate}}</label>
    <div fxLayout="row" fxLayoutAlign="start center">
      <label fxFlex=40>{{'VKI.StartingDate' | translate}}</label>
      <div fxFlex=60 fxLayout="column" style="padding-left: 10px;">
          <mat-form-field class="time">
              <input matInput [matDatepicker]="picker" [placeholder]="'Common.dd-mm-yyyy' | translate" [(ngModel)]="flockStartDate" #date="ngModel" (keydown)="dateInput($event)" (dateChange)="setDate($event.value)" maxlength="10">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
      </div>
    </div>
    <label class="custom-error" *ngIf="date.invalid">{{'Common.InvalidDateRange' | translate}}</label>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button class="button-enabled" mat-button mat-dialog-close (click)="dialogRef.close()">{{'Common.Cancel' | translate}}</button>
    <button [ngClass]="{'button-disabled': !saveButtonAvailability || !date.valid, 'button-enabled': saveButtonAvailability && date.valid}" mat-button mat-dialog-close (click)="saveSlaughterDate()" [disabled]="!saveButtonAvailability">{{'Common.Save' | translate}}</button>
  </mat-dialog-actions>
  <i class="material-icons" (click)="dialogRef.close()">clear</i>
</div>
