import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';

import { environment } from '../../../../environments/environment';
import { Https } from '../http.service';

@Injectable({
  providedIn: 'root',
})
export class CompanyTypeService {
  constructor(private http: Https) {}

  public getCompanyTypeList() {
    const params = new HttpParams().set('sortBy', 'name').set('orders', 'DESC');
    return this.http.get(`${environment.baseUrl}/companyTypes`, params);
  }

  public createOrUpdateCompanyType(payload, saveMode) {
    //saveMode true = save, false = update
    if (saveMode) {
      return this.http.post(`${environment.baseUrl}/companyTypes`, payload);
    } else {
      return this.http.post(`${environment.baseUrl}/companyTypes/update`, payload);
    }
  }

  public deleteCompanyType(payload) {
    return this.http.post(`${environment.baseUrl}/companyTypes/delete/${payload}`, '');
  }
}
