<div class="access-settings-dialog-container">
  <mat-dialog-content [formGroup]="accessSettingsFormGroup">
    <div fxLayout="row">
      <div fxLayout="column" fxFlex=50 fxLayoutAlign="flex-start flex-start">
        <label>{{'AccessSettings.SelectCompany' | translate}}</label>
        <div>
          <mat-form-field>
            <mat-select formControlName="relationCompanyId" (selectionChange)='companySelection()'>
              <mat-option *ngFor="let company of companyAccessList" [value]="company.companyId">
                {{company.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="column" fxFlex=50 fxLayoutAlign="flex-start flex-start">
        <label style="padding-bottom: 10px">{{'AccessSettings.GiveCompanyAccessRight' | translate}}</label>
        <mat-radio-group class="radio-group" formControlName="allStable" (change)="checkIsAllStable($event.value, true)">
          <mat-radio-button color="primary" [value]="true">
            {{'AccessSettings.AllStables' | translate}}
          </mat-radio-button>
          <mat-radio-button color="primary" [value]="false">
            {{'AccessSettings.SpecificStables' | translate}}
          </mat-radio-button>
        </mat-radio-group>
        <ng-container *ngIf="isStableOptionAvailabale">
          <mat-form-field class="multiple-selection-field" hideRequiredMarker>
            <mat-select formControlName="stableAccessList" multiple>
              <mat-select-trigger>
                <mat-label [innerHTML]="getMultiSelected()"></mat-label>
              </mat-select-trigger>
              <mat-option *ngFor="let stable of stableList" [value]="stable.stableId">
                {{stable.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>
      </div>
    </div>
    <div fxLayout="row">
      <div style="padding-top:20px" fxLayout="column" fxFlex=50 fxLayoutAlign="flex-start flex-start">
        <label>{{'AccessSettings.StartDate' | translate}}</label>
        <mat-form-field class="sharing-form time">
          <input matInput formControlName="shareStartDate" [matDatepicker]="startDatePicker"
            [placeholder]="'Common.dd-mm-yyyy' | translate" (keydown)="dateInput('shareStartDate', $event)"
            (dateChange)="setDate('shareStartDate', $event)" maxlength="10">
          <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #startDatePicker></mat-datepicker>
        </mat-form-field>
      </div>
      <div style="padding-top:20px" fxLayout="column" fxFlex=50 fxLayoutAlign="flex-start flex-start">
        <label>{{'AccessSettings.EndDate' | translate}}</label>
        <mat-form-field class="sharing-form time">
          <input matInput formControlName="shareEndDate" [matDatepicker]="endDatePicker"
            [placeholder]="'Common.dd-mm-yyyy' | translate" (keydown)="dateInput('shareEndDate', $event)"
            (dateChange)="setDate('shareEndDate', $event)" maxlength="10">
          <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #endDatePicker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions fxLayout="row" fxLayoutAlign="flex-end center">
    <button [ngClass]="{'button-disabled': !accessSettingsFormGroup.dirty, 'button-enabled': accessSettingsFormGroup.dirty}" mat-button mat-dialog-close (click)="dialogRef.close()" [disabled]="!accessSettingsFormGroup.dirty">{{'Common.Cancel' | translate}}</button>
    <button [ngClass]="{'button-disabled': !accessSettingsFormGroup.valid || !accessSettingsFormGroup.dirty, 'button-enabled': accessSettingsFormGroup.valid && accessSettingsFormGroup.dirty}" mat-button mat-dialog-close (click)="addAccessSettings()" [disabled]="!accessSettingsFormGroup.valid || !accessSettingsFormGroup.dirty">
      <ng-container *ngIf="isNew; else edit">
        {{'Common.Add' | translate}}
      </ng-container>
      <ng-template #edit>
        {{'Common.Edit' | translate}}
      </ng-template>
    </button>
  </mat-dialog-actions>
  <i class="material-icons" (click)="dialogRef.close()">clear</i>
  <app-loading-spinner [show]=showSpinner></app-loading-spinner>
</div>
