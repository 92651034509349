import { Injectable, OnDestroy } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { App } from '@capacitor/app';
import { Dialog } from '@capacitor/dialog';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription, combineLatest, concat, interval, of } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import semverDiff from 'semver-diff';
import { environment } from 'src/environments/environment';
import { NetworkService } from '../network/network.service';
import { CodeService } from '../code.service';
import { TranslationService } from '../translation.service';

const DEFAULT_VERSION_CHECK_INTERVAL = 1000 * 60 * 60; // 1 hours

@Injectable({
  providedIn: 'root',
})
export class IOSVersionService implements OnDestroy {
  private versionCheckSubscription: Subscription;
  private initialized = false;

  constructor(
    private networkService: NetworkService,
    private codeService: CodeService,
    private translate: TranslateService,
    private translationService: TranslationService,
  ) {}

  public async initialize() {
    if (this.initialized) {
      return;
    }
    this.initialized = true;
    if (Capacitor.getPlatform() !== 'ios') {
      return;
    }

    this.versionCheckSubscription = this.getLatestVersionWhenOnline().subscribe(
      (latestVersion) => {
        this.verifyVersion(latestVersion);
      },
      (error) => {
        console.error('::: Error verifying version', error);
      },
    );
  }

  private getLatestVersionWhenOnline(): Observable<any> {
    return concat(of(null), interval(Number(environment.appVersionCheckInterval) || DEFAULT_VERSION_CHECK_INTERVAL)).pipe(
      switchMap(() =>
        combineLatest([
          this.networkService.getIsOfflineMode$().pipe(filter((isOffline) => !isOffline)),
          this.translationService.initialized$.pipe(filter((initialized) => initialized)),
        ]).pipe(
          take(1),
          switchMap(() => this.getLatestVersion()),
        ),
      ),
    );
  }

  private async verifyVersion(latestVersion) {
    console.log('::: verify version', { latestVersion });
    if (latestVersion) {
      try {
        const currentVersion = (await App.getInfo()).version;
        if (latestVersion === currentVersion) {
          return;
        }
        const diff = semverDiff(currentVersion, latestVersion);
        console.log('::: verify version', { diff, latestVersion, currentVersion });
        if (!diff) {
          // could be when currentVersion is higher than latestVersion
          return;
        }
        if (diff === 'major' || diff === 'premajor') {
          this.showBlockingUpdate();
        } else {
          this.showNonBlockingUpdate();
        }
      } catch (error) {
        console.log('::: Error verifying version', { error, latestVersion });
      }
    }
  }

  private async showBlockingUpdate() {
    await Dialog.alert({
      title: this.translate.instant('AppUpdateVersion.BlockingTitle'),
      message: this.translate.instant('AppUpdateVersion.BlockingMessage'),
      buttonTitle: this.translate.instant('AppUpdateVersion.BlockingOkButton'),
    });
    this.openAppStore();
    this.showBlockingUpdate();
  }

  private async showNonBlockingUpdate() {
    const { value } = await Dialog.confirm({
      title: this.translate.instant('AppUpdateVersion.NonBlockingTitle'),
      message: this.translate.instant('AppUpdateVersion.NonBlockingMessage'),
      okButtonTitle: this.translate.instant('AppUpdateVersion.NonBlockingOkButton'),
      cancelButtonTitle: this.translate.instant('AppUpdateVersion.NonBlockingCancelButton'),
    });
    if (value) {
      this.openAppStore();
    }
  }

  private openAppStore() {
    InAppBrowser.create(environment.iosAppStore);
  }

  private getLatestVersion() {
    return this.codeService.getCodeTableValue(environment.iosVersionCodeTableCode).pipe(
      map((response: any) => {
        // if response is incorrect, catchError will handle the error
        return response.list[0].description;
      }),
      catchError((error) => {
        console.log('::: Error getting latest version', { error });
        return of(null);
      }),
    );
  }

  ngOnDestroy(): void {
    if (this.versionCheckSubscription) {
      this.versionCheckSubscription.unsubscribe();
      this.versionCheckSubscription = null;
    }
  }
}
