export class SourceDataHasChangedError extends Error {
  public readonly uid: string;
  public readonly flockNumber: string;
  public readonly key: string;
  constructor(uid: string, flockNumber: string, key: string) {
    super('Source data was changed on server');
    this.name = 'SourceDataHasChangedError';
    this.uid = uid;
    this.flockNumber = flockNumber;
    this.key = key;
  }
}

export class SourceDataNotFoundError extends Error {
  public readonly uid: string;
  public readonly flockNumber: string;
  public readonly key: string;
  constructor(uid: string, flockNumber: string, key: string) {
    super('Source data was not found in cache');
    this.name = 'SourceDataNotFoundError';
    this.uid = uid;
    this.flockNumber = flockNumber;
    this.key = key;
  }
}

export class NoRequestsToSyncError extends Error {
  public readonly uid: string;
  constructor(uid: string) {
    super('No requests to Sync for this user');
    this.name = 'NoRequestsToSyncError';
    this.uid = uid;
  }
}

export class AnotherSyncInProgressError extends Error {
  constructor() {
    super('Another sync in progress');
    this.name = 'AnotherSyncInProgressError';
  }
}

export class RequestSyncFailed extends Error {
  public readonly entryId: number;
  constructor(entryId: number) {
    super('Request sync failed');
    this.name = 'RequestSyncFailed';
    this.entryId = entryId;
  }
}

export class RequestCannotBeSynced extends Error {
  constructor() {
    super('Request cannot be synced');
    this.name = 'RequestCannotBeSynced';
  }
}

export class RequestSyncFailedDueToMaintenance extends Error {
  public readonly entryId: number;
  public readonly endTime: string;
  constructor(entryId: number, endTime: string) {
    super('Request sync failed due to Maintenance period');
    this.name = 'RequestSyncFailedDueToMaintenance';
    this.entryId = entryId;
    this.endTime = endTime;
  }
}
