import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { throwError as _throw, Observable } from 'rxjs';

import { ErrorsService } from './errors.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorsInterceptorService implements HttpInterceptor {
  constructor(
    private router: Router,
    private errorsService: ErrorsService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        (result) => {
          switch (result['status']) {
            case 404:
              this.router.navigate(['/error']);
              break;
            case 302:
              console.log('errors-interceptor.components');
              this.router.navigate(['/unauthorized']);
              break;
            default:
              break;
          }
        },
        (err) => {
          if (err instanceof HttpErrorResponse) {
            // console.log('err', err)
            // return Observable.throw(err);
          }
        },
      ),
    );
  }
}
