const channel = new BroadcastChannel('plukon-offline');

export const OFFLINE_REQUEST_STORED_MESSAGE = 'offline-request-stored';

export const sendMessage = (message: any, translationData?: any) => {
  channel.postMessage({ type: 'message', data: { message, translationData } });
};

export const sendMessageOfflineRequestStored = ({ successMessage, failMessage, errorMessage }) => {
  sendMessage(OFFLINE_REQUEST_STORED_MESSAGE, { successMessage, failMessage, errorMessage });
};

export const subscribeToMessages = (callback: (message: any) => void) => {
  channel.addEventListener('message', (event) => {
    callback(event.data);
  });
};
