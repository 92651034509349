<div class="landing-container">
  <div *ngIf="bannerURL || logoURL" class="banner-container"
    [style]="'--paddingTop: ' + bannerPadding ? '20px' : '10px'">
    <div *ngIf="bannerURL" class="welcome-container" [ngStyle]="{'background-image': 'url(' + bannerURL + ')'}">
      <img *ngIf="logoURL" class="concept-logo" [src]="logoURL" alt="">
    </div>
  </div>

  <div *ngIf="showFeaturedNews && newsObj && newsObj.length > 0" class="news-section" fxLayout="column"
    fxLayoutAlign="center center">
    <div class="news-header-section" fxLayout="row" fxLayoutAlign="space-between center">
      <div class="news-header">
        {{'Landing.FeaturedNews' | translate}}
      </div>
      <button mat-raised-button class="all-news-btn" fxLayout="row" fxLayoutAlign="center center"
        (click)="seeAllArticles()">{{'Landing.SeeAllArticles' | translate}}
      </button>
    </div>

    <div class="flexbox">
      <div *ngFor="let news of newsObj; let i = index">
        <div fxLayout="column" class="news-wrapper-flex">
          <div fxFlex="50" class="news-item" fxLayoutAlign="center center" (click)='openNewsDetails(news)'>
            <img class="news-image-frame" [src]="news.featuredImage" alt="{{news.featuredImageAltText}}">

            <div *ngIf="news.conceptList && news.conceptList.length > 0" fxLayout="column"
              class="concept-container-list">
              <div class="concept-container">
                <div class="icon-wrapper" fxLayout="row" fxLayoutAlign="center center">
                  <!-- <img *ngIf="news.conceptList[0].conceptLayout && news.conceptList[0].conceptLayout.logoURL" class="logo-image" [src]="news.conceptList[0].conceptLayout.logoURL" alt=""> -->
                  {{ news.conceptList[0].translateSubject | translate }}
                </div>
              </div>
            </div>

          </div>

          <div fxFlex="50" class="news-info" fxLayout="column" fxLayoutAlign="start flex-start">
            <div fxFlex="15" class="icon-wrapper" fxLayout="row" fxLayoutAlign="center center"><i
                class="material-icons time">access_time</i>{{news.publishDate}}</div>
            <div fxFlex="60" class="news-title" (click)='openNewsDetails(news)'>{{news.name}}</div>
            <div fxFlex="25">
              <button mat-raised-button (click)='openNewsDetails(news)'>
                {{'Common.ReadMore' | translate}}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-loading-spinner [show]=showSpinner></app-loading-spinner>