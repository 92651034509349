<div class="content-dialog-container" fxLayout="column" fxLayoutAlign="center center">
  <ng-container *ngIf="isHtml; else plain">
    <mat-dialog-content style="white-space: pre-wrap;"[innerHTML]=content></mat-dialog-content>
  </ng-container>
  <ng-template #plain>
    <mat-dialog-content>{{translationKey | translate}}</mat-dialog-content>
  </ng-template>
  <mat-dialog-actions *ngIf="primaryButtonText">
    <button *ngIf="primaryButtonText" class="content-button-enabled" mat-button mat-dialog-close (click)="submit()">{{primaryButtonText | translate}}</button>
  </mat-dialog-actions>
</div>
