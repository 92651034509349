<div class="content-container" fxLayout="column" fxLayoutAlign="center center">
  <div mat-dialog-title>{{'VKIRequest.SubmitConfirmTitle' | translate}}</div>
  <div class="content-body">
    <mat-dialog-content>{{'VKIRequest.SubmitConfirm' | translate}}</mat-dialog-content>
  </div>
  <mat-dialog-actions>
    <button class="button-enabled" mat-button mat-dialog-close (click)="dialogRef.close()">{{'Common.Cancel' | translate}}</button>
    <button class="button-enabled" mat-button mat-dialog-close (click)="submit()">{{'Common.Submit' | translate}}</button>
  </mat-dialog-actions>
  <i class="material-icons" (click)="dialogRef.close()">clear</i>
</div>

