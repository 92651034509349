import { Component, Inject } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { environment } from '../../../../../../environments/environment';
import { ISalmonellaDialogData } from '../salmonella.models';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ResponsiveUIService } from 'src/app/shared/services/responsive-ui.service';
import { MobileDialogComponent } from 'src/app/shared/components/mobile-dialog/mobile-dialog.component';

@Component({
  selector: 'app-salmonella-dialog',
  templateUrl: './salmonella-dialog.component.html',
  styleUrls: ['./salmonella-dialog.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: environment.myDateFormat },
  ],
})
export class SalmonellaDialogComponent extends MobileDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<SalmonellaDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ISalmonellaDialogData,
    responsiveUIService: ResponsiveUIService,
  ) {
    super(dialogRef, responsiveUIService);
  }
}
