import { openDB, DBSchema, IDBPDatabase } from 'idb';

export interface StorableResponse {
  body: any;
  status: number;
  statusText: string;
  url: string;
  ok: boolean;
  headers:
  | string
  | {
    [name: string]: string | string[];
  };
}

const DB_VERSION = 1;
const RESPONSES_OBJECT_STORE_NAME = 'responses';
const UID_INDEX = 'uid';
const URL_INDEX = 'url';
const QUERY_KEY_INDEX = 'queryKey';
const UNIQUE_ENTRY_INDEX = 'unique_uid_url';
const UID_QUERY_KEY_INDEX = 'uid_queryKey';
const ID_KEY_PATH = 'id';

const MS_IN_DAY = 24 * 60 * 60 * 1000;

interface ResponsesDBSchema extends DBSchema {
  [RESPONSES_OBJECT_STORE_NAME]: {
    key: number;
    value: ResponseStoreEntry;
    indexes: {
      [UID_INDEX]: string;
      [URL_INDEX]: string;
      [UID_QUERY_KEY_INDEX]: [string, string];
      [UNIQUE_ENTRY_INDEX]: [string, string];
    };
  };
}

export interface ResponseStoreEntryWithoutId {
  [ID_KEY_PATH]?: number;
  [UID_INDEX]?: string;
  [QUERY_KEY_INDEX]?: string;
  [URL_INDEX]: string;
  response: StorableResponse;
  lastUpdated: number;
}

export interface ResponseStoreEntry extends ResponseStoreEntryWithoutId {
  [ID_KEY_PATH]: number;
}

/**
 * This class is needed to interact with IndexedDB for saving ResponseStoreEntryWithoutId and retrieving ResponseStoreEntry.
 */
export class ResponsesDb {
  private _db: IDBPDatabase<ResponsesDBSchema> | null = null;
  private readonly dnName: string;

  constructor(dbName: string) {
    this.dnName = dbName;
  }

  async add(entry: ResponseStoreEntryWithoutId): Promise<void> {
    const db = await this.getDb();
    const tx = db.transaction(RESPONSES_OBJECT_STORE_NAME, 'readwrite', { durability: 'strict' });
    await tx.store.add(entry as ResponseStoreEntry);
    await tx.done;
  }

  async addOrUpdate(entry: ResponseStoreEntryWithoutId): Promise<void> {
    const db = await this.getDb();
    const tx = db.transaction(RESPONSES_OBJECT_STORE_NAME, 'readwrite');
    const index = tx.store.index(UNIQUE_ENTRY_INDEX);

    const cursor = await index.openCursor([entry.uid, entry.url], 'prev');
    // const cursor = await index.openCursor(queryKey);

    if (cursor && cursor.value) {
      // Update the existing entry with the new data.
      cursor.value[QUERY_KEY_INDEX] = entry.queryKey;
      cursor.value.response = entry.response;
      cursor.value.lastUpdated = entry.lastUpdated;
      await cursor.update(cursor.value);
    } else {
      await tx.store.add(entry as ResponseStoreEntry);
    }

    await tx.done;
  }

  async getByUIDAndUrl(uid: string, url: string): Promise<ResponseStoreEntry | undefined> {
    const db = await this.getDb();
    const tx = db.transaction(RESPONSES_OBJECT_STORE_NAME, 'readonly');
    const index = tx.store.index(UNIQUE_ENTRY_INDEX);
    const cursor = await index.openCursor([uid, url], 'prev');
    if (cursor && cursor.value) {
      return cursor.value;
    }
    return undefined;
  }

  async getByUIDAndQueryKey(uid: string, queryKey: string): Promise<ResponseStoreEntry | undefined> {
    const db = await this.getDb();
    const tx = db.transaction(RESPONSES_OBJECT_STORE_NAME, 'readonly');
    const index = tx.store.index(UID_QUERY_KEY_INDEX);
    // For prod, there should be one entry with uid and url.
    // But for local development, if to run on different domain or ports, there might be multiple entries with same uid and url.
    // Using 'prev' fixes it in the way that it will return the latest entry.
    // ps: Adding cleanup in precache service should help to remove old entries.
    const cursor = await index.openCursor([uid, queryKey], 'prev');
    if (cursor && cursor.value) {
      return cursor.value;
    }
    return undefined;
  }

  async removeOldEntries(ageDays: number): Promise<void> {
    const db = await this.getDb();
    const tx = db.transaction(RESPONSES_OBJECT_STORE_NAME, 'readwrite');
    const store = tx.store;

    const ageThreshold = Date.now() - ageDays * MS_IN_DAY;

    // Create a cursor to iterate through all entries
    let cursor = await store.openCursor();

    // Iterate through entries
    while (cursor) {
      const entry = cursor.value as ResponseStoreEntry;
      if (entry.lastUpdated < ageThreshold) {
        cursor.delete();
      }
      cursor = await cursor.continue();
    }

    await tx.done;
  }

  private async getDb() {
    if (!this._db) {
      this._db = await openDB(this.dnName, DB_VERSION, {
        upgrade: this.upgradeDb,
      });
    }
    return this._db;
  }

  private upgradeDb(db: IDBPDatabase<ResponsesDBSchema>, oldVersion: number) {
    if (oldVersion > 0 && oldVersion < DB_VERSION) {
      if (db.objectStoreNames.contains(RESPONSES_OBJECT_STORE_NAME)) {
        db.deleteObjectStore(RESPONSES_OBJECT_STORE_NAME);
      }
    }
    const objStore = db.createObjectStore(RESPONSES_OBJECT_STORE_NAME, {
      autoIncrement: true,
      keyPath: ID_KEY_PATH,
    });

    objStore.createIndex(UID_INDEX, UID_INDEX, { unique: false });
    objStore.createIndex(URL_INDEX, URL_INDEX, { unique: false });

    objStore.createIndex(UID_QUERY_KEY_INDEX, [UID_INDEX, QUERY_KEY_INDEX], { unique: false });
    // Create the combined unique index
    objStore.createIndex(UNIQUE_ENTRY_INDEX, [UID_INDEX, URL_INDEX], { unique: true });
  }
}
