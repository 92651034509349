
<div class="mat-simple-snackbar">
  <span class="mat-simple-snack-bar-content">{{ translationKey | translate}}</span>
  <div class="mat-simple-snackbar-action">
    <button mat-button class="mat-focus-indicator mat-button mat-button-base" (click)="snackBarRef.dismiss()">
      <span class="mat-button-wrapper">Close</span>
      <span matripple class="mat-ripple mat-button-ripple"></span>
      <span class="mat-button-focus-overlay"></span>
    </button>
  </div>
</div>
