import { openDB, DBSchema, IDBPDatabase } from 'idb';

const DB_VERSION = 4;

const SYNC_CLIENT_ID_STORE_NAME = 'sync-client-id';
const PRECACHE_STATE_STORE_NAME = 'precache-state';
const PRECACHE_CONFIG_STORE_NAME = 'precache-config';

interface SyncDBSchema extends DBSchema {
  [SYNC_CLIENT_ID_STORE_NAME]: {
    key: typeof SYNC_CLIENT_ID_STORE_NAME;
    value: {
      clientId: string;
      timestamp: number;
    };
  };
  [PRECACHE_STATE_STORE_NAME]: {
    key: string;
    value: {
      uid: string;
      timestamp: number;
      success: boolean;
    };
  };
  [PRECACHE_CONFIG_STORE_NAME]: {
    key: string;
    value: {
      uid: string;
      showNotification: boolean;
    };
  };
}

export class SyncDb {
  private _db: IDBPDatabase<SyncDBSchema> | null = null;
  private readonly dbName: string;
  private readonly claimAbandonedTimeout: number;

  constructor(dbName: string, claimAbandonedTimeout = 10000) {
    this.dbName = dbName;
    this.claimAbandonedTimeout = claimAbandonedTimeout;
  }

  public async claimIfNotClaimed(clientId: string) {
    const db = await this.getDb();
    const tx = db.transaction(SYNC_CLIENT_ID_STORE_NAME, 'readwrite');
    const store = tx.store;

    const existingClaim = await store.get(SYNC_CLIENT_ID_STORE_NAME);
    const currentTimestamp = Date.now();

    if (!existingClaim) {
      // If no existing claim, create a new one.
      const newClaim = { clientId, timestamp: currentTimestamp };
      await store.put(newClaim, SYNC_CLIENT_ID_STORE_NAME);
      await tx.done;
      return true;
    } else if (existingClaim.clientId === clientId || existingClaim.timestamp < currentTimestamp - this.claimAbandonedTimeout) {
      // If clientId matches or the claim is older than abandonedDelay, update the claim.
      existingClaim.clientId = clientId;
      existingClaim.timestamp = currentTimestamp;
      await store.put(existingClaim, SYNC_CLIENT_ID_STORE_NAME);
      await tx.done;
      return true;
    }

    return false; // Do not claim in other cases.
  }

  public async releaseClaim(clientId: string): Promise<void> {
    const db = await this.getDb();
    const tx = db.transaction(SYNC_CLIENT_ID_STORE_NAME, 'readwrite');
    const store = tx.store;

    const existingClaim = await store.get(SYNC_CLIENT_ID_STORE_NAME);
    const currentTimestamp = Date.now();

    if (existingClaim && (existingClaim.clientId === clientId || existingClaim.timestamp < currentTimestamp - this.claimAbandonedTimeout)) {
      // If the clientId matches or the claim is older than abandonedTimeout, remove the claim.
      await store.delete(SYNC_CLIENT_ID_STORE_NAME);
    }
    await tx.done;
  }

  public async getPrecacheState(uid: string): Promise<{ timestamp: number; success: boolean } | null> {
    const db = await this.getDb();
    const tx = db.transaction(PRECACHE_STATE_STORE_NAME, 'readonly');
    const store = tx.store;
    return store.get(uid);
  }

  public async storePrecacheState(uid: string, success: boolean): Promise<void> {
    const db = await this.getDb();
    const tx = db.transaction(PRECACHE_STATE_STORE_NAME, 'readwrite');
    const store = tx.store;
    const timestamp = Date.now();
    await store.put({ uid, timestamp, success });
    await tx.done;
  }

  public async deletePrecacheState(uid: string): Promise<void> {
    const db = await this.getDb();
    const tx = db.transaction(PRECACHE_STATE_STORE_NAME, 'readwrite');
    const store = tx.store;
    return store.delete(uid);
  }

  public async savePrecacheConfig(uid: string, data: { showNotification: boolean }): Promise<void> {
    const { showNotification } = data;
    const db = await this.getDb();
    const tx = db.transaction(PRECACHE_CONFIG_STORE_NAME, 'readwrite');
    const store = tx.store;
    await store.put({ uid, showNotification });
    await tx.done;
  }

  public async deletePrecacheConfig(uid: string): Promise<void> {
    const db = await this.getDb();
    const tx = db.transaction(PRECACHE_CONFIG_STORE_NAME, 'readwrite');
    const store = tx.store;
    await store.delete(uid);
    await tx.done;
  }

  public async getPrecacheConfig(uid: string): Promise<{ showNotification: boolean } | null> {
    const db = await this.getDb();
    const tx = db.transaction(PRECACHE_CONFIG_STORE_NAME, 'readonly');
    const store = tx.store;
    return store.get(uid);
  }

  private async getDb() {
    if (!this._db) {
      this._db = await openDB(this.dbName, DB_VERSION, {
        upgrade: this.upgradeDb,
      });
    }
    return this._db;
  }

  private upgradeDb(db: IDBPDatabase<SyncDBSchema>, oldVersion: number) {
    if (oldVersion > 0 && oldVersion < DB_VERSION) {
      if (db.objectStoreNames.contains(SYNC_CLIENT_ID_STORE_NAME)) {
        db.deleteObjectStore(SYNC_CLIENT_ID_STORE_NAME);
      }
      if (db.objectStoreNames.contains(PRECACHE_STATE_STORE_NAME)) {
        db.deleteObjectStore(PRECACHE_STATE_STORE_NAME);
      }
      if (db.objectStoreNames.contains(PRECACHE_CONFIG_STORE_NAME)) {
        db.deleteObjectStore(PRECACHE_CONFIG_STORE_NAME);
      }
    }
    db.createObjectStore(SYNC_CLIENT_ID_STORE_NAME);
    db.createObjectStore(PRECACHE_STATE_STORE_NAME, { keyPath: 'uid' });
    db.createObjectStore(PRECACHE_CONFIG_STORE_NAME, { keyPath: 'uid' });
  }
}
