import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IVaccinationsDialogData } from '../vaccinations.models';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { environment } from '../../../../../../environments/environment';
import { ResponsiveUIService } from 'src/app/shared/services/responsive-ui.service';
import { MobileDialogComponent } from 'src/app/shared/components/mobile-dialog/mobile-dialog.component';

@Component({
  selector: 'app-vaccinations-dialog',
  templateUrl: './vaccinations-dialog.component.html',
  styleUrls: ['./vaccinations-dialog.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: environment.myDateFormat },
  ],
})
export class VaccinationsDialogComponent extends MobileDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<VaccinationsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IVaccinationsDialogData,
    responsiveUIService: ResponsiveUIService,
  ) {
    super(dialogRef, responsiveUIService);
  }
}
