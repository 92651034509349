import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { environment } from 'src/environments/environment';
import { Moment } from 'moment';
import * as moment from 'moment';

@Component({
  selector: 'app-slaughterdate-dialog',
  templateUrl: './slaughterdate-dialog.component.html',
  styleUrls: ['./slaughterdate-dialog.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: environment.myDateFormat },
  ],
})
export class SlaughterdateDialogComponent implements OnInit {
  vkiSlaughterDate: any;
  flockStartDate: any;
  saveButtonAvailability = false;
  minDate: any;
  maxDate: any;
  @ViewChild('date', { static: false }) date = null;

  constructor(
    public dialogRef: MatDialogRef<SlaughterdateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit() {
    this.vkiSlaughterDate = this.vkiSlaughterDate ? moment(this.vkiSlaughterDate, 'DD-MM-YYYY') : null;
    this.minDate = this.flockStartDate ? moment(this.flockStartDate, 'DD-MM-YYYY') : null;
    this.maxDate = moment().add(10, 'days');
    if (this.vkiSlaughterDate) {
      this.saveButtonAvailability = true;
    }
  }

  dateInput(event) {
    if (event.keyCode == 8) {
      // this.vkiSlaughterDate = moment(null, 'DD-MM-YYYY');
      this.saveButtonAvailability = false;
      // this.date.control.reset();
    } else if (event.shiftKey) {
      return false;
    } else if (
      event.keyCode !== 189 &&
      event.keyCode !== 109 &&
      event.keyCode !== 9 &&
      event.keyCode !== 37 &&
      event.keyCode !== 39 &&
      (event.keyCode < 48 || event.keyCode > 57) &&
      (event.keyCode < 96 || event.keyCode > 105)
    ) {
      return false;
    }
  }

  setDate(value) {
    if (value && moment(this.vkiSlaughterDate).isValid()) {
      const date = value.format('DD-MM-YYYY').toString();
      this.vkiSlaughterDate = moment(date, 'DD-MM-YYYY');
      this.saveButtonAvailability = true;
    } else {
      this.vkiSlaughterDate = moment(null, 'DD-MM-YYYY');
      this.saveButtonAvailability = false;
      this.date.control.reset();
    }
  }

  saveSlaughterDate() {
    this.dialogRef.close({
      vkiSlaughterDate: this.vkiSlaughterDate,
    });
  }
}
