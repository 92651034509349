<div id="app-header" #appHeader class="header-wrapper"
  *ngIf="url !== '/logoutsuccess' && url !== '/unauthorized' && url !== '/unauthorized/maintenance'">
  <div class="header-safe-area safe-area-lr">
    <mat-toolbar class='header-container' fxLayout="row" fxLayoutAlign="space-between center">
      <div class="portal-name">
        Plukonnect
      </div>
      <div *ngIf="maintenanceMessage && url !== '/login'" class="maintenance-message">
        {{maintenanceMessage}}
      </div>
      <div *ngIf="preMaintenanceMessage" class="maintenance-message">
        {{preMaintenanceMessage}}
      </div>
      <div class="top-menu-wrapper" fxLayout="row" fxLayoutAlign="space-between center">
        <a *ngIf="!offlineMode && !url.startsWith('/login') && (notificationNumber > 0 || isAdminMyTask)" class="mail"
          (click)="openMyTasks()">
          <span *ngIf="isAdminMyTask; else commonNotification" style="display: inline-block"><i
              class="material-icons-outlined notification-icon">notifications</i></span>
          <ng-template #commonNotification>
            <span style="display: inline-block" [matBadge]=notificationNumber matBadgeOverlap="true"
              matBadgeSize="medium" matBadgeColor="warn" [matBadgeHidden]="notificationNumber < 1"><i
                class="material-icons-outlined notification-icon">notifications</i></span>
          </ng-template>
        </a>
        <span class="dropdown-wrapper" *ngIf="roleSetting?.header?.environment?.allow && url !== '/login'">
          <mat-form-field>
            <mat-select [(ngModel)]='selectedEnvironment' (selectionChange)='environmentSelection($event)'
              [disabled]="!roleSetting?.header?.environment?.editable || displayEnvironmentList.length <= 1">
              <mat-option *ngFor="let environment of displayEnvironmentList" [value]="environment.environmentId">
                {{environment.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </span>
        <div class="redirection-wrapper" fxLayout="row" fxLayoutAlign="center center">
          <span *ngIf="redirectionA" class="redirectionA" fxLayout="row" fxLayoutAlign="center center">
            <i class="material-icons globe">public</i>
            <a href="http://{{redirectionALink}}" target="_blank">{{redirectionA | translate}}</a>
          </span>
          <span *ngIf="!url.startsWith('/login') && userManualURL !== ''" class="userManual">
            <a [href]="userManualURL" target="_blank">{{'Header.DownloadManual' | translate}}</a>
          </span>
          <span *ngIf="url.startsWith('/login')" class="help" (click)="openHelpDialog()">{{'Header.Help' |
            translate}}</span>
          <span *ngIf="!offlineMode && !url.startsWith('/login')" class="contact" (click)="openContactDialog()">{{'Header.Contact' |
            translate}}</span>
          <span class="dropdown-wrapper"
            *ngIf="(url.startsWith('/login') || url.startsWith('/term-and-condition')) && !url.startsWith('/unauthorized') && !url.startsWith('/error') || isAdmin">
            <mat-form-field>
              <mat-select [(ngModel)]='language' (selectionChange)='languageSelection(language)'>
                <mat-option *ngFor="let language of availableLanguageList" [value]="language.name">
                  {{language.translateLanguage | translate}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </span>
        </div>
        <div *ngIf="url !== '/login' && url !== '/term-and-condition'" style="display: flex;">
          <div *ngIf="!offlineMode; else nameText">
            <button *ngIf="userName" mat-button #setting [matMenuTriggerFor]="menu">{{userName}}<i
                class="material-icons">keyboard_arrow_down</i></button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="routeUser()">{{'Menu.UserInfo' | translate}}</button>
              <button mat-menu-item (click)="routeSetting()">{{'Menu.Settings' | translate}}</button>
              <button mat-menu-item (click)="logout()">{{'Header.Logout' | translate}}</button>
            </mat-menu>
          </div>
          <ng-template #nameText>
            {{userName}}
          </ng-template>
        </div>
      </div>
    </mat-toolbar>
  </div>
  <div class="header-safe-area-second safe-area-lr">
    <mat-toolbar
      *ngIf="isLoggedIn && !url.startsWith('/login') && !url.startsWith('/term-and-condition') && !url.startsWith('/unauthorized')"
      class="secondary-header-wrapper" fxLayout="row" fxLayoutAlign="space-around center">
      <div class="menu-icon"
        *ngIf="!router.url.startsWith('/login') && !router.url.startsWith('/vki-checks') && !router.url.startsWith('/my-reports') && !router.url.startsWith('/news') && !router.url.startsWith('/term-and-condition') && router.url !== '/unauthorized' && !router.url.startsWith('/admin') && !router.url.startsWith('/user') && !router.url.startsWith('/my-feedback/questionnaires') && router.url !== '/' && companyList.length > 1; else noMenuIcon">
        <i class="material-icons" (click)="toggleSideNav()">menu</i>
      </div>
      <ng-template #noMenuIcon>
        <div style="padding-left: 24px; margin-right: 1.875rem;">
        </div>
      </ng-template>
      <div class='plukon-logo-wrapper'>
        <a fxLayout="row" fxLayoutAlign="center center" (click)="routeHome()">
          <img class='plukon-logo' src="/assets/images/plukon_logo_2021.png" alt="">
        </a>
      </div>
      <div class="menu-list-wrapper">
        <span [ngClass]="{'menu-selected' : router.url === '/'}"
          *ngIf="!offlineMode && url !== '/login' && url !== '/unauthorized' && url !== '/term-and-condition' && !isInternalMail"
          (click)="routeHome()">{{'Header.Home' | translate}}</span>
        <span [ngClass]="{'menu-selected' : router.url.startsWith('/my-company')}"
          *ngIf="!offlineMode && roleChecked && url !== '/login' && url !== '/unauthorized' && url !== '/term-and-condition' && (!vetenarianView || isSuperAdmin || isQualityEmployee) && !isInternalMail"
          (click)="routeMyPlukon()">{{'Header.MyCompany' | translate}}</span>
        <span style="display: inline-block" [ngClass]="{'menu-selected' : router.url.startsWith('/my-messages')}"
          *ngIf="!offlineMode && roleChecked && url !== '/login' && url !== '/unauthorized' && url !== '/term-and-condition' && (!vetenarianView || isSuperAdmin) && !isInternalMail"
          [matBadge]=documentNumber matBadgeOverlap="true" matBadgeSize="small" matBadgeColor="warn"
          [matBadgeHidden]="documentNumber === 0 || isAdmin || isSuperAdmin"
          (click)="routeCommunication()">{{'Header.Communication' | translate}}</span>
        <span [ngClass]="{'menu-selected' : router.url.startsWith('/my-flock')}"
          *ngIf="roleChecked && url !== '/login' && url !== '/unauthorized' && url !== '/term-and-condition' && (!vetenarianView || isSuperAdmin) && !isGlobal && !isInternalMail"
          (click)="routeMyFlock()">{{'Header.MyFlock' | translate}}</span>
        <span [ngClass]="{'menu-selected' : router.url.startsWith('/my-reports')}"
          *ngIf="!offlineMode && roleChecked && url !== '/login' && url !== '/unauthorized' && url !== '/term-and-condition' && ((hasPoultryOwner && !isGlobal) || isAdmin) && environmentShortName !== 'kknr-be' && environmentShortName !== 'agri-de' && !isQualityEmployee && !isBlocked && !isInternalMail"
          (click)="routeMyReports()">{{'Header.MyReports' | translate}}</span>
        <span [ngClass]="{'menu-selected' : router.url.startsWith('/vki-checks')}"
          *ngIf="!offlineMode && roleChecked && url !== '/login' && url !== '/unauthorized' && url !== '/term-and-condition' && (vetenarianView || isSuperAdmin) && !isInternalMail"
          (click)="routeVKIChecks()">{{'Header.VKIChecks' | translate}}</span>
        <span
          *ngIf="!offlineMode && roleChecked && url !== '/login' && url !== '/unauthorized' && url !== '/term-and-condition' && (isAdmin || hasPoultryOwner) && !isInternalMail"
          [ngClass]="{'menu-selected' : router.url.startsWith('/my-feedback')}"
          (click)="routeMyFeedback()">{{'Header.MyFeedback' | translate}}</span>
        <span [ngClass]="{'menu-selected' : router.url.startsWith('/news')}"
          *ngIf="!offlineMode && roleChecked && url !== '/login' && url !== '/unauthorized' && url !== '/term-and-condition' && (!vetenarianView || isSuperAdmin || isQualityEmployee) && !isInternalMail"
          (click)="routeNews()">{{'Header.News' | translate}}</span>
      </div>
      <div class='menu-wrapper' fxLayout="row" fxLayoutAlign="end center"
        *ngIf="!offlineMode && url !== '/login' && url !== '/term-and-condition' && url !== '/unauthorized' && (!vetenarianView || isSuperAdmin) && isAdmin && roleSetting?.admin?.visible">
        <mat-form-field class="administrator-wrapper">
          <mat-select placeholder='Administrator' [(ngModel)]='adminOption'
            (selectionChange)='changeAdminScreen($event)'>
            <mat-option *ngIf="roleSetting?.admin?.dashboard?.visible" value='dashboard'>
              Dashboard
            </mat-option>
            <mat-option *ngIf="roleSetting?.admin?.user?.visible" value='user'>
              User
            </mat-option>
            <mat-option *ngIf="roleSetting?.admin?.company?.visible" value='company'>
              Company
            </mat-option>
            <mat-option *ngIf="roleSetting?.admin?.environment?.visible" value='environment'>
              Environment
            </mat-option>
            <mat-option *ngIf="roleSetting?.admin?.vkichecks?.visible" value='vki-check'>
              {{'Admin.VKICheck' | translate}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </mat-toolbar>
  </div>
  <div class="mobile-header-safe-area safe-area-lr">
    <mat-toolbar class='header-container' fxLayout="column" style="flex-wrap: wrap; height: auto;">
      <div fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%;">
        <div class="plukon-wrapper" (click)="routeHome(true)">
          <img class="plukon-logo" src="/assets/icons/icon-48x48.png" alt="">
        </div>
        <div class="portal-name">
          Plukonnect
        </div>
        <div class="header-menu-icon" *ngIf="!router.url.startsWith('/login'); else noMenuIcon">
          <i class="material-icons" (click)="toggleHeaderNav(true)">menu</i>
        </div>
        <span class="mobile-dropdown-wrapper" *ngIf="router.url.startsWith('/login')">
          <mat-form-field>
            <mat-select [(ngModel)]='language' (selectionChange)='languageSelection(language)'>
              <mat-option *ngFor="let language of availableLanguageList" [value]="language.name">
                {{language.translateLanguage | translate}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </span>
        <ng-template #noMenuIcon>
          <div style="padding-left: 24px; margin-right: 1.875rem;">
          </div>
        </ng-template>
      </div>
      <div class="small-error-message small-info" fxLayout="row" fxLayoutAlign="start center"
        *ngIf="(maintenanceMessage && url !== '/login') || preMaintenanceMessage">
        <i class="material-icons info">info</i>
        <div id="maintenance-message" ng-init="truncateClass()" class="error-message truncate" #errorMessage>
          {{ maintenanceMessage && url !== '/login' ? maintenanceMessage : (preMaintenanceMessage ? preMaintenanceMessage : '') }}
        </div>
      </div>
      <div *ngIf="(maintenanceMessage && url !== '/login')" class="read-more-text" fxLayout="row" fxLayoutAlign="start center">
        <a *ngIf="truncatable && truncated" (click)="truncateText(false)">{{'Common.ShowMore' | translate}}</a>
        <a *ngIf="truncatable && !truncated" (click)="truncateText(true)">{{'Common.ShowLess' | translate}}</a>
      </div>
    </mat-toolbar>
  </div>
</div>
