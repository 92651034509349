import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription, combineLatest } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { RouterService } from 'src/app/shared/services/router.service';
import { EnvironmentService } from 'src/app/shared/services/admin/environment.service';
import { TranslationService } from 'src/app/shared/services/translation.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { AuthService } from '../../shared/services/auth/auth.service';
import { openExternalLink } from 'src/app/shared/utils/external-link.utils';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, AfterViewInit, OnDestroy {
  environmentSubscription: Subscription;
  plukonTranslationSub: Subscription;
  maintenanceSubscription: Subscription;
  languageChangeSubscription: Subscription;
  httpSubscription: Subscription;
  loggingSubscription: Subscription;
  showLoading = true;
  pictureUrl: string;
  preMaintenanceMessage: string;
  maintenanceMessage: string;
  firefoxBrowser = false;

  constructor(
    private route: ActivatedRoute,
    public translate: TranslateService,
    public routerService: RouterService,
    private environmentService: EnvironmentService,
    public snackBar: MatSnackBar,
    private translationService: TranslationService,
    private commonService: CommonService,
    private authService: AuthService,
  ) { }

  ngOnInit() {
    this.loggingSubscription = combineLatest([this.authService.isLoggingIn$, this.authService.isLoggedIn$]).subscribe(
      ([loggingIn, isLoggedIn]) => {
        this.showLoading = loggingIn || isLoggedIn;
      },
    );
    this.getMaintenanceMessage();
    const browserType = this.detectBrowserName();
    this.firefoxBrowser = browserType === 'firefox';

    if (this.route.snapshot.params['environment']) {
      this.routerService.environmentData.next(this.route.snapshot.params);
      this.getEnvironmentData(this.route.snapshot.params['environment']);
    } else {
      this.routerService.environmentData.next({
        environment: 'kknr-nl',
      });
      this.getEnvironmentData('kknr-nl');
    }

    this.languageChangeSubscription = this.translate.onLangChange.subscribe((lang) => {
      this.getMaintenanceMessage();
    });
  }

  getMaintenanceMessage() {
    this.maintenanceSubscription = this.commonService.getMaintenance().subscribe((result) => {
      this.preMaintenanceMessage = this.translate.instant('Maintenance.PreUpdateMessage');
      this.maintenanceMessage = this.translate.instant('Maintenance.UpdateMessage');
      if (result && result['response']) {
        if (result['response']['isMaintenance']) {
          this.maintenanceMessage = this.maintenanceMessage.replace('[EndDate]', result['response']['maintenanceEndDate']);
          this.preMaintenanceMessage = null;
        } else if (result['response']['isPreMaintenance']) {
          this.maintenanceMessage = null;
          this.preMaintenanceMessage = this.preMaintenanceMessage.replace('[StartDate]', result['response']['maintenanceStartDate']);
          this.preMaintenanceMessage = this.preMaintenanceMessage.replace('[EndDate]', result['response']['maintenanceEndDate']);
        } else {
          this.preMaintenanceMessage = null;
          this.maintenanceMessage = null;
        }
      } else {
        this.preMaintenanceMessage = null;
        this.maintenanceMessage = null;
      }
    });
  }

  detectBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase();

    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

  signIn() {
    this.authService.login();
  }

  async clearCache() {
    try {
      const cacheBaseUrl = environment.linkUrl.replace('#/', '');
      // console.log(`workbox-precache-v2-${cacheBaseUrl}`);
      const deletion = await window.caches.delete(`workbox-precache-v2-${cacheBaseUrl}`);
      if ('serviceWorker' in navigator) {
        // console.log('has service worker');
        navigator.serviceWorker.getRegistrations().then(async function(registrations) {
          // console.log(registrations);
          for(let registration of registrations) {
            await registration.unregister();
          }
          window.location.reload();
        });
      }
      // console.log(deletion);
    } catch (e) {
      console.log(e);
    }
  }

  ngAfterViewInit() {
    this.translate.use('en');
  }

  getEnvironmentData(environment) {
    this.environmentSubscription = this.environmentService.getUserEnvironment(environment).subscribe(
      (result) => {
        this.pictureUrl = result['response'].pictureURL;
        this.plukonTranslationSub = this.translationService.getPlukonTranslation('login').subscribe();
      },
      (error) => {
        console.log('Header (getUserEnvironment) : ', error);
        switch (error.status) {
          case 401:
            this.snackBar.open(this.translate.instant('Portal.UnauthorizedAPICall'), 'Close', { verticalPosition: 'top' });
            break;
          default:
            this.snackBar.open(this.translate.instant('ErrorMessage.DataRetrieveFail'), 'Close', {
              duration: 5000,
              verticalPosition: 'top',
            });
            break;
        }
      },
    );
  }

  openVideo() {
    const video = this.translate.instant('Login.Video');
    openExternalLink(video);
  }

  ngOnDestroy() {
    if (this.environmentSubscription) {
      this.environmentSubscription.unsubscribe();
    }
    if (this.plukonTranslationSub) {
      this.plukonTranslationSub.unsubscribe();
    }
    if (this.maintenanceSubscription) {
      this.maintenanceSubscription.unsubscribe();
    }
    if (this.languageChangeSubscription) {
      this.languageChangeSubscription.unsubscribe();
    }
    if (this.httpSubscription) {
      this.httpSubscription.unsubscribe();
    }
    if (this.loggingSubscription) {
      this.loggingSubscription.unsubscribe();
    }
  }
}
