<div class="term-of-use-container" fxLayout="column" fxLayoutAlign="center center">
  <div class="term-of-use-wrapper">
    <ng-container>
      <div [innerHTML]="sanitizer.bypassSecurityTrustHtml('TermsOfUse.FullHtml' | translate: {internalLinkText: internalLinkText,
        termOfUseLinkText: termOfUseLinkText, privacyLinkText: privacyLinkText, cookieStatementLinkText: cookieStatementLinkText})"></div>
    </ng-container>
    <!-- <ng-container *ngIf="selectedLang == 'en'">
        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
        <br>
        <br>
        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
    </ng-container>
    <ng-container *ngIf="selectedLang == 'du'">
        Lorem Ipsum is gewoon dummy tekst van de drukkerij- en zetbranche.
        <br>
        <br>
        Lorem Ipsum is de standaard dummy-tekst van de industrie sinds de jaren 1500, toen een onbekende printer een kombuis van het type in beslag nam en het tot een soort exemplaarboek maakte. Het heeft niet alleen vijf eeuwen overleefd, maar ook de sprong naar elektronisch zetwerk, en bleef in wezen onveranderd. Het werd gepopulariseerd in de jaren zestig met de uitgave van Letraset-bladen met Lorem Ipsum-passages en meer recent met desktop publishing-software zoals Aldus PageMaker inclusief versies van Lorem Ipsum.
    </ng-container> -->
    <!-- <ng-container *ngIf="selectedLang === 'German'">
      <div class="title">Nutzungsbedingungen Plukonnect </div>
      <br>
      <br>
      <div>
        <div class="sub-title">1. Definitionen</div>
        <br>
        <div>
          1.1 Die folgenden Begriffe habe die nachstehende Bedeutung:
        </div>
        <br>
        <div>
          Plukonnect: Online-Portal, das von Plukon betrieben wird und über www.plukonnect.com erreichbar ist.
        </div>
        <br>
        <div>
          Plukon: Plukon Food Group B.V. mit satzungsmäßigem Sitz in Wezep, eingetragen im niederländischen
          Handelsregister unter der Nummer 30255837 sowie deren Tochtergesellschaften und konzernrechtlich verbundene
          Unternehmen, einschließlich De KuikenaeR B.V. und Agri Geflügel GmbH.
        </div>
        <br>
        <div>
          Nutzer: Die Person, die durch eine persönliche Einladung von Plukon ein Konto auf Plukonnect angelegt hat.
        </div>
        <br>
        <div>
          Nutzungsbedingungen: Diese Nutzungsbedingungen, welche jederzeit unter <a
            [href]="termOfUseLink">{{internalLinkText}}/gebruiksvoorwaarden</a> abgerufen werden können.
        </div>
        <br>
        <div class="sub-title">2. Zweck der Plattform Plukonnect</div>
        <br>
        <div>
          <table>
            <tr>
              <td>2.1</td>
              <td>Das Ziel von Plukonnect ist es den digitalen Informationsaustausch zwischen Plukon, den
                Geflügelhaltern
                und den sonstigen Parteien in der Lieferkette zu verbessern, sowie die Etablierung einer besseren
                Rückverfolgbarkeit der gesamten Masthähnchenproduktion. Mittels Plukonnect sollen Inhalte digitalisiert
                und
                Abläufe automatisiert werden, umso den Informationsaustausch insgesamt zu beschleunigen und effizienter
                zu
                gestalten.</td>
            </tr>
          </table>
          <br>
          <table>
            <tr>
              <td>2.2</td>
              <td>Plukonnect fungiert als eine Austauschplattform für Dokumente beispielsweise von Schlachtergebnissen
                und anderen Informationen.
              </td>
            </tr>
          </table>
          <br>
          <table>
            <tr>
              <td>2.3 </td>
              <td>Informationen, die über Plukonnect ausgetauscht oder zur Verfügung gestellt werden, umfassen
                insbesondere:
              </td>
            </tr>
          </table>
          <br>
        </div>
        <div>
          <ul type="none">
            <li>a) Schlachtberichte</li>
            <li>b) Schlachtkonten</li>
            <li>c) Laborergebnisse</li>
            <li>d) Ante-mortem Herdendaten (VKI)</li>
          </ul>
        </div>
        <br>
        <div class="sub-title">3. Errichtung eines Nutzerprofils, Funktion und Nutzung von Plukonnect</div>
        <br>
        <div>
          <table>
            <tr>
              <td>3.1</td>
              <td>Die Errichtung eines Nutzerkontos ist nur aufgrund einer vorherigen Einladung per E-Mail seitens
                Plukon
                möglich. Der Nutzer kann dann ein persönliches Konto erstellen, das Zugriff auf Plukonnect ermöglicht.
              </td>
            </tr>
          </table>
          <br>
          <table>
            <tr>
              <td>3.2</td>
              <td>
                Jedes Nutzerkonto verfügt über ein eigenes Profil mit zugehörigen Funktionalitäten, welche je nach
                Position und Aufgaben des Nutzers unterschiedlich ausgestaltet sind. Das Konto ermöglicht dem Nutzer
                Informationen mit Plukon und anderen Parteien in der Lieferkette auszutauschen und die von Plukon dem
                Nutzer zur Verfügung gestellten Informationen einzusehen.
              </td>
            </tr>
          </table>
          <br>
          <table>
            <tr>
              <td>3.3</td>
              <td>
                Die Nutzung von Plukonnect ist für den Nutzer kostenfrei. Etwaige Kosten, die notwendig sind, damit der
                Nutzer auf Plukonnect zugreifen und Plukonnect nutzen kann, wie etwa Bereitstellung von Computern,
                sonstige IT
                Infrastruktur oder Internetzugangskosten hat der Nutzer selbst zu tragen. Die ordnungsgemäße Nutzung des
                Portals sowie dessen Vervollständigung durch Ergänzung, Änderung oder Unterzeichnung der über Plukonnect
                zur
                Verfügung gestellten Dokumente obliegt der Verantwortung des Nutzers. Der Nutzer gewährleistet, dass er
                keine
                unrichtigen Informationen über Plukonnect übermittelt oder etwaige Unrichtigkeiten unverzüglich
                korrigiert
                werden.
              </td>
            </tr>
          </table>
          <br>
          <table>
            <tr>
              <td>3.4</td>
              <td>
                Bei technischen Fragen, Störungen oder Problemen bei der Nutzung des Portals kann sich der Nutzer über
                die
                Hilfefunktion von Plukonnect direkt an Plukon wenden. In allen weiteren Fällen stehen dem Nutzer seine
                jeweiligen Ansprechpartner bei De KuikenaeR oder Agri Geflügel zur Verfügung.
              </td>
            </tr>
          </table>
        </div>
        <br>
        <div class="sub-title">4. Pflichten des Nutzers und Vertraulichkeitsregelung</div>
        <br>
        <div>
          <table>
            <tr>
              <td>4.1</td>
              <td>Alle von Plukon über Plukonnect zur Verfügung gestellten Informationen sind streng vertraulich und
                dürfen
                nur für die Zwecke verwendet werden, für die sie zur Verfügung gestellt wurden. Der Nutzer verpflichtet
                sich
                zur Geheimhaltung und Sicherstellung der Geheimhaltung solcher Informationen. Eine Weitergabe dieser
                Informationen an unbefugte Dritte durch den Nutzer ist untersagt. Soweit der Nutzer über Plukonnect
                bereitgestellte Informationen herunterlädt, wird der Nutzer solche Informationen in angemessenen Umfang
                gegen
                den Zugriff durch unbefugte Dritte schützen.
              </td>
            </tr>
          </table>
          <br>
          <table>
            <tr>
              <td>4.2</td>
              <td>
                Der Nutzer ist für die Geheimhaltung und Sicherheit seiner Zugangsdaten zu Plukonnect verantwortlich.
              </td>
            </tr>
          </table>
          <br>
          <table>
            <tr>
              <td>4.3</td>
              <td>
                Sofern es für den internen Arbeitsablauf des Nutzers notwendig ist, dürfen die bereitgestellten
                Information ausnahmsweise an Mitarbeiter, Berater, Buchhalter übermittelt werden. Der Nutzer ist in
                diesem
                Fall ebenfalls verpflichtet, die Vertraulichkeit der bereitgestellten Informationen zu gewährleisten.
                Gleiches
                gilt auch in Fällen der Weitergabe an berechtigte Dritte, welche ebenfalls durch den Nutzer Personen zur
                Vertraulichkeit zu verpflichten sind.
              </td>
            </tr>
          </table>
          <br>
          <table>
            <tr>
              <td>4.4</td>
              <td>
                Die Pflicht zur Geheimhaltung besteht nicht für solche Informationen, die nachweislich
              </td>
            </tr>
          </table>
          <br>
          <table class="tab">
            <tr>
              <td>a)</td>
              <td>
                dem Nutzer vor der Mitteilung bereits bekannt waren, oder
              </td>
            </tr>
            <tr>
              <td>b)</td>
              <td>
                der Öffentlichkeit vor der Mitteilung bekannt oder allgemein zugänglich waren, oder
              </td>
            </tr>
            <tr>
              <td>c)</td>
              <td>
                der Öffentlichkeit nach der Mitteilung ohne Mitwirkung oder Verschulden des empfangenden
                Vertragspartners bekannt oder allgemein zugänglich wurden, oder
              </td>
            </tr>
            <tr>
              <td>d)</td>
              <td>
                im Wesentlichen Informationen entsprechen, die dem Nutzer zu irgendeinem Zeitpunkt von einem
                berechtigten Dritten offenbart oder zugänglich gemacht wurden, oder
              </td>
            </tr>
            <tr>
              <td>e)</td>
              <td>
                der Nutzer unabhängig von der Kenntnis der fraglichen Information selbständig entwickelt hat oder hat
                entwickeln lassen.
              </td>
            </tr>
          </table>
          <br>
          <table>
            <tr>
              <td>4.5</td>
              <td>
                Gibt der Nutzer unrechtmäßig vertrauliche Informationen an Dritte weiter oder verschaffen sich Dritte
                unbefugt Zugang, ist der Nutzer verpflichtet Plukon unverzüglich, spätestens jedoch innerhalb von 24
                Stunden
                nach Feststellung der Weitergabe der Daten oder des unberechtigten Zugangs, hierüber zu informieren. Der
                Nutzer stellt Plukon schadlos und haftet Plukon gegenüber für alle Schäden, die sich aus einer
                schuldhaften
                Verletzung der vorstehenden Pflicht ergeben.
              </td>
            </tr>
          </table>
          <br>
          <table>
            <tr>
              <td>4.6</td>
              <td>
                Besteht der begründete Verdacht, dass der Nutzer unrechtmäßig vertrauliche Informationen an Dritte
                weitergibt, weitergegeben hat, oder dass andere unberechtigte Personen das persönliche Konto des Nutzers
                nutzen, behält sich Plukon vor, dem Nutzer den Zugang zu Plukonnect vorübergehend oder dauerhaft zu
                verweigern
                und/oder nach fruchtlosem Ablauf einer Frist zur Beseitigung von 7 Tagen das Konto dauerhaft zu löschen.
                Plukon wird den Nutzer über die Accountsperrung sowie die Möglichkeit der Löschung per E-Mail
                informieren.
                Plukon haftet nicht für Schäden, die dem Nutzer dadurch entstehen, dass ihm der Zugang zu Plukonnect
                aufgrund
                der zuvor genannten Gründe verweigert und/oder das Konto gelöscht wird.
              </td>
            </tr>
          </table>
        </div>
        <br>
        <div class="sub-title">5. Rechte am geistigem Eigentum</div>
        <br>
        <div>
          <table>
            <tr>
              <td>5.1</td>
              <td>
                Alle über Plukonnect von Plukon zur Verfügung gestellten Inhalte, wie z.B. Texte, Bilder, Logos, Designs
                oder anderes, sind und verbleiben im Eigentum von Plukon. Die Nutzung oder Verbreitung dieser Inhalte an
                Dritte außerhalb von Plukonnect ist ohne die ausdrückliche vorherige schriftliche Zustimmung von Plukon
                nicht
                gestattet.
              </td>
            </tr>
          </table>
        </div>
        <br>
        <div class="sub-title">6. Datenschutz und Cookies</div>
        <br>
        <div>
          <table>
            <tr>
              <td>6.1</td>
              <td>
                Plukon verarbeitet personenbezogene Daten der Nutzer, um die ordnungsgemäße Funktion von Plukonnect zu
                gewährleisten. Genauere Informationen darüber, welche personenbezogenen Daten verarbeitet werden, wie
                Plukon
                mit diesen personenbezogenen Daten umgeht und welche Rechte dem Nutzer in Bezug auf diese Daten
                zustehen,
                können der Datenschutzerklärung von Plukon entnommen werden. Diese ist unter <a
                  [href]="privacyLink">{{internalLinkText}}/privacyverklaring</a> abrufbar.
              </td>
            </tr>
          </table>
          <br>
          <table>
            <tr>
              <td>6.2</td>
              <td>
                Plukon kann personenbezogene Daten auch durch die Verwendung von Cookies erfassen. Weitere Informationen
                dazu finden Sie auf der Plukon-Cookie-Richtlinie, welche unter <a
                  [href]="cookieStatementLink">{{internalLinkText}}/cookieverklaring</a> abgerufen werden kann.
              </td>
            </tr>
          </table>
        </div>
        <br>
        <div class="sub-title">7. Wartung und Änderung von Plukonnect</div>
        <br>
        <div>
          <table>
            <tr>
              <td>7.1</td>
              <td>Plukon behält sich vor jederzeit - angekündigt oder unangekündigt - Wartung an Plukonnect
                durchzuführen.
                Für den Zeitraum der Wartungsarbeiten kann es zu eingeschränkter Nutzbarkeit oder vorübergehender
                Abschaltung
                von Plukonnect kommen. Soweit technisch möglich kündigt Plukon Wartungsarbeiten im Vorfeld per E-Mail
                und über
                Plukonnect an und führt solche Wartungsarbeiten außerhalb der üblichen Bürozeiten durch.
              </td>
            </tr>
          </table>
          <br>
          <table>
            <tr>
              <td>7.2</td>
              <td>
                Plukon haftet nicht für Schäden, die durch die vorübergehende Unzugänglichkeit von Plukonnect, aufgrund
                der
                Erfüllung der vertraglichen Wartungs- und Bereitstellungspflicht durch Plukon, entstehen.
              </td>
            </tr>
          </table>
          <br>
          <table>
            <tr>
              <td>7.3</td>
              <td>
                Plukon behält sich vor, das Design und die Funktionalitäten von Plukonnect zu ändern, zu erweitern oder
                einzustellen
              </td>
            </tr>
          </table>
        </div>
        <br>
        <div class="sub-title">8. Verhaltensregeln (sog. „Netiquette“)</div>
        <br>
        <div>
          <table>
            <tr>
              <td>8.1</td>
              <td>
                Der Nutzer hat von Handlungen abzusehen, die nicht dem Zweck des Portals entsprechen. Darüber hinaus ist
                der Nutzer verpflichtet, bei der Nutzung von Plukonnect die üblichen Verhaltensweisen und Regeln
                einzuhalten,
                wie sie normalerweise im Internet gelten.
              </td>
            </tr>
          </table>
          <br>
          <table>
            <tr>
              <td>8.2</td>
              <td>
                Hierzu zählen insbesondere
              </td>
            </tr>
          </table>
          <br>
          <table class="tab">
            <tr>
              <td>a)</td>
              <td>
                Höflichkeit, d.h. ein Unterlassen von Beleidigungen irgendwelcher Art
              </td>
            </tr>
            <tr>
              <td>b)</td>
              <td>
                Vermeidung von Missverständnisse
              </td>
            </tr>
            <tr>
              <td>c)</td>
              <td>
                Beachtung der Lesbarkeit
              </td>
            </tr>
            <tr>
              <td>d)</td>
              <td>
                Dokumente und Informationen Dritter nicht ohne deren Zustimmung zu verbreiten
              </td>
            </tr>
          </table>
          <br>
          <table>
            <tr>
              <td>8.3</td>
              <td>
                Die Nutzung des persönlichen Konto ist ausschließlich dem ursprünglich eingeladenen Nutzer vorbehalten.
                Eine Weitergabe der Zugangsdaten an Dritte ist untersagt. Bei Verdacht auf Missbrauch behält sich Plukon
                das
                Recht vor den Zugang des Nutzer vorübergehend oder dauerhaft zu sperren und im Falle einer wiederholten
                Weitergabe der Daten das Konto zu löschen. Plukon haftet nicht für etwaige Schäden, die durch die
                Verweigerung
                des Zugangs zu Plukonnect und/oder die Löschung eines Kontos nach diesem 8.3 entstehen.
              </td>
            </tr>
          </table>
        </div>
        <br>
        <div class="sub-title">9. Haftung</div>
        <br>
        <div>
          <table>
            <tr>
              <td>9.1</td>
              <td>
                Die Nutzung des Portals sowie die Bereitstellung, Vervollständigung, Anpassung, sowie Unterzeichnung der
                über Plukonnect zur Verfügung gestellten Dokumente obliegt dem Nutzer.
              </td>
            </tr>
          </table>
          <br>
          <table>
            <tr>
              <td>9.2</td>
              <td>
                Plukon haftet unbeschränkt bei Vorsatz oder grober Fahrlässigkeit, sowie für die Verletzung von Leben,
                Leib oder Gesundheit und nach gesetzlichen Vorschriften wie dem Produkthaftungsgesetz, die nicht
                abbedungen
                werden können. Bei leicht fahrlässiger Verletzung einer Pflicht, die wesentlich für die Nutzung von
                Plukonnect
                ist, ist die Haftung von Plukon der Höhe nach begrenzt auf den Schaden, der nach der Art des fraglichen
                Geschäfts vorhersehbar und typisch ist.
              </td>
            </tr>
          </table>
          <br>
          <table>
            <tr>
              <td>9.3</td>
              <td>
                Eine weitergehende Haftung von Plukon besteht nicht.
              </td>
            </tr>
          </table>
          <br>
          <table>
            <tr>
              <td>9.4</td>
              <td>
                Die vorstehende Haftungsbeschränkung gilt auch für die persönliche Haftung der Mitarbeiter, Vertreter
                und
                Organe von Plukon oder deren Erfüllungsgehilfen.
              </td>
            </tr>
          </table>
        </div>
        <br>
        <div class="sub-title">10. Änderungen der Nutzungsbedingungen</div>
        <br>
        <div>
          <table>
            <tr>
              <td>10.1</td>
              <td>
                Plukon behält sich das Recht vor die Nutzungsbedingungen von Zeit zu Zeit mit Wirkung für die Zukunft zu
                aktualisieren und zu ergänzen.
              </td>
            </tr>
          </table>
          <br>
          <table>
            <tr>
              <td>10.2</td>
              <td>
                Plukon übermittelt dem Nutzer gegebenenfalls per E-Mail rechtzeitig per E-Mail die geänderten
                Nutzungsbedingungen und gibt dem Nutzer die Gelegenheit, innerhalb von einem Monat nach Erhalt der
                geänderten
                Geschäftsbedingungen schriftlich oder per E-Mail Widerspruch gegen diese einzulegen. Der Nutzer
                akzeptiert die
                Änderungen, wenn er keinen Widerspruch erklärt. Plukon wird den Nutzer jeweils in der schriftlichen
                Information gesondert auf die Folgen hinweisen, die ein fehlender schriftlicher Widerspruch gegenüber
                Plukon
                während der im vorstehenden Satz genannten Frist hat.
              </td>
            </tr>
          </table>
          <br>
          <table>
            <tr>
              <td>10.3</td>
              <td>
                Änderungen treten zu dem Zeitpunkt in Kraft, der bei Übermittlung der geänderten Nutzungsbedingungen
                angekündigt wurde. Wurde kein Zeitpunkt des Inkrafttretens mitgeteilt, so treten die Änderungen
                gegenüber dem
                Nutzer in Kraft, nachdem er über die Änderung in Kenntnis gesetzt wurde und die oben erwähnte Frist von
                einem
                Monat abgelaufen ist, ohne dass der Nutzer Widerspruch gegen die Änderung eingelegt hat.
              </td>
            </tr>
          </table>
          <br>
          <table>
            <tr>
              <td>10.4</td>
              <td>
                Wenn der Nutzer mit den Änderungen nicht einverstanden ist, kann dieser vor dem vorgeschlagenen
                Zeitpunkt
                des Wirksamwerdens der Änderung fristlos und kostenfrei kündigen. Legt der Nutzer innerhalb der
                genannten
                Frist Widerspruch gegen die geänderten Geschäftsbedingungen ein, hat Plukon das Recht, den zu diesem
                Zeitpunkt
                mit dem Nutzer bestehenden Vertrag mit angemessener Frist mindestens jedoch 3 Monaten zu beenden.
              </td>
            </tr>
          </table>
          <br>
          <table>
            <tr>
              <td>10.5</td>
              <td>
                Die aktuelle Version der Nutzungsbedingungen ist jederzeit unter <a
                  [href]="termOfUseLink">{{internalLinkText}}/gebruiksvoorwaarden</a> einsehbar.
              </td>
            </tr>
          </table>
        </div>
        <br>
        <div class="sub-title">11. Salvatorische Klausel</div>
        <br>
        <div>
          <table>
            <tr>
              <td>11.1</td>
              <td>
                Sollten einzelne Bestimmungen dieser Nutzungsvereinbarungen ganz oder teilweise unwirksam oder
                undurchführbar sein oder werden, so berührt dies nicht die Wirksamkeit der übrigen Bestimmungen dieser
                Nutzungsbedingungen. Für diesen Fall verpflichten sich die Parteien, unter Berücksichtigung des
                Grundsatzes
                von Treu und Glauben an Stelle der unwirksamen Bestimmung eine wirksame Bestimmung zu vereinbaren,
                welche dem
                Sinn und Zweck der unwirksamen Bestimmung möglichst nahe kommt und von der anzunehmen ist, dass die
                Parteien
                sie im Zeitpunkt des Vertragsschlusses vereinbart hätten, wenn sie die Unwirksamkeit oder Nichtigkeit
                gekannt
                oder vorhergesehen hätten.
              </td>
            </tr>
          </table>
        </div>
        <br>
        <div class="sub-title">12. Anwendbares Recht</div>
        <br>
        <div>
          <table>
            <tr>
              <td>12.1</td>
              <td>
                Für diese Bedingungen gilt ausschließlich deutsches Recht, mit der Maßgabe, dass das Übereinkommen der
                Vereinten Nationen über Verträge über den internationalen Warenkauf vom 11. April 1980 (CISG) nicht
                gilt.
              </td>
            </tr>
          </table>
          <br>
          <table>
            <tr>
              <td>12.2</td>
              <td>
                Ist der Lieferant Kaufmann, eine juristische Person des öffentlichen Rechts oder ein
                öffentlich-rechtliches Sondervermögen oder hat er in Deutschland keinen allgemeinen Gerichtsstand, so
                ist
                Gerichtsstand für alle etwaigen Streitigkeiten zwischen den Parteien das Landgericht Oldenburg.
              </td>
            </tr>
          </table>
          <br>
          <table>
            <tr>
              <td>12.3</td>
              <td>
                Ungeachtet der Bestimmungen in Artikel 12.2 hat Plukon das Recht, den Nutzer vor einem nach deutschem
                Recht oder vor einem nach geltenden internationalen Übereinkommen zuständigen Gericht zu verklagen.
              </td>
            </tr>
          </table>
          <br>
          <table>
            <tr>
              <td>12.4</td>
              <td>
                Zwingende gesetzliche Bestimmungen über ausschließliche Gerichtsstände bleiben von dieser Regelung
                jedoch unberührt.
              </td>
            </tr>
          </table>
        </div>
      </div>
    </ng-container> -->
    <!-- <ng-container *ngIf="selectedLang === 'English' || selectedLang === 'Nederlands' || selectedLang === 'Français'">
      <div style="font-size: 1rem;font-weight: bold;text-decoration: underline;text-align:center">Gebruiksvoorwaarden Plukonnect</div>
      <br>
      <br>
      <div>
        <div style="font-size: 1rem;font-weight: bold;">1. Definities</div>
        <br>
        <div>
          Plukonnect: Dit online portaal, eigendom van Plukon, bereikbaar via {{internalLinkText}}
        </div>
        <br>
        <div>
          Plukon: Plukon Food Group B.V en haar dochtervennootschappen, waaronder De KuikenaeR B.V.
        </div>
        <br>
        <div>
          Gebruiker: De persoon die door middel van een persoonlijke uitnodiging van Plukon een account
          heeft aangemaakt op Plukonnect.
        </div>
        <br>
        <div>
          Gebruiksvoorwaarden: Deze gebruiksvoorwaarden van Plukonnect, zoals ze van tijd tot tijd
          geüpdatet worden. Deze gebruiksvoorwaarden zijn terug te vinden op {{termOfUseLinkText}}.
        </div>
        <br>
        <div style="font-size: 1rem;font-weight: bold;">2. Doel</div>
        <br>
        <div>
          Het doel van Plukonnect is het bewerkstelligen van een digitale informatie-uitwisseling tussen Plukon
          (en haar dochterondernemingen), pluimveehouders en ketenpartijen. Plukon streeft ernaar de
          uitwisseling van documenten, slachtresultaten en overige informatie (zoals maar niet gelimiteerd tot
          slachtrapporten, slachtafrekeningen, laboratoriumuitslagen, prijscirculaires en ante-mortem
          koppelinformatie) waar mogelijk te digitaliseren en automatiseren door middel van Plukonnect. Dit
          zal de informatie-uitwisseling versnellen en efficiënter laten verlopen. Daarbij hoopt Plukon dat
          Plukonnect bijdraagt aan een betere track-and-tracing in de gehele vleeskuikenproductiekolom.
        </div>
        <br>
        <div style="font-size: 1rem;font-weight: bold;">3. Functionaliteiten</div>
        <br>
        <div>
          Gebruikers kunnen slechts op uitnodiging van Plukon een persoonlijk account aanmaken waarmee
          Gebruikers toegang hebben tot Plukonnect. Iedere Gebruiker heeft een persoonlijke omgeving met
          bijbehorende functionaliteiten. De toegangsrechten zijn afhankelijk van de rol en functie van de
          Gebruiker. Plukonnect biedt Gebruikers de mogelijkheid om zowel informatie te delen met Plukon en
          andere ketenpartijen, als kennis te nemen van informatie beschikbaar gesteld door Plukon.
        </div>
        <br>
        <div>
          Gebruikers maken voor eigen rekening en risico gebruik van Plukonnect. Het juist gebruiken van het
          portaal, als ook het juist invullen, aanpassen of ondertekenen van documenten beschikbaar gesteld
          via Plukonnect, is en blijft de verantwoordelijkheid van de Gebruiker. Bij (technische) vragen,
          storingen of problemen bij het gebruik van het portaal kan de Gebruiker contact opnemen met
          Plukon via de helpfunctie van Plukonnect. Voor inhoudelijke vragen met betrekking tot de informatie
          beschikbaar gesteld via Plukonnect kan de Gebruiker contact opnemen met zijn contactpersoon bij
          De KuikenaeR.
        </div>
        <br>
        <div style="font-size: 1rem;font-weight: bold;">4. Functionaliteiten</div>
        <br>
        <div>
          Alle informatie die door Plukon via Plukonnect ter beschikking wordt gesteld is strikt vertrouwelijk en
          dient alleen gebruikt worden voor het doel waarvoor deze informatie ter beschikking is gesteld.
          Gebruiker is verplicht uiterst zorgvuldig om te gaan met deze informatie. Gebruiker deelt deze
          informatie niet met derden, met uitzondering van werknemers, adviseurs, accountants e.d. van
          Gebruiker voor wie het noodzakelijk is dat zij kennisnemen van de vertrouwelijke informatie.
        </div>
        <br>
        <div>
          Gebruiker is verplicht de geheimhouding van deze vertrouwelijke informatie te waarborgen door
          dezelfde geheimhouding op te leggen aan deze personen als waartoe hij zelf verplicht is. Onder delen
          van vertrouwelijke informatie wordt ook verstaan het delen van de persoonlijke inloggegevens van
          Plukonnect of het onzorgvuldig omgaan met deze inloggegevens.
        </div>
        <br>
        <div>
          Indien Gebruiker toch vertrouwelijke informatie onrechtmatig deelt met derden, is Gebruiker
          verplicht dit zo spoedig mogelijk doch binnen vierentwintig uur na ontdekking dit te melden bij
          Plukon. Gebruiker vrijwaart Plukon en is jegens Plukon aansprakelijk voor eventuele schade
          voortvloeiend uit het onrechtmatig delen van vertrouwelijke informatie.
        </div>
        <br>
        <div>
          Indien Plukon een redelijk vermoeden heeft dat Gebruiker vertrouwelijke informatie onrechtmatig
          met derden deelt of heeft gedeeld, of als Plukon een redelijk vermoeden heeft dat andere personen
          gebruik maken van het persoonlijke account van Gebruiker, behoudt Plukon zich het recht voor om
          Gebruiker (al dan niet tijdelijk) de toegang te ontzeggen of het account te verwijderen. Plukon zal de
          Gebruiker hierover informeren. Plukon is niet aansprakelijk voor schade van Gebruiker als gevolg van
          het ontzeggen van de toegang tot Plukonnect en/of het verwijderen van het account.
        </div>
        <br>
        <div style="font-size: 1rem;font-weight: bold;">5. Intellectuele eigendomsrechten</div>
        <br>
        <div>
          Alle inhoud die via Plukonnect beschikbaar wordt gemaakt, zoals maar niet gelimiteerd tot teksten,
          afbeeldingen, logo’s, designs of anderszins, is en blijft eigendom van Plukon. Het gebruiken of
          verspreiden van deze inhoud buiten Plukonnect is expliciet niet toegestaan behoudens Plukons
          uitdrukkelijke voorafgaande schriftelijke toestemming.
        </div>
        <br>
        <div style="font-size: 1rem;font-weight: bold;">6. Privacy</div>
        <br>
        <div>
          Om alle functionaliteiten van Plukonnect goed te laten werken, verwerkt Plukon een aantal
          persoonsgegevens. Plukon doet dit zorgvuldig. In onze privacyverklaring is terug te vinden terug
          welke persoonsgegevens worden verwerkt, hoe Plukon met deze persoonsgegevens omgaat en
          welke rechten Gebruikers hebben ten aanzien van deze persoonsgegevens. De privacyverklaring is te
          vinden via {{privacyLinkText}}. Cookies kunnen deel uitmaken
          van
          de
          persoonsgegevens die worden verwerkt. Hierover vindt u meer informatie in onze cookieverklaring: {{cookieStatementLinkText}}.
        </div>
        <br>
        <div style="font-size: 1rem;font-weight: bold;">7. Onderhoud</div>
        <br>
        <div>
          Plukon behoudt zich het recht voor om – aangekondigd of onaangekondigd – onderhoud te
          verrichten aan Plukonnect, waardoor Plukonnect mogelijk traag of niet volledig werkt of tijdelijk
          offline is. Waar mogelijk kondigt Plukon onderhoud aan en laat Plukon onderhoud plaatsvinden
          buiten kantooruren. Plukon is niet aansprakelijk voor schade als gevolg van ontoegankelijkheid van
          Plukonnect.
        </div>
        <br>
        <div style="font-size: 1rem;font-weight: bold;">8. Gedragsregels</div>
        <br>
        <div>
          Gebruiker zal zich onthouden van handelingen die niet passen bij het doel van het portaal. Gebruiker
          zal zich daarbij bij het gebruik van Plukonnect houden aan gebruikelijke omgangsvormen en
          gedragsregels zoals deze normaal gelden op internet (‘netiquette’). Gebruiker zal alleen inloggen met
          zijn eigen persoonlijk account en Gebruiker zal deze accountgegevens niet delen met anderen. Indien
          Plukon een vermoeden heeft van misbruik behoudt Plukon zich het recht voor om Gebruiker (al dan
          niet tijdelijk) de toegang te ontzeggen of het account te verwijderen. Plukon is niet aansprakelijk voor
          schade als gevolg van het ontzeggen van de toegang tot Plukonnect en/of het verwijderen van een
          account.
        </div>
        <br>
        <div style="font-size: 1rem;font-weight: bold;">9. Aansprakelijkheid</div>
        <br>
        <div>
          Gebruikers maken voor eigen rekening en risico gebruik van Plukonnect. Het juist gebruiken van het
          portaal, als ook het juist aanleveren van en invullen/aanpassen/tekenen van documenten
          beschikbaar gesteld via Plukonnect, is en blijft de verantwoordelijkheid van de Gebruiker. Plukon is
          niet aansprakelijk voor enige schade voortvloeiend uit het gebruik van Plukonnect, behoudens
          schade veroorzaakt door opzet of grove schuld aan de zijde van Plukon.
        </div>
        <br>
        <div style="font-size: 1rem;font-weight: bold;">10. Wijzigingen op de gebruiksvoorwaarden</div>
        <br>
        <div>
          Plukon behoudt zich het recht voor om van tijd tot tijd deze Gebruiksvoorwaarden te updaten en aan
          te passen. Gebruiker zal hierover tijdig worden geïnformeerd. Na een wijziging van de
          Gebruiksvoorwaarden zullen de nieuwe Gebruiksvoorwaarden worden aangeboden via Plukonnect,
          waar Gebruiker de mogelijkheid heeft deze in te zien en te accepteren. De meest recente versie van
          de Gebruiksvoorwaarden zijn steeds te raadplegen via {{termOfUseLinkText}}.
        </div>
        <br>
        <div style="font-size: 1rem;font-weight: bold;">11. Salvatorische clausule</div>
        <br>
        <div>
          In het geval dat enige van deze bepalingen geheel of gedeeltelijk ongeldig of onwerkzaam is of
          wordt, zal dit de geldigheid of werkzaamheid van de overige bepalingen in deze
          Gebruiksvoorwaarden niet aantasten. Gebruiker gaat ermee akkoord dat een ongeldige of strijdige
          bepaling wordt vervangen door een geldige bepaling die het beoogde doel het beste dient. Hetzelfde
          geldt in geval van een omissie.
        </div>
        <br>
        <div style="font-size: 1rem;font-weight: bold;">12. Toepasselijk recht</div>
        <br>
        <div>
          Op deze voorwaarden is uitsluitend Nederlands recht van toepassing.
        </div>
      </div>
    </ng-container> -->
  </div>
</div>
