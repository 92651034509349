import { EventEmitter, Injectable } from '@angular/core';
import { environment } from './../../../environments/environment';
import { BehaviorSubject } from '../../../../node_modules/rxjs';
import { Https } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class MyPlukonService {
  public routerSelectedCompany: BehaviorSubject<any>;
  public selectedCompany: BehaviorSubject<any>;
  myFlockCurrentTab = '/vki-data';
  company: BehaviorSubject<any>;
  conceptLayout: BehaviorSubject<any>;
  flockInfoSaved = new EventEmitter(); //use to inform my-flock.component when basic saved successfully
  flockNewInfoFromRequest = new EventEmitter();
  selectedCurrentCompany: any;
  formStatusMap: BehaviorSubject<Map<string, string>>;
  isLinearStatus: BehaviorSubject<boolean>;
  flockLoadingStatusMap: BehaviorSubject<Map<string, string>>;
  flockLoading: BehaviorSubject<boolean>;
  accessTab: BehaviorSubject<boolean>;
  sharingTab: BehaviorSubject<boolean>;
  emailTab: BehaviorSubject<boolean>;
  isCompanyGlobal: BehaviorSubject<boolean>;
  certificateTab: BehaviorSubject<boolean>;
  companyCanDeactivate: BehaviorSubject<boolean>;

  constructor(private http: Https) {
    this.selectedCompany = new BehaviorSubject<any>(null);
    this.routerSelectedCompany = new BehaviorSubject<any>(null);
    this.company = new BehaviorSubject<any>(null);
    this.conceptLayout = new BehaviorSubject<any>(null);
    this.formStatusMap = new BehaviorSubject<any>(null);
    this.flockLoadingStatusMap = new BehaviorSubject<any>(null);
    this.flockLoading = new BehaviorSubject<boolean>(null);
    this.isLinearStatus = new BehaviorSubject<boolean>(null);
    this.accessTab = new BehaviorSubject<boolean>(true);
    this.sharingTab = new BehaviorSubject<boolean>(true);
    this.emailTab = new BehaviorSubject<boolean>(true);
    this.isCompanyGlobal = new BehaviorSubject<boolean>(true);
    this.certificateTab = new BehaviorSubject<boolean>(true);
    this.companyCanDeactivate = new BehaviorSubject<boolean>(true);
  }

  set menuCompany(value: any) {
    this.selectedCurrentCompany = value;
  }

  get menuCompany(): any {
    return this.selectedCurrentCompany;
  }

  public getDocumentSharing(companyId) {
    return this.http.get(`${environment.baseUrl}/documentsSharing/${companyId}`, '');
  }

  public getDocumentSettings(companyId) {
    return this.http.get(`${environment.baseUrl}/documentsSharing/${companyId}`, '');
  }

  public getDocumentSharingEmployee(ownerCompanyId) {
    return this.http.get(`${environment.baseUrl}/documentsUserSharing/${ownerCompanyId}`, '');
  }

  public saveDocumentSharing(payload) {
    //remove companyName
    payload.list.forEach((documentSharing) => {
      documentSharing['companyName'] = null;
    });

    return this.http.post(`${environment.baseUrl}/documentsSharing`, payload);
  }

  public saveDocumentSharingEmployee(payload) {
    return this.http.post(`${environment.baseUrl}/documentsUserSharing`, payload);
  }

  public getCompanyAccessList() {
    return this.http.get(`${environment.baseUrl}/companies/accessRight`, '');
  }

  public getAccessSettings(companyId) {
    return this.http.get(`${environment.baseUrl}/accessSettings/${companyId}`, '');
  }

  public saveAccessSettings(payload) {
    return this.http.post(`${environment.baseUrl}/accessSettings`, payload);
  }

  public checkAvailability(payload) {
    return this.http.post(`${environment.baseUrl}/accessSettings/checkAvailability`, payload);
  }

  public getConceptLayout(envId, companyId) {
    return this.http.get(`${environment.baseUrl}/concept/layout/${envId}/${companyId}`, '');
  }

  public getCertificateList(plukonId) {
    return this.http.get(`${environment.baseUrl}/plukonKras/certificate/registered/${plukonId}`, '');
  }

  public getCertificateTypeList(plukonId) {
    return this.http.get(`${environment.baseUrl}/plukonKras/certificate/${plukonId}`, '');
  }

  public uploadCertificateFile(data) {
    return this.http.upload(`${environment.baseUrl}/plukonKras/certificate/registered/tempfile`, data);
  }

  public cancelUpload(data) {
    return this.http.post(`${environment.baseUrl}/plukonKras/certificate/registered/tempfile/cancel`, data);
  }

  public saveCertificate(payload) {
    return this.http.post(`${environment.baseUrl}/plukonKras/certificate/registered`, payload);
  }
}
