import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';

import { environment } from '../../../../environments/environment';
import { Https } from '../http.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  userData: any;

  constructor(private http: Https) {
    console.log('UserService instance created...');
  }

  public getUserList(mainEnvironment) {
    const params = new HttpParams().set('sortBy', 'fullName').set('orders', 'ASC');
    if (mainEnvironment) {
      return this.http.get(`${environment.baseUrl}/users/environment/${mainEnvironment}`, params);
    } else {
      return this.http.get(`${environment.baseUrl}/users`, params);
    }
  }

  public getUserById(payload) {
    return this.http.get(`${environment.baseUrl}/users/${payload}`, '');
  }

  public createOrUpdateUser(payload, saveMode) {
    //saveMode true = save, false = update
    if (saveMode) {
      return this.http.post(`${environment.baseUrl}/users`, payload);
    } else {
      return this.http.post(`${environment.baseUrl}/users/update`, payload);
    }
  }

  public deleteUser(payload) {
    return this.http.post(`${environment.baseUrl}/users/delete/${payload}`, '');
  }

  public sendInvitation(userId) {
    const payload = { userId: userId };
    return this.http.post(`${environment.baseUrl}/users/sendEmail`, payload);
  }

  public checkEmail(payload) {
    return this.http.get(`${environment.baseUrl}/users/userDetail/${payload}`, '');
  }

  public checkUserName(payload) {
    return this.http.get(`${environment.baseUrl}/users/userDetail/${payload}`, '');
  }

  set userDate(value: any) {
    this.userData = value;
  }

  get userDate(): any {
    return this.userData;
  }

  public createDomainUser(payload) {
    return this.http.post(`${environment.baseUrl}/directories`, payload);
  }

  public resetPassword(payload) {
    return this.http.post(`${environment.baseUrl}/directories/resetPassword`, payload);
  }

  public getPreferredLocationList(environmentCode) {
    return this.http.get(`${environment.baseUrl}/environments/preferSlaughterLocation/${environmentCode}`, null);
  }

  public getVKIFilterList(environmentCode) {
    return this.http.get(`${environment.baseUrl}/plukonKras/vkiFilters/${environmentCode}`, null);
  }
}
