import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { NetworkService } from 'src/app/shared/services/network/network.service';
import { OfflineRequestsService } from 'src/app/shared/services/offline/offline-request.service';

@Component({
  selector: 'app-offline-status',
  templateUrl: './offline-status.component.html',
  styleUrls: ['./offline-status.component.scss'],
})
export class OfflineStatusComponent {
  constructor(
    public networkService: NetworkService,
    public offlineRequestService: OfflineRequestsService,
    public snackBar: MatSnackBar,
    public translate: TranslateService,
  ) {}
}
