<div class="bottom-navigation" *ngIf="hasAllowedItems">
  <div class="navigation-container">
    <div *ngIf="myFlockAllowed" fxFlex="20" class="icon-container" (click)="router.navigate(['/my-flock/vki-data'])">
      <i class="material-icons">gite</i>
      <div class="icon-text">{{'BottomNavigation.MyFlock' | translate}}</div>
    </div>
    <div *ngIf="isMyFlockLossesAllowed" fxFlex="20" class="icon-container" (click)="router.navigate(['/my-flock/stable-card'])">
      <img class="icon" src="/assets/images/icon/stable_losses.svg" alt="">
      <div class="icon-text">{{'BottomNavigation.StableCard' | translate}}</div>
    </div>
    <div *ngIf="isMyFlockTechnicalAllowed" fxFlex="20" class="icon-container" (click)="router.navigate(['/my-flock/stable-technical'])">
      <img class="icon" src="/assets/images/icon/stable_technical.svg" alt="">
      <div class="icon-text">{{'BottomNavigation.StableTechnical' | translate}}</div>
    </div>
    <div *ngIf="myFlockAllowed" fxFlex="20" class="icon-container" (click)="router.navigate(['/my-flock/vki-requests'])">
      <i class="material-icons">pending_actions</i>
      <div class="icon-text">{{'BottomNavigation.VKIRequests' | translate}}</div>
    </div>
    <div *ngIf="myMessagesAllowed" fxFlex="20" class="icon-container" (click)="router.navigate(['/my-messages/documentation'])">
      <i class="material-icons">description</i>
      <div class="icon-text">{{'BottomNavigation.MyMessages' | translate}}</div>
    </div>
  </div>
</div>
