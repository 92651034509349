import { ResponseStoreEntry } from '../responses/cached-responses.service';

const ONE_HOUR = 1000 * 60 * 60;
const ONE_DAY = ONE_HOUR * 24;

export const isFlockBasicsBodyOutdated = (cachedResponse: ResponseStoreEntry): boolean => {
  const responseBody = cachedResponse.response.body;
  const flockStartingDate = responseBody.response.startingDate
    ? new Date(responseBody.response.startingDate.split('-').reverse().join('-'))
    : undefined;
  const responseDate = new Date(cachedResponse.lastUpdated);
  let outdatedThreshold = ONE_DAY;
  // If flock is older than 29 days, threshold is 1 hour
  if (flockStartingDate && Date.now() - new Date(flockStartingDate).getTime() > ONE_DAY * 29) {
    outdatedThreshold = ONE_HOUR;
  }
  return Date.now() - responseDate.getTime() > outdatedThreshold;
};
