import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'euro',
})
export class EuroPipe implements PipeTransform {
  transform(val: any, decimalPoint: any): string {
    if (val !== null) {
      return Number(val).toLocaleString('de-DE', { minimumFractionDigits: decimalPoint, maximumFractionDigits: decimalPoint });
    } else {
      return '';
    }
  }
}
