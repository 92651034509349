import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { App, URLOpenListenerEvent } from '@capacitor/app';

@Injectable({
  providedIn: 'root',
})
export class DeepLinksService {
  constructor(
    private router: Router,
    private zone: NgZone,
  ) {}

  public initialize() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        const slug = event.url.split('#').pop();
        if (slug) {
          this.router.navigateByUrl(`${slug}`);
        }
      });
    });
  }
}
