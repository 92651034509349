import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { Https } from '../http.service';

@Injectable({
  providedIn: 'root',
})
export class EmailNotificationService {
  constructor(private http: Https) {}

  public getSetting() {
    return this.http.get(`${environment.baseUrl}/users/userSettings`, '');
  }

  public updateSetting(payload) {
    return this.http.post(`${environment.baseUrl}/users/userSettings/update`, payload);
  }
}
