<div [formGroup]="data.stableTechnicalForm">
  <div formArrayName="stableTechnicalRows">
    <div class="stable-card-technical-dialog-cont" [formGroupName]="data.index">
      <div class="close-mark" (click)="closeDialog()"></div>
      <div class="form-field-cont no-input">
        <div class="cell wide">
          <label>{{ 'StableTechnical.Date' | translate }}</label>
          <span class="value">{{ data.displayStableData.date}}</span>
        </div>
        <div class="cell wide">
          <label>{{ 'StableTechnical.Age' | translate }}</label>
          <span class="value">{{ data.displayStableData.age}}</span>
        </div>
      </div>
      <div class="form-field-cont column">
        <div *ngIf="data.columns.maxTempAvailable"
             class="cell wide">
          <label>{{ 'StableTechnical.TemperatureHeader' | translate }}</label>
          <div class="target-cont max">
            <label class="target-label">{{ 'StableTechnical.TempTarget' | translate }}</label>
            {{ data.displayStableData.tempTarget || '-'}}
          </div>
        </div>
        <div class="row-field">
          <div *ngIf="!data.columns.maxTempAvailable"
               class="cell wide">
            <label>{{ 'StableTechnical.TemperatureHeader' | translate }}</label>
            <div class="target-cont">
              <label class="target-label">{{ 'StableTechnical.TempTarget' | translate }}</label>
              {{ data.displayStableData.tempTarget || '-'}}
            </div>
          </div>
          <div class="cell wide">
            <mat-form-field
              class="stable-input"
              [ngClass]="{'small-error-number-form': !data.checkDecimalValidity(data.index, 'tempMin')}"
            >
              <input
                matInput
                type="text"
                inputmode="decimal"
                [placeholder]="'StableTechnical.TempMin' | translate"
                [value]="data.stableTechnicalFormArrayEl.get('tempMin').value == null ? '' : data.stableTechnicalFormArrayEl.get('tempMin').value.toString() | replacePeriod"
                formControlName="tempMin"
                (keydown)="data.decimalInput($event, data.index, 'tempMin')"
                (keyup)="data.checkInvalidClass()"
                [readonly]="readonly"
              >
            </mat-form-field>
          </div>
          <div *ngIf="data.columns.maxTempAvailable"
               class="cell wide">
            <mat-form-field
              class="stable-input"
              [ngClass]="{'small-error-number-form': !data.checkDecimalValidity(data.index, 'tempMax')}"
            >
              <input
                matInput
                type="text"
                inputmode="decimal"
                [placeholder]="'StableTechnical.TempMax' | translate"
                [value]="data.stableTechnicalFormArrayEl.get('tempMax').value == null ? '' : data.stableTechnicalFormArrayEl.get('tempMax').value.toString() | replacePeriod"
                formControlName="tempMax"
                (keydown)="data.decimalInput($event, data.index, 'tempMax')"
                (keyup)="data.checkInvalidClass()"
                [readonly]="readonly"
              >
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="form-field-cont">
        <div class="cell wide">
          <label>{{ 'StableTechnical.HumidTarget' | translate }}</label>
          <span class="value no-input">{{ data.displayStableData.humidTarget || '-'}}</span>
        </div>
      </div>
      <div *ngIf="data.columns.waterMeterAvailable"
        class="form-field-cont">
        <div class="cell">
          <label>{{ 'StableTechnical.WaterMeter' | translate }}</label>
          <mat-form-field
            [ngClass]="{'small-error-number-form': data.stableTechnicalFormArrayEl.get('waterMeter').errors}"
            class="stable-input">
            <input
              inputmode="decimal"
              matInput
              [maxLength]="data.waterMeterLength"
              formControlName="waterMeter"
              (keydown)="data.numberInput($event)"
              (focusin)="data.removeSeparation(data.index, 'waterMeter')"
              (focusout)="data.setSeparation(data.index, 'waterMeter')"
              [readonly]="readonly">
          </mat-form-field>
        </div>
      </div>
      <div class="form-field-cont">
        <div class="cell wide">
          <label>{{ 'StableTechnical.WaterHeader' | translate }}</label>
          <div class="target-cont">
            <label class="target-label">{{ 'StableTechnical.WaterTarget' | translate }}</label>
            {{ data.displayStableData.waterTarget || '-'}}
          </div>
        </div>
        <div class="cell wide">
          <mat-form-field
            [ngClass]="{'small-error-number-form': data.stableTechnicalFormArrayEl.get('waterActual').errors}"
            class="stable-input">
            <input
              inputmode="decimal"
              matInput
              maxlength="3"
              [placeholder]="'StableTechnical.WaterActual' | translate"
              formControlName="waterActual"
              (keydown)="data.numberInput($event)"
              (input)="data.updateWaterFeedRatio(data.index, 'waterActual')"
              [readonly]="readonly"
            >
          </mat-form-field>
        </div>
      </div>
      <div class="form-field-cont">
        <div class="cell wide">
          <label>{{ 'StableTechnical.FeedUsageHeader' | translate }}</label>
          <div class="target-cont">
            <label class="target-label">{{ 'StableTechnical.FeedTarget' | translate }}</label>
            {{ data.displayStableData.feedTarget || '-'}}
          </div>
        </div>
        <div class="cell wide">
          <mat-form-field
            [ngClass]="{'small-error-number-form': data.stableTechnicalFormArrayEl.get('feedActual').errors}"
            class="stable-input">
            <input
              inputmode="decimal"
              matInput
              maxlength="3"
              [placeholder]="'StableTechnical.FeedActual' | translate"
              formControlName="feedActual"
              (keydown)="data.numberInput($event)"
              (input)="data.updateWaterFeedRatio(data.index, 'feedActual')"
              [readonly]="readonly">
          </mat-form-field>
        </div>
      </div>
      <div class="form-field-cont">
        <div class="cell">
          <label>{{ 'StableTechnical.WheatPerc' | translate }}</label>
          <mat-form-field
            [ngClass]="{'small-error-number-form': data.stableTechnicalFormArrayEl.get('wheatPerc').errors}"
            class="stable-input">
            <input
              inputmode="decimal"
              matInput
              maxlength="3"
              formControlName="wheatPerc"
              (keydown)="data.numberInput($event)"
              (input)="data.numberChange(data.index, 'wheatPerc')"
              [readonly]="readonly">
          </mat-form-field>
        </div>
      </div>
      <div class="form-field-cont">
        <div class="cell wide">
          <label>{{ 'StableTechnical.FeedCummulativeHeader' | translate }}</label>
          <div class="target-cont">
            <label class="target-label">{{ 'StableTechnical.FeedTarget' | translate }}</label>
            {{ data.displayStableData.feedCumulativeTarget || '-'}}
          </div>
        </div>
        <div class="cell wide">
          <mat-form-field
            [ngClass]="{'small-error-number-form': data.stableTechnicalFormArrayEl.get('feedCumulativeActual').errors}"
            class="stable-input">
            <input
              inputmode="decimal"
              matInput
              [placeholder]="'StableTechnical.FeedCumulativeActual' | translate"
              [maxLength]="data.cumulative.feedCumulativeActualLength"
              formControlName="feedCumulativeActual"
              (input)="data.cumulative.updateFeedCumulativeActual(data.index+1)"
              (keydown)="data.numberInput($event)"
              (focusin)="data.removeSeparation(data.index, 'feedCumulativeActual')"
              (focusout)="data.setSeparation(data.index, 'feedCumulativeActual')"
              [readonly]="readonly">
          </mat-form-field>
        </div>
      </div>
      <div class="form-field-cont">
        <div class="cell">
          <label>{{ 'StableTechnical.WaterFeedRatio' | translate }}</label>
          <mat-form-field
            class="stable-input"
            [ngClass]="{'small-error-number-form': !data.checkDecimalValidity(data.index, 'waterFeedRatio')}"
          >
            <input
              matInput
              type="text"
              inputmode="decimal"
              [value]="data.stableTechnicalFormArrayEl.get('waterFeedRatio').value == null ? '' : data.stableTechnicalFormArrayEl.get('waterFeedRatio').value.toString() | replacePeriod"
              formControlName="waterFeedRatio"
              (keydown)="data.decimalInput($event, data.index, 'waterFeedRatio')"
              (keyup)="data.checkInvalidClass()"
              [readonly]="readonly">
          </mat-form-field>
        </div>
      </div>
      <div class="form-field-cont">
        <div class="cell wide">
          <label>{{ 'StableTechnical.BroilerweightHeader' | translate }}</label>
          <div class="target-cont">
            <label class="target-label">{{ 'StableTechnical.FeedTarget' | translate }}</label>
            {{ data.displayStableData.weightTarget || '-'}}
          </div>
        </div>
        <div class="cell wide">
          <mat-form-field
            [ngClass]="{'small-error-number-form': data.stableTechnicalFormArrayEl.get('weightActual').errors}"
            class="stable-input">
            <input
              inputmode="decimal"
              matInput
              [placeholder]="'StableTechnical.FeedActual' | translate"
              [maxLength]="data.weight.weightActualLength"
              formControlName="weightActual"
              (keydown)="data.numberInput($event)"
              (input)="data.weight.updateWeightActual(data.index)"
              (focusin)="data.removeSeparation(data.index, 'weightActual')"
              (focusout)="data.setSeparation(data.index, 'weightActual')"
              [readonly]="readonly">
          </mat-form-field>
        </div>
      </div>
      <div class="form-field-cont">
        <div class="cell wide">
          <label>{{ 'StableTechnical.BroilerGainHeader' | translate }}</label>
          <div class="target-cont">
            <label class="target-label">{{ 'StableTechnical.FeedTarget' | translate }}</label>
            {{ data.displayStableData.weightGainTarget || '-'}}
          </div>
        </div>
        <div class="cell wide">
          <mat-form-field
            [ngClass]="{'small-error-number-form': data.stableTechnicalFormArrayEl.get('weightGainActual').errors}"
            class="stable-input">
            <input
              inputmode="decimal"
              matInput
              [placeholder]="'StableTechnical.FeedActual' | translate"
              [maxLength]="data.weight.weightGainActualLength"
              formControlName="weightGainActual"
              [value]="data.stableTechnicalFormArrayEl.get('weightGainActual').value"
              (keydown)="data.numberInput($event)"
              (focusin)="data.removeSeparation(data.index, 'weightGainActual')"
              (focusout)="data.setSeparation(data.index, 'weightGainActual')"
              [readonly]="readonly">
          </mat-form-field>
        </div>
      </div>
      <div class="form-field-cont">
        <div class="cell">
          <label>{{ 'StableTechnical.EmergencyCheck' | translate }}</label>
          <mat-form-field class="stable-input" [ngClass]="{'small-error-number-form': !data.time.checkTimeValidity(data.index, 'emergencyCheck')}" >
            <input
              inputmode="numeric"
              matInput
              [value]="data.stableTechnicalFormArrayEl.get('emergencyCheck').value === 'null' ? '' : data.stableTechnicalFormArrayEl.get('emergencyCheck').value | time" formControlName="emergencyCheck"
              (keydown)="data.time.timeInput($event, data.index, 'emergencyCheck')"
              (keyup)="data.checkInvalidClass()"
              (input)="data.numberChange(data.index, 'emergencyCheck', true)"
              [readonly]="readonly">
          </mat-form-field>
        </div>
      </div>
      <div class="action-buttons-cont">
        <div class="close-btn">
          <button class="button-green" mat-raised-button (click)="closeDialog()" >
            {{'VKIMobile.Close' | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
