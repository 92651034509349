import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import { SocialAuthService } from 'src/app/shared/services/social-auth.service';
import { UserService } from '../../shared/services/admin/user.service';
import { CodeService } from 'src/app/shared/services/code.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslationService } from 'src/app/shared/services/translation.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent implements OnInit, OnDestroy {
  domainSubscription: Subscription;
  salutationSubscription: Subscription;
  plukonTranslationSub: Subscription;
  showSpinner: boolean;
  fullName: string;
  userName: string;
  domain: string;
  accountName: string;
  displayLanguage: string;
  salutation: string;
  email: string;

  domainList = [];
  salutationList = [];
  displayLanguageList = [];

  // userForm = new FormGroup ({
  //   firstNameFormControl : new FormControl(''),
  //   middleNameFormControl : new FormControl(''),
  //   lastNameFormControl : new FormControl(''),
  //   userNameFormControl : new FormControl(''),
  //   domainNameFormControl : new FormControl(''),
  //   displayLanguageFormControl: new FormControl(''),
  //   salutationFormControl: new FormControl(''),
  //   emailFormControl: new FormControl('')
  // });

  constructor(
    private socialService: SocialAuthService,
    private userService: UserService,
    private codeService: CodeService,
    public translate: TranslateService,
    public snackBar: MatSnackBar,
    private router: Router,
    private translationService: TranslationService,
  ) {}

  ngOnInit() {
    this.socialService.getMyProfile().subscribe(
      (result) => {
        if (result) {
          if (result['response'].tncAgree == false || result['response'].tncAgree == null) {
            this.router.navigate(['/term-and-condition']);
          } else {
            this.plukonTranslationSub = this.translationService.getPlukonTranslation(null).subscribe();
          }
        }
      },
      (error) => {
        if (error.status == '401') {
          this.snackBar.open(this.translate.instant('Portal.UnauthorizedAPICall'), 'Close', { verticalPosition: 'top' });
          return false;
        }
        if (error.status == '404') {
          this.router.navigate(['/login']);
          return false;
        }
      },
    );

    // this.userForm.disable();
    // this.getDomainList();
    // this.getSalutationList();
    if (this.userService.userData) {
      this.fullName = this.userService.userData.fullName;
      this.userName = this.userService.userData.userName;
      this.domain = this.userService.userData.domain;
      this.accountName = this.userName + this.domain;
      this.displayLanguage = this.userService.userData.displayLanguage;
      this.salutation = this.userService.userData.salutation;
      this.email = this.userService.userData.email;
    } else {
      this.showSpinner = true;
      this.socialService.getMyProfile().subscribe(
        (result) => {
          this.fullName = result['response'].fullName ? result['response'].fullName : '';
          this.userName = result['response'].userName ? result['response'].userName : '';
          this.domain = result['response'].domainObj ? result['response'].domainObj.name : '';
          this.accountName = this.userName + this.domain;
          this.displayLanguage = result['response'].displayLanguageObj
            ? `${result['response'].displayLanguageObj.codeTable}.${result['response'].displayLanguageObj.name}`
            : null;
          this.salutation = result['response'].salutationObj
            ? `${result['response'].salutationObj.codeTable}.${result['response'].salutationObj.name}`
            : null;
          this.email = result['response'].email ? (this.email = result['response'].email) : '';
          this.userService.userData = {
            fullName: this.fullName,
            userName: this.userName,
            domain: this.domain,
            displayLanguage: this.displayLanguage,
            salutation: this.salutation,
            email: this.email,
          };
          this.showSpinner = false;
        },
        (error) => {
          console.log('Plukon User (getMyProfile) : ', error);
          switch (error.status) {
            case 401:
              this.snackBar.open(this.translate.instant('Portal.UnauthorizedAPICall'), 'Close', { verticalPosition: 'top' });
              break;
            default:
              this.snackBar.open(this.translate.instant('ErrorMessage.DataRetrieveFail'), 'Close', {
                duration: 5000,
                verticalPosition: 'top',
              });
              break;
          }
          this.showSpinner = false;
        },
      );
    }
  }

  // getDomainList() {
  //   let self = this;
  //   self.domainList = [];
  //   this.domainSubscription = this.codeService.getPortalDomainCodeList().subscribe(result => {
  //     _.forEach(result['list'], function (value) {
  //       self.domainList.push({
  //         'codeTableId': value.codeTableId,
  //         'name': value.name
  //       });
  //     });
  //     this.codeService.getPlukonDomainCodeList().subscribe(result => {
  //       _.forEach(result['list'], function (value) {
  //         self.domainList.push({
  //           'codeTableId': value.codeTableId,
  //           'name': value.name
  //         });
  //       });
  //     });
  //     this.domainSubscription.unsubscribe();
  //   });
  // }

  // getSalutationList() {
  //   let self = this;
  //   this.salutationSubscription = this.codeService.getSalutationCodeList().subscribe(result => {
  //     _.forEach(result['list'], function (value) {
  //       self.salutationList.push({
  //         'codeTableId': value.codeTableId,
  //         'name': value.name
  //       });
  //     });
  //     this.salutationSubscription.unsubscribe();
  //   });
  // }

  ngOnDestroy() {
    if (this.domainSubscription) {
      this.domainSubscription.unsubscribe();
    }
    if (this.salutationSubscription) {
      this.salutationSubscription.unsubscribe();
    }
    if (this.plukonTranslationSub) {
      this.plukonTranslationSub.unsubscribe();
    }
  }
}
