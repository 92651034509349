<div
  *ngIf="router.url.startsWith('/my-company') ||  router.url.startsWith('/my-messages') || router.url.startsWith('/my-flock') || router.url.startsWith('/my-feedback/question-entries') && (responsiveUIService.isMobile$ | async) && !!this.companyList"
  id="company-selector-row" class="mobile-selector-row" [ngClass]="{ 'mobile-selector': router.url.startsWith('/my-flock'), 'mobile-only-company-selector': !router.url.startsWith('/my-flock') }"
  [style]="'--header-height: ' + responsiveUIService.appHeaderHeight() + 'px'">
  <mat-form-field floatLabel='never' class="company-selector">
    <mat-select [(value)]="selectedRowItem" placeholder="{{'AccessSettings.SelectCompany' | translate}}">
      <mat-option
        *ngFor="let company of companyList"
        (click)="displayCompanyData(company, null, null)"
        [value]="company"
      >
        {{company.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field *ngIf="router.url.startsWith('/my-flock')" class="stable-selector" floatLabel='never'>
    <mat-select [(value)]="selectedStableItem" placeholder="{{'VKI.StableSelect' | translate}}">
      <mat-option
        *ngFor="let stable of stableList"
        (click)="stableSelectionChange(stable)"
        [value]="stable.plukonId"
      >
        {{stable.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
