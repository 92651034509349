import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../shared/services/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class MaintenanceInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService, public translate: TranslateService, public snackBar: MatSnackBar) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 503 && error.error && error.error.messageCode === 'Maintenance Window') {
          // Handle the maintenance response
          const otherDetails = error.error;
          const startTime = otherDetails.startTime;
          const endTime = otherDetails.endTime;

          // Logout user and redirect back to /login screen
          this.authService.logout();

          // Show snackbar with error about maintenance
          let maintenanceMessage = this.translate.instant('Maintenance.UpdateMessage');
          maintenanceMessage = maintenanceMessage.replace('[EndDate]', endTime);
          this.snackBar.open(maintenanceMessage, 'Close', { verticalPosition: 'top' });

          // Return an observable with a user-facing error message
          return throwError(() => new Error('Service is under maintenance.'));
        }
        // Return other errors to be handled normally
        return throwError(error);
      })
    );
  }
}
