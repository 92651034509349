import { Injectable } from '@angular/core';
import { OfflineRequestsService } from './offline-request.service';
import { PrecacheService } from './precache.service';
import { OfflineStatusHandlerService } from './offline-status-handler.service';

@Injectable({
  providedIn: 'root',
})
export class OfflineServices {
  private initialized = false;

  constructor(
    private offlineRequestService: OfflineRequestsService,
    private precacheService: PrecacheService,
    private offlineStatusHandlerService: OfflineStatusHandlerService,
  ) {}

  public async initialize() {
    if (this.initialized) {
      return;
    }
    this.initialized = true;

    try {
      await this.offlineRequestService.initialize();
      this.precacheService.initialize();
      this.offlineStatusHandlerService.initialize();
    } catch (error) {
      console.log('::: Error initializing offline services', error);
    }
  }
}
