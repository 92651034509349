import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { getCachedResponsesService, ResponseStoreEntry, StorableResponse } from 'src/lib/responses/cached-responses.service';
import { cloneDeep } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class HttpCacheService {
  public async getCachedResponse(url: string, uid: string | undefined): Promise<ResponseStoreEntry> {
    return getCachedResponsesService().getCachedResponse(url, uid);
  }

  public async saveResponse(response: HttpResponse<any>, uid: string | undefined, queryKey?: string): Promise<void> {
    const responseToCache = response.clone();
    const storableResponse = httpResponseToStorableResponse(responseToCache);
    return getCachedResponsesService().cacheResponse(storableResponse, uid, queryKey);
  }
}

const httpResponseToStorableResponse = (response: HttpResponse<any>): StorableResponse => {
  const headers = response.headers.keys().reduce(
    (acc, key) => {
      acc[key] = response.headers.getAll(key);
      return acc;
    },
    {} as { [name: string]: string | string[] },
  );

  return {
    // clone object because some components change response body
    body: cloneDeep(response.body),
    status: response.status,
    statusText: response.statusText,
    url: response.url,
    ok: response.ok,
    headers,
  };
};
