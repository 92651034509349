import { openDB, DBSchema, IDBPDatabase } from 'idb';
import { RequestData } from './storable-request';

const DB_VERSION = 1;
const REQUEST_OBJECT_STORE_NAME = 'requests';
const UID_INDEX = 'uid';
const QUERY_KEY_INDEX = 'queryKey';
const COMBINED_INDEX = 'uid_queryKey'; // Unique combined index name
const ID_KEY_PATH = 'id';

interface RequestsDBSchema extends DBSchema {
  [REQUEST_OBJECT_STORE_NAME]: {
    key: number;
    value: RequestStoreEntry;
    indexes: {
      [UID_INDEX]: string;
      [QUERY_KEY_INDEX]: string;
      [COMBINED_INDEX]: [string, string];
    };
  };
}

export interface RequestStoreEntryWithoutId {
  [ID_KEY_PATH]?: number;
  [UID_INDEX]: string;
  [QUERY_KEY_INDEX]: string;
  requestData: RequestData;
  timestamp: number;
  rawBody?: any;
  startSyncing?: number;
  metadata?: Record<string, string | number>;
}

export interface RequestStoreEntry extends RequestStoreEntryWithoutId {
  [ID_KEY_PATH]: number;
}

/**
 * This class is needed to interact with IndexedDB for saving and retrieving RequestStoreEntry.
 */
export class RequestsDb {
  private _db: IDBPDatabase<RequestsDBSchema> | null = null;
  private readonly dnName: string;

  constructor(dbName: string) {
    this.dnName = dbName;
  }

  async add(entry: RequestStoreEntryWithoutId): Promise<void> {
    const db = await this.getDb();
    const tx = db.transaction(REQUEST_OBJECT_STORE_NAME, 'readwrite', { durability: 'strict' });
    await tx.store.add(entry as RequestStoreEntry);
    await tx.done;
  }

  async replace(entry: RequestStoreEntry): Promise<void> {
    const id = entry[ID_KEY_PATH];
    if (id === undefined) {
      throw new Error('Entry must have an ID to be replaced.');
    }

    const db = await this.getDb();
    const tx = db.transaction(REQUEST_OBJECT_STORE_NAME, 'readwrite', { durability: 'strict' });
    await tx.store.put(entry); // Use put instead of add to update by ID
    await tx.done;
  }

  async getById(id: number): Promise<RequestStoreEntry | undefined> {
    const db = await this.getDb();
    return db.get(REQUEST_OBJECT_STORE_NAME, id);
  }

  async deleteById(id: number): Promise<void> {
    const db = await this.getDb();
    const tx = db.transaction(REQUEST_OBJECT_STORE_NAME, 'readwrite', { durability: 'strict' });
    await tx.store.delete(id);
    await tx.done;
  }

  async getFirstEntryId(): Promise<number | undefined> {
    const db = await this.getDb();
    const cursor = await db.transaction(REQUEST_OBJECT_STORE_NAME).store.openCursor();
    return cursor?.value.id;
  }

  async getAllByUID(uid: string): Promise<RequestStoreEntry[]> {
    const db = await this.getDb();
    const results = await db.getAllFromIndex(REQUEST_OBJECT_STORE_NAME, UID_INDEX, IDBKeyRange.only(uid));
    return results ? results : [];
  }

  async getCountByUID(uid: string): Promise<number> {
    const db = await this.getDb();
    return db.countFromIndex(REQUEST_OBJECT_STORE_NAME, UID_INDEX, IDBKeyRange.only(uid));
  }

  // async getCountByUIDAndKey(uid: string, key: string): Promise<number> {
  // const db = await this.getDb();
  // return db.countFromIndex(REQUEST_OBJECT_STORE_NAME, COMBINED_INDEX, IDBKeyRange.only([uid, key]));
  // }

  async getEndEntryFromIndex(query: IDBKeyRange, direction: IDBCursorDirection): Promise<RequestStoreEntry | undefined> {
    const db = await this.getDb();
    const cursor = await db.transaction(REQUEST_OBJECT_STORE_NAME).store.index(UID_INDEX).openCursor(query, direction);
    return cursor?.value;
  }

  async getFirstEntryByUID(uid: string): Promise<RequestStoreEntry | undefined> {
    return await this.getEndEntryFromIndex(IDBKeyRange.only(uid), 'next');
  }

  async getLastEntryByUID(uid: string): Promise<RequestStoreEntry | undefined> {
    return await this.getEndEntryFromIndex(IDBKeyRange.only(uid), 'prev');
  }

  async getEntriesByUIDAndKey(uid: string, key: string): Promise<RequestStoreEntry[]> {
    const db = await this.getDb();
    const index = db.transaction(REQUEST_OBJECT_STORE_NAME).store.index(COMBINED_INDEX);
    // The list is should be ordered by key.
    // The order supposed to work from the box: https://stackoverflow.com/questions/67531388/does-idbindex-getall-return-a-sorted-list
    const results = await index.getAll(IDBKeyRange.only([uid, key]));
    return results ? results : [];
  }

  private async getDb() {
    if (!this._db) {
      this._db = await openDB(this.dnName, DB_VERSION, {
        upgrade: this.upgradeDb,
      });
    }
    return this._db;
  }

  private upgradeDb(db: IDBPDatabase<RequestsDBSchema>, oldVersion: number) {
    if (oldVersion > 0 && oldVersion < DB_VERSION) {
      if (db.objectStoreNames.contains(REQUEST_OBJECT_STORE_NAME)) {
        db.deleteObjectStore(REQUEST_OBJECT_STORE_NAME);
      }
    }
    const objStore = db.createObjectStore(REQUEST_OBJECT_STORE_NAME, {
      autoIncrement: true,
      keyPath: ID_KEY_PATH,
    });

    objStore.createIndex(UID_INDEX, UID_INDEX, { unique: false });
    objStore.createIndex(QUERY_KEY_INDEX, QUERY_KEY_INDEX, { unique: false });

    // Create the combined unique index
    objStore.createIndex(COMBINED_INDEX, [UID_INDEX, QUERY_KEY_INDEX], { unique: false });
  }
}
