import { SyncDb } from './sync-db';
import { v4 as uuidv4 } from 'uuid';

export const clientId = uuidv4();

class SyncService {
  private db: SyncDb;
  constructor() {
    this.db = new SyncDb('plukon-sync');
  }
  public async isAllowedToSync() {
    return this.db.claimIfNotClaimed(clientId);
  }

  public async syncComplete() {
    return this.db.releaseClaim(clientId);
  }

  public async getPrecacheState(uid: string) {
    return this.db.getPrecacheState(uid);
  }

  public async storePrecacheSuccess(uid: string) {
    return this.db.storePrecacheState(uid, true);
  }

  public async resetPrecacheState(uid: string) {
    return this.db.deletePrecacheState(uid);
  }

  public async storePrecacheFailure(uid: string) {
    return this.db.storePrecacheState(uid, false);
  }

  public async storePrecacheConfig(uid: string, showNotification: boolean) {
    return this.db.savePrecacheConfig(uid, { showNotification });
  }

  public async getPrecacheConfig(uid: string) {
    return this.db.getPrecacheConfig(uid);
  }

  public async resetPrecacheConfig(uid: string) {
    return this.db.deletePrecacheConfig(uid);
  }
}

let serviceInstance: SyncService;

export const getSyncService = (): SyncService => {
  if (!serviceInstance) {
    serviceInstance = new SyncService();
  }
  return serviceInstance;
};
