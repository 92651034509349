import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as _ from 'lodash';

import { Utils } from '../../../shared/functions/utils';
import { CodeService } from '../../services/code.service';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-contact-dialog',
  templateUrl: './contact-dialog.component.html',
  styleUrls: ['./contact-dialog.component.scss'],
})
export class ContactDialogComponent implements OnInit, OnDestroy {
  subjectSubscription: Subscription;
  profileSubscription: Subscription;
  submitContactSubscription: Subscription;
  subjectList = [];
  userId: string;
  mainEnvironment: string;
  environmentName: string;
  contactName: string;
  contactEmail: string;
  contactAddress: string;
  contactTelephone: string;
  contactTranslation: any;
  showSpinner: boolean;

  contactForm = this.fb.group({
    name: [''],
    email: ['', [Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$')]],
    telephone: ['', [Validators.pattern('^[0-9()/ +-]+$')]],
    subject: [null, Validators.required],
    question: ['', Validators.required],
  });

  constructor(
    public dialogRef: MatDialogRef<ContactDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService,
    private fb: UntypedFormBuilder,
    private codeService: CodeService,
    public snackBar: MatSnackBar,
    private commonService: CommonService,
  ) {}

  ngOnInit() {
    //get current lang when switch tab
    if (this.translate.currentLang) {
      this.contactTranslation = this.translate.instant('Contact.ContactMessage');
      this.contactTranslation = this.contactTranslation.replace('[Telephone]', this.contactTelephone);
    }
    //detect change in language
    this.translate.onLangChange.subscribe((result) => {
      this.contactTranslation = this.translate.instant('Contact.ContactMessage');
      this.contactTranslation = this.contactTranslation.replace('[Telephone]', this.contactTelephone);
    });

    this.contactForm.get('name').patchValue(this.contactName ? this.contactName : '');
    this.contactForm.get('email').patchValue(this.contactEmail ? this.contactEmail : '');

    Promise.all([this.getSubjectList()]).then(() => {});
  }

  getSubjectList() {
    return new Promise((resolve, reject) => {
      const self = this;
      this.subjectList = [];

      this.subjectSubscription = this.codeService.getSubjectCode().subscribe(
        (result) => {
          _.forEach(result['list'], function (value) {
            self.subjectList.push({
              codeTableId: value.codeTableId,
              codeTableNumber: value.codeTableNumber,
              name: value.name,
              translateSubject: value.name ? `${value.codeTable}.${value.name}` : '',
            });
          });
          this.subjectSubscription.unsubscribe();
          resolve(this.subjectList);
        },
        (error) => {
          console.log('Contact (getSubjectList) : ', error);
          switch (error.status) {
            case 401:
              this.snackBar.open(this.translate.instant('Portal.UnauthorizedAPICall'), 'Close', { verticalPosition: 'top' });
              break;
            default:
              this.snackBar.open(this.translate.instant('ErrorMessage.DataRetrieveFail'), 'Close', { verticalPosition: 'top' });
              break;
          }
          reject();
        },
      );
    });
  }

  telephoneInput(event) {
    if (
      (event.keyCode >= 48 && event.keyCode <= 57) ||
      (event.keyCode >= 96 && event.keyCode <= 105) ||
      event.keyCode == 8 ||
      event.keyCode == 9 ||
      event.keyCode == 37 ||
      event.keyCode == 39 ||
      event.key == '-' ||
      event.key == '+' ||
      event.key == '(' ||
      event.key == ')' ||
      event.key == '/' ||
      event.key == ' '
    ) {
      return true;
    } else {
      return false;
    }
  }

  onSubmitContact() {
    this.showSpinner = true;
    const data = {
      userId: this.userId,
      name: this.contactForm.get('name').value,
      email: this.contactForm.get('email').value,
      telephoneNumber: this.contactForm.get('telephone').value,
      subject: this.contactForm.get('subject').value,
      question: this.contactForm.get('question').value,
      mainEnvironment: this.mainEnvironment,
    };

    data.telephoneNumber = data.telephoneNumber;

    this.submitContactSubscription = this.commonService.submitContact(data).subscribe(
      (result) => {
        if (result) {
          this.showSpinner = false;
          this.contactForm.reset();
          this.dialogRef.close(true);
        }
      },
      (error) => {
        this.showSpinner = false;
        console.log('Contact (onSubmitContact) : ', error);
        switch (error.status) {
          case 401:
            this.snackBar.open(this.translate.instant('Portal.UnauthorizedAPICall'), 'Close', { verticalPosition: 'top' });
            break;
          default:
            this.snackBar.open(this.translate.instant('ErrorMessage.DataRetrieveFail'), 'Close', { verticalPosition: 'top' });
            break;
        }
      },
    );
  }

  ngOnDestroy() {
    if (this.subjectSubscription) {
      this.subjectSubscription.unsubscribe();
    }
    if (this.profileSubscription) {
      this.profileSubscription.unsubscribe();
    }
    if (this.submitContactSubscription) {
      this.submitContactSubscription.unsubscribe();
    }
  }
}
