import { Injectable } from '@angular/core';
import { HttpContext } from '@angular/common/http';

import { environment } from '../../../../../environments/environment';
import { Https } from '../../http.service';
import { FLOCK_NUMBER, FLOCK_REQUEST_KEY } from 'src/app/interceptors/my-flock.interceptor';
import { KEY_FLOCK_COCCIDIO } from 'src/lib/my-flock/constants';

@Injectable({
  providedIn: 'root',
})
export class CoccidiostatsService {
  currentCoccidiostatsData: any;
  currentCoccidiostatsDataOri: any; // store original data from previous save(if any)
  currentFeedTypeDate: any;
  currentCoccidiostatsListData: any;
  currentCoccidiostatsError: any;

  constructor(private http: Https) {}

  public getCoccidiostats(flockNumber) {
    const context = new HttpContext();
    context.set(FLOCK_NUMBER, flockNumber);
    context.set(FLOCK_REQUEST_KEY, KEY_FLOCK_COCCIDIO);

    return this.http.get(`${environment.baseUrl}/plukonKras/flock/coccidio/${flockNumber}`, undefined, undefined, context);
  }

  public getCoccidiostatsList(stableId) {
    return this.http.get(`${environment.baseUrl}/plukonKras/therapy/coccidio/${stableId}`, '');
  }

  public saveCoccidiostate(payload) {
    const flockNumber = payload?.flockNumber;
    const context = new HttpContext();
    context.set(FLOCK_NUMBER, flockNumber);
    context.set(FLOCK_REQUEST_KEY, KEY_FLOCK_COCCIDIO);
    return this.http.post(`${environment.baseUrl}/plukonKras/flock/coccidio`, payload, undefined, context);
  }

  set coccidiostatsData(value: any) {
    this.currentCoccidiostatsData = value;
  }

  get coccidiostatsData(): any {
    return this.currentCoccidiostatsData;
  }

  set coccidiostatsDataOri(value: any) {
    this.currentCoccidiostatsDataOri = value;
  }

  get coccidiostatsDataOri(): any {
    return this.currentCoccidiostatsDataOri;
  }

  set feedTypeData(value: any) {
    this.currentFeedTypeDate = value;
  }

  get feedTypeData(): any {
    return this.currentFeedTypeDate;
  }

  set coccidiostatListData(value: any) {
    this.currentCoccidiostatsListData = value;
  }

  get coccidiostatListData(): any {
    return this.currentCoccidiostatsListData;
  }

  set coccidiostatsError(value: any) {
    this.currentCoccidiostatsError = value;
  }

  get coccidiostatsError(): any {
    return this.currentCoccidiostatsError;
  }
}
