<div class="footer-wrapper safe-area-lr" [style]="router.url === '/' ? '--footerPadding: 56px' : '--footerPadding: 0'">
  <div class="footer" fxLayout="column" fxLayoutAlign="center center">
    <div class="footer-label" fxLayout="row" fxLayoutAlign="center center">
      <img class="footer-icon" src="/assets/images/new_footer.png" alt="">
    </div>
    <div class="link">
      <label class="link-item"><i class="material-icons copyright">copyright</i>{{'Footer.PlukonFoodGroup' |
        translate}}</label>
      <label class="link-item" *ngIf="!router.url.startsWith('/unauthorized')"
        (click)="footerRouting('terms-of-use')">{{'Footer.TermsOfUse' | translate}}</label>
      <label class="link-item" *ngIf="!router.url.startsWith('/unauthorized')"
        (click)="footerRouting('cookie-statement')">{{'Footer.CookieStatement' | translate}}</label>
      <label class="link-item" *ngIf="!router.url.startsWith('/unauthorized')"
        (click)="footerRouting('privacy-statement')">{{'Footer.PrivacyStatement' | translate}}</label>
    </div>
  </div>
</div>

<div class="mobile-footer-wrapper safe-area-lr">
  <div class="footer" fxLayout="column" fxLayoutAlign="center center">
    <div class="footer-label" fxLayout="row" fxLayoutAlign="center center">
      <img class="footer-icon" src="/assets/images/new_footer.png" alt="">
    </div>
  </div>
</div>
