import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { TranslateModule, TranslateLoader, MissingTranslationHandler } from '@ngx-translate/core';
import { PlukonMissingTranslationHandler } from './shared/functions/missing-translation-handler';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedServiceModule } from './shared/shared-service.module';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AuthGuardService as AuthGuard } from './shared/services/auth-guard.service';

import { LoginComponent } from './components/login/login.component';
import { LandingComponent } from './components/landing/landing.component';
import { UserComponent } from './components/user/user.component';
import { TermOfUseComponent } from './components/term-of-use/term-of-use.component';
import { CookieStatementComponent } from './components/cookie-statement/cookie-statement.component';
import { PrivacyStatementComponent } from './components/privacy-statement/privacy-statement.component';
import { TermsAndConditionComponent } from './components/terms-and-condition/terms-and-condition.component';
import { CanDeactivatedService } from './shared/services/can-deactivated.service';
import { MatGridListModule } from '@angular/material/grid-list';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { SocialAuthService } from './shared/services/social-auth.service';
import { BeforeInstallPromptComponent } from './components/before-install-prompt/before-install-prompt.component';
import { NgOptimizedImage } from '@angular/common';
import { AuthInterceptor } from './interceptors/auth-interceptor';
import { AuthService } from './shared/services/auth/auth.service';
import { TranslationsLoader } from './shared/services/translation/translations-loader';
import { Https } from './shared/services/http.service';
import { MyFlockInterceptor } from './interceptors/my-flock.interceptor';
import { MobileCompanySelectorComponent } from './components/mobile-company-selector/mobile-company-selector.component';
import { HttpCacheInterceptor } from './interceptors/http-cache.interceptor';
import { NetworkService } from './shared/services/network/network.service';
import { NotificationsReminderModalComponent } from './components/notifications-reminder-modal/notifications-reminder-modal.component';
import { OfflineStatusComponent } from './components/offline-status/offline-status.component';
import { WorkboxService } from './shared/services/workbox/workbox.service';
import { MobileServices } from './shared/services/mobile/mobile-services.service';
import { RouteReuseStrategy } from '@angular/router';
import { CustomReuseStrategy } from './shared/routing/custom-reuse.strategy';
import { OfflineServices } from './shared/services/offline/offline-services.service';
import { MaintenanceInterceptor } from './interceptors/maintenance.interceptor';

export function tokenGetter() {
  return sessionStorage.getItem('authToken');
}

// AoT requires an exported function for factories
export function createTranslateLoader(https: Https, injector: Injector) {
  return new TranslationsLoader(https, injector);
}

export function initializeApp(
  authService: AuthService,
  networkService: NetworkService,
  workboxService: WorkboxService,
  mobileServices: MobileServices,
  offlineServices: OfflineServices,
) {
  return async () => {
    try {
      await networkService.initialize();
      await mobileServices.initialize();
      await offlineServices.initialize();
      await authService.initialize();
      workboxService.initialize();
    } catch (error) {
      // TODO - show something went wrong, reload the app
      console.log('::: Error in app initialization', error);
    }
  };
}

const modules = [SharedModule];
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LandingComponent,
    UserComponent,
    TermOfUseComponent,
    CookieStatementComponent,
    PrivacyStatementComponent,
    TermsAndConditionComponent,
    BeforeInstallPromptComponent,
    MobileCompanySelectorComponent,
    NotificationsReminderModalComponent,
    OfflineStatusComponent,
  ],
  imports: [
    MatGridListModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgxGoogleAnalyticsModule.forRoot('UA-132046177-1'),
    NgxGoogleAnalyticsRouterModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [Https, Injector],
      },
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: PlukonMissingTranslationHandler },
      useDefaultLang: false,
      extend: true,
    }),
    SharedServiceModule.forRoot(),
    ...modules,
    NgOptimizedImage,
  ],
  providers: [
    SocialAuthService,
    AuthService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AuthService, NetworkService, WorkboxService, MobileServices, OfflineServices],
      multi: true,
    },
    AuthGuard,
    CanDeactivatedService,
    { provide: HTTP_INTERCEPTORS, useClass: MaintenanceInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: MyFlockInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpCacheInterceptor, multi: true },
    { provide: RouteReuseStrategy, useClass: CustomReuseStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
