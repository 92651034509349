import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'time',
})
export class TimePipe implements PipeTransform {
  transform(value: string): string {
    if (value !== undefined && value !== null) {
      if (value.includes(':')) {
        return value;
      } else if (value.length == 2) {
        return value + ':';
      } else if (value.length > 2) {
        const newVal = [value.slice(0, 2), ':', value.slice(2)].join('');
        return newVal;
      } else {
        return value;
      }
    } else {
      return '';
    }
  }
}
