import { Component, NgZone, OnDestroy, OnInit, ViewChild, isDevMode } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import * as _ from 'lodash';
import { TranslationService } from './shared/services/translation.service';
import { SocialAuthService } from './shared/services/social-auth.service';
import { CommonService } from './shared/services/common.service';
import { CompanyService } from './shared/services/admin/company.service';
import { MyPlukonService } from './shared/services/my-plukon.service';
import { environment } from '../environments/environment';
import { Company } from './shared/models/company';
import { VKIService } from './shared/services/flock/vki.service';
import { Title } from '@angular/platform-browser';
import { AuthGuardService } from './shared/services/auth-guard.service';
import { MyTaskService } from './shared/services/my-task.service';
import { CodeService } from './shared/services/code.service';
import { DialogService } from './shared/services/dialog.service';
import { QuestionnaireService } from './shared/services/questionnaire/questionnaire.service';
import { Https } from './shared/services/http.service';
import { RouterService } from './shared/services/router.service';
import { NotificationsService } from './shared/services/notifications/notifications.service';
import { NetworkService } from './shared/services/network/network.service';
import { AuthService } from './shared/services/auth/auth.service';
import { NewsService } from './shared/services/news.service';
import { RoleAccessService } from './shared/services/role-access.service';
import { ResponsiveUIService } from './shared/services/responsive-ui.service';
import { openExternalLink } from './shared/utils/external-link.utils';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [Title],
})
export class AppComponent implements OnDestroy, OnInit {
  companyListSubscription: Subscription;
  @ViewChild('sidenav', { static: false }) sideNav: MatSidenav;
  @ViewChild('headerSideNav', { static: false }) headerSideNav: MatSidenav;
  plukonTranslationSub: Subscription;
  dataSource: MatTableDataSource<Company>;
  myPlukonSubscription: Subscription;
  vkiSubscription: Subscription;
  profileSubscription: Subscription;
  selectedCompanySubscription: Subscription;
  isMyTaskOpenSubscription: Subscription;
  myTaskSubscription: Subscription;
  myTasksTypeCodeSubscription: Subscription;
  vkiConfigSubscription: Subscription;
  is2FADisabledSubscription: Subscription;
  authInitializedSubscription: Subscription;
  vkiStableSubscription: Subscription;
  appInitialized = false;
  translationEng = {};
  translationDutch = {};
  translationGerman = {};
  timer: any;
  stable: any;
  columns: any;
  selectedLang: any;
  displayedColumns: any;
  selectedRowItem: any;
  ruleConfigMap: any;
  configList: any;
  user: any;
  opened = true;
  adminFlag: string;
  companyName: string;
  country: string;
  link: string;
  environmentShortName: string;
  redirectionA: string;
  selectedEnvironment: string;
  username = '';
  userManualURL = '';
  mainEnvironment = null;
  userRole = null;
  isMyTaskOpen = false;
  isMyTaskRole = false;
  isAdminMyTask = false;
  isHeaderNavOpen = false;
  is2FAEnabled = true;
  isQualityEmployee = false;
  isInternalMail = false;
  isBlocked = false;
  isSuperAdmin = false;
  vetenarianView = false;
  hasPoultryOwner = false;
  isGlobal = false;
  isAdmin = false;
  notificationNumber = 0;
  expandedIndex = 0;
  mustFill = 0;
  shouldFill = 0;
  couldFill = 0;
  companyList = [];
  ruleConfig = [];
  myTasksList = [];
  myTasksTypeCodeList = [];
  selectedCompany: any;
  selectedStableId: any;
  enforceTaskTypeQuestionnaire = false;
  enforceTaskTypeMasterDataCheck = false;
  enforceTaskTypeRenewCertificate = false;
  enforceTaskTypeVKIData = false;
  enforceTaskTypeWeightNotification = false;
  enforceTaskTypeVKIDataUnapproved = false;
  enforceTaskTypeRejectedCertificate = false;
  enforceTaskTypeQuestionnaireParam: number;
  enforceTaskTypeMasterDataCheckParam: number;
  enforceTaskTypeRenewCertificateParam: number;
  enforceTaskTypeVKIDataParam: number;
  enforceTaskTypeWeightNotificationParam: number;
  enforceTaskTypeVKIDataUnapprovedParam: number;
  enforceTaskTypeRejectedCertificateParam: number;
  documentNumber: number;
  offlineMode = false;
  isDevMode = false;

  constructor(
    public router: Router,
    public translate: TranslateService,
    public translationService: TranslationService,
    public socialAuthService: SocialAuthService,
    private socialService: SocialAuthService,
    private commonService: CommonService,
    private companyService: CompanyService,
    private myPlukonService: MyPlukonService,
    public snackBar: MatSnackBar,
    private vkiService: VKIService,
    private titleService: Title,
    private authGuardService: AuthGuardService,
    private myTaskService: MyTaskService,
    private codeService: CodeService,
    private dialogService: DialogService,
    private questionnaireService: QuestionnaireService,
    private route: ActivatedRoute,
    private http: Https,
    public routerService: RouterService,
    private zone: NgZone,
    private notifications: NotificationsService,
    private networkService: NetworkService,
    private authService: AuthService,
    private newsService: NewsService,
    private roleAccess: RoleAccessService,
    public responsiveUIService: ResponsiveUIService,
  ) {
    this.displayCompanyData = this.displayCompanyData.bind(this);
    this.isDevMode = isDevMode();
    // setInterval(function () {
    //   let url = router.url;

    //   if (url !== "/login" && url !== "/unauthorized/maintenance" && url !== "/unauthorized") {
    //     commonService.keepSession().subscribe();
    //   }
    // }, 50000);

    this.router.events.subscribe((ev) => {
      const url = router.url;
      if (
        url !== '/login' &&
        url !== '/unauthorized/maintenance' &&
        url !== '/unauthorized' &&
        ev instanceof NavigationEnd &&
        (this.notificationNumber || this.notificationNumber == 0)
      ) {
        this.getMyTasks();
      }

      if (ev instanceof NavigationEnd) {
        this.authService.isLoggedIn$.pipe(take(1)).subscribe(isLoggedIn => {
          if (isLoggedIn && this.router.url.includes('/login')) {
            this.router.navigate(['']);
          }
        });

      }
    });

    this.authService.isLoggedIn$.subscribe((loggedIn: boolean) => {
      if (loggedIn && this.router.url.includes('/login')) {
        this.router.navigate(['']);
      }
    });

    this.titleService.setTitle(`${environment.tabName}`);

    this.columns = [{ columnDef: 'name', header: 'Name', cell: (element: Company) => `${element.name}` }];
    this.displayedColumns = this.columns.map((c) => c.columnDef);
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd && (<any>window).ga) {
        (<any>window).ga('set', 'page', event.urlAfterRedirects);
        (<any>window).ga('send', 'pageview');
      }
    });

    if (translate.currentLang) {
      this.langSelected(translate.currentLang);
    }
    //detect change in language
    translate.onLangChange.subscribe((result) => {
      this.langSelected(result.lang);
    });
  }

  langSelected(lang) {
    switch (lang) {
      case 'en':
        this.selectedLang = 'EN';
        break;
      case 'du':
        this.selectedLang = 'NL';
        break;
      case 'ge':
        this.selectedLang = 'DE';
        break;
      case 'fr':
        this.selectedLang = 'FR';
        break;
      default:
        this.selectedLang = 'EN';
    }
  }

  ngOnInit(): void {
    const self = this;

    this.authInitializedSubscription = this.authService.initialized$.subscribe((authInitialized) => {
      this.appInitialized = authInitialized;
    });
    this.networkService.getIsOfflineMode$().subscribe((isOffline) => {
      this.offlineMode = isOffline;
    });
    this.vkiStableSubscription = this.vkiService.selectedStableId.subscribe((data) => {
      this.selectedStableId = data;
    });

    this.is2FADisabledSubscription = this.socialService.is2FADialogShow.subscribe((is2FADialogShow) => {
      if (
        is2FADialogShow !== null &&
        !is2FADialogShow &&
        this.isMyTaskRole &&
        this.myTasksList &&
        this.myTasksList.find((t) => t.taskStatus == 4)
      ) {
        this.myTaskNotificationPopUp();
      }
    });

    this.plukonTranslationSub = this.translationService.getPlukonTranslation(null).subscribe();

    this.routerService.environmentData.next({
      environment: 'kknr-nl',
    });

    this.authService.isLoggedIn$.subscribe((loggedIn) => {
      if (loggedIn) {
        this.socialService.getMyProfile().subscribe(
          (result) => {
            if (result) {
              if (
                !this.socialService.firstCheck2FA.getValue() &&
                result['response'].domainObj &&
                result['response'].domainObj.name &&
                result['response'].domainObj.name == '@plukonnect.com' &&
                result['response'].needToShow2FADialog == true
              ) {
                this.is2FAEnabled = false;
                this.show2FAPopup(result['response'].manualSignedURL ?? '');
              }
              this.socialService.firstCheck2FA.next(true);

              this.username = result['response'].userName;
              this.commonService.myUserProfile.next(result);
              if (result['response']) {
                this.setUser(result['response']);
              }
              this.commonService.isAdmin.next(result['response'].admin);
              this.commonService.isTestUser.next(result['response'].testUser);
              this.adminFlag = result['response'].admin;
              let i = 0;
              const userRoleList = result['response']['userRoleList'] ? result['response']['userRoleList'] : [];
              this.companyListSubscription = this.authGuardService.mainEnvironment.subscribe((profile) => {
                if (profile) {
                  this.environmentShortName = profile.environmentShortName;
                  this.selectedEnvironment = profile.environment;
                  if (this.mainEnvironment !== profile.environment) {
                    this.mainEnvironment = null;
                    this.isMyTaskRole = false;
                    this.isAdminMyTask = false;
                    const isInternalMail =
                      profile.role === 'Other' &&
                      userRoleList.length > 0 &&
                      (userRoleList.filter((data) => data.roleObj.name === 'internalMailPlanning').length > 0 ||
                        userRoleList.filter((data) => data.roleObj.name === 'InternalMailAdmin').length > 0 ||
                        userRoleList.filter((data) => data.roleObj.name === 'internalMailquality').length > 0);
                    this.isMyTaskRole =
                      profile.role === 'Other' &&
                      userRoleList.length > 0 &&
                      (userRoleList.filter((data) => data.roleObj.name === 'Poultry Owner').length > 0 ||
                        userRoleList.filter((data) => data.roleObj.name === 'Poultry Employee').length > 0);
                    this.isAdminMyTask =
                      this.adminFlag &&
                      profile.role &&
                      (profile.role === 'Superadmin' ||
                        profile.role === 'Admin' ||
                        profile.role === 'Planning Employee' ||
                        profile.role === 'Sales Representative');
                    if (profile) {
                      this.companyName = null;
                      this.mainEnvironment = profile.environment;
                      this.userRole = profile.role;
                      this.profileSubscription = this.commonService.myUserProfile.subscribe((profile) => {
                        if (profile['response']) {
                          this.setUser(result['response']);
                        }
                        const isAdmin = profile['response'].admin;
                        this.myPlukonService.selectedCompany.next(null);
                        if (!isInternalMail) {
                          this.getCompanyList(this.mainEnvironment, isAdmin);
                        }
                        if (i !== 0) {
                          this.selectedRowItem = null;
                          this.myPlukonService.routerSelectedCompany.next(null);
                          this.myPlukonService.company.next(null);
                          this.myPlukonService.menuCompany = null;
                        }
                        i++;
                        // this.profileSubscription.unsubscribe();
                        this.getVKiConfgiTasksTypeCode();
                      });
                    } else {
                      this.profileSubscription = this.commonService.myUserProfile.subscribe((profile) => {
                        if (profile['response']) {
                          this.setUser(result['response']);
                        }
                        const isAdmin = profile['response'].admin;
                        this.myPlukonService.selectedCompany.next(null);
                        if (!isInternalMail) {
                          this.getCompanyList(profile['response'].environment, isAdmin);
                        }
                        this.getVKiConfgiTasksTypeCode();
                        // this.profileSubscription.unsubscribe();
                      });
                    }
                    this.getMyTasks();
                  }
                }
              });
            }
          },
          (error) => {
            if (error.status == '401') {
              console.log('App (getMyProfile) : ', error);
              this.snackBar.open(this.translate.instant('Portal.UnauthorizedAPICall'), 'Close', { verticalPosition: 'top' });
              return false;
            }
            if (error.status == '404') {
              console.log('App (getMyProfile) : ', error);
              return false;
            }
          },
        );
      }
    });

    this.myTaskService.getMyTasksEvent.subscribe((result) => {
      this.getMyTasks();
    });

    if (this.selectedCompanySubscription) {
      this.selectedCompanySubscription.unsubscribe();
    }

    this.vkiSubscription = this.vkiService.flockBoxInfo.subscribe((result) => {
      // let flockNumber = this.vkiService.flockData ? this.vkiService.flockData.flockNumber : null;
      if (!result) {
        this.stable = '';
      } else {
        this.stable = result['stable'] ? result['stable'] : '';
      }
    });

    this.isMyTaskOpenSubscription = this.myTaskService.isMyTaskOpen.subscribe((isOpen) => {
      if (isOpen && this.isHeaderNavOpen) {
        this.toggleHeaderNav();
      }
      this.isMyTaskOpen = isOpen;
    });
  }

  setUser(user) {
    this.user = user;
    this.redirectionA = this.user.environmentObj.globeMenuObj
      ? `${this.user.environmentObj.globeMenuObj.codeTable}.${this.user.environmentObj.globeMenuObj.name}`
      : '';
    const userRoleList = this.user['userRoleList'] ? this.user['userRoleList'] : [];
    this.hasPoultryOwner = userRoleList.filter((data) => data.roleObj.name === 'Poultry Owner').length > 0;
    this.vetenarianView = this.checkisAllowAccess(this.user.userRoleMainCompanyObj);
    this.isAdmin = this.user.admin;
    this.isBlocked = this.userRole === 'Planning Employee';
    this.isQualityEmployee = this.userRole === 'Quality employee';
    this.isInternalMail =
      this.userRole === 'Other' &&
      userRoleList.length > 0 &&
      (userRoleList.filter((data) => data.roleObj.name === 'internalMailPlanning').length > 0 ||
        userRoleList.filter((data) => data.roleObj.name === 'InternalMailAdmin').length > 0 ||
        userRoleList.filter((data) => data.roleObj.name === 'internalMailquality').length > 0);
    const mainRole = this.user.userRoleMainCompanyObj ? this.user.userRoleMainCompanyObj.name : null;
    if (
      !this.user.admin &&
      (userRoleList.filter((data) => data.roleObj.name === 'Ext. Advisor').length > 0 || mainRole === 'Ext. Advisor')
    ) {
      this.isGlobal = false;
    } else {
      if (this.user.thisUserFromGlobalEnv) {
        this.isGlobal = true;
      } else {
        this.isGlobal = false;
      }
    }
  }

  checkisAllowAccess(role) {
    if (role && role.name) {
      if (
        (role.name === 'Veterinarian Employee' && this.isAdmin === false) ||
        (role.name === 'Quality employee' && this.isAdmin === true) ||
        (role.name === 'Superadmin' && this.isAdmin === true)
      ) {
        if (role.name === 'Superadmin') {
          this.isSuperAdmin = true;
        } else {
          this.isSuperAdmin = false;
        }
        return true;
      }
      this.isSuperAdmin = false;
      return false;
    } else {
      return false;
    }
  }

  getVKiConfgiTasksTypeCode() {
    if (this.mainEnvironment) {
      Promise.all([this.getVKIConfig(), this.getMyTasksTypeCode()]).then((config) => {
        this.roleAccess.vkiConfigSubject.next(config[0] ? config[0] : null);
        if (config[0]) {
          this.configList;
          this.configList = config[0];
          this.ruleConfigMap = new Map();
          this.ruleConfig = this.configList ? this.configList.filter((data) => data.type === 'rule' && data.part === 'myTasks') : [];

          for (const configs of this.ruleConfig) {
            this.ruleConfigMap.set(configs.name, configs);
          }

          if (
            this.ruleConfigMap &&
            this.ruleConfigMap.get('enforceTaskTypeQuestionnaire') &&
            this.ruleConfigMap.get('enforceTaskTypeQuestionnaire')['state'] === 3
          ) {
            this.enforceTaskTypeQuestionnaire = true;
            this.enforceTaskTypeQuestionnaireParam = this.ruleConfigMap.get('enforceTaskTypeQuestionnaire')['param1'];
          } else {
            this.enforceTaskTypeQuestionnaire = false;
          }

          if (
            this.ruleConfigMap &&
            this.ruleConfigMap.get('enforceTaskTypeMasterDataCheck') &&
            this.ruleConfigMap.get('enforceTaskTypeMasterDataCheck')['state'] === 3
          ) {
            this.enforceTaskTypeMasterDataCheck = true;
            this.enforceTaskTypeMasterDataCheckParam = this.ruleConfigMap.get('enforceTaskTypeMasterDataCheck')['param1'];
          } else {
            this.enforceTaskTypeMasterDataCheck = false;
          }

          if (
            this.ruleConfigMap &&
            this.ruleConfigMap.get('enforceTaskTypeRenewCertificate') &&
            this.ruleConfigMap.get('enforceTaskTypeRenewCertificate')['state'] === 3
          ) {
            this.enforceTaskTypeRenewCertificate = true;
            this.enforceTaskTypeRenewCertificateParam = this.ruleConfigMap.get('enforceTaskTypeRenewCertificate')['param1'];
          } else {
            this.enforceTaskTypeRenewCertificate = false;
          }

          if (
            this.ruleConfigMap &&
            this.ruleConfigMap.get('enforceTaskTypeVKIData') &&
            this.ruleConfigMap.get('enforceTaskTypeVKIData')['state'] === 3
          ) {
            this.enforceTaskTypeVKIData = true;
            this.enforceTaskTypeVKIDataParam = this.ruleConfigMap.get('enforceTaskTypeVKIData')['param1'];
          } else {
            this.enforceTaskTypeVKIData = false;
          }

          if (
            this.ruleConfigMap &&
            this.ruleConfigMap.get('enforceTaskTypeWeightNotification') &&
            this.ruleConfigMap.get('enforceTaskTypeWeightNotification')['state'] === 3
          ) {
            this.enforceTaskTypeWeightNotification = true;
            this.enforceTaskTypeWeightNotificationParam = this.ruleConfigMap.get('enforceTaskTypeWeightNotification')['param1'];
          } else {
            this.enforceTaskTypeWeightNotification = false;
          }

          if (
            this.ruleConfigMap &&
            this.ruleConfigMap.get('enforceTaskTypeVKIDataUnapproved') &&
            this.ruleConfigMap.get('enforceTaskTypeVKIDataUnapproved')['state'] === 3
          ) {
            this.enforceTaskTypeVKIDataUnapproved = true;
            this.enforceTaskTypeVKIDataUnapprovedParam = this.ruleConfigMap.get('enforceTaskTypeVKIDataUnapproved')['param1'];
          } else {
            this.enforceTaskTypeVKIDataUnapproved = false;
          }

          if (
            this.ruleConfigMap &&
            this.ruleConfigMap.get('enforceTaskTypeRejectedCertificate') &&
            this.ruleConfigMap.get('enforceTaskTypeRejectedCertificate')['state'] === 3
          ) {
            this.enforceTaskTypeRejectedCertificate = true;
            this.enforceTaskTypeRejectedCertificateParam = this.ruleConfigMap.get('enforceTaskTypeRejectedCertificate')['param1'];
          } else {
            this.enforceTaskTypeRejectedCertificate = false;
          }
        }
      });
    }
  }

  getMyTasks() {
    if (this.router.url.includes('/unauthorized')) {
      return;
    }
    if (this.isMyTaskRole || this.isAdminMyTask) {
      if (this.myTaskSubscription) {
        this.myTaskSubscription.unsubscribe();
      }
      if (this.isMyTaskRole) {
        this.myTaskSubscription = this.myTaskService.getUserTask().subscribe(
          (userTask) => {
            if (userTask && userTask['list'] && userTask['list'][0] && userTask['list'][0]['tasks']) {
              this.notificationNumber = userTask['list'][0]['tasks'].length;
              const oldMyTaskList = _.cloneDeep(this.myTasksList);
              this.myTasksList = userTask['list'][0]['tasks'];

              this.myTaskService.myTaskList.next(this.myTasksList);
              if (JSON.stringify(this.myTasksList) !== JSON.stringify(oldMyTaskList) && this.myTasksList.find((t) => t.taskStatus == 4)) {
                if (!this.responsiveUIService.isMobile) {
                  this.myTaskService.isMyTaskOpen.next(true);
                }
                this.myTaskNotificationPopUp();
              }
            } else {
              this.myTaskService.isMyTaskOpen.next(false);
              this.myTasksList = [];
              this.myTaskService.myTaskList.next(this.myTasksList);
              if (this.timer) {
                clearTimeout(this.timer);
              }
              this.mustFill = 0;
              this.shouldFill = 0;
              this.couldFill = 0;
              this.notificationNumber = 0;
            }
          },
          (error) => {
            this.notificationNumber = 0;
            if (error.status == '401') {
              console.log('App (getUserTask) : ', error);
              this.snackBar.open(this.translate.instant('Portal.UnauthorizedAPICall'), 'Close', { verticalPosition: 'top' });
              return false;
            }
            if (error.status == '404') {
              console.log('App (getUserTask) : ', error);
              return false;
            }
          },
        );
      } else if (this.isAdminMyTask) {
        if (this.myPlukonService.selectedCompany.getValue()) {
          this.myTaskSubscription = this.myTaskService.getCompanyTask(this.myPlukonService.selectedCompany.getValue().companyId).subscribe(
            (userTask) => {
              if (userTask && userTask['list'] && userTask['list'][0] && userTask['list'][0]['tasks']) {
                this.notificationNumber = userTask['list'][0]['tasks'].length;
                this.myTasksList = userTask['list'][0]['tasks'];
                this.myTaskService.myTaskList.next(this.myTasksList);
              } else {
                this.myTasksList = [];
                this.myTaskService.myTaskList.next(this.myTasksList);
                this.notificationNumber = 0;
              }
            },
            (error) => {
              this.notificationNumber = 0;
              if (error.status == '401') {
                console.log('App (getUserTask) : ', error);
                this.snackBar.open(this.translate.instant('Portal.UnauthorizedAPICall'), 'Close', { verticalPosition: 'top' });
                return false;
              }
              if (error.status == '404') {
                console.log('App (getUserTask) : ', error);
                return false;
              }
            },
          );
        } else {
          this.myTasksList = [];
          this.myTaskService.myTaskList.next(this.myTasksList);
          this.notificationNumber = 0;
        }
      }
    }
  }

  show2FAPopup(userManualURL) {
    if (!this.is2FAEnabled && this.commonService.isTestUser.getValue()) {
      this.socialService.is2FADialogShow.next(true);
      this.dialogService.context2FA('2FA.LoginPopUp2FA', false, null, null, null, true, userManualURL).subscribe((res) => {
        this.socialService.is2FADialogShow.next(false);
      });
    }
  }

  getAppHeaderHeight() {
    return document.getElementById('app-header') ? document.getElementById('app-header').offsetHeight + 10 : 0;
  }

  appHeaderHeight() {
    return this.responsiveUIService.appHeaderHeight();
  }

  appTopStickyHeight() {
    if (this.responsiveUIService.isMobile) {
      const appTopStickyHeight = this.responsiveUIService.appTopStickyHeight();
      const myFlockTab = document.getElementById('my-flock-tab') ? document.getElementById('my-flock-tab').offsetHeight : 0;
      const myCompanyTab = document.getElementById('my-plukon-tab') ? document.getElementById('my-plukon-tab').offsetHeight : 0;
      return appTopStickyHeight + myFlockTab + myCompanyTab;
    }
    return 0;
  }

  getMyTaskFloatingRight() {
    return 32 + (this.myTaskService.isMyTaskOpen.getValue() ? 300 : 0);
  }

  getCompanyList(mainEnvironment, isAdmin) {
    const self = this;
    let newCompanyListSubscription: Subscription;
    newCompanyListSubscription = this.companyService.getUserPortalCompanyList(mainEnvironment).subscribe(
      (result) => {
        //sort data before displaying
        // if (isAdmin) {
        //   result['list'].sort(this.sortedName);
        // } else {
        //   result['list'].sort(function (x, y) { return (x === y) ? 0 : x ? -1 : 1; });
        // }
        self.companyList = [];

        _.forEach(result['list'], function (data) {
          self.companyList.push({
            plukonId: data.plukonId ? data.plukonId : '',
            name: data.name,
            companyId: data.companyId,
            country: data.country,
            isGlobal: data.fromGlobalEnv,
            companyType: data.companyTypeObj ? data.companyTypeObj.name : null,
            companyEnvironment: data.environmentObj ? data.environmentObj.shortName.toLowerCase() : null,
          });
        });
        newCompanyListSubscription.unsubscribe();
        this.dataSource = new MatTableDataSource(self.companyList);

        if (this.companyList != []) {
          this.selectedCompanySubscription = this.myPlukonService.selectedCompany.subscribe((result) => {
            if (result) {
              if (this.isAdminMyTask && this.selectedCompany !== result) {
                this.getMyTasks();
              }
              this.selectedCompany = result;
              const selectedCompany = result;
              this.myPlukonService.getConceptLayout(this.mainEnvironment, selectedCompany.companyId).subscribe(
                (result) => {
                  this.myPlukonService.conceptLayout.next(result);
                },
                (error) => {
                  this.myPlukonService.conceptLayout.next(false);
                  if (error.status == '401') {
                    console.log('App (getConceptLayout) : ', error);
                    this.snackBar.open(this.translate.instant('Portal.UnauthorizedAPICall'), 'Close', { verticalPosition: 'top' });
                    return false;
                  }
                  if (error.status == '404') {
                    console.log('App (getConceptLayout) : ', error);
                    return false;
                  }
                },
              );
            }
            if (this.companyList.length > 1) {
              this.myPlukonService.conceptLayout.next(false);
            } else {
              this.myPlukonService.conceptLayout.next(null);
            }
          });
        }

        if (self.companyList.length === 1) {
          this.myPlukonService.selectedCompany.next(self.companyList[0]);
          if (!this.router.url.includes('/vki-data') && !this.router.url.includes('/vki-requests')) {
            this.displayCompanyData(self.companyList[0], null, null);
          }
        }

        //Lucas Step 2
        this.myPlukonSubscription = this.myPlukonService.routerSelectedCompany.subscribe((result) => {
          if (result != null) {
            for (const company of this.companyList) {
              if (company.plukonId.replace(/\s/g, '') == result[0]) {
                this.displayCompanyData(company, null, result[1]);
                if (result[2]) {
                  this.questionnaireService.selectedQuestionnaire.next({
                    id: this.route.snapshot.params['questionnaireId'],
                  });
                }
              }
            }
          }
        });
      },
      (error) => {
        if (error.status == '401') {
          this.snackBar.open(this.translate.instant('Portal.UnauthorizedAPICall'), 'Close', { verticalPosition: 'top' });
        }
      },
    );
  }

  getVKIConfig() {
    return new Promise((resolve, reject) => {
      this.vkiConfigSubscription = this.vkiService.getVKIConfig(this.mainEnvironment, this.country).subscribe(
        (config) => {
          resolve(config && config['list'] ? config['list'] : null);
        },
        (error) => {
          switch (error.status) {
            case 401:
              this.snackBar.open(this.translate.instant('Portal.UnauthorizedAPICall'), 'Close', { verticalPosition: 'top' });
              break;
            default:
              break;
          }
          reject();
        },
      );
    });
  }

  getMyTasksTypeCode() {
    return new Promise((resolve, reject) => {
      const self = this;
      this.myTasksTypeCodeList = [];

      this.myTasksTypeCodeSubscription = this.codeService.getMyTasksTypeCode().subscribe(
        (result) => {
          _.forEach(result['list'], function (value) {
            self.myTasksTypeCodeList.push({
              codeTableId: value.codeTableId,
              codeTableNumber: value.codeTableNumber,
              name: value.name,
              translateTaskType: value.name ? `${value.codeTable}.${value.name}` : '',
            });
          });
          this.myTasksTypeCodeSubscription.unsubscribe();
          resolve(this.myTasksTypeCodeList);
        },
        (error) => {
          console.log('App (getMyTasksTypeCode) : ', error);
          switch (error.status) {
            case 401:
              this.snackBar.open(this.translate.instant('Portal.UnauthorizedAPICall'), 'Close', { verticalPosition: 'top' });
              break;
            default:
              this.snackBar.open(this.translate.instant('ErrorMessage.DataRetrieveFail'), 'Close', { verticalPosition: 'top' });
              break;
          }
          reject();
        },
      );
    });
  }

  setExpandIndex(i) {
    this.expandedIndex = i;
  }

  getTaskTypeName(task) {
    const taskType = this.myTasksTypeCodeList.find((t) => t.codeTableNumber == task.taskType);
    return taskType ? taskType.translateTaskType : '';
  }

  getTaskTitle(taskType) {
    switch (taskType) {
      case 1:
        return 'MyTasks.TaskNameQuestionnaire';
      case 2:
        return 'MyTasks.TaskNameMasterDataCheck';
      case 3:
        return 'MyTasks.TaskNameRenewCertificate';
      case 4:
        return 'MyTasks.TaskNameVKIData';
      case 5:
        return 'MyTasks.TaskNameWeightNotification';
      case 6:
        return 'MyTasks.TaskNameVKIDataUnapproved';
      case 7:
        return 'MyTasks.TaskNameRejectedCertificate';
      default:
        return '';
    }
  }

  getTaskTitleSecondRow(task) {
    switch (task.taskType) {
      case 1:
        return task.taskSpecifications.name[this.selectedLang] ?? '';
      case 3:
        return task.taskSpecifications.name[this.selectedLang] ?? '';
      case 4:
        return task.taskSpecifications.id ?? '';
      case 5:
        return 'To be defined';
      case 6:
        return task.taskSpecifications.id ?? '';
      case 7:
        return task.taskSpecifications.name[this.selectedLang] ?? '';
      default:
        return '';
    }
  }

  getTaskDescriptionByTaskType(taskType) {
    switch (taskType) {
      case 1:
        return [
          'MyTasks.TaskDescriptionDisplayQuestionnaire',
          'MyTasks.TaskDescription1stWarningQuestionnaire',
          'MyTasks.TaskDescription2ndWarningQuestionnaire',
          'MyTasks.TaskDescriptionOverdueQuestionnaire',
        ];
      case 2:
        return [
          'MyTasks.TaskDescriptionDisplayMasterDataCheck',
          'MyTasks.TaskDescription1stWarningMasterDataCheck',
          'MyTasks.TaskDescription2ndWarningMasterDataCheck',
          'MyTasks.TaskDescriptionOverdueMasterDataCheck',
        ];
      case 3:
        return [
          'MyTasks.TaskDescriptionDisplayRenewCertificate',
          'MyTasks.TaskDescription1stWarningRenewCertificate',
          'MyTasks.TaskDescription2ndWarningRenewCertificate',
          'MyTasks.TaskDescriptionOverdueRenewCertificate',
        ];
      case 4:
        return [
          'MyTasks.TaskDescriptionDisplayVKIData',
          'MyTasks.TaskDescription1stWarningVKIData',
          'MyTasks.TaskDescription2ndWarningVKIData',
          'MyTasks.TaskDescriptionOverdueVKIData',
        ];
      case 5:
        return [
          'MyTasks.TaskDescriptionDisplayWeightNotification',
          'MyTasks.TaskDescription1stWarningWeightNotification',
          'MyTasks.TaskDescription2ndWarningWeightNotification',
          'MyTasks.TaskDescriptionOverdueWeightNotification',
        ];
      case 6:
        return [
          'MyTasks.TaskDescriptionDisplayVKIDataUnapproved',
          'MyTasks.TaskDescription1stWarningVKIDataUnapproved',
          'MyTasks.TaskDescription2ndWarningVKIDataUnapproved',
          'MyTasks.TaskDescriptionOverdueVKIDataUnapproved',
        ];
      case 7:
        return [
          'MyTasks.TaskDescriptionDisplayRejectedCertificate',
          'MyTasks.TaskDescription1stWarningRejectedCertificate',
          'MyTasks.TaskDescription2ndWarningRejectedCertificate',
          'MyTasks.TaskDescriptionOverdueRejectedCertificate',
        ];
      default:
        return ['', '', '', ''];
    }
  }

  replaceDescriptionObject(translateObj, task) {
    let description = this.translate.instant(translateObj || ' ');
    switch (task.taskType) {
      case 1: {
        description = description.replace('[QUESTIONNAIRE NAME]', task.taskSpecifications.name[this.selectedLang]);
        return description;
      }
      case 2: {
        return description;
      }
      case 3: {
        description = description.replace('[CERTIFICATE TYPE]', task.taskSpecifications.name[this.selectedLang]);
        return description;
      }
      case 4: {
        description = description.replace('[STABLE ID]', task.taskSpecifications.id);
        return description;
      }
      case 5: {
        description = description.replace('[STABLE ID]', task.taskSpecifications.id);
        return description;
      }
      case 6: {
        description = description.replace('[STABLE ID]', task.taskSpecifications.id);
        return description;
      }
      case 7: {
        description = description.replace('[CERTIFICATE TYPE]', task.taskSpecifications.name[this.selectedLang]);
        return description;
      }
      default:
        return description;
    }
  }

  myTaskNotificationPopUp() {
    if (this.socialAuthService.is2FADialogShow.getValue()) {
      return;
    }
    if (this.timer) {
      clearTimeout(this.timer);
    }
    const overdueTasks = this.myTasksList.filter((t) => t.taskStatus == 4);
    this.mustFill = 0;
    this.shouldFill = 0;
    this.couldFill = 0;
    overdueTasks.forEach((task) => {
      switch (task.taskType) {
        case 1:
          this.countFill(this.enforceTaskTypeQuestionnaire, this.enforceTaskTypeQuestionnaireParam);
          break;
        case 2:
          this.countFill(this.enforceTaskTypeMasterDataCheck, this.enforceTaskTypeMasterDataCheckParam);
          break;
        case 3:
          this.countFill(this.enforceTaskTypeRenewCertificate, this.enforceTaskTypeRenewCertificateParam);
          break;
        case 4:
          this.countFill(this.enforceTaskTypeVKIData, this.enforceTaskTypeVKIDataParam);
          break;
        case 5:
          this.countFill(this.enforceTaskTypeWeightNotification, this.enforceTaskTypeWeightNotificationParam);
          break;
        case 6:
          this.countFill(this.enforceTaskTypeVKIDataUnapproved, this.enforceTaskTypeVKIDataUnapprovedParam);
          break;
        case 7:
          this.countFill(this.enforceTaskTypeRejectedCertificate, this.enforceTaskTypeRejectedCertificateParam);
          break;
        default:
          break;
      }
    });

    if (this.mustFill > 0) {
      this.openMyTaskNotification();
      this.startTimer(2);
    } else if (this.shouldFill > 0) {
      this.openMyTaskNotification();
      this.startTimer(5);
    } else if (this.couldFill > 0) {
      this.openMyTaskNotification();
    }
  }

  openMyTaskNotification() {
    if (this.dialogService.myTaskDialogRef == null || this.dialogService.myTaskDialogRef == undefined) {
      this.zone.run(() => {
        this.dialogService.myTaskContext('MyTasks.NotificationPopUp', true, null, null, null, true).subscribe((result) => {
          this.dialogService.myTaskDialogRef = null;
        });
      });
    }
  }

  startTimer(minute) {
    this.timer = setInterval(
      () => {
        this.openMyTaskNotification();
      },
      minute * 60 * 1000,
    );
  }

  countFill(isEnforced, enforceParam) {
    if (isEnforced) {
      if (enforceParam == 1) {
        this.mustFill++;
      } else if (enforceParam == 2) {
        this.shouldFill++;
      } else if (enforceParam == 3) {
        this.couldFill++;
      }
    }
  }

  sortedName(a, b) {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  }

  openMyTasks(fromHeader?: boolean, isMobile?: boolean) {
    if (fromHeader && isMobile && this.myTaskService.isMyTaskOpen.getValue()) {
      this.toggleHeaderNav();
      return;
    }

    if (this.isAdminMyTask) {
      if (fromHeader && this.notificationNumber < 1) {
        if (this.myTaskService.isMyTaskOpen.getValue()) {
          this.myTaskService.isMyTaskOpen.next(false);
        } else {
          this.myTaskService.isMyTaskOpen.next(true);
          this.sideNav.open();
        }
      } else {
        this.myTaskService.isMyTaskOpen.next(!this.myTaskService.isMyTaskOpen.getValue());
      }
    } else {
      if (this.mustFill > 0 && !this.responsiveUIService.isMobile && this.isMyTaskOpen) {
        this.dialogService.context('MyTasks.OverdueCloseWarning', true, null, null, null, true).subscribe();
        return;
      }
      this.myTaskService.isMyTaskOpen.next(!this.myTaskService.isMyTaskOpen.getValue());
    }
  }

  linkToTaskObject(task, isMobile) {
    if (isMobile) {
      this.openMyTasks(false);
    }
    if (task.taskRelatedCompany.plukonId) {
      const selectedCompany = this.companyList.find((company) => company.plukonId == task.taskRelatedCompany.plukonId);
      let stabldId;
      if ((task.taskType == 4 || task.taskType == 6) && task.taskSpecifications.id) {
        stabldId = task.taskSpecifications.id;
      }
      if (selectedCompany) {
        this.displayCompanyData(selectedCompany, null, stabldId);
      }
    }

    if (task.taskType == 1 && task.taskSpecifications.id) {
      this.questionnaireService.selectedQuestionnaire.next({
        id: task.taskSpecifications.id.substring(0, task.taskSpecifications.id.indexOf('.')),
      });
    }
    const slug = task.taskURL.split('#').pop();
    if (slug) {
      this.router.navigateByUrl(`${slug}`);
    }
  }

  openVideo(url) {
    this.link = null;
    if (url.includes('/my-flock')) {
      this.link = this.translate.instant('MyFlock.Video');
      openExternalLink(this.link);
    } else {
      this.link = this.translate.instant('MyMessage.Video');
      openExternalLink(this.link);
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  selectedRow(row) {
    this.selectedRowItem = row;
  }

  stableSelectionChange(stable) {
    this.vkiService.selectedStableId.next(stable.plukonId);
  }

  displayCompanyData(data, event, stableId) {
    if (this.responsiveUIService.isMobile) {
      this.opened = false;
    }

    if (this.router.url.includes('/my-company/company') && !this.myPlukonService.companyCanDeactivate.getValue()) {
      alert(this.translate.instant('Warning.SaveChanges'));
    } else {
      this.selectedRowItem = data;
      this.myPlukonService.selectedCompany.next(data);
      this.vkiService.selectedStableId.next(null);
      this.companyName = data.name;

      if (event != null) {
        //mean user click company
        stableId = null;
        this.myPlukonService.flockLoading.next(false);
        this.myPlukonService.routerSelectedCompany.next(null);
      }
      //Lucas Step 3
      this.myPlukonService.company.next({
        stableId, //special for /my-flock usage
        companyId: data.companyId,
        plukonId: data.plukonId,
        companyName: this.companyName,
        country: data.country,
        isGlobal: data.isGlobal,
        companyType: data.companyType,
        companyEnvironment: data.companyEnvironment,
      });

      this.myPlukonService.menuCompany = {
        companyId: data.companyId,
        plukonId: data.plukonId,
        companyName: this.companyName,
        isGlobal: data.isGlobal,
        companyType: data.companyType,
        companyEnvironment: data.companyEnvironment,
      };
    }
  }

  toggleSideNav() {
    this.sideNav.toggle();
  }

  toggleHeaderNav(open?) {
    if (open || this.headerSideNav.opened) {
      this.headerSideNav.toggle();
    }
  }

  setUserManualURL(url) {
    this.userManualURL = url;
  }

  setDocumentNumber(documentNumber) {
    this.documentNumber = documentNumber;
  }

  routeTo(path) {
    this.router.navigate([path]);
    this.toggleHeaderNav();
    this.checkAndCloseMyTask();
  }

  checkAndCloseMyTask() {
    if (this.myTaskService.isMyTaskOpen.getValue()) {
      this.myTaskService.isMyTaskOpen.next(false);
    }
  }

  redirectionALink() {
    openExternalLink('http://' + this.user.environmentObj.globeMenuObj.description)
  }

  downloadManual() {
    openExternalLink(this.userManualURL);
  }

  routeVKIChecks() {
    if (this.userRole === 'Quality employee' && this.environmentShortName === 'kknr-be') {
      this.router.navigate(['admin/vki-check']);
      this.routerService.setRoute('vki-check');
    } else {
      this.router.navigate(['/vki-checks']);
    }
    this.toggleHeaderNav();
  }

  routeNews() {
    this.newsService.initState.next(true);
    this.router.navigate(['/news/concept']);
    this.toggleHeaderNav();
    this.checkAndCloseMyTask();
  }

  openContactDialog() {
    this.dialogService
      .contactDialog(
        this.user.userId,
        this.selectedEnvironment,
        this.user.environmentObj.name ? this.user.environmentObj.name : '',
        this.user.fullName ? this.user.fullName : '',
        this.user.email ? this.user.email : '',
        this.user.environmentObj.address ? this.user.environmentObj.address : '',
        this.user.environmentObj.telephone ? this.user.environmentObj.telephone : '',
      )
      .subscribe();
  }

  logout() {
    this.toggleHeaderNav();
    Promise.all([this.notifications.removeUserDataToNotificationsList(), this.authService.logout()])
      .then(() => {
        if (this.timer) {
          clearTimeout(this.timer);
        }
        window.location.reload();
      }).catch((e) => console.warn('Notifications disabled'));
  }

  ngOnDestroy(): void {
    // sessionStorage.clear();
    if (this.plukonTranslationSub) {
      this.plukonTranslationSub.unsubscribe();
    }
    if (this.myPlukonSubscription) {
      this.myPlukonSubscription.unsubscribe();
    }
    if (this.vkiSubscription) {
      this.vkiSubscription.unsubscribe();
    }
    if (this.companyListSubscription) {
      this.companyListSubscription.unsubscribe();
    }
    if (this.profileSubscription) {
      this.profileSubscription.unsubscribe();
    }
    if (this.selectedCompanySubscription) {
      this.selectedCompanySubscription.unsubscribe();
    }
    if (this.isMyTaskOpenSubscription) {
      this.isMyTaskOpenSubscription.unsubscribe();
    }
    if (this.myTaskSubscription) {
      this.myTaskSubscription.unsubscribe();
    }
    if (this.myTasksTypeCodeSubscription) {
      this.myTasksTypeCodeSubscription.unsubscribe();
    }
    if (this.vkiConfigSubscription) {
      this.vkiConfigSubscription.unsubscribe();
    }
    if (this.is2FADisabledSubscription) {
      this.is2FADisabledSubscription.unsubscribe();
    }
    if (this.timer) {
      clearTimeout(this.timer);
    }
    if (this.authInitializedSubscription) {
      this.authInitializedSubscription.unsubscribe();
    }
    if (this.vkiStableSubscription) {
      this.vkiStableSubscription.unsubscribe();
    }
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }
}
