import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Network } from '@capacitor/network';
import { offlineEnabled } from '../../utils/offline-utils';

const isOfflineAndEnabled = (isOnline) => (offlineEnabled() ? !isOnline : false);

@Injectable({
  providedIn: 'root',
})
export class NetworkService {
  private isOnlineSubject = new BehaviorSubject<boolean>(undefined);

  constructor(private ngZone: NgZone) {
    Network.addListener('networkStatusChange', (status) => {
      this.connected = status.connected;
    });
  }

  public async initialize() {
    const status = await Network.getStatus();
    this.connected = status.connected;
  }

  getNetworkStatus(): Observable<boolean> {
    return this.isOnlineSubject.asObservable();
  }

  getIsOfflineMode$(): Observable<boolean> {
    return this.isOnlineSubject.pipe(map(isOfflineAndEnabled));
  }

  get isOfflineMode(): boolean {
    return isOfflineAndEnabled(this.isOnlineSubject.value);
  }

  private set connected(value: boolean) {
    this.ngZone.run(() => {
      this.isOnlineSubject.next(value);
    });
  }
}
