<div [formGroup]="data.salmonellaForm">
  <div formArrayName="salmonellaRows">
    <div class="salmonella-dialog-cont" [formGroupName]="data.index" >
      <div class="close-mark" (click)="closeDialog()"></div>
      <div *ngFor="let column of data.salmonella.columns">
        <div *ngIf="column.columnDef === 'reportTypeTranslation'" class="form-field-cont">
          <label>{{ column.header | translate }}</label>
          <mat-label *ngIf="(data.index == 0 && data.ruleConfigMap && (data.ruleConfigMap.get('samonellaTableInsertSheet')['state'] === 0
                              || (data.ruleConfigMap.get('samonellaTableInsertSheet')['state'] === 3
                                  && data.ruleConfigMap.get('samonellaTableResearchTypeSelection')['state'] === 0)) )
                            || data.index != 0"
                    class="display-text"
                     [ngClass]="{
                    'warning': ((data.index == data.isSampleNotValidIndex && !data.checked && data.isSampleNotValid) || (data.index == data.isSalmonellaExampleExpiredIndex && !data.checked && data.isSalmonellaExampleExpired)),
                    'error': ((data.index == data.isSampleNotValidIndex && data.checked && data.isSampleNotValid) || (data.index == data.isSalmonellaExampleExpiredIndex && data.checked && data.isSalmonellaExampleExpired))
                    }">
            {{data.salmonella.value.get('reportTypeTranslation').value | translate}}
          </mat-label>
          <mat-form-field *ngIf="(data.index == 0 && data.ruleConfigMap && data.ruleConfigMap.get('samonellaTableResearchTypeSelection')['state'] === 3 && data.ruleConfigMap.get('samonellaTableInsertSheet')['state'] === 3)">
            <mat-select formControlName="reportTypeSelection" (selectionChange)="data.reportTypeSelection(data.index , $event)">
              <mat-option *ngFor="let reportType of data.reportTypeSubList" [value]="reportType.codeTableNumber">
                {{reportType.translateReportType | translate}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="column.columnDef === 'dateSample'" class="form-field-cont">
          <label>{{ column.header | translate }}</label>
          <mat-form-field class="date-form">
            <input matInput
                   formControlName="dateSample"
                   [matDatepicker]="picker"
                   [placeholder]="'Common.dd-mm-yyyy' | translate"
                   [min]="data.salmonella.value.get('minSampleDate').value"
                   [max]="data.salmonella.value.get('maxSampleDate').value"
                   (keydown)="data.dates.dateInput('dateSample', $event, data.index)"
                   (dateChange)="data.dates.dateSampleInput($event, data.index)"
                   maxlength="10">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
        <div *ngIf="column.columnDef === 'dateResult'" class="form-field-cont">
          <label>{{ column.header | translate }}</label>
          <mat-form-field class="date-form">
            <input matInput formControlName="dateResult"
                   [matDatepicker]="picker2"
                   [placeholder]="'Common.dd-mm-yyyy' | translate"
                   [min]="data.salmonella.value.get('minResultDate').value"
                   [max]="data.salmonella.value.get('maxResultDate').value"
                   (keydown)="data.dates.dateInput('dateResult', $event, data.index)"
                   (dateChange)="data.dates.dateResultInput($event, data.index)"
                   maxlength="10">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>
        </div>
        <div *ngIf="column.columnDef === 'result'" class="form-field-cont">
          <label>{{ column.header | translate }}</label>
          <mat-form-field>
            <mat-select (mousedown)="data.results.clearResultFilter(data.index)" formControlName="result"
                        (selectionChange)="data.results.changeDetection()" (openedChange)='responsiveUIService.optionOpened($event)'>
              <mat-option>
                <ngx-mat-select-search [placeholderLabel]="'Common.Search' | translate"
                                       [noEntriesFoundLabel]="'Common.NoResult' | translate" #search (input)="data.results.filterResult(data.index, search)">
                </ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let result of data.results.filteredResultListBigArray[data.index] | async" [value]="result.id">
                {{result.name}}
              </mat-option>
              <mat-option *ngIf="(data.results.filteredResultListBigArray[data.index] | async).length == 0" disabled>
                {{'Common.NoResult' | translate}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="column.columnDef === 'laboratory'" class="form-field-cont">
          <label>{{ column.header | translate }}</label>
          <mat-form-field>
            <mat-select (mousedown)="data.laboratory.clearLaboratoryFilter(data.index)" formControlName="laboratory"
                        (selectionChange)="data.laboratory.laboratorySelection(data.index , $event)" (openedChange)='responsiveUIService.optionOpened($event)'>
              <mat-option>
                <ngx-mat-select-search [placeholderLabel]="'Common.Search' | translate"
                                       [noEntriesFoundLabel]="'Common.NoResult' | translate" #search
                                       (input)="data.laboratory.filterLaboratory(data.index, search)">
                </ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let laboratory of data.laboratory.filteredLaboratoryListBigArray[data.index] | async"
                          [value]="laboratory.labId" [disabled]="laboratory.labId === 'PLABW'">
                {{laboratory.labName}}
              </mat-option>
              <mat-option *ngIf="(data.laboratory.filteredLaboratoryListBigArray[data.index] | async).length == 0" disabled>
                {{'Common.NoResult' | translate}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="column.columnDef === 'referenceNumber'" class="form-field-cont">
          <label>{{ column.header | translate }}</label>
          <mat-form-field
            [ngClass]="{'small-error-number-form': data.salmonella.value.get('referenceNumberError').value}">
            <input matInput #referenceNumber formControlName="referenceNumber" maxlength="50" (input)="data.setReferenceNumber(data.index, referenceNumber)">
          </mat-form-field>
        </div>
        <div *ngIf="column.columnDef === 'takenBy'" class="form-field-cont">
          <label> {{ column.header | translate }}</label>
          <mat-form-field
            [ngClass]="{'small-error-number-form': data.salmonella.value.get('takenByError').value}">
            <input matInput #takenBy formControlName="takenBy" maxlength="150" (input)="data.setTakenBy(data.index, takenBy)">
          </mat-form-field>
        </div>
      </div>
      <div class="action-buttons-cont">
        <button *ngIf="data.salmonella.value.get('icon').value === 'bin' && !data.editBlocked"
                mat-icon-button
                (click)="data.deleteSalmonella(data.index)"
        >
          <i class="material-icons">delete_forever</i>
        </button>
        <button *ngIf="data.salmonella.value.get('icon').value === 'lock' && !data.editBlocked"
                type="button"
                mat-icon-button>
          <i class="material-icons">lock</i>
        </button>
        <div class="close-btn">
          <button class="button-green" mat-raised-button (click)="closeDialog()" >
            {{'VKIMobile.Close' | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
