import { Injectable } from '@angular/core';
import { HttpContext } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { Https } from '../../http.service';
import { FLOCK_NUMBER, FLOCK_REQUEST_EXTRA_KEY, FLOCK_REQUEST_KEY, METADATA } from 'src/app/interceptors/my-flock.interceptor';
import { KEY_CANCEL_REQUEST, KEY_FLOCK_STEP_MEDICATION, KEY_UPLOAD_TEMP_FILE } from 'src/lib/my-flock/constants';

@Injectable({
  providedIn: 'root',
})
export class MedicationService {
  currentMedicationData: any;
  currentMedicationDataOri: any;
  currentIllnessListData: any;
  currentAntiBioticData: any;
  currentUnitListData: any;
  currentMedicationError: any;
  currentUploadDataOri: any;

  constructor(private http: Https) {}

  public getMedication(flockNumber) {
    const context = new HttpContext();
    context.set(FLOCK_NUMBER, flockNumber);
    context.set(FLOCK_REQUEST_KEY, KEY_FLOCK_STEP_MEDICATION);
    return this.http.get(`${environment.baseUrl}/plukonKras/flock/medication/${flockNumber}`, undefined, undefined, context);
  }

  public getAntiBioticList(stableId) {
    return this.http.get(`${environment.baseUrl}/plukonKras/therapy/antibiotic/${stableId}`, '');
  }

  public saveMedication(payload) {
    const context = new HttpContext();
    context.set(FLOCK_NUMBER, payload?.flockNumber);
    context.set(FLOCK_REQUEST_KEY, KEY_FLOCK_STEP_MEDICATION);
    return this.http.post(`${environment.baseUrl}/plukonKras/flock/medication`, payload, undefined, context);
  }

  public uploadFile(data) {
    const context = new HttpContext();
    context.set(FLOCK_NUMBER, data.get('flockNumber'));
    context.set(FLOCK_REQUEST_KEY, KEY_FLOCK_STEP_MEDICATION);
    context.set(FLOCK_REQUEST_EXTRA_KEY, KEY_UPLOAD_TEMP_FILE);
    context.set(METADATA, {
      uuid: data.get('uuid'),
    });
    return this.http.upload(`${environment.baseUrl}/plukonKras/flock/medication/tempfile`, data, undefined, context);
  }

  public cancelUpload(payload) {
    const context = new HttpContext();
    context.set(FLOCK_NUMBER, payload?.flockNumber);
    context.set(FLOCK_REQUEST_KEY, KEY_FLOCK_STEP_MEDICATION);
    context.set(FLOCK_REQUEST_EXTRA_KEY, KEY_CANCEL_REQUEST);
    return this.http.post(`${environment.baseUrl}/plukonKras/flock/medication/tempfile/cancel`, payload, undefined, context);
  }

  set medicationData(value: any) {
    this.currentMedicationData = value;
  }

  get medicationData(): any {
    return this.currentMedicationData;
  }

  set medicationDataOri(value: any) {
    this.currentMedicationDataOri = value;
  }

  get medicationDataOri(): any {
    return this.currentMedicationDataOri;
  }

  set illnessListData(value: any) {
    this.currentIllnessListData = value;
  }

  get illnessListData(): any {
    return this.currentIllnessListData;
  }

  set antiBioticListData(value: any) {
    this.currentAntiBioticData = value;
  }

  get antiBioticListData(): any {
    return this.currentAntiBioticData;
  }

  set unitListData(value: any) {
    this.currentUnitListData = value;
  }

  get unitListData(): any {
    return this.currentUnitListData;
  }

  set medicationError(value: any) {
    this.currentMedicationError = value;
  }

  get medicationError(): any {
    return this.currentMedicationError;
  }

  set uploadDataOri(value: any) {
    this.currentUploadDataOri = value;
  }

  get uploadDataOri(): any {
    return this.currentUploadDataOri;
  }
}
