<app-offline-status></app-offline-status>
<app-header *ngIf="router.url !== '/error/unauthorized' && router.url !== '/error'" [url]="router.url"
  (sideMenuTrigger)="toggleSideNav()" (mobileHeaderMenuTrigger)="toggleHeaderNav($event)" (userManualURLEmitter)="setUserManualURL($event)"
  (openMyTasksTrigger)="openMyTasks(true)" (documentNumberEmitter)="setDocumentNumber($event)" (mobileCloseMyTasksEmitter)="checkAndCloseMyTask()" [companyList]="companyList" [notificationNumber]="notificationNumber"
  [isAdminMyTask]="isAdminMyTask"></app-header>
<app-loading-spinner [show]=!appInitialized></app-loading-spinner>
<mat-sidenav-container class="side-navbar" autosize [ngStyle]="{'padding-top': appTopStickyHeight() + 'px'}">
  <mat-sidenav *ngIf="responsiveUIService.isMobile$ | async" #headerSideNav position="end" mode="over" [(opened)]="isHeaderNavOpen"
    class="mat-mobile-sidenav">
    <div class="nav-header" fxLayout="row" fxLayoutAlign="space-between">
      <div class="name-container">
        <div class="plukon-wrapper">
          <img class="plukon-logo" src="/assets/icons/icon-48x48.png" alt="">
        </div>
        {{ username }}
      </div>
      <div style="cursor: pointer;">
        <i class="material-icons" (click)="toggleHeaderNav()">clear</i>
      </div>
    </div>
    <div class="nav-content" fxLayout="column">
      <div fxLayout="row" class="icon-menu notification-menu" *ngIf="!offlineMode && !router.url.startsWith('/login') && (notificationNumber > 0 || isAdminMyTask)" (click)="openMyTasks(true, true)">
        <span *ngIf="isAdminMyTask; else commonNotification" class="notification-container"><i
          class="material-icons-outlined notification-icons">notifications</i></span>
        <ng-template #commonNotification>
          <span class="notification-container" [matBadge]=notificationNumber matBadgeOverlap="true"
            matBadgeSize="small" matBadgeColor="warn" [matBadgeHidden]="notificationNumber < 1"><i
              class="material-icons-outlined notification-icons">notifications</i></span>
        </ng-template>
        {{'Header.MyTasks' | translate}}
      </div>
      <div fxLayout="row" class="icon-menu" (click)="routeTo('/my-company/company')"
        *ngIf="!offlineMode && router.url !== '/login' && router.url !== '/unauthorized' && router.url !== '/term-and-condition' && (!vetenarianView || isSuperAdmin || isQualityEmployee) && !isInternalMail">
        <i class="material-icons">apartment</i>
        {{'Header.MyCompany' | translate}}
      </div>
      <div fxLayout="row" class="icon-menu message-menu" (click)="routeTo('/my-messages/documentation')" *ngIf="!offlineMode && router.url !== '/login' && router.url !== '/unauthorized' && router.url !== '/term-and-condition' && (!vetenarianView || isSuperAdmin) && !isInternalMail">
        <i class="material-icons">description</i>
        <span class="notification-container" [matBadge]=documentNumber matBadgeOverlap="true"
            matBadgeSize="small" matBadgeColor="warn" [matBadgeHidden]="documentNumber === 0 || isAdmin || isSuperAdmin">{{'Header.Communication' | translate}}</span>
      </div>
      <div fxLayout="row" class="icon-menu" (click)="routeTo('/my-flock/vki-data')" *ngIf="url !== '/login' && url !== '/unauthorized' && url !== '/term-and-condition' && (!vetenarianView || isSuperAdmin) && !isGlobal && !isInternalMail">
        <i class="material-icons">gite</i>
        {{'Header.MyFlock' | translate}}
      </div>
      <!-- https://gcompany-projects.atlassian.net/browse/TP-1106 Hide menu items not used via mobile app
        <div fxLayout="row" class="icon-menu" (click)="routeTo('/my-reports')" *ngIf="!offlineMode && url !== '/login' && url !== '/unauthorized' && url !== '/term-and-condition' && ((hasPoultryOwner && !isGlobal) || isAdmin) && environmentShortName !== 'kknr-be' && environmentShortName !== 'agri-de' && !isQualityEmployee && !isBlocked && !isInternalMail">
          <i class="material-icons">bar_chart</i>
          {{'Header.MyReports' | translate}}
        </div>
        <div fxLayout="row" class="icon-menu" (click)="routeVKIChecks()" *ngIf="!offlineMode && url !== '/login' && url !== '/unauthorized' && url !== '/term-and-condition' && (vetenarianView || isSuperAdmin) && !isInternalMail">
          <i class="material-icons">fact_check</i>
          {{'Header.VKIChecks' | translate}}
        </div>
      -->
      <div fxLayout="row" class="icon-menu" (click)="routeTo('/my-feedback/question-entries')" *ngIf="!offlineMode && url !== '/login' && url !== '/unauthorized' && url !== '/term-and-condition' && (isAdmin || hasPoultryOwner) && !isInternalMail">
        <i class="material-icons">sentiment_very_satisfied</i>
        {{'Header.MyFeedback' | translate}}
      </div>
      <div fxLayout="row" class="icon-menu" (click)="routeNews()" *ngIf="!offlineMode && url !== '/login' && url !== '/unauthorized' && url !== '/term-and-condition' && (!vetenarianView || isSuperAdmin || isQualityEmployee) && !isInternalMail">
        <i class="material-icons">newspaper</i>
        {{'Header.News' | translate}}
      </div>
      <mat-divider class="divider"></mat-divider>
      <div *ngIf="redirectionA" class="icon-menu" fxLayout="row" (click)="redirectionALink()">
        <i class="material-icons globe">public</i>
        {{redirectionA | translate}}
      </div>
      <div fxLayout="row" class="icon-menu" (click)="routeTo('/user/info')" *ngIf="!offlineMode">
        <i class="material-icons">person</i>
        {{'Menu.UserInfo' | translate}}
      </div>
      <div fxLayout="row" class="icon-menu" (click)="routeTo('/user/settings')" *ngIf="!offlineMode">
        <i class="material-icons">settings</i>
        {{'Menu.Settings' | translate}}
      </div>
      <div fxLayout="row" class="icon-menu" (click)="openContactDialog()" *ngIf="!offlineMode">
        <i class="material-icons">chat</i>
        {{'Header.Contact' | translate}}
      </div>
      <div fxLayout="row" class="icon-menu" (click)="downloadManual()" *ngIf="userManualURL !== ''">
        <i class="material-icons">question_mark</i>
        {{'Header.DownloadManual' | translate}}
      </div>
      <div fxLayout="row" class="icon-menu" (click)="logout()">
        <i class="material-icons">logout</i>
        {{'Header.Logout' | translate}}
      </div>
      <mat-divider class="divider"></mat-divider>
      <div fxLayout="row" class="icon-menu" (click)="routeTo('/terms-of-use')">
        {{'Footer.TermsOfUse' | translate}}
      </div>
      <div fxLayout="row" class="icon-menu" (click)="routeTo('/cookie-statement')">
        {{'Footer.CookieStatement' | translate}}
      </div>
      <div fxLayout="row" class="icon-menu" (click)="routeTo('/privacy-statement')">
        {{'Footer.PrivacyStatement' | translate}}
      </div>
      <app-dev-items *ngIf="isDevMode" [precache]="precache"></app-dev-items>
    </div>
    <div class="fixed-link">
      <label class="link-item"><i class="material-icons copyright">copyright</i>{{'Footer.PlukonFoodGroup' |
        translate}}</label>
    </div>
  </mat-sidenav>
  <mat-sidenav *ngIf="(responsiveUIService.isMobile$ | async) === false && !router.url.startsWith('/logoutsuccess') && !router.url.startsWith('/login') &&
          !router.url.startsWith('/term-and-condition') &&
          router.url !== '/unauthorized' && router.url !== '/unauthorized/maintenance' && router.url !== '/' &&
          !router.url.startsWith('/admin') && !router.url.startsWith('/vki-checks') &&
          !router.url.startsWith('/user') && !router.url.startsWith('/home') &&
          !router.url.startsWith('/error') && !router.url.startsWith('/terms-of-use') &&
          !router.url.startsWith('/cookie-statement') && !router.url.startsWith('/privacy-statement') &&
          !router.url.startsWith('/my-reports') && !router.url.startsWith('/news') &&
          !router.url.startsWith('/my-feedback/questionnaires') &&
          companyList.length > 1" #sidenav [mode]="(responsiveUIService.isMobile$ | async) === false ? 'side' : 'over'" [(opened)]="opened"
    position="start">
    <div fxLayout="column" class="sidenav-scroll">
      <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="{{'Common.Search' | translate}}">
      </mat-form-field>
      <div class="scrollbar">
        <table class="company-table" mat-table [dataSource]="dataSource" matSort>
          <!-- dynamic render table -->
          <ng-container *ngFor="let column of columns" [cdkColumnDef]="column.columnDef">
            <ng-container>
              <td mat-cell *cdkCellDef="let row" (click)="displayCompanyData(row,$event)">
                <ng-container *ngIf="column.cell(row) !== 'true' && column.cell(row) !== 'false'">
                  {{ column.cell(row) }}
                </ng-container>
              </td>
            </ng-container>
          </ng-container>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"
            [ngClass]="{ 'selected': row === selectedRowItem }" class="row-hover"></tr>
        </table>
      </div>
    </div>
  </mat-sidenav>
  <mat-sidenav #myTask position="end" [mode]="(responsiveUIService.isMobile$ | async) === false ? 'side' : 'over'" [(opened)]="isMyTaskOpen"
    class="mytask-navbar" [style]="'--appHeaderHeight: ' + getAppHeaderHeight() + 'px'" *ngIf="!offlineMode && !router.url.startsWith('/logoutsuccess') && !router.url.startsWith('/login') &&
          router.url !== '/unauthorized' && router.url !== '/unauthorized/maintenance' &&
          !router.url.startsWith('/error') && (notificationNumber > 0 || isAdminMyTask)">
    <div class="mytask-top-header">
      <span>{{'Header.MyTasks' | translate}}</span>
      <i class="material-icons" (click)="openMyTasks(false)">clear</i>
    </div>
    <div *ngIf="isAdminMyTask && notificationNumber !== null && notificationNumber > 0" class="mytask-label">
      {{ 'MyTasks.AdminFunctionFixedText' | translate }}
    </div>
    <div *ngIf="isAdminMyTask && (!notificationNumber || notificationNumber < 0); else taskList" class="mytask-label">
      {{(!myPlukonService.company.getValue() ? ('MyTasks.AdminFunctionSelectCompany' | translate) : ('Common.NoRecord'
      | translate))}}
    </div>
    <ng-template #taskList>
      <mat-accordion>
        <mat-expansion-panel *ngFor="let task of myTasksList; let i = index" class="mytask-panel expansion-header"
          [expanded]="expandedIndex === i" (opened)="setExpandIndex(i)"
          [ngClass]="{ 'overdue': task.taskStatus == 4, 'secondWarning': task.taskStatus == 3, 'firstWarning': task.taskStatus == 2, 'display': task.taskStatus == 1 }">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span>{{ getTaskTitle(task.taskType) | translate}}</span>
              <span class="task-desc" *ngIf="task.taskType !== 2">{{ getTaskTitleSecondRow(task) }}</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="expansion-box" fxLayout="row" fxLayoutAlign="start">
            <label fxFlex=30 class="lbl-task">{{'MyTasks.TaskType' | translate}}</label>
            <div fxFlex=70 class="lbl-task-detail">
              <label>{{ getTaskTypeName(task) | translate}}</label>
            </div>
          </div>
          <div class="expansion-box" fxLayout="row" fxLayoutAlign="start">
            <label fxFlex=30 class="lbl-task">{{'MyTasks.TaskDescription' | translate}}</label>
            <div fxFlex=70 class="lbl-task-detail">
              <label>{{ replaceDescriptionObject(getTaskDescriptionByTaskType(task.taskType)[task.taskStatus-1], task)
                }}</label>
            </div>
          </div>
          <div class="expansion-box" fxLayout="row" fxLayoutAlign="start">
            <label fxFlex=30 class="lbl-task">{{'MyTasks.TaskObject' | translate}}</label>
            <div fxFlex=70 class="lbl-task-detail">
              <a href="javascript:void(0);" (click)="linkToTaskObject(task, true)">{{ 'MyTasks.TaskObject' | translate}}</a>
            </div>
          </div>
          <div class="expansion-box" fxLayout="row" fxLayoutAlign="start">
            <label fxFlex=30 class="lbl-task">{{'MyTasks.DateCreated' | translate}}</label>
            <div fxFlex=70 class="lbl-task-detail">
              <label>{{ task.taskCreationDate }}</label>
            </div>
          </div>
          <div class="expansion-box" fxLayout="row" fxLayoutAlign="start">
            <label fxFlex=30 class="lbl-task">{{'MyTasks.DueDate' | translate}}</label>
            <div fxFlex=70 class="lbl-task-detail">
              <label>{{ task.taskDueDate }}</label>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </ng-template>
  </mat-sidenav>
  <mat-sidenav-content
    [style]="'--appTopStickyHeight: ' + appTopStickyHeight() + 'px; --contentPaddingTop: ' + ((router.url.startsWith('/login') || router.url === '/' || router.url.startsWith('/term-and-condition')) ? 'env(safe-area-inset-top, 20px);' : 'calc(143.75px + env(safe-area-inset-top, 20px));') + '--footerMargin: ' + (router.url.startsWith('/my-flock') ? '0' : '74' ) + 'px'">
    <div *ngIf="(responsiveUIService.isMobile$ | async) === false && !offlineMode && !router.url.startsWith('/logoutsuccess') && !router.url.startsWith('/login') &&
      router.url !== '/unauthorized' && router.url !== '/unauthorized/maintenance' &&
      !router.url.startsWith('/error') && (notificationNumber > 0 || isAdminMyTask)"
      [style]="'--myTaskFloatingRight: ' + getMyTaskFloatingRight() + 'px; --appHeaderHeight: ' + getAppHeaderHeight() + 'px;'"
      class="floating-button">
      <i *ngIf="isMyTaskOpen; else closedMyTaskFloatingButton" class="material-icons floating-icons"
        (click)="openMyTasks()">clear</i>
      <ng-template #closedMyTaskFloatingButton>
        <i class="material-icons floating-icons" (click)="openMyTasks()">chevron_right</i>
      </ng-template>
    </div>
    <div *ngIf="(responsiveUIService.isMobile$ | async) === false">
      <div class="company" fxLayout="row" fxLayoutAlign="space-between center"
        *ngIf="router.url.startsWith('/my-company') ||  router.url.startsWith('/my-messages') || router.url.startsWith('/my-flock') || router.url.startsWith('/my-feedback/question-entries')">
        <span *ngIf="companyName">{{companyName}}</span>
        <span *ngIf="!companyName">{{'Common.NoCompany' | translate}}</span>
        <button *ngIf="(router.url.startsWith('/my-flock') || router.url.startsWith('/my-messages'))" class="video-button"
          mat-button (click)="openVideo(router.url)">
          <i class="material-icons">movie</i> {{'WatchVideo.Text' | translate}}
        </button>
        <div *ngIf="stable && router.url.startsWith('/my-flock')" class="stable">{{stable}}</div>
      </div>
    </div>
      <app-mobile-company-selector
        [companyList]="companyList"
        [displayCompanyData]="displayCompanyData"
        [stableSelectionChange]="stableSelectionChange"
        [selectedRowItem]="selectedRowItem"
        [selectedStableItem]="selectedStableId"
      ></app-mobile-company-selector>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
<app-bottom-navigation
  *ngIf="!router.url.startsWith('/login') && !router.url.startsWith('/unauthorized') && !router.url.startsWith('/logoutsuccess') && !router.url.startsWith('/my-flock')"></app-bottom-navigation>
<app-footer *ngIf="!router.url.startsWith('/login') && !router.url.startsWith('/term-and-condition')"></app-footer>
<app-before-install-prompt></app-before-install-prompt>
