import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { forEach } from 'lodash';
import { Subscription } from 'rxjs';
import { MyErrorStateMatcher } from '../../functions/error-matcher';
import { DocumentationService } from '../../services/communication/documentation.service';
import { DialogService } from '../../services/dialog.service';

@Component({
  selector: 'app-email-document-dialog',
  templateUrl: './email-document-dialog.component.html',
  styleUrls: ['./email-document-dialog.component.scss'],
})
export class EmailDocumentDialogComponent implements OnInit {
  documentSharingSubscription: Subscription;
  sendDocExternalEmailSubscription: Subscription;
  email: string;
  companyId: any;
  data: any;
  canSend: any;
  showSpinner: boolean;
  saveButton: boolean;
  cancelButton: boolean;
  saveButtonAvailability = true;
  matcher = new MyErrorStateMatcher();

  constructor(
    public dialogRef: MatDialogRef<EmailDocumentDialogComponent>,
    public translate: TranslateService,
    private dialogService: DialogService,
    private documentationService: DocumentationService,
    public snackBar: MatSnackBar,
  ) {}

  emailList = [];
  newEmailList = [];

  emailForm = new UntypedFormGroup({
    emailFormControl: new UntypedFormControl('', [Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,4}$')]),
  });

  validationError = {
    email: [{ type: 'pattern', message: 'EmailDocumentDialog.EmailInvalid' }],
  };

  ngOnInit(): void {
    this.showSpinner = true;
    if (this.companyId && this.data) {
      this.documentSharingSubscription = this.documentationService
        .getDocExternalEmailList(this.companyId, this.data.documentTypeId)
        .subscribe(
          (result) => {
            if (result && result['list'] && result['list'].length > 0) {
              result['list'].forEach((data) => {
                this.emailList.push({
                  email: data.email,
                });
              });
            } else {
              this.emailList = [];
            }
            this.showSpinner = false;
          },
          (error) => {
            this.showSpinner = false;
            console.log(error);
            switch (error.status) {
              case 401:
                this.snackBar.open(this.translate.instant('Portal.UnauthorizedAPICall'), 'Close', { verticalPosition: 'top' });
                break;
              default:
                break;
            }
          },
        );
    }
  }

  addEmail() {
    this.changeDetection();
    if (this.email && this.email !== '') {
      this.emailList.push({
        email: this.email,
      });
      this.newEmailList.push({
        email: this.email,
      });
      this.emailForm.reset();
    }
    // TP-873 Check duplicate email
    // if (this.email && this.email !== "") {
    //   if (this.emailList.find((data) => data.email === this.email)) {
    //     this.dialogService.confirm('EmailDocumentDialog.EmailExisted', false, true);
    //   } else {
    //     this.emailList.push({
    //       'email': this.email
    //     });
    //     this.newEmailList.push({
    //       'email': this.email
    //     });
    //     this.emailForm.reset();
    //   }
    // }
  }

  saveAvailable() {
    if (this.emailForm.valid) {
      this.saveButtonAvailability = true;
    } else {
      this.saveButtonAvailability = false;
    }
  }

  changeDetection() {
    if (this.emailForm.dirty) {
      this.saveAvailable();
    } else {
      this.saveButtonAvailability = true;
    }
  }

  sendEmail() {
    // 10-Nov-2022 Kasim request change Add button function
    // if (this.email && this.email.length > 0) {
    //   this.dialogService.confirm('EmailDocumentDialog.UnsavedEmail', false, true);
    // }
    if (!(this.email && this.email !== '') && this.emailList.length < 1 && this.newEmailList.length < 1) {
      this.dialogService.confirm('EmailDocumentDialog.NoMailReceipient', false, true);
    } else {
      const newEmailDataList = _.cloneDeep(this.newEmailList);
      if (this.email && this.email !== '') {
        newEmailDataList.push({
          email: this.email,
        });
      }
      this.showSpinner = true;
      const data = {
        list: newEmailDataList,
      };

      this.sendDocExternalEmailSubscription = this.documentationService
        .sendDocExternalEmail(this.companyId, this.data.document, data)
        .subscribe(
          (result) => {
            if (result) {
              this.showSpinner = false;
              this.dialogRef.close();
            }
          },
          (error) => {
            this.showSpinner = false;
            console.log(error);
            switch (error.status) {
              case 401:
                this.snackBar.open(this.translate.instant('Portal.UnauthorizedAPICall'), 'Close', { verticalPosition: 'top' });
                break;
              default:
                break;
            }
          },
        );
    }
  }

  ngOnDestroy() {
    if (this.documentSharingSubscription) {
      this.documentSharingSubscription.unsubscribe();
    }
    if (this.sendDocExternalEmailSubscription) {
      this.sendDocExternalEmailSubscription.unsubscribe();
    }
  }
}
