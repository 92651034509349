import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { ALLOW_WITHOUT_AUTH, SKIP_AUTH } from 'src/app/interceptors/auth-interceptor';

interface CustomHttpGetParams {
  noCache?: boolean;
  skipAuth?: boolean;
  allowWithoutAuth?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class Https {
  idToken: BehaviorSubject<string>;

  constructor(private https: HttpClient) {}

  get(url: string, params?, { noCache, skipAuth, allowWithoutAuth }: CustomHttpGetParams = {}, context = new HttpContext()) {
    try {
      const httpHeaders = new HttpHeaders();
      if (skipAuth) {
        context.set(SKIP_AUTH, true);
      }
      if (allowWithoutAuth) {
        context.set(ALLOW_WITHOUT_AUTH, true);
      }
      if (noCache) {
        httpHeaders.set('Cache-Control', 'no-cache, no-transform');
      }
      return this.https.get(url, { headers: httpHeaders, params, context });
    } catch (e) {
      // why the logic is duplicated here?
      console.log(e);
      const context = new HttpContext();
      if (skipAuth) {
        context.set(SKIP_AUTH, true);
      }
      if (noCache) {
        const httpHeaders = new HttpHeaders().set('Cache-Control', 'no-cache, no-transform');
        return this.https.get(url, { headers: httpHeaders, params, context });
      }
      return this.https.get(url, { params, context });
    }
  }
  post(url, data, params?, context?: HttpContext) {
    const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Accept', 'application/json');
    return this.https.post(url, data, { headers: httpHeaders, params, context });
  }
  postJson(url, data) {
    const header = new HttpHeaders();
    header.append('Content-Type', 'application/json');
    header.append('Accept', 'application/json');

    return this.https.post(url, data, { headers: header });
  }

  posts(url, data) {
    const header = new HttpHeaders();
    header.append('Content-Type', 'application/json');
    header.append('Accept', 'application/json');

    const test = JSON.stringify({ name: 'Tan Eng Boon', age: '31', address: 'desa park city' });

    return this.https.post(url, test, { headers: header });
  }

  upload(url, data, params?, context?: HttpContext) {
    const httpHeaders = new HttpHeaders();
    return this.https.post(url, data, { headers: httpHeaders, params, context });
  }

  delete(url) {
    const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Accept', 'application/json');
    return this.https.delete(url, { headers: httpHeaders });
  }

  put(url, data) {
    const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Accept', 'application/json');
    return this.https.put(url, data, { headers: httpHeaders });
  }


  getWithBlob(url: string, params?, {
    skipAuth,
    allowWithoutAuth
  }: CustomHttpGetParams = {}, context = new HttpContext()) {
    try {
      const httpHeaders = new HttpHeaders();
      if (skipAuth) {
        context.set(SKIP_AUTH, true);
      }
      if (allowWithoutAuth) {
        context.set(ALLOW_WITHOUT_AUTH, true);
      }
      httpHeaders.set('Cache-Control', 'no-cache, no-transform');
      return this.https.get(url, {headers: httpHeaders, params, context, observe: 'response', responseType: 'blob'});
    } catch (e) {
      // why the logic is duplicated here?
      console.log(e);
      const context = new HttpContext();
      if (skipAuth) {
        context.set(SKIP_AUTH, true);
      }
      const httpHeaders = new HttpHeaders().set('Cache-Control', 'no-cache, no-transform');
      return this.https.get(url, {headers: httpHeaders, params, context, observe: 'response', responseType: 'blob'});
    }
  }
}
