export function deepCompare(obj1: any, obj2: any, skipProperties: string[] = []): boolean {
  if (typeof obj1 !== typeof obj2) {
    return false;
  }

  if (typeof obj1 === 'object' && obj1 !== null && obj2 !== null) {
    if (Array.isArray(obj1) && Array.isArray(obj2)) {
      if (obj1.length !== obj2.length) {
        return false;
      }

      for (let i = 0; i < obj1.length; i++) {
        if (!deepCompare(obj1[i], obj2[i], skipProperties)) {
          return false;
        }
      }
    } else {
      const keys1 = Object.keys(obj1).filter((key) => !skipProperties.includes(key));
      const keys2 = Object.keys(obj2).filter((key) => !skipProperties.includes(key));

      if (keys1.length !== keys2.length) {
        return false;
      }

      for (const key of keys1) {
        if (!keys2.includes(key) || !deepCompare(obj1[key], obj2[key], skipProperties)) {
          return false;
        }
      }
    }
  } else {
    return obj1 === obj2;
  }

  return true;
}
