<div class="email-document-dialog-container">
  <mat-dialog-content [formGroup]="emailForm">
    <label>
      <span [translate]="'EmailDocumentDialog.HeaderText'" [translateParams]="{docType: data.documentType | translate}"></span>
    </label>
    <div>
      <ng-container *ngFor="let email of emailList">
        <div style="padding-top:20px">
          <label>{{ email.email }}</label>
        </div>
      </ng-container>
    </div>
    <div fxLayout="row">
      <div fxLayout="column" class="input-container" fxLayoutAlign="flex-start flex-start">
        <mat-form-field>
          <input matInput formControlName="emailFormControl" [errorStateMatcher]="matcher"
            [(ngModel)]="email" (keydown)="changeDetection()">
          <mat-error *ngFor="let validation of validationError.email">
            <mat-error *ngIf="emailForm.get('emailFormControl').hasError(validation.type)">{{validation.message | translate}}</mat-error>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>

  <div fxLayout="row" fxLayoutAlign="flex-start center">
    <button
      [ngClass]="{'icon-button-disabled': !emailForm.valid, 'icon-button-enabled': emailForm.valid}"
      class="icon-button" mat-raised-button (click)="addEmail()"
      [disabled]="!emailForm.valid">
      <i style="position: unset !important;" class="material-icons">add</i>
    </button>
  </div>

  <mat-dialog-actions fxLayout="row" fxLayoutAlign="flex-end center">
    <button class="button-enabled" mat-button mat-dialog-close (click)="dialogRef.close()">
      {{'Common.Cancel' | translate}}
    </button>
    <button [ngClass]="{'button-disabled': !emailForm.valid || !canSend, 'button-enabled': emailForm.valid && canSend}" mat-button (click)="sendEmail()" [disabled]="!emailForm.valid || !canSend">
      {{'Common.Send' | translate}}
    </button>
  </mat-dialog-actions>
  <i style="color: red;" class="material-icons" (click)="dialogRef.close()">clear</i>
  <app-loading-spinner [show]=showSpinner></app-loading-spinner>
</div>
