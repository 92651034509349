import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { LandingComponent } from './components/landing/landing.component';
import { UserComponent } from './components/user/user.component';
import { TermOfUseComponent } from './components/term-of-use/term-of-use.component';
import { CookieStatementComponent } from './components/cookie-statement/cookie-statement.component';
import { PrivacyStatementComponent } from './components/privacy-statement/privacy-statement.component';
import { TermsAndConditionComponent } from './components/terms-and-condition/terms-and-condition.component';
import { ErrorComponent } from './shared/components/error/error.component';
import { UnauthorizeComponent } from './shared/components/unauthorize/unauthorize.component';
import { LogoutComponent } from './shared/components/logout/logout.component';
import { AuthGuardService as AuthGuard } from './shared/services/auth-guard.service';
import { UnauthorizeMaintenanceComponent } from './shared/components/unauthorize-maintenance/unauthorize-maintenance.component';

const routes: Routes = [
  { path: '', component: LandingComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'login/:environment', component: LoginComponent },
  { path: 'login/:environment/:language', component: LoginComponent },
  { path: 'user/settings', loadChildren: () => import('./modules/setting/setting.module').then((m) => m.SettingModule) },
  { path: 'terms-of-use', component: TermOfUseComponent },
  { path: 'cookie-statement', component: CookieStatementComponent },
  { path: 'privacy-statement', component: PrivacyStatementComponent },
  { path: 'term-and-condition', component: TermsAndConditionComponent },
  { path: 'user/info', component: UserComponent },
  { path: 'admin', loadChildren: () => import('./modules/admin/admin.module').then((m) => m.AdminModule) },
  { path: 'vki-checks', loadChildren: () => import('./modules/vki-checks/vki-checks.module').then((m) => m.VKICheckModule) },
  { path: 'my-company', loadChildren: () => import('./modules/my-plukon/my-plukon.module').then((m) => m.MyPlukonModule) }, //name change from my-plukon to my-company
  { path: 'my-messages', loadChildren: () => import('./modules/communication/communication.module').then((m) => m.CommunicationModule) }, //name change from communication to my-messages
  {
    path: 'my-flock',
    loadChildren: () => import('./modules/my-flock/my-flock.module').then((m) => m.MyFlockModule),
    data: { skipReuse: true },
  },
  { path: 'my-reports', loadChildren: () => import('./modules/my-reports/my-reports.module').then((m) => m.MyReportsModule) },
  { path: 'news', loadChildren: () => import('./modules/news/news.module').then((m) => m.NewsModule) },
  { path: 'my-feedback', loadChildren: () => import('./modules/my-feedback/my-feedback.module').then((m) => m.MyFeedbackModule) },
  { path: 'unauthorized/maintenance', component: UnauthorizeMaintenanceComponent },
  { path: 'unauthorized', component: UnauthorizeComponent },
  { path: 'logoutsuccess', component: LogoutComponent },
  { path: '**', component: ErrorComponent },
];

export const AppRoutingModule: ModuleWithProviders<unknown> = RouterModule.forRoot(routes, {
  useHash: true,
  onSameUrlNavigation: 'reload',
});
