<div fxLayout="row" class="icon-menu" (click)="toggleShowPrecacheNotification()">
  <i class="material-icons">open_in_new</i>
  Toggle Precache Notification State()
</div>
<div fxLayout="row" class="icon-menu" (click)="cleanupCache()">
  <i class="material-icons">open_in_new</i>
  Cleanup User Cache
</div>
<div fxLayout="row" class="icon-menu" (click)="iosBgSync()">
  <i class="material-icons">open_in_new</i>
  iosBgSync
</div>
<div fxLayout="row" class="icon-menu" (click)="openTwoButtonsDialog()">
  <i class="material-icons">open_in_new</i>
  Open Two Buttons Dialog
</div>
<div fxLayout="row" class="icon-menu" (click)="openOKDialog()">
  <i class="material-icons">open_in_new</i>
  Open OK Dialog
</div>
<div fxLayout="row" class="icon-menu" (click)="openBlockingOKDialog()">
  <i class="material-icons">open_in_new</i>
  Open Blocking OK Dialog
</div>
<div fxLayout="row" class="icon-menu" (click)="openRetryCancelDialog()">
  <i class="material-icons">open_in_new</i>
  Open Retry Dialog
</div>
<div fxLayout="row" class="icon-menu" (click)="openSnackbar()">
  <i class="material-icons">open_in_new</i>
  Open Snackbar
</div>
<div fxLayout="row" class="icon-menu" (click)="sendSwRequestStored()">
  <i class="material-icons">open_in_new</i>
  Send SW Request Stored
</div>
<div fxLayout="row" class="icon-menu" (click)="sendSwDebug()">
  <i class="material-icons">open_in_new</i>
  Send SW Debug
</div>
<div fxLayout="row" class="icon-menu">
  <a href="https://plukon-dev.appspot.com/#/my-messages/documentation/PLVH1">
  Deep Link
  </a>
</div>
<div fxLayout="row" class="icon-menu" (click)="expireToken()">
  <i class="material-icons">open_in_new</i>
  Expire Token
</div>
