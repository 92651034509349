import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ICoccidiostatsDialogData } from '../coccidiostats.models';
import * as moment from 'moment/moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { environment } from '../../../../../../environments/environment';
import { ResponsiveUIService } from 'src/app/shared/services/responsive-ui.service';
import { MobileDialogComponent } from 'src/app/shared/components/mobile-dialog/mobile-dialog.component';

@Component({
  selector: 'app-coccidiostats-dialog',
  templateUrl: './coccidiostats-dialog.component.html',
  styleUrls: ['./coccidiostats-dialog.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: environment.myDateFormat },
  ],
})
export class CoccidiostatsDialogComponent extends MobileDialogComponent {
  startDate = moment(new Date(), 'DD-MM-YYYY');
  constructor(
    public dialogRef: MatDialogRef<CoccidiostatsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ICoccidiostatsDialogData,
    responsiveUIService: ResponsiveUIService,
  ) {
    super(dialogRef, responsiveUIService);
  }
}
