import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { BackgroundFetch } from '@transistorsoft/capacitor-background-fetch';
import { offlineEnabled } from '../../utils/offline-utils';
import { App } from '@capacitor/app';
import { OfflineRequestsService } from './offline-request.service';
import { NotificationsService } from '../notifications/notifications.service';
import { TranslateService } from '@ngx-translate/core';

/**
 * Dev note:
 * By the time of design and implementation, '@transistorsoft/capacitor-background-fetch' was the only plugin that supports background fetch on iOS.
 * Now there's another official plugin that perhaps be adapted for the same purpose:
 * https://capacitorjs.com/docs/apis/background-runner
 * But currently it doesn't support IndexedDB in it's runners.
 */

const BackgroundFetchAvailable = Capacitor.isPluginAvailable('BackgroundFetch');

const APP_HIDDEN_TRANSLATION_KEY = 'OfflineMode.PendingRequestsAppHiddenNotification';
const APP_IN_BG_TRANSLATION_KEY = 'OfflineMode.PendingRequestsAppInBackgroundNotification';

@Injectable({
  providedIn: 'root',
})
export class IOSBgSyncService {
  constructor(
    private offlineRequestService: OfflineRequestsService,
    private notificationsService: NotificationsService,
    private translate: TranslateService,
  ) {}

  private initialized = false;

  async initialize() {
    if (this.initialized) {
      return;
    }
    this.initialized = true;
    if (!offlineEnabled() || Capacitor.getPlatform() !== 'ios' || !BackgroundFetchAvailable) {
      return false;
    }

    this.setupAppListeners();
    await this.setupBackgroundFetch();
  }

  private setupAppListeners() {
    App.addListener('appStateChange', async ({ isActive }) => {
      this.setActive(isActive);
    });
  }

  private async setupBackgroundFetch() {
    const status = await BackgroundFetch.configure(
      { minimumFetchInterval: 15 },
      async (taskId) => {
        // console.log(':::BgFetchIOSService - BackgroundFetch event:', taskId);
        try {
          this.sendNotificationIfNeeded(APP_IN_BG_TRANSLATION_KEY);
        } catch (error) {
          // console.log(':::BgFetchIOSService - enableInBackgroundAndSync error', { error });
        } finally {
          BackgroundFetch.finish(taskId);
        }
      },
      async (taskId) => {
        // console.log(':::BgFetchIOSService - BackgroundFetch timeout:', { taskId });
        BackgroundFetch.finish(taskId);
      },
    );

    if (status === BackgroundFetch.STATUS_AVAILABLE) {
      // console.log('[:::BgFetchIOSService] BackgroundFetch is configured');
    } else {
      if (status === BackgroundFetch.STATUS_DENIED) {
        // console.log('[:::BgFetchIOSService] Background behavior is explicitly disabled by user for the whole system');
      }
      if (status === BackgroundFetch.STATUS_RESTRICTED) {
        // console.log('[:::BgFetchIOSService] Background behavior is not available and user cannot enable them');
      }
    }
  }

  private setActive(isActive: boolean) {
    // console.log(':::BgFetchIOSService - setActive', { isActive });
    if (!isActive) {
      this.sendNotificationIfNeeded(APP_HIDDEN_TRANSLATION_KEY);
    }
  }

  private sendNotificationIfNeeded(translateKey: string) {
    const hasRequestsToSync = this.offlineRequestService.hasRequestsToSync;
    if (hasRequestsToSync) {
      const message = this.translate.instant(translateKey);
      this.notificationsService.showNotification(message);
    }
  }
}
