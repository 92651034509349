import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { VKIService } from 'src/app/shared/services/flock/vki.service';
import { ResponsiveUIService } from 'src/app/shared/services/responsive-ui.service';

@Component({
  selector: 'app-mobile-company-selector',
  templateUrl: './mobile-company-selector.component.html',
  styleUrls: ['./mobile-company-selector.component.scss'],
})
export class MobileCompanySelectorComponent implements OnInit {
  @Input() selectedRowItem;
  @Input() companyList;
  @Input() displayCompanyData;
  @Input() stableSelectionChange;
  @Input() selectedStableItem;
  stableList = [];
  vkiStepSubscription: Subscription;

  constructor(
    public router: Router,
    private vkiService: VKIService,
    public responsiveUIService: ResponsiveUIService,
  ) {}

  ngOnInit(): void {
    this.vkiStepSubscription = this.vkiService.stableList.subscribe((data) => {
      this.stableList = data ?? [];
    });
  }
}
