import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-chicken-deliver-dialog',
  templateUrl: './chicken-deliver-dialog.component.html',
  styleUrls: ['./chicken-deliver-dialog.component.scss'],
})
export class ChickenDeliverDialogComponent implements OnInit {
  chickenDeliverFormGroup: UntypedFormGroup;
  chickenDelivered: number;

  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<ChickenDeliverDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.chickenDeliverFormGroup = this.fb.group({
      chickenDeliver: [0, Validators.max(300000)],
    });
  }

  ngOnInit() {
    this.chickenDeliverFormGroup.get('chickenDeliver').patchValue(this.chickenDelivered ? this.chickenDelivered : 0);
  }

  numberInput(event) {
    if (event.shiftKey) {
      return false;
    } else if (
      (event.keyCode >= 48 && event.keyCode <= 57) ||
      (event.keyCode >= 96 && event.keyCode <= 105) ||
      event.keyCode == 8 ||
      event.keyCode == 9 ||
      event.keyCode == 37 ||
      event.keyCode == 39
    ) {
      return true;
    } else {
      return false;
    }
  }

  numberChange() {
    let value = this.chickenDeliverFormGroup.get('chickenDeliver').value;
    if (value !== undefined && value !== null && value !== '') {
      const res = parseInt(value.toString().replace(/[,.-\s]/g, ''));
      this.chickenDeliverFormGroup.get('chickenDeliver').patchValue(isNaN(res) ? '' : res);
    }
  }

  saveChickenDeliver() {
    this.dialogRef.close({
      chickenDelivered: this.chickenDeliverFormGroup.get('chickenDeliver').value,
    });
  }
}
