<div [style]="'z-index: ' + is2FA ? '1001 !important' : '1000'" class="content-dialog-container" fxLayout="column" fxLayoutAlign="center center">
  <div class="notification-title" *ngIf="isMyTask">
    <span style="display: inline-block" matBadgeOverlap="true" matBadgeSize="medium" matBadgeColor="warn" [matBadgeHidden]="true"><i class="material-icons-outlined notification-icon">notifications</i></span>
    <span class="notification-title-text">{{'MyTasks.NotificationTitle' | translate}}</span>
  </div>
  <ng-container *ngIf="isTranslateString; else notTranslateString">
    <mat-dialog-content *ngIf="is2FA; else commonTranslate" class="fa-content">
      <div [innerHTML]="sanitizer.bypassSecurityTrustHtml(getContentTranslation())"></div>
    </mat-dialog-content>
    <ng-template #commonTranslate>
      <mat-dialog-content>{{content | translate}}</mat-dialog-content>
    </ng-template>
  </ng-container>
  <ng-template #notTranslateString>
    <ng-container *ngIf="isHtml; else plain">
      <mat-dialog-content style="white-space: pre-wrap;"[innerHTML]=content></mat-dialog-content>
    </ng-container>
    <ng-template #plain>
      <mat-dialog-content>{{content}}</mat-dialog-content>
    </ng-template>
  </ng-template>
  <div *ngIf="isHelp" fxLayoutAlign="center center" style="padding-top: 24px;">
    <div fxLayout="column" fxLayoutAlign="center center">
      <div>
        <a mat-raised-button class="button-enabled" href="https://help.plukonnect.com" target="_blank">
            {{'Contact.RemoteSupport' | translate}}
        </a>
      </div>
      <span class="windows-message">
        {{'Contact.OnlyForWindows' | translate}}
      </span>
    </div>
  </div>
  <img *ngIf="showGif" class="img-gif" [src]="firefoxProblemUrl"/>
  <mat-dialog-actions *ngIf="primaryButtonText || secondaryButtonText">
    <button *ngIf="primaryButtonText" class="content-button-enabled" mat-button mat-dialog-close (click)="dialogRef.close(false)">{{primaryButtonText | translate}}</button>
    <button *ngIf="secondaryButtonText" class="content-button-enabled" mat-button mat-dialog-close (click)="submit()">{{secondaryButtonText | translate}}</button>
  </mat-dialog-actions>
    <i class="material-icons content-icon" (click)="dialogRef.close()">clear</i>
</div>
