import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FirebaseMessaging } from '@capacitor-firebase/messaging';
import { LocalNotifications } from '@capacitor/local-notifications';
import { Capacitor } from '@capacitor/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import * as moment from 'moment/moment';
import { DialogService } from '../dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { NetworkService } from '../network/network.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  token = null;
  options = {
    vapidKey: environment.firebaseVapIdKey,
  };
  constructor(
    public snackBar: MatSnackBar,
    private https: HttpClient,
    private auth: AuthService,
    private dialogService: DialogService,
    private translate: TranslateService,
    private networkService: NetworkService,
  ) {
    this.auth.currentUser$.subscribe(async (user) => {
      if (user) {
        if (!this.networkService.isOfflineMode) {
          return this.initFirebaseMessaging().then(() => this.addUserDataToNotificationsList(user));
        }
      }
    });
    this.auth.isLoggingOut$.subscribe((status: boolean) => {
      if (status && this.auth.currentUser) {
        return this.removeUserDataToNotificationsList();
      }
    });
  }

  askForPermission = async () => {
    const result = await FirebaseMessaging.requestPermissions();
    return result.receive;
  };

  handleRemindPermissionModal = async (permission: string) => {
    const granted = permission === 'granted';
    const lastQuestion = localStorage.getItem('askFormPermissionTime');
    if (!granted && !lastQuestion) {
      const today = moment(new Date(), 'DD-MM-YYYY');
      const todayJSON = JSON.stringify(today);
      return localStorage.setItem('askFormPermissionTime', todayJSON);
    }
    if (!granted && lastQuestion) {
      const today = moment(new Date(), 'DD-MM-YYYY');
      const lastQuestionDateJSON = localStorage.getItem('askFormPermissionTime');
      const lastQuestionDate = JSON.parse(lastQuestionDateJSON);
      const daysGap = today.diff(lastQuestionDate, 'days');
      if (daysGap >= 90) {
        const todayJSON = JSON.stringify(today);
        this.dialogService.notificationsReminderDialog();
        return localStorage.setItem('askFormPermissionTime', todayJSON);
      }
    }
    if (granted && lastQuestion) {
      return localStorage.removeItem('askFormPermissionTime');
    }
  };

  initFirebaseMessaging = async () => {
    const permission = await this.askForPermission();
    await this.handleRemindPermissionModal(permission);
    if (permission === 'granted') {
      if (Capacitor.getPlatform() === 'web' && 'serviceWorker' in navigator) {
        const registrationPromise = navigator.serviceWorker.ready;
        const registration = await registrationPromise;
        const options = {
          ...this.options,
          serviceWorkerRegistration: registration,
        };
        const { token } = await FirebaseMessaging.getToken(options);
        this.token = token;
        return token;
      }
      const { token } = await FirebaseMessaging.getToken(this.options);
      this.token = token;
      return token;
    }
  };
  showNotification = async (body: string, title = 'Plukonnect') => {
    const permission = await LocalNotifications.requestPermissions();
    if (permission.display === 'granted') {
      await LocalNotifications.schedule({
        notifications: [
          {
            body,
            title,
            id: Math.round(Math.random() * 10000), // any number. Not used here, but can be used to cancel the notification
          },
        ],
      });
    } else {
      this.snackBar.open(this.translate.instant('Message.NotificationDisabled'), 'Close', {
        duration: 3000,
        verticalPosition: 'top',
        panelClass: ['orange'],
      });
    }
  };

  addUserDataToNotificationsList = async (userData) => {
    if (this.token) {
      await this.https
        .put(`${environment.notificationsFunctionsUrl}/addTokenToUser`, {
          userEmail: userData.email,
          deviceToken: this.token,
        })
        .toPromise();
    }
  };

  removeUserDataToNotificationsList = async () => {
    if (this.token) {
      await this.https
        .put(`${environment.notificationsFunctionsUrl}/removeTokenFromUser`, {
          deviceToken: this.token,
        })
        .toPromise();
    }
  };
}
