import { RequestIsSyncingAlreadyError, getStorableRequestsService } from '../requests/storable-requests.service';
import { ResponseStoreEntry, getCachedResponsesService } from '../responses/cached-responses.service';
import { getSyncService } from '../sync/sync.service';
import { deepCompare } from '../utils/compare-utils';
import { updateBodyRequestWithFailedFileUpload, updateBodyRequestWithFileData, updateBodyRequestWithSaveData } from './file-upload.helper';
import { KEY_CANCEL_REQUEST, KEY_FLOCK_BASICS, KEY_UPLOAD_TEMP_FILE } from './constants';
import { combineCachedResponseAndRequest } from './cached-response.helper';
import { isFlockBasicsBodyOutdated } from './flock-basics.helper';
import {
  AnotherSyncInProgressError,
  SourceDataHasChangedError,
  NoRequestsToSyncError,
  RequestCannotBeSynced,
  RequestSyncFailed,
  SourceDataNotFoundError,
  RequestSyncFailedDueToMaintenance,
} from './errors';
import { getQueryKey, parseQueryKey } from './query-key.helper';

// Ignore vkiName due to field is not immediately reflected and not used in my-flock
// https://app-eu.wrike.com/open.htm?id=1337497649
const skipFieldsComparison = ['serverDateTime', 'signedUrl', 'vkiName'];

/**
 * This service should manage only general my-flock data.
 * Here should not be any logic about specific flock steps, e.g.medication or vaccination.
 */
class MyFlockService {
  constructor() {}

  public async verifyFlockBasicsNotChanged(data: { uid: string; flockNumber: string; token: string }): Promise<void> {
    return this.verifySourceDataNotChanged({
      uid: data.uid,
      flockNumber: data.flockNumber,
      key: KEY_FLOCK_BASICS,
      token: data.token,
    });
  }

  public async verifySourceDataNotChanged(data: { uid: string; flockNumber: string; key: string; token: string }): Promise<void> {
    try {
      const { uid, flockNumber, key, token } = data;
      const queryKey = getQueryKey(flockNumber, key);
      const cachedResponse = await getCachedResponsesService().getCachedResponseByQueryKey(uid, queryKey);
      if (!cachedResponse) {
        throw new SourceDataNotFoundError(uid, flockNumber, key);
      }
      const remoteResponse = await fetch(cachedResponse.url, {
        method: 'GET',
        headers: { Authorization: `Bearer ${token}`, 'X-preSave': 'true' },
      });
      if (remoteResponse.ok) {
        const remoteJson = await remoteResponse.json();
        if (!deepCompare(cachedResponse.response.body, remoteJson, skipFieldsComparison)) {
          throw new SourceDataHasChangedError(uid, flockNumber, key);
        } else {
          // Update cache for source data (because new response can have new values for fields from 'skipFieldsComparison')
          await getCachedResponsesService().cacheResponse(
            {
              body: remoteJson,
              status: remoteResponse.status,
              statusText: remoteResponse.statusText,
              url: remoteResponse.url,
              ok: remoteResponse.ok,
              headers: cachedResponse.response.headers,
            },
            uid,
            queryKey,
          );
        }
      } else {
        throw new Error('Failed to fetch');
      }
    } catch (error) {
      console.log('verifySourceDataNotChanged failed', error);
      throw error;
    }
  }

  public async refreshSourceData(data: { uid: string; flockNumber: string; token: string; key: string }): Promise<void> {
    try {
      const { uid, flockNumber, token, key } = data;
      const queryKey = getQueryKey(flockNumber, key);
      const cachedResponse = await this.getCachedResponse({ uid, flockNumber, key });
      const response = await fetch(cachedResponse.url, { headers: { Authorization: `Bearer ${token}` } });
      if (response.ok) {
        const remoteJson = await response.json();
        const headers = {};
        response.headers.forEach((value, key) => {
          headers[key] = value;
        });
        await getCachedResponsesService().cacheResponse(
          {
            body: remoteJson,
            status: response.status,
            statusText: response.statusText,
            url: response.url,
            ok: response.ok,
            headers,
          },
          uid,
          queryKey,
        );
      } else {
        throw new Error('Failed to fetch');
      }
    } catch (error) {
      console.log('refreshSourceData failed', error);
      throw error;
    }
  }

  public async getCachedResponse(data: { uid: string; flockNumber: string; key: string }): Promise<ResponseStoreEntry> {
    const { uid, flockNumber, key } = data;
    const queryKey = getQueryKey(flockNumber, key);
    const cachedResponse = await getCachedResponsesService().getCachedResponseByQueryKey(uid, queryKey);
    if (!cachedResponse) {
      throw new SourceDataNotFoundError(uid, flockNumber, key);
    }
    return cachedResponse;
  }

  public async isFlockBasicsOutdated(data: { uid: string; flockBasicsGetUrl: string; throwNotFound: boolean }): Promise<boolean> {
    try {
      const { uid, flockBasicsGetUrl, throwNotFound } = data;
      const cachedResponse = await getCachedResponsesService().getCachedResponse(flockBasicsGetUrl, uid);
      if (!cachedResponse) {
        if (throwNotFound) throw new SourceDataNotFoundError(uid, undefined, KEY_FLOCK_BASICS);
        return true;
      }

      return isFlockBasicsBodyOutdated(cachedResponse);
    } catch (error) {
      console.log('isFlockBasicsOutdated failed', error);
      throw error;
    }
  }

  public async getOfflineData(data: { uid: string; flockNumber: string; key: string }): Promise<any> {
    try {
      const { uid, flockNumber, key } = data;
      const queryKey = getQueryKey(flockNumber, key);
      const cachedResponse = await getCachedResponsesService().getCachedResponseByQueryKey(uid, queryKey);
      if (!cachedResponse) {
        throw new SourceDataNotFoundError(uid, flockNumber, key);
      }
      // TODO - currently we get only saved steps request.
      // But we need to take into account uploadFile and cancel requests (e.g. for medication)
      // I added getStoredRequestsByQueryKeys so it it accept multiple queryKeys
      // Alan, please add to 'queryKeys' needed keys for offline files data management and adjust combineCachedResponseAndRequest correspondingly
      const queryKeys = [queryKey, getQueryKey(flockNumber, key, KEY_UPLOAD_TEMP_FILE), getQueryKey(flockNumber, key, KEY_CANCEL_REQUEST)];
      const storedRequests = await getStorableRequestsService().getStoredRequestsByQueryKeys({ uid, queryKeys: queryKeys });
      if (storedRequests && storedRequests.length) {
        let combinedResponseBody = {
          responseBody: cachedResponse.response.body,
          lastSaveResponse: cachedResponse.response.body,
        };
        for (let i = 0; i < storedRequests.length; i++) {
          const { rawBody: cachedRequestBody, queryKey: cachedRequestQueryKey, metadata: cachedRequestMetadata } = storedRequests[i];
          combinedResponseBody = combineCachedResponseAndRequest({
            cachedResponseBody: combinedResponseBody.responseBody,
            cachedRequestBody: cachedRequestBody,
            cachedRequestQueryKey: cachedRequestQueryKey,
            cachedRequestMetadata: cachedRequestMetadata,
            lastSaveResponse: combinedResponseBody.lastSaveResponse,
            flockNumber,
            key,
          });
        }
        return combinedResponseBody.lastSaveResponse;
      } else {
        return cachedResponse.response.body;
      }
    } catch (error) {
      console.log('getOfflineData failed', error);
      throw error;
    }
  }

  public async syncNextRequest(data: { uid: string; token: string }): Promise<void> {
    const { uid, token } = data;
    // console.log('::: syncNextRequest', { uid, token });
    const hasRequestsToSync = await getStorableRequestsService().hasRequestsToSync(uid);
    if (hasRequestsToSync) {
      // console.log('::: hasRequestsToSync', { hasRequestsToSync });
      // CLAIM SYNC PERMISSION (for concurrent syncs)
      const isAllowedToSync = await getSyncService().isAllowedToSync();
      // console.log('::: isAllowedToSync', { isAllowedToSync });
      if (isAllowedToSync) {
        let requestEntry;
        try {
          requestEntry = await getStorableRequestsService().getFirstAndMarkAsSyncing(uid);
        } catch (error) {
          if (error instanceof RequestIsSyncingAlreadyError) {
            throw new AnotherSyncInProgressError();
          } else {
            throw error;
          }
        }
        // console.log('::: requestEntry', { requestEntry });
        if (!requestEntry) {
          throw new NoRequestsToSyncError(uid);
        }
        const { request, queryKey } = requestEntry;
        if (queryKey === undefined || queryKey.indexOf('-') === -1) {
          throw new Error('Invalid query key');
        }
        const { flockNumber, flockRequestKey, extraRequestKey } = parseQueryKey(queryKey);
        const isStepSaveRequest = extraRequestKey === undefined;
        const isUploadTempFile = extraRequestKey === KEY_UPLOAD_TEMP_FILE;

        if (isStepSaveRequest) {
          // VERIFY FLOCK BASICS AND SOURCE DATA IF STEP
          try {
            // console.log('::: verifyFlockBasicsNotChanged');
            await this.verifyFlockBasicsNotChanged({ uid, flockNumber, token });
            if (flockRequestKey !== KEY_FLOCK_BASICS) {
              // console.log('::: verifySourceDataNotChanged');
              await this.verifySourceDataNotChanged({ uid, flockNumber, key: flockRequestKey, token });
            }
          } catch (error) {
            const shouldDeleteRequest = error instanceof SourceDataHasChangedError || error instanceof SourceDataNotFoundError;
            console.log('::: error', { error, shouldDeleteRequest });
            if (shouldDeleteRequest) {
              await getStorableRequestsService().deleteRequest(requestEntry.entryId);
              throw new RequestCannotBeSynced();
            } else {
              await getStorableRequestsService().markAsNotSyncing(requestEntry.entryId);
              throw new RequestSyncFailed(requestEntry.entryId);
            }
          }
        }

        // MAKE POST REQUEST
        try {
          const requestToFetch = request.clone();
          requestToFetch.headers.delete('Authorization');
          requestToFetch.headers.set('Authorization', `Bearer ${token}`);
          // console.log('::: await fetch POST', { requestToFetch });
          const response = await fetch(requestToFetch);
          // console.log('::: await fetch response', { response });
          console.log(response);
          if (response.ok) {
            await getStorableRequestsService().deleteRequest(requestEntry.entryId);
            const responseBody = await response.json();
            if (isStepSaveRequest) {
              await this.updatePendingRequestsWithSavedStepResponse({ uid, flockNumber, flockRequestKey, responseBody });
              await this.refreshSourceData({ uid, flockNumber, token, key: flockRequestKey });
            }
            if (isUploadTempFile) {
              await this.updatePendingRequestsWithFileUploadResponse({ uid, flockNumber, flockRequestKey, responseBody });
            }
            // no extra logic needed for /cancel request
          } else {
            if (response.status === 503) {
              const responseBody = await response.json();
              if (responseBody.messageCode === 'Maintenance Window') {
                const endTime = responseBody.endTime;
                throw new RequestSyncFailedDueToMaintenance(requestEntry.entryId, endTime);
              }
            }
            throw new Error('Failed to fetch');
          }
        } catch (error) {
          console.log('::: await fetch ERROR', { error });
          await getStorableRequestsService().markAsNotSyncing(requestEntry.entryId);
          if (error instanceof RequestSyncFailedDueToMaintenance) {
            throw new RequestSyncFailedDueToMaintenance(requestEntry.entryId, error.endTime);
          }
          throw new RequestSyncFailed(requestEntry.entryId);
        }
      } else {
        throw new AnotherSyncInProgressError();
      }
    } else {
      throw new NoRequestsToSyncError(uid);
    }
  }

  private async updatePendingRequestsWithFileUploadResponse(data: {
    uid: string;
    flockNumber: string;
    flockRequestKey: string;
    responseBody: any;
  }): Promise<void> {
    const { uid, flockNumber, flockRequestKey, responseBody } = data;
    const queryKey = getQueryKey(flockNumber, flockRequestKey);
    const requests = await getStorableRequestsService().getStoredRequestsByQueryKey({ uid, queryKey });
    if (!requests || !requests.length) {
      return;
    }
    for (let i = 0; i < requests.length; i++) {
      const request = requests[i];
      const { rawBody } = request;
      const updatedRequestBody = updateBodyRequestWithFileData({ rawBody, fileUploadResponse: responseBody, flockRequestKey });
      await getStorableRequestsService().updateRequestBody({ entryId: request.entryId, rawBody: updatedRequestBody });
    }
  }

  private async updatePendingRequestsWithSavedStepResponse(data: {
    uid: string;
    flockNumber: string;
    flockRequestKey: string;
    responseBody: any;
  }): Promise<void> {
    const { uid, flockNumber, flockRequestKey, responseBody } = data;
    const queryKey = getQueryKey(flockNumber, flockRequestKey);
    const requests = await getStorableRequestsService().getStoredRequestsByQueryKey({ uid, queryKey });
    if (!requests || !requests.length) {
      return;
    }
    for (let i = 0; i < requests.length; i++) {
      const request = requests[i];
      const { rawBody } = request;
      const updatedRequestBody = updateBodyRequestWithSaveData({ rawBody, saveResponse: responseBody, flockRequestKey });
      await getStorableRequestsService().updateRequestBody({ entryId: request.entryId, rawBody: updatedRequestBody });
    }
  }

  private async updatePendingRequestsWithFailedFileUpload(data: {
    uid: string;
    flockNumber: string;
    flockRequestKey: string;
    failedFileUploadUUID: string;
  }): Promise<void> {
    const { uid, flockNumber, flockRequestKey, failedFileUploadUUID } = data;
    const queryKey = getQueryKey(flockNumber, flockRequestKey);
    const requests = await getStorableRequestsService().getStoredRequestsByQueryKey({ uid, queryKey });
    if (!requests || !requests.length) {
      return;
    }
    for (let i = 0; i < requests.length; i++) {
      const request = requests[i];
      const { rawBody } = request;
      const updatedRequestBody = updateBodyRequestWithFailedFileUpload({ rawBody, fileUUID: failedFileUploadUUID, flockRequestKey });
      await getStorableRequestsService().updateRequestBody({ entryId: request.entryId, rawBody: updatedRequestBody });
    }
  }

  public async cancelRequest(entryId: number): Promise<void> {
    const entry = await getStorableRequestsService().getStoredRequestsByEntryId(entryId);
    if (entry && entry.queryKey) {
      const { flockNumber, flockRequestKey, extraRequestKey } = parseQueryKey(entry.queryKey);
      const isUploadTempFile = extraRequestKey === KEY_UPLOAD_TEMP_FILE;
      if (isUploadTempFile) {
        await this.updatePendingRequestsWithFailedFileUpload({
          uid: entry.uid,
          flockNumber,
          flockRequestKey,
          failedFileUploadUUID: entry.metadata?.uuid as string,
        });
      }
    }
    return getStorableRequestsService().deleteRequest(entryId);
  }

  public syncComplete(): Promise<void> {
    return getSyncService().syncComplete();
  }

  public registerOutdatedPopupShowed = (uid: string): void => {
    getSyncService().storePrecacheConfig(uid, true);
  };
}
let serviceInstance: MyFlockService;

export const getMyFlockService = (): MyFlockService => {
  if (!serviceInstance) {
    serviceInstance = new MyFlockService();
  }
  return serviceInstance;
};
