<div class="privacy-statement-container" fxLayout="column" fxLayoutAlign="center center">
  <div class="privacy-statement-wrapper">
    <ng-container>
      <div [innerHTML]="sanitizer.bypassSecurityTrustHtml('PrivacyStatement.FullHtml' | translate)"></div>
    </ng-container>
    <!-- <ng-container *ngIf="selectedLang == 'en'">
            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
            <br>
            <br>
            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
        </ng-container>
        <ng-container *ngIf="selectedLang == 'du'">
            Lorem Ipsum is gewoon dummy tekst van de drukkerij- en zetbranche.
            <br>
            <br>
            Lorem Ipsum is de standaard dummy-tekst van de industrie sinds de jaren 1500, toen een onbekende printer een kombuis van het type in beslag nam en het tot een soort exemplaarboek maakte. Het heeft niet alleen vijf eeuwen overleefd, maar ook de sprong naar elektronisch zetwerk, en bleef in wezen onveranderd. Het werd gepopulariseerd in de jaren zestig met de uitgave van Letraset-bladen met Lorem Ipsum-passages en meer recent met desktop publishing-software zoals Aldus PageMaker inclusief versies van Lorem Ipsum.
        </ng-container> -->
    <!-- <ng-container *ngIf="selectedLang === 'German'">
      <div class="sub-title">Datenschutzerklärung Plukonnect</div>
      <br>
      <div>
        Dies ist die Datenschutzerklärung des Online-Portals Plukonnect, das von der Plukon Food Group B.V. mit Sitz in
        Industrieweg 36, 8091 AZ Wezep (Niederlande) verwaltet wird und im Handelsregister der Handelskammer unter der
        Nummer 30244837, nachstehend <label class="bold">"Plukon"</label> genannt, eingetragen ist.
      </div>
      <br>
      <div>
        Sie geben personenbezogene Daten an uns weiter, entweder bewusst oder unbewusst. Wir halten es für wichtig, dass
        Ihre personenbezogenen Daten mit Sorgfalt behandelt werden. Deshalb haben wir Ihnen in dieser
        Datenschutzerklärung erläutert, wie wir mit Ihren personenbezogenen Daten umgehen und welche Rechte Sie haben.
        Wenn Sie Fragen zum Lesen dieser Erklärung haben, können Sie uns Ihre Frage gerne per E-Mail stellen:
        <div class="link">privacy@plukon.nl.</div>
      </div>
      <br>
      <div>
        In der folgenden Tabelle können Sie schnell und einfach herausfinden, zu welchem Zweck wir welche
        personenbezogenen Daten von Ihnen erheben und wie lange wir diese speichern.
      </div>
      <br>
      <table class="border-table">
        <tr>
          <th>Zweck</th>
          <th>Welche personenbezogenen Daten</th>
          <th>Rechtsgrundslagen</th>
          <th>Speicherdauer</th>
        </tr>
        <tr>
          <td valign="top">
            Ermöglichung der Nutzung von Plukonnect
          </td>
          <td valign="top">
            <ul>
              <li>Accoun</li>
              <li>Vorname</li>
              <li>Familienname</li>
              <li>Geschlecht </li>
              <li>E-Mail-Adresse</li>
            </ul>
          </td>
          <td valign="top">
            <div>
              Notwendig für die Ausführung eines Vertrages
            </div>
            <br>
            <div>
              Ohne diese Account-Informationen ist es nicht möglich, dem Benutzer Zugang zu allen Funktionen von
              Plukonnect zu geben
            </div>
          </td>
          <td valign="top">
            Bis 1 Jahr nach Beendigung des Vertrages zwischen dem Nutzer von Plukonnect und Plukon, es sei denn, es
            besteht eine gesetzliche Verpflichtung zur längeren Aufbewahrung der personenbezogenen Daten.
          </td>
        </tr>
        <tr>
          <td valign="top">
            Bearbeitung von Fragen, Anmerkungen und Beschwerden über das Kontaktformular und/oder die "Hilfe-Funktion"
            auf Plukonnect
          </td>
          <td valign="top">
            <ul>
              <li>Vorname</li>
              <li>Familienname</li>
              <li>E-Mail-Adresse</li>
              <li>(Mobil)-Telefonnummer</li>
            </ul>
          </td>
          <td valign="top">
            <div>Berechtigte Interessen der Plukon</div>
            <br>
            <div>
              Die Angabe Ihrer personenbezogenen Daten ist nicht zwingend erforderlich, aber wenn Sie uns Ihre
              personenbezogenen Daten nicht zur Verfügung stellen, kann Plukon Sie nicht kontaktieren, um Ihre Frage,
              Bemerkung oder Beschwerde zu beantworten.
            </div>
          </td>
          <td valign="top">
            Bis maximal 6 Monate nach Bearbeitung der Frage, Bemerkung oder Beschwerde.
          </td>
        </tr>
      </table>
      <br>
      <div>
        Die in der obigen Tabelle beschriebene Bereitstellung personenbezogener Daten basiert nicht auf einer
        gesetzlichen Verpflichtung.
      </div>
      <br>
      <div class="sub-title">Cookies</div>
      <br>
      <div>
        Wir können Cookies verwenden, um Ihre personenbezogenen Daten zu verarbeiten. Weitere Informationen dazu finden
        Sie in unserer Cookie-Erklärung.
      </div>
      <br>
      <div class="sub-title">Weitergabe personenbezogener Daten an Dritte</div>
      <br>
      <div>
        Ohne Ihre Zustimmung gibt Plukon Ihre personenbezogenen Daten nur dann an Dritte weiter, wenn Plukon gesetzlich
        verpflichtet ist, Ihre personenbezogenen Daten an diese Dritten weiterzugeben oder wenn Plukon ein berechtigtes
        Interesse daran hat. Wir können Dritte in die Erbringung unserer Dienstleistungen einbeziehen. Diese Dritten
        sind unsere Verarbeiter und verarbeiten daher nur personenbezogene Daten zugunsten von Plukon.
      </div>
      <br>
      <div>
        Wir weisen darauf hin, dass Plukonnect Ihnen die Möglichkeit bietet, Ihre personenbezogenen Daten an Dritte
        weiterzugeben, die ebenfalls Plukonnect nutzen. Die korrekte Nutzung dieser Option liegt in Ihrer eigenen
        Verantwortung. Selbstverständlich können Sie sich mit Fragen oder Kommentaren zu dieser Funktionalität an Plukon
        wenden.
      </div>
      <br>
      <div class="sub-title">Ihre Rechte</div>
      <br>
      <div>Sie haben die folgenden Rechte:</div>
      <br>
      <div>
        <table class="tab">
          <tr>
            <td>-</td>
            <td>Recht auf Auskunft über Ihre bei uns gespeicherten persönlichen Daten gem. Art. 15 DSGVO,</td>
          </tr>
          <tr>
            <td>-</td>
            <td>Recht auf Berichtigung unrichtiger oder auf Vervollständigung richtiger Daten gem. Art. 16 DSGVO,</td>
          </tr>
          <tr>
            <td>-</td>
            <td>Recht auf Löschung Ihrer bei uns gespeicherten Daten gem. Art. 17 DSGVO,</td>
          </tr>
          <tr>
            <td>-</td>
            <td>Recht auf Einschränkung der Verarbeitung Ihrer Daten gem. Art. 18 DSGVO,</td>
          </tr>
          <tr>
            <td>-</td>
            <td>Recht auf Unterrichtung gegenüber Empfängern über die Geltendmachung von Betroffenenrechten,</td>
          </tr>
          <tr>
            <td>-</td>
            <td>Recht auf Datenübertragbarkeit gem. Art. 20 DSGVO</td>
          </tr>
        </table>
      </div>
      <br>
      <div>
        Diese Rechte sind nicht absolut. Wir werden Ihre Anfrage in Übereinstimmung mit der
        EU-Datenschutzgrundverordnung (DSGVO) prüfen. Wir werden Sie so schnell wie möglich, auf jeden Fall aber
        innerhalb eines Monats nach Erhalt Ihrer Anfrage, über die Maßnahmen informieren, die wir als Reaktion auf Ihre
        Anfrage ergriffen haben. Aufgrund der Komplexität Ihrer Anfrage oder der Anzahl der eingehenden Anfragen können
        wir diese Frist um zwei Monate verlängern. Wir werden Sie in diesem Fall informieren.
      </div>
      <br>
      <div class="sub-title">Der Schutz von personenbezogenen Daten</div>
      <br>
      <div>
        Wir leiten alle angemessenen Vorsorgemaßnahmen ein, um die von Ihnen zur Verfügung gestellten personenbezogenen
        Daten zu schützen und zu sichern. Ebenso verlangen wir derartige Maßnahmen von Dritten, die Ihre
        personenbezogenen Daten für uns verarbeiten.
      </div>
      <br>
      <div class="sub-title">Verlinkte Sites</div>
      <br>
      <div>
        Plukonnect kann Links zu anderen Websites enthalten. Diese Datenschutzerklärung gilt nur für die Website von
        Plukonnect. Andere Websites haben möglicherweise ihre eigenen Datenschutzrichtlinien. Plukon empfiehlt Ihnen,
        vor der Nutzung immer die entsprechenden Datenschutzerklärungen anderer Websites zu beachten.
      </div>
      <br>
      <div class="sub-title">Änderungen der Datenschutzerklärung</div>
      <br>
      <div>
        Plukon überarbeitet seine Datenschutzerklärung von Zeit zu Zeit, um sie auf dem neuesten Stand zu halten.
        Plukonnect wird immer die neueste Version unserer Datenschutzerklärung enthalten. Plukon empfiehlt Ihnen daher,
        die Datenschutzerklärung regelmäßig zu lesen. Plukon wird alles in seiner Macht Stehende tun, um Sie über
        wichtige Änderungen zu informieren.
      </div>
      <br>
      <div class="sub-title">Kontaktdaten</div>
      <br>
      <div>
        Wenn Sie Fragen zur Verarbeitung Ihrer persönlichen Daten haben oder von Plukon Einsicht, Berichtigung und/oder
        Löschung Ihrer persönlichen Daten verlangen möchten, wenden Sie sich bitte über die untenstehenden Kontaktdaten
        an uns:
        <table class="non-border-table">
          <tr>
            <td>Verantwortlicher:</td>
            <td class='second-column'>Herr Brett J. Bos – Director Legal Affairs</td>
          </tr>
          <tr>
            <td>E-Mail-Adresse:</td>
            <td class='second-column'><label class="link">privacy@plukon.nl</label></td>
          </tr>
        </table>
      </div>
      <br>
      <div class="sub-title">Beschwerde über die Verarbeitung Ihrer personenbezogenen Daten</div>
      <br>
      <div>
        Natürlich helfen wir Ihnen auch gerne weiter, wenn Sie eine Beschwerde über die Verarbeitung Ihrer
        personenbezogenen Daten haben. Hinaus besteht das Recht auf Beschwerde bei einer Aufsichtsbehörde, insbesondere
        in dem Mitgliedstaat ihres Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, zu,
        wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die DSGVO
        verstößt.
      </div>
    </ng-container> -->
    <!-- <ng-container *ngIf="true">
      <div style="font-size:1rem;font-weight:bold;">Privacyverklaring</div>
      <br>
      <div>
        Dit is de privacyverklaring van online portal Plukonnect, dat wordt beheerd door Plukon Food Group
        B.V., gevestigd aan de Industrieweg 36, 8091 AZ Wezep (Nederland) en ingeschreven in het
        handelsregister van de Kamer van Koophandel onder nummer 30244837, hierna te noemen:
        <label style="font-weight:bold;">‘Plukon’</label>.
      </div>
      <br>
      <div>
        Bewust of onbewust deelt u persoonsgegevens met ons. Wij vinden het van belang dat zorgvuldig
        wordt omgegaan met uw persoonsgegevens. Daarom hebben wij in deze privacyverklaring voor u uiteengezet
        hoe wij met uw persoonsgegevens omgaan en welke rechten u heeft. Heeft u vragen naar aanleiding
        van het lezen van deze verklaring, stel uw vraag dan gerust per e-mail aan:
        <div style="color:#0000ff;text-decoration:underline;display:inline;">privacy@plukon.nl</div>

        Of aan de data protection officer (functionaris gegevensbescherming) via de
        <div style="color:#0000ff;text-decoration:underline;display:inline;">dpo@plukon.nl</div>
      </div>
      <br>
      <div>
        In onderstaande tabel kunt u snel en eenvoudig terugvinden met welk doel we welke
        persoonsgegevens van u verzamelen en hoelang wij deze bewaren.
      </div>
      <br>
      <table border="1" bordercolor="#dddddd" style="border-collapse:collapse;width:100%;text-align:left;">
        <tr>
          <th style="padding:8px;">Doel</th>
          <th style="padding:8px;">Welke persoonsgegevens</th>
          <th style="padding:8px;">Grondslag</th>
          <th style="padding:8px;">Bewaartermijn</th>
        </tr>
        <tr>
          <td valign="top" style="padding:8px;">
            Gebruiker de
            mogelijkheid gebruik
            te maken van
            Plukonnect
          </td>
          <td valign="top" style="padding:8px;">
            <ul style="padding-left:15px;margin-top:0px">
              <li>Account</li>
              <li>Voornaam</li>
              <li>Achternaam</li>
              <li>Geslacht</li>
              <li>E-mailadres</li>
            </ul>
          </td>
          <td valign="top" style="padding:8px;">
            <div>
              Noodzakelijk voor de uitvoering van een overeenkomst
            </div>
            <br>
            <div>
              Zonder deze accountgegevens is het onmogelijk om de gebruiker toegang te verlenen tot alle
              functionaliteiten van Plukonnect
            </div>
          </td>
          <td valign="top" style="padding:8px;">
            Tot 1 jaar nadat de overeenkomst tussen de gebruiker van Plukonnect en Plukon is beëindigd, tenzij er een
            wettelijke verplichting bestaat om de persoonsgegevens langer te bewaren.
          </td>
        </tr>
        <tr>
          <td valign="top" style="padding:8px;">
            Vragen, opmerkingen en klachten afhandelen door middel van het contactformulier en/of de ‘help-functie’ op
            Plukonnect
          </td>
          <td valign="top" style="padding:8px;">
            <ul style="padding-left:15px;margin-top:0px">
              <li>Voornaam</li>
              <li>Achternaam</li>
              <li>E-mailadres</li>
              <li>(Mobiele) telefoonnummer</li>
            </ul>
          </td>
          <td valign="top" style="padding:8px;">
            <div>Gerechtvaardigd belang van Plukon</div>
            <br>
            <div>Het verstrekken van uw persoonsgegevens is niet verplicht, maar indien u ons de persoonsgegevens niet
              verstrekt, dan kan Plukon geen contact met u opnemen om uw vraag, opmerking of klacht te beantwoorden.
            </div>
          </td>
          <td valign="top" style="padding:8px;">
            Tot maximaal 3 maanden nadat de vraag, opmerking of klacht is afgehandeld.
          </td>
        </tr>
      </table>
      <br>
      <div>
        Het verstrekken van de persoonsgegevens zoals omschreven in bovenstaande tabel is niet gebaseerd op een wettelijke verplichting.
      </div>
      <br>
      <div style="font-size:1rem;font-weight:bold;">Cookies</div>
      <div>
        Wij kunnen voor de verwerking van uw persoonsgegevens gebruikmaken van cookies. U kunt hierover
        meer informatie vinden in ons cookiebeleid.
      </div>
      <br>
      <div style="font-size:1rem;font-weight:bold;">Verstrekking van persoonsgegevens aan derden</div>
      <div>
        Zonder uw toestemming verstrekt Plukon uw persoonsgegevens alleen aan derden als Plukon
        wettelijk verplicht is om uw persoonsgegevens te verstrekken aan deze derden of als Plukon hierbij
        een gerechtvaardigd belang heeft. Daarbij kunnen wij derden inschakelen in het kader van de
        uitvoering van onze dienstverlening. Deze derden zijn verwerkers van ons en verwerken de
        persoonsgegevens dus slechts ten behoeve van Plukon.
      </div>
      <br>
      <div>
        Graag wijzen wij u erop dat Plukonnect u de mogelijkheid biedt om zélf persoonsgegevens met derden
        te delen die ook gebruik maken van Plukonnect. Het juiste gebruik van deze optie is uw eigen
        verantwoordelijkheid. Uiteraard kunt u contact opnemen met Plukon bij vragen of opmerkingen over
        deze functionaliteit.
      </div>
      <br>
      <div style="font-size:1rem;font-weight:bold;">Inzage, correctie en verwijdering van persoonsgegevens</div>
      <div>U heeft het recht ons te verzoeken om:</div>
      <br>
      <div>
        <ul type="none">
          <li>- inzage te geven in uw persoonsgegevens (zoals uw adres of telefoonnummer),</li>
          <li>- uw persoonsgegevens (zoals uw adres of telefoonnummer) te laten aanvullen, corrigeren of verwijderen;
          </li>
          <li>- verzet aan te tekenen tegen de verwerkingen van uw persoonsgegevens;</li>
          <li>- uw persoonsgegevens te beperken; en/of</li>
          <li>- uw persoonsgegevens door te geven aan u of aan een derde.</li>
        </ul>
      </div>
      <br>
      <div>
        Deze rechten zijn niet absoluut. Wij zullen uw verzoek beoordelen conform de Algemene Verordening
        Gegevensbescherming (AVG) en van toepassing zijnde uitvoeringswetten. Wij zullen u zo spoedig
        mogelijk, maar in elk geval binnen een maand na ontvangst van uw verzoek, informatie verstrekken
        over de acties die wij hebben ondernomen naar aanleiding van uw verzoek. Deze termijn kunnen wij
        met twee maanden verlengen in verband met de complexiteit van uw verzoek of het aantal verzoeken
        dat wij krijgen. We zullen u in dat geval informeren.
      </div>
      <br>
      <div style="font-size:1rem;font-weight:bold;">Beveiliging persoonsgegevens</div>
      <div>
        Plukon treft passende maatregelen om uw persoonsgegevens te beveiligen tegen verlies,
        ongeautoriseerde toegang, publicatie en onrechtmatige verwerking. Zo zorgen wij dat alleen de
        noodzakelijke personen toegang hebben tot uw gegevens, dat de toegang tot de persoonsgegevens
        afgeschermd is en dat onze veiligheidsmaatregelen regelmatig gecontroleerd worden.
      </div>
      <br>
      <div style="font-size:1rem;font-weight:bold;">Links naar andere websites</div>
      <div>
        Plukonnect kan links naar andere websites bevatten. Deze privacyverklaring is alleen van toepassing
        op de website van Plukonnect. Andere websites kunnen hun eigen privacybeleid hanteren. Plukon
        raadt u aan om voor het gebruik van andere websites altijd de betreffende privacyverklaring van die
        websites te raadplegen.
      </div>
      <br>
      <div style="font-size:1rem;font-weight:bold;">Wijziging van het privacybeleid</div>
      <div>
        Plukon past haar privacybeleid van tijd tot tijd aan om deze up-to-date te houden. Op Plukonnect zal
        steeds de meest recente versie van onze privacyverklaring worden opgenomen. Plukon raadt u dan
        ook aan de privacyverklaring regelmatig te raadplegen. Bij belangrijke wijzigingen zal Plukon er alles
        aan doen u zo goed mogelijk te informeren.
      </div>
      <br>
      <div style="font-size:1rem;font-weight:bold;">Contactgegevens</div>
      <div>
        Indien u vragen hebt over de verwerking van uw persoonsgegevens of Plukon wilt verzoeken tot
        inzage, correctie en/of verwijdering van uw persoonsgegevens, kunt u contact opnemen via
        onderstaande contactgegevens:
        <table>
          <tr>
            <td>Verantwoordelijke:</td>
            <td style="padding-left:10px;">Dhr. Mr. Brett J. Bos – Director Legal Affairs</td>
          </tr>
          <tr>
            <td>E-mail:</td>
            <td style="padding-left:10px;"><label style="color:#0000ff;text-decoration:underline;display:inline;">privacy@plukon.nl</label></td>
          </tr>
        </table>

        <br>
        <div>Functionaris voor</div>
        <table>
          <tr>
            <td>Gegevensbescherming:</td>
            <td style="padding-left:10px;">Ray Mennink en Rob C.J. Sevenstern</td>
          </tr>
          <tr>
            <td>E-mail:</td>
            <td style="padding-left:10px;"><label style="color:#0000ff;text-decoration:underline;display:inline;">dpo@plukon.nl</label></td>
          </tr>
        </table>
      </div>
      <br>
      <div style="font-size: 1rem;font-weight: bold;">Klacht over de verwerking van uw Persoonsgegevens</div>
      <div>
        Natuurlijk helpen wij u ook graag verder als u een klacht heeft over de verwerking van uw
        persoonsgegevens. Op grond van de AVG heeft u bovendien het recht om een klacht in te dienen bij
        de Autoriteit Persoonsgegevens over onze verwerkingen van uw persoonsgegevens. U kunt hiervoor
        contact opnemen met de Autoriteit Persoonsgegevens.
      </div>
    </ng-container> -->
  </div>
</div>
