import { EventEmitter, Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { Https } from '../http.service';

@Injectable({
  providedIn: 'root',
})
export class QuestionnaireService {
  public routerSelectedId: BehaviorSubject<any>;
  public selectedQuestionnaire: BehaviorSubject<any>;

  constructor(private http: Https) {
    this.routerSelectedId = new BehaviorSubject<any>(null);
    this.selectedQuestionnaire = new BehaviorSubject<any>(null);
  }

  public getQuestionEntries(companyId) {
    return this.http.get(`${environment.baseUrl}/plukonKras/questions/entries/${companyId}`, '');
  }

  public getQuestionsByEntryId(entryId) {
    return this.http.get(`${environment.baseUrl}/plukonKras/questions/${entryId}`, '');
  }

  public getAnswersByEntryId(entryId) {
    return this.http.get(`${environment.baseUrl}/plukonKras/questions/answers/${entryId}`, '');
  }

  public uploadQuestionaireFile(data) {
    return this.http.upload(`${environment.baseUrl}/plukonKras/questionaire/tempfile`, data);
  }

  public cancelQuestionaireUpload(data) {
    return this.http.post(`${environment.baseUrl}/plukonKras/questionaire/tempfile/cancel`, data);
  }

  public saveAsnwers(payload) {
    return this.http.post(`${environment.baseUrl}/plukonKras/questions/answers`, payload);
  }
}
