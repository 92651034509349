import { Injectable } from '@angular/core';
import { BehaviorSubject } from '../../../../node_modules/rxjs';
import { Https } from './http.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  public myUserProfile: BehaviorSubject<any>;
  public isAdmin: BehaviorSubject<any>;
  public isTestUser: BehaviorSubject<any>;
  // isAdmin: string;

  constructor(private http: Https) {
    this.myUserProfile = new BehaviorSubject<any>(null);
    this.isAdmin = new BehaviorSubject<any>(null);
    this.isTestUser = new BehaviorSubject<any>(true);
  }

  public getMaintenance() {
    return this.http.get(`${environment.baseUrl}/maintenance`, null, { skipAuth: true });
  }

  public saveMaintenance(payload) {
    return this.http.post(`${environment.baseUrl}/maintenance/save`, payload);
  }

  public runReminderBatchJob() {
    return this.http.get(`${environment.baseUrl}/cronJob/email/assessment/reminder`, null);
  }

  public runBatchEmailSending() {
    return this.http.get(`${environment.baseUrl}/cronJob/email/document`, null);
  }

  public submitContact(payload) {
    return this.http.post(`${environment.baseUrl}/contactus`, payload);
  }

  public runBulkInsertCompany() {
    return this.http.put(`${environment.baseUrl}/companies/bulk`, null);
  }

  public runBulkInsertUser() {
    return this.http.put(`${environment.baseUrl}/users/bulk`, null);
  }

  public runBulkSendInvitation() {
    return this.http.put(`${environment.baseUrl}/users/sendInvitation/bulk`, null);
  }

  public clearMemCache() {
    return this.http.put(`${environment.baseUrl}/memCache/clear`, null);
  }

  // public keepSession() {
  //   return this.http.get(`${environment.baseLoginUrl}/keepAlive`, null);
  // }
}
