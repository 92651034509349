<div class="user-container" fxLayout="column" fxLayoutAlign="center start" >
  <div class="user-wrapper">
    <label class="title">{{'MyPlukonUser.MyProfile' | translate}}</label>
    <div class="user-detail" fxLayout="row" fxLayoutAlign="start">
      <label class="label">{{'MyPlukonUser.Salutation' | translate}}</label>
      <label class="content">{{salutation | translate}}</label>
    </div>
    <div class="user-detail" fxLayout="row" fxLayoutAlign="start">
      <label class="label">{{'MyPlukonUser.FullName' | translate}}</label>
      <label class="content">{{fullName}}</label>
    </div>
    <div class="user-detail" fxLayout="row" fxLayoutAlign="start">
        <label class="label">{{'MyPlukonUser.AccountName' | translate}}</label>
        <label class="content">{{accountName}}</label>
    </div>
    <div class="user-detail" fxLayout="row" fxLayoutAlign="start">
        <label class="label">{{'MyPlukonUser.DisplayLanguage' | translate}}</label>
        <label class="content">{{displayLanguage | translate}}</label>
    </div>
    <div class="user-detail" fxLayout="row" fxLayoutAlign="start">
        <label class="label">{{'MyPlukonUser.UserEmail' | translate}}</label>
        <label class="content">{{email}}</label>
    </div>
  </div>
  <app-loading-spinner [show]=showSpinner></app-loading-spinner>
</div>
