import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-cumulative-confirmation-dialog',
  templateUrl: './cumulative-confirmation-dialog.component.html',
  styleUrls: ['./cumulative-confirmation-dialog.component.scss'],
})
export class CumulativeConfirmationDialogComponent implements OnInit {
  public content: string;

  constructor(
    public dialogRef: MatDialogRef<CumulativeConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {}

  submit() {
    this.dialogRef.close(true);
  }
}
