/**
 * The motivation of this service is to store the request in the IndexedDB.
 * This service should be able to work without Angular framework.
 * This service should work in service worker.
 */
import { RequestStoreEntry, RequestStoreEntryWithoutId, RequestsDb } from './requests-db';
import { StorableRequest } from './storable-request';

const DB_NAME = 'plukon-requests';

export interface StorableCheckSourceData {
  body: string;
  url: string;
}

export class RequestIsSyncingAlreadyError extends Error {
  constructor() {
    super('Requests is syncing already');
    this.name = 'RequestIsSyncingAlreadyError';
  }
}

export type StoredRequestMetadata = RequestStoreEntryWithoutId['metadata'];

class StorableRequestsService {
  private readonly db: RequestsDb;

  constructor() {
    this.db = new RequestsDb(DB_NAME);
  }

  public async storeRequest(data: {
    request: Request;
    rawBody: any;
    uid: string;
    queryKey: string | undefined;
    metadata?: StoredRequestMetadata;
  }): Promise<void> {
    const { request, uid, queryKey, rawBody, metadata } = data;
    const storableRequest = await StorableRequest.fromRequest(request);
    const entry: RequestStoreEntryWithoutId = {
      timestamp: new Date().getTime(),
      requestData: storableRequest.toObject(),
      uid,
      queryKey,
      rawBody,
      metadata,
    };
    return this.db.add(entry);
  }

  public async hasRequestsToSync(uid: string): Promise<boolean> {
    const size = await this.db.getCountByUID(uid);
    return size > 0;
  }

  public async getStoredRequestsByEntryId(entryId: number): Promise<any> {
    const entry = await this.db.getById(entryId);
    if (entry) {
      return entry;
    }
    return undefined;
  }

  public async getStoredRequestsByQueryKey(data: { uid: string; queryKey: string }) {
    const { uid, queryKey } = data;
    return this.getStoredRequestsByQueryKeys({ uid, queryKeys: [queryKey] });
  }

  public async getStoredRequestsByQueryKeys(data: {
    uid: string;
    queryKeys: string[];
  }): Promise<{ entryId: number; request: Request; rawBody: any; age: number; queryKey: string; metadata: any }[] | undefined> {
    const { uid, queryKeys } = data;

    const allEntries: RequestStoreEntry[] = [];
    for (const queryKey of queryKeys) {
      const entries = await this.db.getEntriesByUIDAndKey(uid, queryKey);
      allEntries.push(...entries);
    }
    // sort entries by the order they were added
    allEntries.sort((a, b) => a.id - b.id);

    if (allEntries && allEntries.length) {
      return allEntries.map((entry) => {
        const storableRequest = new StorableRequest(entry.requestData);
        return {
          entryId: entry.id,
          request: storableRequest.toRequest(),
          rawBody: entry.rawBody,
          age: new Date().getTime() - entry.timestamp,
          queryKey: entry.queryKey,
          metadata: entry.metadata,
        };
      });
    }
    return undefined;
  }

  public async getFirstAndMarkAsSyncing(
    uid: string,
    abandonedTimeout = 15000,
  ): Promise<{ entryId: number; request: Request; rawBody: any; queryKey?: string } | undefined> {
    const entry = await this.db.getFirstEntryByUID(uid);
    if (entry) {
      // If the request is already syncing, we should not try to sync it again.
      // E.g. the case - user closed a tab with one sync process and started in another tab.
      if (entry.startSyncing && entry.startSyncing > new Date().getTime() - abandonedTimeout) {
        throw new RequestIsSyncingAlreadyError();
      }
      entry.startSyncing = new Date().getTime();
      await this.db.replace(entry);
      return {
        entryId: entry.id,
        request: new StorableRequest(entry.requestData).toRequest(),
        rawBody: entry.rawBody,
        queryKey: entry.queryKey,
      };
    }
    return undefined;
  }

  public async markAsNotSyncing(id: number): Promise<void> {
    const entry = await this.db.getById(id);
    if (entry) {
      entry.startSyncing = undefined;
      await this.db.replace(entry);
    }
  }

  public async updateRequestBody(data: { entryId: number; rawBody: object }): Promise<void> {
    const { entryId, rawBody } = data;
    const entry = await this.db.getById(entryId);
    if (entry) {
      const storableRequest = new StorableRequest(entry.requestData);
      storableRequest.updateBody(rawBody);
      entry.rawBody = rawBody;
      entry.requestData = storableRequest.toObject();
      await this.db.replace(entry);
    }
  }

  public async deleteRequest(id: number): Promise<void> {
    return this.db.deleteById(id);
  }
}

let serviceInstance: StorableRequestsService;

export const getStorableRequestsService = (): StorableRequestsService => {
  if (!serviceInstance) {
    serviceInstance = new StorableRequestsService();
  }
  return serviceInstance;
};
