import { HttpContextToken, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UID } from './auth-interceptor';
import { NetworkService } from '../shared/services/network/network.service';
import { HttpCacheService } from '../shared/services/offline/http-cache.service';
import { offlineEnabled } from '../shared/utils/offline-utils';

export const SKIP_CACHING = new HttpContextToken<boolean>(() => undefined);
export const CACHE_QUERY_KEY = new HttpContextToken<string>(() => undefined);
export const GET_CACHE_QUERY_KEY = new HttpContextToken<(request: HttpRequest<any>, response: HttpResponse<any>) => string>(
  () => undefined,
);

@Injectable()
export class HttpCacheInterceptor implements HttpInterceptor {
  constructor(
    private httpCacheService: HttpCacheService,
    private networkService: NetworkService,
  ) {}

  private async handleCacheableRequest(request: HttpRequest<any>, next: HttpHandler): Promise<any> {
    const uid = request.context.get(UID);

    if (this.networkService.isOfflineMode) {
      const cachedResponse = await this.httpCacheService.getCachedResponse(request.urlWithParams, uid);
      if (cachedResponse?.response) {
        const headers = new HttpHeaders(cachedResponse.response.headers);
        return new HttpResponse({
          ...cachedResponse.response,
          headers: headers,
        });
      } else {
        return next.handle(request).toPromise();
      }
    } else {
      return next
        .handle(request)
        .pipe(
          tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              const skipCache = request.context.get(SKIP_CACHING);
              if (skipCache) {
                return;
              }
              if (this.shouldStoreResponse(event)) {
                const getCacheQueryKey = request.context.get(GET_CACHE_QUERY_KEY);
                const cacheQueryKey = getCacheQueryKey ? getCacheQueryKey(request, event) : request.context.get(CACHE_QUERY_KEY);
                this.httpCacheService.saveResponse(event, uid, cacheQueryKey).catch((err) => {
                  console.error('Error in caching http response', { err, event, uid, cacheQueryKey });
                });
              }
            }
          }),
        )
        .toPromise();
    }
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (offlineEnabled() && this.isRequestToCache(request)) {
      return from(this.handleCacheableRequest(request, next));
    }
    return next.handle(request);
  }

  private shouldStoreResponse(res: HttpResponse<any>): boolean {
    return [0, 200].includes(res.status);
  }

  // This can be extended or configured via constructor if needed
  private isRequestToCache(req: HttpRequest<any>): boolean {
    return req.method === 'GET' && req.url.includes('/api/');
  }
}
