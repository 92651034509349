import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-before-install-prompt',
  templateUrl: './before-install-prompt.component.html',
  styleUrls: ['./before-install-prompt.component.scss'],
})
export class BeforeInstallPromptComponent implements OnInit, OnDestroy {
  isHidden = true;
  deferredPrompt;
  doNotShowItAgain = false;
  blockedByUser = false;
  constructor() {
    this.blockedByUser = document.cookie.search('installAppMsg') !== -1;
  }

  ngOnInit(): void {
    window.addEventListener('beforeinstallprompt', (event: Event) => {
      event.preventDefault();
      this.deferredPrompt = event;
      if (event) {
        this.isHidden = false;
      }
    });
  }

  changeDontShowValue = () => {
    this.doNotShowItAgain = !this.doNotShowItAgain;
  };
  addAppPrompt = async () => {
    await this.deferredPrompt.prompt();
    const { outcome } = await this.deferredPrompt.userChoice;
    if (outcome !== 'dismissed') {
      this.isHidden = true;
    }
  };

  decline() {
    this.isHidden = true;
    if (this.doNotShowItAgain) {
      const expTime = 24 * 60 * 60 * 140; /// 140 days === 20 weeks
      document.cookie = `installAppMsg=false; max-age=${expTime}; path=/;`;
      this.blockedByUser = true;
    }
  }

  ngOnDestroy() {
    window.removeEventListener('beforeinstallprompt', () => {});
  }
}
