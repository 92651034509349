<div class="content-container" fxLayout="column" fxLayoutAlign="center center">
  <div class="content-body">
    <mat-dialog-content>{{'CumulativeDialog.Message' | translate}}</mat-dialog-content>
  </div>
  <mat-dialog-actions>
    <button class="button-enabled" mat-button mat-dialog-close (click)="dialogRef.close()">{{'Common.No' | translate}}</button>
    <button class="button-enabled" mat-button mat-dialog-close (click)="submit()">{{'Common.Yes' | translate}}</button>
  </mat-dialog-actions>
</div>

