<div [formGroup]="data.stableLogForm">
  <div formArrayName="stableLogRows">
    <div class="stable-card-dialog-cont" [formGroupName]="data.index">
      <div class="close-mark" (click)="closeDialog()"></div>
        <div class="form-field-cont first">
          <div class="cell medium-small">
            <span>{{ 'StableCard.Date' | translate}}</span>
            <span class="value" [ngClass]="{ 'add-height': data.index === 0 }">{{ data.displayStableRecordData.date}}</span>
          </div>
          <div class="cell small">
            <span>{{ 'StableCard.Age' | translate}}</span>
            <span class="value" [ngClass]="{ 'add-height': data.index === 0 }">{{ data.displayStableRecordData.age}}</span>
          </div>
          <div class="cell medium">
            <span>{{ 'StableCard.Amount' | translate}}</span>
            <span class="value" *ngIf="data.index > 0">{{ data.displayStableRecordData.amount }}</span>
            <div class="action-value" *ngIf="data.index === 0">
              {{ data.editableChickenAmt.value }}
              <button
                mat-icon-button
                (click)="data.editChickenDevlier()"
                [disabled]="data.blockFirst !== 2 && (data.displayStableRecordData.blocked !== 0 || data.isBlocked || (data.editBlocked && data.dataStatus == 2))">
                    <i class="material-icons">edit</i>
                  </button>
            </div>
          </div>
        </div>
      <div class="form-field-cont">
        <div [ngClass]="twiceFormControl ? 'cell wide' : 'cell full'">
          <label>{{ 'StableCard.Deaths' | translate}}</label>
          <mat-form-field
            [ngClass]="{'small-error-number-form': data.stableLogFormArray.get([data.index]).get('deaths').errors}"
            class="stable-input">
            <input
              inputmode="decimal"
              placeholder="{{ 'StableCard.Registration1' | translate }}"
              matInput
              formControlName="deaths"
              (keydown)="data.numberInput($event, data.index, 'deaths')"
              (keyup)="data.numberChange(data.index, 'deaths')"
              [readonly]="data.blockFirst !== 2 && (data.displayStableRecordData.blocked !== 0 || data.isBlocked || (data.editBlocked && data.dataStatus == 2))"
            >
          </mat-form-field>
        </div>
        <div class="cell wide" *ngIf="data.stableLogForm.get('onceTwiceFormControl').value == 2">
          <mat-form-field
            [ngClass]="{'small-error-number-form': data.stableLogFormArray.get([data.index]).get('deathsPM').errors}">
            <input
              inputmode="decimal"
              placeholder="{{ 'StableCard.Registration2' | translate }}"
              matInput
              formControlName="deathsPM"
              (keydown)="data.numberInput($event, data.index, 'deathsPM')"
              (keyup)="data.numberChange(data.index, 'deathsPM')"
              [readonly]="data.blockFirst !== 2 && (data.displayStableRecordData.blocked !== 0 || data.isBlocked || (data.editBlocked && data.dataStatus == 2))"
            >
          </mat-form-field>
        </div>
      </div>
      <div class="form-field-cont">
        <div [ngClass]="twiceFormControl ? 'cell wide' : 'cell full'">
          <label>{{ 'StableCard.Selection' | translate}}</label>
          <mat-form-field
            [ngClass]="{'small-error-number-form': data.stableLogFormArray.get([data.index]).get('selection').errors}"
            class="stable-input">
            <input
              inputmode="decimal"
              placeholder="{{ 'StableCard.Registration1' | translate }}"
              matInput
              formControlName="selection"
              (keydown)="data.numberInput($event, data.index, 'selection')"
              (keyup)="data.numberChange(data.index, 'selection')"
              [readonly]="data.blockFirst !== 2 && (data.displayStableRecordData.blocked !== 0 || data.isBlocked || (data.editBlocked && data.dataStatus == 2))"
            >
          </mat-form-field>
        </div>
        <div class="cell wide" *ngIf="data.stableLogForm.get('onceTwiceFormControl').value == 2">
          <mat-form-field
            [ngClass]="{'small-error-number-form': data.stableLogFormArray.get([data.index]).get('selectionPM').errors}"
          >
            <input
              inputmode="decimal"
              placeholder="{{ 'StableCard.Registration2' | translate }}"
              matInput
              formControlName="selectionPM"
              (keydown)="data.numberInput($event, data.index, 'selectionPM')"
              (keyup)="data.numberChange(data.index, 'selectionPM')"
              [readonly]="data.blockFirst !== 2 && (data.displayStableRecordData.blocked !== 0 || data.isBlocked || (data.editBlocked && data.dataStatus == 2))"
            >
          </mat-form-field>
        </div>
      </div>
      <div *ngIf="data.smallColumnActive" class="form-field-cont">
        <div [ngClass]="twiceFormControl ? 'cell wide' : 'cell full'">
          <label>{{ 'StableCard.Small' | translate}}</label>
          <mat-form-field
            [ngClass]="{'small-error-number-form': data.stableLogFormArray.get([data.index]).get('small').errors}"
            class="stable-input">
            <input
              inputmode="decimal"
              placeholder="{{ 'StableCard.Registration1' | translate }}"
              matInput
              formControlName="small"
              (keydown)="data.numberInput($event, data.index, 'small')"
              (keyup)="data.numberChange(data.index, 'small')"
              [readonly]="data.blockFirst !== 2 && (data.displayStableRecordData.blocked !== 0 || data.isBlocked || (data.editBlocked && data.dataStatus == 2))"
            >
          </mat-form-field>
        </div>
        <div class="cell wide" *ngIf="data.stableLogForm.get('onceTwiceFormControl').value == 2">
          <mat-form-field
            [ngClass]="{'small-error-number-form': data.stableLogFormArray.get([data.index]).get('smallPM').errors}"
          >
            <input
              inputmode="decimal"
              placeholder="{{ 'StableCard.Registration2' | translate }}"
              matInput
              formControlName="smallPM"
              (keydown)="data.numberInput($event, data.index, 'smallPM')"
              (keyup)="data.numberChange(data.index, 'smallPM')"
              [readonly]="data.blockFirst !== 2 && (data.displayStableRecordData.blocked !== 0 || data.isBlocked || (data.editBlocked && data.dataStatus == 2))"
            >
          </mat-form-field>
        </div>
      </div>

      <div class="form-field-cont">
        <div [ngClass]="twiceFormControl ? 'cell wide' : 'cell full'">
          <label>{{ 'StableCard.LegProblem' | translate}}</label>
          <mat-form-field
            [ngClass]="{'small-error-number-form': data.stableLogFormArray.get([data.index]).get('selectionLeg').errors}"
            class="stable-input">
            <input
              inputmode="decimal"
              placeholder="{{ 'StableCard.Registration1' | translate }}"
              matInput
              formControlName="selectionLeg"
              (keydown)="data.numberInput($event, data.index, 'selectionLeg')"
              (keyup)="data.numberChange(data.index, 'selectionLeg')"
              [readonly]="data.blockFirst !== 2 && (data.displayStableRecordData.blocked !== 0 || data.isBlocked || (data.editBlocked && data.dataStatus == 2))"
            >
          </mat-form-field>
        </div>
        <div class="cell wide" *ngIf="data.stableLogForm.get('onceTwiceFormControl').value == 2">
          <mat-form-field
            [ngClass]="{'small-error-number-form': data.stableLogFormArray.get([data.index]).get('selectionLegPM').errors}">
            <input
              inputmode="decimal"
              placeholder="{{ 'StableCard.Registration2' | translate }}"
              matInput
              formControlName="selectionLegPM"
              (keydown)="data.numberInput($event, data.index, 'selectionLegPM')"
              (keyup)="data.numberChange(data.index, 'selectionLegPM')"
              [readonly]="data.blockFirst !== 2 && (data.displayStableRecordData.blocked !== 0 || data.isBlocked || (data.editBlocked && data.dataStatus == 2))"
            >
          </mat-form-field>
        </div>
      </div>
      <div class="form-field-cont">
        <div class="cell">
          <div>
            <span>{{ 'StableCard.ThinningBroilers' | translate }}</span>
            <mat-checkbox
              color="primary"
              formControlName="thinningBirdsCheckbox"
              (change)="data.thinningBirdsChange($event, data.index)"
              [disabled]="data.blockFirst !== 2 && (data.displayStableRecordData.blocked !== 0 || data.isBlocked || (data.editBlocked && data.dataStatus == 2))"
            >
            </mat-checkbox>
          </div>
          <div *ngIf="data.stableLogFormArray.get([data.index]).get('thinningBirdsCheckbox').value">
            <mat-form-field
              [ngClass]="{'small-error-number-form': data.stableLogFormArray.get([data.index]).get('thinningBirds').errors}">
              <input
                inputmode="decimal"
                matInput
                formControlName="thinningBirds"
                (keydown)="data.numberInput($event, data.index, 'thinningBirds')"
                (keyup)="data.numberChange(data.index, 'thinningBirds')"
                [readonly]="data.blockFirst !== 2 && (data.displayStableRecordData.blocked !== 0 || data.isBlocked || (data.editBlocked && data.dataStatus == 2))"
              >
            </mat-form-field>
          </div>
        </div>
      </div>
      <label>{{ 'StableCard.DailyLoss' | translate}}</label>
      <div class="form-field-cont">
        <div class="cell wide">
          <span class="value">{{ data.displayStableRecordData.dailyLoss}}</span>
        </div>
        <div class="cell wide">
          <span class="value">{{ data.displayStableRecordData.dailyLossPerc | euro:2}} %</span>
        </div>
      </div>
      <label>{{ 'StableCard.TotalLoss' | translate}}</label>
      <div class="form-field-cont">
        <div class="cell wide">
          <span class="value">{{ data.displayStableRecordData.totalLoss}}</span>
        </div>
        <div class="cell wide">
          <span class="value">{{ data.displayStableRecordData.totalLossPerc | euro:2}} %</span>
        </div>
      </div>
      <label>{{ 'StableCard.CumulativeDailyLossPercentage' | translate}}</label>
      <div class="form-field-cont">
        <div class="cell wide">
          <span class="value">{{ data.displayStableRecordData.cumDailyLossPerc | euro:2}} %</span>
        </div>
      </div>
      <div class="form-field-cont">
        <div class="cell">
          <span>{{ 'StableCard.Explanation' | translate}}</span>
          <mat-form-field
            [ngClass]="{'small-error-number-form': data.stableLogFormArray.get([data.index]).get('description').errors}"
          >
            <input
              matInput
              formControlName="description"
              [readonly]="data.blockFirst !== 2 && (data.displayStableRecordData.blocked !== 0 || data.isBlocked || (data.editBlocked && data.dataStatus == 2))"
            >
          </mat-form-field>
        </div>
      </div>
      <div class="action-buttons-cont">
        <button
          mat-icon-button
          *ngIf="data.stableLogFormArray.get([data.index]).get('blocked').value == 0 && !(data.editBlocked && data.dataStatus == 2)"
          [disabled]="data.blockFirst !== 2 && (data.isBlocked || (data.editBlocked && data.dataStatus == 2))"
          (click)="data.resetStableLog(data.index)"
        >
          <i class="material-icons">delete_forever</i>
        </button>
        <div class="close-btn">
          <button class="button-green" mat-raised-button (click)="closeDialog()" >
            {{'VKIMobile.Close' | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
  <app-loading-spinner [show]=data.showSpinner.value></app-loading-spinner>
</div>
