import { Injectable } from '@angular/core';
import { HttpContext } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { Https } from '../../http.service';
import { FLOCK_NUMBER, FLOCK_REQUEST_EXTRA_KEY, FLOCK_REQUEST_KEY, METADATA } from 'src/app/interceptors/my-flock.interceptor';
import { KEY_CANCEL_REQUEST, KEY_FLOCK_STEP_VACCINATION, KEY_UPLOAD_TEMP_FILE } from 'src/lib/my-flock/constants';

@Injectable({
  providedIn: 'root',
})
export class VaccinationService {
  currentVaccinationData: any;
  currentIllnessListData: any;
  currentAntiBioticData: any;
  currentMethodListData: any;
  currentVaccinationListData: any;
  currentVaccinationError: any;
  currentUploadDataOri: any;

  constructor(private http: Https) {}

  public getVaccination(flockNumber) {
    const context = new HttpContext();
    context.set(FLOCK_NUMBER, flockNumber);
    context.set(FLOCK_REQUEST_KEY, KEY_FLOCK_STEP_VACCINATION);
    return this.http.get(`${environment.baseUrl}/plukonKras/flock/vaccination/${flockNumber}`, undefined, undefined, context);
  }

  public getAntiBioticList(stableId) {
    return this.http.get(`${environment.baseUrl}/plukonKras/therapy/vaccin/${stableId}`, '');
  }

  public saveVaccination(payload) {
    const context = new HttpContext();
    context.set(FLOCK_NUMBER, payload?.flockNumber);
    context.set(FLOCK_REQUEST_KEY, KEY_FLOCK_STEP_VACCINATION);
    return this.http.post(`${environment.baseUrl}/plukonKras/flock/vaccination`, payload, undefined, context);
  }

  public uploadFile(data) {
    const context = new HttpContext();
    context.set(FLOCK_NUMBER, data.get('flockNumber'));
    context.set(FLOCK_REQUEST_KEY, KEY_FLOCK_STEP_VACCINATION);
    context.set(FLOCK_REQUEST_EXTRA_KEY, KEY_UPLOAD_TEMP_FILE);
    context.set(METADATA, {
      uuid: data.get('uuid'),
      documentName: data.get('documentName'),
    });
    return this.http.upload(`${environment.baseUrl}/plukonKras/flock/vaccination/tempfile`, data, undefined, context);
  }

  public cancelUpload(payload) {
    const context = new HttpContext();
    context.set(FLOCK_NUMBER, payload?.flockNumber);
    context.set(FLOCK_REQUEST_KEY, KEY_FLOCK_STEP_VACCINATION);
    context.set(FLOCK_REQUEST_EXTRA_KEY, KEY_CANCEL_REQUEST);
    return this.http.post(`${environment.baseUrl}/plukonKras/flock/vaccination/tempfile/cancel`, payload, undefined, context);
  }

  set vaccinationData(value: any) {
    this.currentVaccinationData = value;
  }

  get vaccinationData(): any {
    return this.currentVaccinationData;
  }

  set vaccinationDataOri(value: any) {
    this.currentVaccinationListData = value;
  }

  get vaccinationDataOri(): any {
    return this.currentVaccinationListData;
  }

  set illnessListData(value: any) {
    this.currentIllnessListData = value;
  }

  get illnessListData(): any {
    return this.currentIllnessListData;
  }

  set antiBioticListData(value: any) {
    this.currentAntiBioticData = value;
  }

  get antiBioticListData(): any {
    return this.currentAntiBioticData;
  }

  set methodListData(value: any) {
    this.currentMethodListData = value;
  }

  get methodListData(): any {
    return this.currentMethodListData;
  }

  set vaccinationError(value: any) {
    this.currentVaccinationError = value;
  }

  get vaccinationError(): any {
    return this.currentVaccinationError;
  }

  set uploadDataOri(value: any) {
    this.currentUploadDataOri = value;
  }

  get uploadDataOri(): any {
    return this.currentUploadDataOri;
  }
}
