import {
  KEY_CANCEL_REQUEST,
  KEY_FLOCK_BASICS,
  KEY_FLOCK_COCCIDIO,
  KEY_FLOCK_ORIGIN,
  KEY_FLOCK_STEP_MEDICATION,
  KEY_FLOCK_STEP_MORTALITY,
  KEY_FLOCK_STEP_SALMONELLA,
  KEY_FLOCK_STEP_TECHNICAL,
  KEY_FLOCK_STEP_VACCINATION,
  KEY_STABLE_LOG_LOSSES,
  KEY_STABLE_LOG_TECHNICAL,
  KEY_UPLOAD_TEMP_FILE,
  KEY_VKI_REQUEST,
} from './constants';
import { parseQueryKey } from './query-key.helper';

export interface CombineDataType {
  cachedResponseBody: any;
  cachedRequestBody: any;
  cachedRequestQueryKey: string;
  cachedRequestMetadata: any;
  lastSaveResponse: any;
  flockNumber: string;
  key: string;
}

export const combineCachedResponseAndRequest = (data: CombineDataType): { responseBody: any; lastSaveResponse: any } => {
  const { extraRequestKey } = parseQueryKey(data.cachedRequestQueryKey);
  if (extraRequestKey === undefined) {
    const result = combineCachedResponseAndStepSaveRequest(data);
    return {
      responseBody: result,
      lastSaveResponse: result,
    };
  }
  if (extraRequestKey === KEY_UPLOAD_TEMP_FILE) {
    const result = combineCachedResponseAndUploadFileRequest(data);
    return {
      responseBody: result,
      lastSaveResponse: data.lastSaveResponse,
    };
  }
  if (extraRequestKey === KEY_CANCEL_REQUEST) {
    const result = combineCachedResponseAndCancelRequest(data);
    return {
      responseBody: result,
      lastSaveResponse: data.lastSaveResponse,
    };
  }
};

const combineCachedResponseAndCancelRequest = (data: CombineDataType): any => {
  const { lastSaveResponse, key } = data;
  switch (key) {
    default: {
      return lastSaveResponse;
    }
  }
};

const combineCachedResponseAndUploadFileRequest = (data: CombineDataType): any => {
  const { cachedResponseBody, cachedRequestMetadata, key } = data;
  switch (key) {
    case KEY_FLOCK_STEP_MEDICATION: {
      return {
        ...cachedResponseBody,
        response: {
          ...cachedResponseBody.response,
          statement: [
            {
              originalDocumentName: cachedRequestMetadata.documentName ? cachedRequestMetadata.documentName : '',
              isOffline: true,
            },
          ],
        },
      };
    }
    case KEY_FLOCK_STEP_VACCINATION:
      return {
        ...cachedResponseBody,
        response: {
          ...cachedResponseBody.response,
          statement: [
            {
              originalDocumentName: cachedRequestMetadata.documentName ? cachedRequestMetadata.documentName : '',
              isOffline: true,
            },
          ],
        },
      };
    default: {
      return cachedResponseBody;
    }
  }
};

const combineCachedResponseAndStepSaveRequest = (data: {
  cachedResponseBody: any;
  cachedRequestBody: any;
  cachedRequestQueryKey: string;
  cachedRequestMetadata: any;
  lastSaveResponse: any;
  flockNumber: string;
  key: string;
}): any => {
  const { cachedResponseBody, cachedRequestBody, key } = data;
  if (!cachedRequestBody) {
    return cachedResponseBody;
  }
  switch (key) {
    case KEY_FLOCK_STEP_TECHNICAL:
      return {
        ...cachedResponseBody,
        response: {
          ...cachedResponseBody.response,
          ...cachedRequestBody,
          statements: cachedRequestBody.insertUpdate,
          insertUpdate: null,
        },
      };
    case KEY_FLOCK_BASICS:
      return {
        ...cachedResponseBody,
        response: {
          ...cachedResponseBody.response,
          ...cachedRequestBody,
          feedingLocation: cachedRequestBody.insertUpdate,
          insertUpdate: null,
        },
      };
    case KEY_FLOCK_ORIGIN:
      return {
        ...cachedResponseBody,
        response: {
          ...cachedResponseBody.response,
          ...cachedRequestBody,
          breedingOrigins: cachedRequestBody.insertUpdate,
          insertUpdate: null,
        },
      };
    case KEY_FLOCK_STEP_MORTALITY:
      return {
        ...cachedResponseBody,
        response: {
          ...cachedResponseBody.response,
          ...cachedRequestBody,
          statement: cachedRequestBody.insertUpdate,
          insertUpdate: null,
        },
      };
    case KEY_FLOCK_COCCIDIO:
      return {
        ...cachedResponseBody,
        response: {
          ...cachedResponseBody.response,
          ...cachedRequestBody,
          treatmentsC: cachedRequestBody.insertUpdate,
          insertUpdate: null,
        },
      };
    case KEY_FLOCK_STEP_MEDICATION:
      return {
        ...cachedResponseBody,
        response: {
          ...cachedResponseBody.response,
          treatmentsM: cachedRequestBody.insertUpdate,
          containCriticalMedicine: cachedRequestBody.containCriticalMedicine,
          medicationNo: cachedRequestBody.medicationNo,
        },
      };
    case KEY_FLOCK_STEP_VACCINATION:
      return {
        ...cachedResponseBody,
        response: {
          ...cachedResponseBody.response,
          treatmentsV: cachedRequestBody.insertUpdate,
        },
      };
    case KEY_FLOCK_STEP_SALMONELLA:
      return {
        ...cachedResponseBody,
        response: {
          ...cachedResponseBody.response,
          salmonella: cachedRequestBody.insertUpdateSalmonella,
          reports: cachedRequestBody.insertUpdate,
          insertUpdate: null,
        },
      };
    case KEY_STABLE_LOG_LOSSES:
      return {
        ...cachedResponseBody,
        response: {
          ...cachedResponseBody.response,
          mortality: getUpdatedList(cachedResponseBody.response.mortality, cachedRequestBody.insertUpdate),
        },
      };
    case KEY_STABLE_LOG_TECHNICAL:
      return {
        ...cachedResponseBody,
        response: {
          ...cachedResponseBody.response,
          technical: getUpdatedList(cachedResponseBody.response.technical, cachedRequestBody.insertUpdate),
        },
      };
    case KEY_VKI_REQUEST:
      // NOT IMPLEMENTED as VKI Requests update Flock basics data
      throw new Error('Not implemented');
    default:
      return {
        ...cachedRequestBody,
        response: {
          ...cachedResponseBody.response,
          ...cachedRequestBody,
        },
      };
  }
};

const getUpdatedList = (responseData, insertUpdate) => {
  const data = responseData;
  if (insertUpdate) {
    insertUpdate.forEach((update) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].id === update.id) {
          data[i] = {
            ...data[i],
            ...update,
          };
          break;
        }
      }
    });
  }
  return data;
};
