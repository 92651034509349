import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

import { LoginService } from '../../shared/services/login-service';
import { SocialAuthService } from 'src/app/shared/services/social-auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslationService } from 'src/app/shared/services/translation.service';
import { Https } from 'src/app/shared/services/http.service';
import { AuthService } from 'src/app/shared/services/auth/auth.service';

@Component({
  selector: 'app-terms-and-condition',
  templateUrl: './terms-and-condition.component.html',
  styleUrls: ['./terms-and-condition.component.scss'],
})
export class TermsAndConditionComponent implements OnInit, OnDestroy {
  saveTermAndConditionSubscription: Subscription;
  socialSubscription: Subscription;
  plukonTranslationSub: Subscription;
  selectedLang: any;
  sentence: any;
  showSpinner: boolean;
  agreeFlag = false;
  internalLink: any;
  internalLinkText: any;
  termOfUseLink: any;
  privacyLink: any;
  cookieStatementLink: any;

  constructor(
    private translate: TranslateService,
    private router: Router,
    private socialService: SocialAuthService,
    private loginService: LoginService,
    public snackBar: MatSnackBar,
    private translationService: TranslationService,
    private http: Https,
    private authService: AuthService,
  ) {
    // //get current lang when switch tab
    // if(translate.currentLang) {
    //   this.selectedLang = translate.currentLang;
    // }
    // //detect change in language
    // translate.onLangChange.subscribe(result=>{
    //   this.selectedLang = result.lang;
    // });
  }

  ngOnInit() {
    this.socialSubscription = this.socialService.getMyProfile().subscribe(
      (result) => {
        this.plukonTranslationSub = this.translationService.getPlukonTranslation(null).subscribe();

        if (result) {
          if (result['response'].tncAgree == true) {
            this.router.navigate(['']);
          }
          if (result['response']['displayLanguageObj'].name) {
            this.selectedLang = result['response']['displayLanguageObj'].name;
          }
        }
      },
      (error) => {
        if (error.status == '401') {
          this.snackBar.open(this.translate.instant('Portal.UnauthorizedAPICall'), 'Close', { verticalPosition: 'top' });
          return false;
        }
        if (error.status == '404') {
          this.router.navigate(['/login']);
          return false;
        }
      },
    );

    this.internalLink = environment.linkUrl;
    this.internalLinkText = environment.linkUrlText;
    this.termOfUseLink = `${environment.linkUrl}terms-of-use`;
    this.privacyLink = `${environment.linkUrl}privacy-statement`;
    this.cookieStatementLink = `${environment.linkUrl}cookie-statement`;
  }

  agreeTermAndCondition(event) {
    this.agreeFlag = event.checked;
  }

  backToLoginPage() {
    this.authService.logout();
  }

  saveTermAndCondition() {
    this.showSpinner = true;
    const data = {
      tncAgree: this.agreeFlag,
    };

    this.saveTermAndConditionSubscription = this.loginService.saveTermAndCondition(data).subscribe(
      (result) => {
        if (result) {
          this.showSpinner = false;
          this.router.navigate(['']);
        }
      },
      (error) => {
        this.showSpinner = false;
        console.log('Term and Condition (saveTermAndCondition) : ', error);
        switch (error.status) {
          case 401:
            this.snackBar.open(this.translate.instant('Portal.UnauthorizedAPICall'), 'Close', { verticalPosition: 'top' });
            break;
          default:
            this.snackBar.open(this.translate.instant('ErrorMessage.DataRetrieveFail'), 'Close', {
              duration: 5000,
              verticalPosition: 'top',
            });
            break;
        }
      },
    );
  }

  ngOnDestroy() {
    if (this.socialSubscription) {
      this.socialSubscription.unsubscribe();
    }
    if (this.saveTermAndConditionSubscription) {
      this.saveTermAndConditionSubscription.unsubscribe();
    }
    if (this.plukonTranslationSub) {
      this.plukonTranslationSub.unsubscribe();
    }
  }
}
