import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { MyErrorStateMatcher } from '../../functions/error-matcher';
import { DialogService } from '../../services/dialog.service';

@Component({
  selector: 'app-email-sharing-dialog',
  templateUrl: './email-sharing-dialog.component.html',
  styleUrls: ['./email-sharing-dialog.component.scss'],
})
export class EmailSharingDialogComponent implements OnInit {
  companyId: any;
  isNew: any;
  data: any;
  existingEmailList: any;
  email: any;
  emailSharingForm: UntypedFormGroup;
  showSpinner = false;
  documentList: any;
  matcher = new MyErrorStateMatcher();

  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<EmailSharingDialogComponent>,
    public translate: TranslateService,
    public snackBar: MatSnackBar,
    private dialogService: DialogService,
  ) {
    this.emailSharingForm = this.fb.group({
      email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$')]],
      documentList: [null, [Validators.required]],
    });
  }

  validationError = {
    email: [
      { type: 'required', message: 'EmailSharingDialog.EmailRequired' },
      { type: 'pattern', message: 'EmailSharingDialog.EmailInvalid' },
    ],
  };

  ngOnInit(): void {
    this.showSpinner = true;
    if (this.data) {
      this.patchFormValue(this.data);
    }
    this.showSpinner = false;
  }

  changeDetection() {
    this.emailSharingForm.get('email').updateValueAndValidity();
    this.emailSharingForm.get('documentList').markAsTouched();
    this.emailSharingForm.get('documentList').updateValueAndValidity();
  }

  patchFormValue(content) {
    this.emailSharingForm.get('email').patchValue(this.email);
    this.emailSharingForm
      .get('documentList')
      .patchValue(
        content.documentTypeObjList && content.documentTypeObjList.length > 0
          ? content.documentTypeObjList.map((data) => data.codeTableId)
          : null,
      );
  }

  getMultiSelected() {
    const data = _.cloneDeep(this.emailSharingForm.value);
    if (data.documentList && data.documentList.length > 0) {
      const selectedAnsList = data.documentList.map((value) => {
        const tempDoc = this.documentList.filter((doc) => doc.codeTableId === value)
          ? this.documentList.filter((doc) => doc.codeTableId === value)[0]
          : null;
        return tempDoc ? this.translate.instant(tempDoc.codeTable + '.' + tempDoc.name) : null;
      });
      return selectedAnsList.length > 0 ? selectedAnsList.join('<br/>') : null;
    }
    return '';
  }

  saveSharing() {
    // TP-873 Check duplicate email
    // if (this.isNew) {
    //   if (this.existingEmailList.find((data) => data.email === this.email)) {
    //     this.dialogService.confirm('EmailDocumentDialog.EmailExisted', false, true);
    //     return;
    //   }
    // } else {
    //   if (this.existingEmailList.find((data) => data.email === this.email && data.email !== this.data.email)) {
    //     this.dialogService.confirm('EmailDocumentDialog.EmailExisted', false, true);
    //     return;
    //   }
    // }
    const data = _.cloneDeep(this.emailSharingForm.value);
    if (data.documentList && data.documentList.length > 0) {
      data.documentList = data.documentList.map((value) => ({
        documentTypeObj:
          this.documentList.filter((doc) => doc.codeTableId === value).length > 0
            ? this.documentList.filter((doc) => doc.codeTableId === value)[0]
            : null,
      }));
    }
    this.dialogRef.close(data);
  }
}
