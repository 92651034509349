<div class="content-container">
  <div *ngIf="!isPhone; else phoneTemplate" [formGroup]="companyFormGroup">
    <label class="message-label">{{ getDescriptionTranslation() }}</label>
    <div *ngIf="fieldName === 'bankAccFormControl'; else taxNumber" class="financial-field field-div">
      <label class="input-label">{{'MyPlukonCompany.BankAccountNumber' | translate}}</label>
      <div class="input-field" fxLayout="column">
        <mat-form-field>
          <div [ngClass]="{'input-enabled': companyFormGroup.get('bankAccFormControl').enabled}">
            <input matInput formControlName="bankAccFormControl" backAccInput="backAcc" (keydown)="changeDetection()">
          </div>
          <mat-error *ngIf="companyFormGroup.get('bankAccFormControl').hasError('required')">
            <mat-error>{{'MyPlukonCompany.IBANRequired' | translate}}</mat-error>
          </mat-error>
          <mat-error *ngIf="companyFormGroup.get('bankAccFormControl').hasError('backAcc') || invalidIBAN">
            <mat-error>{{'MyPlukonCompany.IBANInvalid' | translate}}</mat-error>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <ng-template #taxNumber>
      <div class="financial-field field-div">
        <label class="input-label">{{'MyPlukonCompany.TaxNumber' | translate}}</label>
        <div class="input-field" fxLayout="column">
          <mat-form-field>
            <div [ngClass]="{'input-enabled': companyFormGroup.get('taxNumberFormControl').enabled}">
              <input matInput formControlName="taxNumberFormControl" (keydown)="changeDetection()">
            </div>
            <mat-error *ngIf="companyFormGroup.get('taxNumberFormControl').hasError('required')">
              <mat-error>{{'MyPlukonCompany.TaxNumberRequired' | translate}}</mat-error>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </ng-template>
  </div>
  <ng-template #phoneTemplate>
    <div [formGroup]="companyFormGroup">
      <div fxLayout="row" fxLayoutAlign="center center">
        <label>{{ getPhoneDescriptionTranslation() }}</label>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center" style="padding: 8px;">
        <label style="font-style: italic;">{{ oldValue }}</label>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center" class="field-div">
        <ngx-intl-tel-input
            [cssClass]="'custom'"
            [enableAutoCountrySelect]="true"
            [enablePlaceholder]="false"
            [searchCountryFlag]="true"
            [searchCountryField]="[SearchCountryField.DialCode, SearchCountryField.Name]"
            [onlyCountries]="onlyCountries"
            [selectFirstCountry]="false"
            [selectedCountryISO]="getSelectedCountry()"
            [maxLength]="15"
            [phoneValidation]="false"
            [separateDialCode]="true"
            name="phoneFormControl"
            formControlName="phoneFormControl"
          >
        </ngx-intl-tel-input>
      </div>
    </div>
  </ng-template>
  <mat-dialog-actions style="justify-content: center;">
    <button class="button-enabled" mat-button mat-dialog-close (click)="dialogRef.close()">{{'Common.Cancel' | translate}}</button>
    <button [ngClass]="{'button-disabled': !companyFormGroup.valid || !saveEnabled, 'button-enabled': companyFormGroup.valid && saveEnabled}" mat-button (click)="save()" [disabled]="!companyFormGroup.valid || !saveEnabled">{{'Common.Save' | translate}}</button>
  </mat-dialog-actions>
  <i class="material-icons" (click)="dialogRef.close()">clear</i>
  <app-loading-spinner [show]=showSpinner></app-loading-spinner>
</div>
