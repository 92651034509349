import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-content-dialog',
  templateUrl: './content-dialog.component.html',
  styleUrls: ['./content-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ContentDialogComponent implements OnInit {
  public content: string;
  public isHtml: boolean;
  public primaryButtonText: string;
  public secondaryButtonText: string;
  public showGif = false;
  public firefoxProblemUrl;
  public isTranslateString: boolean;
  public isMyTask = false;
  public notificationNumber: number;
  public is2FA = false;
  public manualUrl: string;
  public isHelp = false;

  constructor(
    public sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<ContentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public translate: TranslateService,
  ) {}

  ngOnInit() {
    this.firefoxProblemUrl = environment.firefoxProblemGif;
  }

  getContentTranslation() {
    if (this.is2FA) {
      const contentTranslation = this.translate.instant(this.content);
      return contentTranslation.replace('[MANUAL_LINK]', this.manualUrl);
    }
  }

  submit() {
    this.dialogRef.close(true);
  }
}
