// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    serviceWorker: true,
    enableOffline: true,
    enableMyFlockOffline: true,
    notificationsFunctionsUrl: 'https://us-central1-plukon-dev.cloudfunctions.net/notificationsFCM',
    baseUrl: 'https://api.dev.plukonnect.com/api/v1',
    baseUrlV2: 'https://api.dev.plukonnect.com/api/v2',
    baseUrlV3: 'https://api.dev.plukonnect.com/api/v3',
    iosAppStore: 'itms-apps://apps.apple.com/app/id6468566611',
    iosVersionCodeTableCode: 'plukonnectIosVersionDEV',
    appVersionCheckInterval: 15000, // every 15 sec
    magazinePicture: 'https://storage.googleapis.com/plukon-dev-images/magazine.jpg',
    firefoxProblemGif: 'https://storage.googleapis.com/plukon-dev-public/FirefoxLoginProblem.gif',
    firebaseApiKey: 'AIzaSyARc6moYyGVOMkD-z_Cm28jFjhw-oNW1zU',
    firebaseAuthDomain: 'plukon-dev.firebaseapp.com',
    firebaseProjectId: 'plukon-dev',
    firebaseStorageBucket:  'plukon-dev.appspot.com',
    firebaseMessagingSenderId: '516264089543',
    firebaseAppId: '1:516264089543:web:cd3a373f2738cd6754ebe2',
    firebaseVapIdKey: 'BKUXyhLiID-gBEoNwo6xpTI1BGUOhAdMOdAp_q-a4gDraN3YLx6vPSIpgC40mi251SBts2AuYsjgDhWJzWCOFK0',
    linkUrl: 'https://dev.plukonnect.com/#/',
    linkUrlText: 'www.dev.plukonnect.com.com',
    tabName: 'Dev Plukonnect',
    reportNL: 'https://datastudio.google.com/embed/reporting/1989zgdUappAb7wyYwgl58X8wq08ygS8l/page/LEdHB&hl=nl',
    reportBE: 'https://datastudio.google.com/embed/reporting/1989zgdUappAb7wyYwgl58X8wq08ygS8l/page/LEdHB&hl=de',
    myDateFormat: {
      parse: {
        dateInput: 'DD-MM-YYYY',
      },
      display: {
        dateInput: 'DD-MM-YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
      },
    },
    myDateTimeFormat: {
      parse: {
        dateInput: 'DD-MM-YYYY hh:mm',
      },
      display: {
        dateInput: 'DD-MM-YYYY hh:mm',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
      },
    },
  };

  /*
   * In development mode, to ignore zone related error stack frames such as
   * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
   * import the following file, but please comment it out in production mode
   * because it will have performance impact when throw error
   */
  // import 'zone.js/dist/zone-error';  // Included with Angular CLI.
