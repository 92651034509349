import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-losses-edit-animal-dialog',
  templateUrl: './losses-edit-animal-dialog.component.html',
  styleUrls: ['./losses-edit-animal-dialog.component.scss'],
})
export class LossesEditAnimalDialogComponent implements OnInit {
  public content: string;

  constructor(
    public dialogRef: MatDialogRef<LossesEditAnimalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit() {}

  submit() {
    this.dialogRef.close(true);
  }
}
