import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { Https } from './http.service';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from './common.service';

import * as _ from 'lodash';
import { BehaviorSubject, Subscription, Observable, from } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  private initializedSubject = new BehaviorSubject<boolean>(false);

  translationEng = {};
  translationDutch = {};
  translationGerman = {};
  translationFrench = {};
  translateLanguage = false;
  languageSelected: BehaviorSubject<string>;
  languageSelectedCodeTableId: BehaviorSubject<number>;
  profileSub: Subscription;
  languageSubscription: Subscription;

  constructor(
    private http: Https,
    private translate: TranslateService,
    private commonService: CommonService,
    public snackBar: MatSnackBar,
  ) {
    this.languageSelected = new BehaviorSubject<any>(null);
    this.languageSelectedCodeTableId = new BehaviorSubject<any>(null);
  }

  public get initialized$(): Observable<boolean> {
    return this.initializedSubject.asObservable();
  }

  public getTranslation() {
    return this.http.get(`${environment.baseUrlV2}/translations`, null, { skipAuth: true });
  }

  public getCodeTableTranslation() {
    return this.http.get(`${environment.baseUrl}/codeTables`, null, { skipAuth: true });
  }

  public syncTranslation() {
    return this.http.put(`${environment.baseUrlV2}/translations`, null);
  }

  public syncCodeTable() {
    return this.http.put(`${environment.baseUrl}/codeTables`, null);
  }

  public syncVKIConfig() {
    return this.http.put(`${environment.baseUrl}/vkiConfig`, null);
  }

  public syncHubSpotTag() {
    return this.http.put(`${environment.baseUrl}/news`, null);
  }

  public syncConceptLayout() {
    return this.http.put(`${environment.baseUrl}/concept/layout`, null);
  }

  public getPlukonTranslation(mode) {
    if (_.isEmpty(this.translationEng)) {
      return from(
        Promise.all([this.getTranslationData(), this.getCodeTableData()]).then(() => {
          this.translate.setTranslation('en', this.translationEng);
          this.translate.setTranslation('du', this.translationDutch);
          this.translate.setTranslation('ge', this.translationGerman);
          this.translate.setTranslation('fr', this.translationFrench);
          this.listenLanguageChange(mode);
          this.initializedSubject.next(true);
        }),
      );
    } else {
      this.listenLanguageChange(mode);
      return from(Promise.resolve());
    }
  }

  listenLanguageChange(mode) {
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe();
    }

    this.languageSubscription = this.languageSelected.subscribe((lang) => {
      if (!lang) {
        if (mode === 'login') {
          this.languageSelection(lang);
        } else {
          this.profileSub = this.commonService.myUserProfile.subscribe((result) => {
            if (result) {
              const language = result['response']['displayLanguageObj']['name']
                ? result['response']['displayLanguageObj']['name']
                : 'English';
              this.languageSelection(language);
              this.profileSub.unsubscribe();
            }
          });
        }
      } else {
        this.languageSelection(lang);
      }
    });
  }

  getTranslationData() {
    const self = this;
    return new Promise((resolve, reject) => {
      this.getTranslation().subscribe(
        (respond) => {
          _.forEach(respond['list'], function (value) {
            self.translationDutch[`${value['object']}.${value['field']}`] = `${value['language1']}`;
            self.translationEng[`${value['object']}.${value['field']}`] = `${value['language2']}`;
            self.translationGerman[`${value['object']}.${value['field']}`] = `${value['language3']}`;
            self.translationFrench[`${value['object']}.${value['field']}`] = `${value['language4']}`;
          });
          resolve(true);
        },
        (error) => {
          console.log('getTranslation Failed');
          if (error.status == '401') {
            this.snackBar.open(this.translate.instant('Portal.UnauthorizedAPICall'), 'Close', { verticalPosition: 'top' });
          }
          reject();
        },
      );
    });
  }

  getCodeTableData() {
    const self = this;
    return new Promise((resolve, reject) => {
      this.getCodeTableTranslation().subscribe(
        (respond) => {
          _.forEach(respond['list'], function (value) {
            self.translationDutch[`${value['codeTable']}.${value['name']}`] = `${value['language1']}`;
            self.translationEng[`${value['codeTable']}.${value['name']}`] = `${value['language2']}`;
            self.translationGerman[`${value['codeTable']}.${value['name']}`] = `${value['language3']}`;
            self.translationFrench[`${value['codeTable']}.${value['name']}`] = `${value['language4']}`;
          });
          resolve(true);
        },
        (error) => {
          console.log('getCodetable Failed');
          if (error.status == '401') {
            this.snackBar.open(this.translate.instant('Portal.UnauthorizedAPICall'), 'Close', { verticalPosition: 'top' });
          }
          reject();
        },
      );
    });
  }

  languageSelection(selectedLanguage) {
    switch (selectedLanguage) {
      case 'English':
        this.translate.use('en');
        break;
      case 'Nederlands':
        this.translate.use('du');
        break;
      case 'German':
        this.translate.use('ge');
        break;
      case 'Français':
        this.translate.use('fr');
        break;
      default:
        this.translate.use('en');
    }
  }
}
