import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-cancel-submit-dialog',
  templateUrl: './cancel-submit-dialog.component.html',
  styleUrls: ['./cancel-submit-dialog.component.scss'],
})
export class CancelSubmitDialogComponent implements OnInit {
  public content: string;

  constructor(
    public dialogRef: MatDialogRef<CancelSubmitDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit() {}

  submit() {
    this.dialogRef.close(true);
  }
}
