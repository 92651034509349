export class RoleSetting {
  private roleSetting = [
    {
      role: 'Superadmin',
      module: {
        header: {
          environment: {
            allow: true,
            editable: true,
          },
        },
        admin: {
          visible: true,
          dashboard: {
            visible: true,
            superAdminButton: true,
            adminButton: true,
          },
          user: {
            visible: true,
          },
          company: {
            visible: true,
          },
          environment: {
            visible: true,
          },
          vkichecks: {
            visible: true,
          },
        },
      },
    },
    {
      role: 'Admin',
      module: {
        header: {
          environment: {
            allow: true,
            editable: true,
          },
        },
        admin: {
          visible: true,
          dashboard: {
            visible: true,
            superAdminButton: false,
            adminButton: true,
          },
          user: {
            visible: true,
          },
          company: {
            visible: true,
          },
          environment: {
            visible: false,
          },
          vkichecks: {
            visible: false,
          },
        },
      },
    },
    {
      role: 'Planning Employee',
      module: {
        header: {
          environment: {
            allow: true,
            editable: true,
          },
        },
        admin: {
          visible: true,
          dashboard: {
            visible: false,
            superAdminButton: false,
            adminButton: false,
          },
          user: {
            visible: false,
          },
          company: {
            visible: false,
          },
          environment: {
            visible: false,
          },
          vkichecks: {
            visible: true,
          },
        },
      },
    },
    {
      role: 'Sales Representative',
      module: {
        header: {
          environment: {
            allow: true,
            editable: true,
          },
        },
        admin: {
          visible: false,
          dashboard: {
            visible: false,
            superAdminButton: false,
            adminButton: false,
          },
          user: {
            visible: false,
          },
          company: {
            visible: false,
          },
          environment: {
            visible: false,
          },
          vkichecks: {
            visible: false,
          },
        },
      },
    },
    {
      role: 'Quality employee',
      module: {
        header: {
          environment: {
            allow: false,
            editable: false,
          },
        },
        admin: {
          visible: false,
          dashboard: {
            visible: false,
            superAdminButton: false,
            adminButton: false,
          },
          user: {
            visible: false,
          },
          company: {
            visible: false,
          },
          environment: {
            visible: false,
          },
          vkichecks: {
            visible: false,
          },
        },
      },
    },
    {
      role: 'Veterinarian Employee',
      module: {
        header: {
          environment: {
            allow: false,
            editable: false,
          },
        },
        admin: {
          visible: false,
          dashboard: {
            visible: false,
            superAdminButton: false,
            adminButton: false,
          },
          user: {
            visible: false,
          },
          company: {
            visible: false,
          },
          environment: {
            visible: false,
          },
          vkichecks: {
            visible: false,
          },
        },
      },
    },
    {
      role: 'Other',
      module: {
        header: {
          environment: {
            allow: false,
            editable: false,
          },
        },
        admin: {
          visible: false,
          dashboard: {
            visible: false,
            superAdminButton: false,
            adminButton: false,
          },
          user: {
            visible: false,
          },
          company: {
            visible: false,
          },
          environment: {
            visible: false,
          },
          vkichecks: {
            visible: false,
          },
        },
      },
    },
  ];

  getRoleSetting(role) {
    const setting = this.roleSetting.find((data) => data.role === role);

    if (setting) {
      return setting;
    } else {
      return this.roleSetting.find((data) => data.role === 'Other');
    }
  }
}
