<div class="email-sharing-dialog-container">
  <mat-dialog-content [formGroup]="emailSharingForm">
    <label>
      {{'EmailSharingDialog.Email' | translate}}
    </label>
    <div fxLayout="row">
      <div fxLayout="column" fxFlex=50 fxLayoutAlign="flex-start flex-start">
        <mat-form-field>
          <input matInput formControlName="email" [errorStateMatcher]="matcher"
            [(ngModel)]="email" (keydown)="changeDetection()">
          <mat-error *ngFor="let validation of validationError.email">
            <mat-error *ngIf="emailSharingForm.get('email').hasError(validation.type)">{{validation.message | translate}}</mat-error>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <label>
      {{'EmailSharingDialog.Description' | translate}}
    </label>
    <div fxLayout="row">
      <div fxLayout="column" fxFlex=50 fxLayoutAlign="flex-start flex-start">
        <mat-form-field class="multiple-selection-field" hideRequiredMarker>
          <mat-select formControlName="documentList" multiple>
            <mat-select-trigger>
              <mat-label [innerHTML]="getMultiSelected()"></mat-label>
            </mat-select-trigger>
            <mat-option *ngFor="let document of documentList" [value]="document.codeTableId">
              {{document.codeTable + '.' + document.name | translate}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions fxLayout="row" fxLayoutAlign="flex-end center">
    <button [ngClass]="{'button-disabled': !emailSharingForm.dirty, 'button-enabled': emailSharingForm.dirty}" mat-button mat-dialog-close (click)="dialogRef.close()" [disabled]="!emailSharingForm.dirty">
      {{'Common.Cancel' | translate}}
    </button>
    <button [ngClass]="{'button-disabled': !emailSharingForm.dirty || !emailSharingForm.valid, 'button-enabled': emailSharingForm.dirty && emailSharingForm.valid}" mat-button (click)="saveSharing()" [disabled]="!emailSharingForm.dirty || !emailSharingForm.valid">
      <ng-container *ngIf="isNew; else edit">
        {{'Common.Add' | translate}}
      </ng-container>
      <ng-template #edit>
        {{'Common.Edit' | translate}}
      </ng-template>
    </button>
  </mat-dialog-actions>
  <i style="color: red;" class="material-icons" (click)="dialogRef.close()">clear</i>
  <app-loading-spinner [show]=showSpinner></app-loading-spinner>
</div>
