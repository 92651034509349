import { Injectable, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/internal/operators/take';
import { CommonService } from './common.service';
import { RoleSetting } from './setting/role-setting.service';
import { SocialAuthService } from './social-auth.service';
import { NetworkService } from './network/network.service';
import { AuthService } from './auth/auth.service';
import { HttpStatusCode } from '@angular/common/http';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate, CanActivateChild, OnDestroy {
  roleSetting: BehaviorSubject<any>;
  mainEnvironment: BehaviorSubject<any>;
  isTestUserSubscription: Subscription;
  isTestUser = false;

  constructor(
    private socialService: SocialAuthService,
    private roleService: RoleSetting,
    public snackBar: MatSnackBar,
    public translate: TranslateService,
    private router: Router,
    private commonService: CommonService,
    private networkService: NetworkService,
    private authService: AuthService,
  ) {
    this.roleSetting = new BehaviorSubject<any>(null);
    this.mainEnvironment = new BehaviorSubject<any>(null);
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.routeCheck(state);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    return this.routeCheck(state);
  }

  async routeCheck(state) {
    await this.authService.initialized$
      .pipe(
        filter((authInitialized) => authInitialized === true),
        take(1),
      )
      .toPromise();

    if (!this.authService.isAuthenticated()) {
      this.router.navigate(['/login']);
      return false;
    }
    return Promise.all([this.getProfile(state.url), this.getIsTestUser()])
      .then((result) => {
        if (this.networkService.isOfflineMode && !this.isSupportOffline(state.url)) {
          return false;
        }
        if (result[0]['allowedAccess']) {
          const setting = this.roleService.getRoleSetting(result[0]['role']);
          this.roleSetting.next(setting);
          return true;
        } else {
          console.log('auth-guard.components ' + state.url);
          this.router.navigate(['/unauthorized']);
          return false;
        }
      })
      .catch((error) => {
        if (error) {
          if (this.authService.isAuthenticated()) {
            if (error.status === HttpStatusCode.Unauthorized) {
              this.snackBar.open(this.translate.instant('Portal.UnauthorizedAPICall'), 'Close', { verticalPosition: 'top' });
            }
          }
          this.router.navigate(['/login']);
          return false;
        }
      });
  }

  getIsTestUser() {
    return new Promise((resolve, reject) => {
      this.isTestUserSubscription = this.commonService.isTestUser.subscribe(
        (data) => {
          this.isTestUser = data;
          resolve(this.isTestUser);
        },
        (error) => {
          console.log('error', error.status);
          if (this.authService.isAuthenticated() && error.status == '401') {
            this.snackBar.open(this.translate.instant('Portal.UnauthorizedAPICall'), 'Close', { verticalPosition: 'top' });
          }
          reject(true);
        },
      );
    });
  }

  getProfile(url) {
    return new Promise((resolve, reject) => {
      this.socialService
        .getMyProfileRole()
        .pipe(take(1))
        .subscribe(
          (result) => {
            const userRoleList = result['response']['userRoleList'] ? result['response']['userRoleList'] : [];
            const role = result['response'].userRoleMainCompanyObj ? result['response'].userRoleMainCompanyObj.name : null;
            const isAdmin = result['response'].admin;
            const isGlobal =
              !result['response'].admin &&
                (userRoleList.filter((value) => value.roleObj.name === 'Ext. Advisor').length > 0 || role === 'Ext. Advisor')
                ? false
                : result['response'].thisUserFromGlobalEnv;
            const allowedAccess = this.routeInterceptor(url, role, isAdmin, isGlobal, userRoleList);
            const data = {
              allowedAccess,
              role,
            };
            resolve(data);
          },
          (error) => {
            console.log('error', error.status);
            if (this.authService.isAuthenticated() && error.status == '401') {
              this.snackBar.open(this.translate.instant('Portal.UnauthorizedAPICall'), 'Close', { verticalPosition: 'top' });
            }
            if (error.status === 404 || error.status === 0) {
              reject(true);
            }
            reject(true);
          },
        );
    });
  }

  routeInterceptor(url, role, isAdmin, isGlobal, userRoleList) {
    if (isAdmin) {
      if (
        role === 'Superadmin' ||
        role === 'Admin' ||
        role === 'Sales Representative' ||
        role === 'Planning Employee' ||
        role === 'Quality employee'
      ) {
        return this.internalRoleVerification(url, role, isGlobal);
      } else {
        return false;
      }
    } else {
      if (
        role === 'Superadmin' ||
        role === 'Admin' ||
        role === 'Sales Representative' ||
        role === 'Planning Employee' ||
        role === 'Quality employee'
      ) {
        return false;
      } else {
        return this.externalRoleVerification(url, role, isGlobal, userRoleList);
      }
    }
  }

  isSupportOffline(url) {
    console.log(url);
    if (url == '/' || url.includes('my-flock')) {
      return true;
    } else {
      this.snackBar.open('This feature is not supported in offline mode.', 'Close', { verticalPosition: 'top' });
      return false;
    }
  }

  internalRoleVerification(url, role, isGlobal) {
    if (url.includes('admin/dashboard')) {
      if (role === 'Superadmin' || role === 'Admin') {
        return true;
      } else {
        return false;
      }
    } else if (url.includes('admin/user')) {
      if (role === 'Superadmin' || role === 'Admin') {
        return true;
      } else {
        return false;
      }
    } else if (url.includes('admin/company')) {
      if (role === 'Superadmin' || role === 'Admin') {
        return true;
      } else {
        return false;
      }
    } else if (url.includes('admin/environment')) {
      if (role === 'Superadmin') {
        return true;
      } else {
        return false;
      }
    } else if (url.includes('admin/vki-check')) {
      if (role === 'Superadmin' || role === 'Planning Employee' || role === 'Quality employee') {
        return true;
      } else {
        return false;
      }
    } else if (url.includes('my-company')) {
      //TP-816 June 3, 2022, 3:26 AM Harm comment updated access control
      if (url.includes('my-company/company')) {
        return true;
      } else if (url.includes('my-company/sharing-settings')) {
        if (role === 'Superadmin' || role === 'Admin' || role === 'Sales Representative') {
          return true;
        } else {
          return false;
        }
      } else if (url.includes('my-company/access-settings')) {
        if (role === 'Superadmin' || role === 'Admin' || role === 'Sales Representative') {
          return true;
        } else {
          return false;
        }
      } else if (url.includes('my-company/certificate')) {
        return true;
      }
    } else if (url.includes('my-messages')) {
      if (role === 'Quality employee') {
        return false;
      }
      return true;
    } else if (url.includes('my-flock')) {
      if (isGlobal) {
        return false;
      } else if (role === 'Quality employee') {
        return false;
      } else {
        if (url.includes('my-flock/stable-technical')) {
          return true;
        } else if (url.includes('my-flock/stable-card')) {
          return true;
        } else if (url.includes('my-flock/vki-data') || url.includes('my-flock/vki-requests')) {
          return true;
        } else {
          return false;
        }
      }
    } else if (url.includes('my-reports')) {
      if (role === 'Superadmin' || role === 'Admin' || role === 'Sales Representative') {
        return true;
      } else {
        return false;
      }
    } else if (url.includes('vki-checks')) {
      if (role === 'Superadmin' || role === 'Quality employee') {
        return true;
      } else {
        return false;
      }
    } else if (url.includes('my-feedback')) {
      return true;
    } else {
      return true;
    }
  }

  externalRoleVerification(url, role, isGlobal, userRoleList) {
    const isInternalMail =
      userRoleList.filter((data) => data.roleObj.name === 'internalMailPlanning').length > 0 ||
      userRoleList.filter((data) => data.roleObj.name === 'InternalMailAdmin').length > 0 ||
      userRoleList.filter((data) => data.roleObj.name === 'internalMailquality').length > 0;
    if (url.includes('admin')) {
      return false;
    } else if (url.includes('vki-checks')) {
      if (role === 'Veterinarian Employee') {
        return true;
      } else {
        return false;
      }
    } else if (url.includes('my-company')) {
      //TP-816 June 3, 2022, 3:26 AM Harm comment updated access control
      if (url.includes('my-company/company')) {
        if (role === 'Veterinarian Employee' || isInternalMail) {
          return false;
        } else {
          return true;
        }
      } else if (url.includes('my-company/certificate')) {
        if (userRoleList.filter((data) => data.roleObj.name === 'Poultry Owner').length > 0) {
          return true;
        } else {
          return false;
        }
      } else if (url.includes('my-company/sharing-settings')) {
        if (
          userRoleList.filter((data) => data.roleObj.name === 'Ext. Administration').length > 0 ||
          (userRoleList.filter((data) => data.roleObj.name === 'Poultry Owner').length > 0 && !isGlobal)
        ) {
          return true;
        } else {
          return false;
        }
      } else if (url.includes('my-company/access-settings')) {
        if (userRoleList.filter((data) => data.roleObj.name === 'Poultry Owner').length > 0) {
          return true;
        } else {
          return false;
        }
      }
    } else if (url.includes('my-messages')) {
      if (role === 'Veterinarian Employee' || isInternalMail) {
        return false;
      } else {
        return true;
      }
    } else if (url.includes('my-flock')) {
      if (
        role === 'Veterinarian Employee' ||
        isGlobal ||
        userRoleList.filter((data) => data.roleObj.name === 'Ext. Administration').length > 0 ||
        isInternalMail
      ) {
        return false;
      } else {
        return true;
      }
    } else if (url.includes('my-reports')) {
      if (userRoleList.filter((data) => data.roleObj.name === 'Poultry Owner').length > 0 && !isGlobal) {
        return true;
      } else {
        return false;
      }
    } else if (url.includes('news')) {
      //TP-830 June 8, 2022, 10:36 PM Kasim's comment allow Quality Employee to access News
      if (role === 'Veterinarian Employee' || isInternalMail) {
        return false;
      } else {
        return true;
      }
    } else if (url.includes('my-feedback')) {
      if (userRoleList.filter(data => data.roleObj.name === 'Poultry Owner').length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  ngOnDestroy(): void {
    if (this.isTestUserSubscription) {
      this.isTestUserSubscription.unsubscribe();
    }
  }
}
