import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from '../../services/translation.service';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../../services/auth/auth.service';
import {NotificationsService} from '../../services/notifications/notifications.service';

@Component({
  selector: 'app-unauthorize-maintenance',
  templateUrl: './unauthorize-maintenance.component.html',
  styleUrls: ['./unauthorize-maintenance.component.scss'],
})
export class UnauthorizeMaintenanceComponent implements OnInit, OnDestroy {
  translationSubscription: Subscription;

  constructor(
    public translate: TranslateService,
    private translationService: TranslationService,
    public snackBar: MatSnackBar,
    private authService: AuthService,
    private notifications: NotificationsService
  ) {
    this.translationSubscription = this.translationService.getPlukonTranslation('login').subscribe((result) => {
      if (translate.currentLang) {
        this.translate.use(translate.currentLang);
      } else {
        this.translate.use('en');
      }
    });
  }

  ngOnInit() { }

  login() {
    Promise.all([this.notifications.removeUserDataToNotificationsList(), this.authService.logout()])
      .then(() => {
        window.location.reload();
      }).catch((e) => console.warn('Notifications disabled'));
  }

  ngOnDestroy() {
    if (this.translationSubscription) {
      this.translationSubscription.unsubscribe();
    }
  }
}
