import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CdkTableModule } from '@angular/cdk/table';
import { TranslateModule } from '@ngx-translate/core';
import { EuropeanNotationPipe } from './pipe/european-number-pipe';
import { EuroPipe } from './pipe/euro-pipe';
import { NativeElementInjectorDirective } from './directives/native-element-injector-directive';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { HeaderComponent } from './components/header/header.component';
import { ErrorComponent } from './components/error/error.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { UnauthorizeComponent } from './components/unauthorize/unauthorize.component';
import { ContentDialogComponent } from './components/content-dialog/content-dialog.component';
import { LogoutComponent } from './components/logout/logout.component';
import { FooterComponent } from './components/footer/footer.component';
import { SlaughterdateDialogComponent } from './components/slaughterdate-dialog/slaughterdate-dialog.component';
import { CancelSubmitDialogComponent } from './components/cancel-submit-dialog/cancel-submit-dialog.component';
import { ContactDialogComponent } from './components/contact-dialog/contact-dialog.component';
import { StartdateDialogComponent } from './components/startdate-dialog/startdate-dialog.component';
import { UnauthorizeMaintenanceComponent } from './components/unauthorize-maintenance/unauthorize-maintenance.component';
import { AccessSettingsDialogComponent } from './components/access-settings-dialog/access-settings-dialog.component';
import { CumulativeConfirmationDialogComponent } from './components/cumulative-confirmation-dialog/cumulative-confirmation-dialog.component';
import { ChickenDeliverDialogComponent } from './components/chicken-deliver-dialog/chicken-deliver-dialog.component';
import { RemovePeriodPipe } from './pipe/decimal-period-pipe';
import { TimePipe } from './pipe/time-pipe';
import { EmailDocumentDialogComponent } from './components/email-document-dialog/email-document-dialog.component';
import { EmailSharingDialogComponent } from './components/email-sharing-dialog/email-sharing-dialog.component';
import { CustomIBANValidatorDirective } from './directives/custom-ibanvalidator.directive';
import { ContentReverseButtonDialogComponent } from './components/content-reverse-button-dialog/content-reverse-button-dialog.component';
import { SingleActionDialogComponent } from './components/single-action-dialog/single-action-dialog.component';
import { CompanyFieldDialogComponent } from './components/company-field-dialog/company-field-dialog.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { BottomNavigationComponent } from './components/bottom-navigation/bottom-navigation.component';
import { DatePipe } from './pipe/date-pipe';
import { DevItemsComponent } from './components/dev-items/dev-items.component';
import { CustomSnackbarComponent } from './components/custom-snackbar/custom-snackbar.component';
import { LossesEditAnimalDialogComponent } from './components/losses-edit-animal-dialog/losses-edit-animal-dialog.component';

const modules = [
  CommonModule,
  HttpClientModule,
  FlexLayoutModule,
  TranslateModule,
  FormsModule,
  MatFormFieldModule,
  ReactiveFormsModule,
  MatInputModule,
  MatButtonModule,
  MatListModule,
  MatCardModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatSidenavModule,
  MatToolbarModule,
  MatCheckboxModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatPaginatorModule,
  MatDialogModule,
  CdkTableModule,
  MatTabsModule,
  MatExpansionModule,
  MatChipsModule,
  MatMenuModule,
  MatSlideToggleModule,
  MatStepperModule,
  NgxMatSelectSearchModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatMomentDateModule,
  MatBadgeModule,
  MatRadioModule,
  MatTooltipModule,
  NgxIntlTelInputModule,
];

@NgModule({
  imports: [ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }), ...modules],
  declarations: [
    LoadingSpinnerComponent,
    HeaderComponent,
    ErrorComponent,
    DialogComponent,
    UnauthorizeComponent,
    ContentDialogComponent,
    LogoutComponent,
    FooterComponent,
    EuropeanNotationPipe,
    EuroPipe,
    DatePipe,
    RemovePeriodPipe,
    TimePipe,
    NativeElementInjectorDirective,
    CustomIBANValidatorDirective,
    SlaughterdateDialogComponent,
    CancelSubmitDialogComponent,
    ContactDialogComponent,
    StartdateDialogComponent,
    UnauthorizeMaintenanceComponent,
    AccessSettingsDialogComponent,
    CumulativeConfirmationDialogComponent,
    EmailDocumentDialogComponent,
    EmailSharingDialogComponent,
    ChickenDeliverDialogComponent,
    ContentReverseButtonDialogComponent,
    SingleActionDialogComponent,
    CompanyFieldDialogComponent,
    LossesEditAnimalDialogComponent,
    DevItemsComponent,
    BottomNavigationComponent,
    CustomSnackbarComponent,
  ],
  exports: [
    LoadingSpinnerComponent,
    HeaderComponent,
    ErrorComponent,
    DialogComponent,
    UnauthorizeComponent,
    ContentDialogComponent,
    FooterComponent,
    EuropeanNotationPipe,
    EuroPipe,
    DatePipe,
    RemovePeriodPipe,
    TimePipe,
    NativeElementInjectorDirective,
    CustomIBANValidatorDirective,
    UnauthorizeMaintenanceComponent,
    ChickenDeliverDialogComponent,
    ContentReverseButtonDialogComponent,
    SingleActionDialogComponent,
    CompanyFieldDialogComponent,
    DevItemsComponent,
    BottomNavigationComponent,
    CustomSnackbarComponent,
    ...modules,
  ],
})
export class SharedModule {}
