export const KEY_FLOCK_BASICS = 'flockBasics';
export const KEY_FLOCK_ORIGIN = 'flockOrigin';
export const KEY_FLOCK_COCCIDIO = 'flockCoccidio';
export const KEY_UPLOAD_TEMP_FILE = 'uploadTempFile';
export const KEY_CANCEL_REQUEST = 'cancelRequest';
export const KEY_STABLE_LOG_LOSSES = 'flockStableLog';
export const KEY_STABLE_LOG_TECHNICAL = 'flockStableLogTechnical';
export const KEY_VKI_REQUEST = 'flockVKIRequest';
export const KEY_FLOCK_STEP_TECHNICAL = 'flockTechnical';
export const KEY_FLOCK_STEP_MORTALITY = 'flockMortality';
export const KEY_FLOCK_STEP_MEDICATION = 'flockMedication';
export const KEY_FLOCK_STEP_VACCINATION = 'flockVaccination';
export const KEY_FLOCK_STEP_SALMONELLA = 'flockSalmonella';
