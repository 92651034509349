<div *ngIf="!isHidden && !blockedByUser" class="before-install-prompt-cont">
  <p class="before-install-p">{{'InstallPrompt.AppAvailableQuestion' | translate}}</p>
  <div class="before-install-action-cont">
    <div class="actions-btn">
      <button class="before-prompt-button  install-button" (click)="addAppPrompt()" mat-raised-button>{{'InstallPrompt.Install' | translate}}</button>
      <button class="before-prompt-button " (click)="decline()" mat-raised-button>{{'InstallPrompt.Decline' | translate}}</button>
    </div>
    <div class="before-prompt-check-cont ">
      <mat-checkbox
        data-testid="notShowCheck"
        [checked]="doNotShowItAgain"
        (change)="changeDontShowValue()"
        class="before-prompt-check">{{'InstallPrompt.DoNotShowAgain' | translate}}</mat-checkbox>
    </div>
  </div>
</div>
