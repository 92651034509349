import { NgModule, ModuleWithProviders, Optional, SkipSelf, ErrorHandler } from '@angular/core';
import { SocialAuthService } from './services/social-auth.service';
import { TranslationService } from './services/translation.service';
import { RouterService } from './services/router.service';
import { CompanyService } from './services/admin/company.service';
import { CompanyTypeService } from './services/admin/company-type.service';
import { EnvironmentService } from './services/admin/environment.service';
import { UserService } from './services/admin/user.service';
import { VKIAdminService } from './services/admin/vki-admin.service';
import { DialogService } from './services/dialog.service';
import { CodeService } from './services/code.service';
import { MyPlukonService } from './services/my-plukon.service';
import { DocumentationService } from './services/communication/documentation.service';
import { VKIService } from './services/flock/vki.service';
import { FlockTechnicalService } from './services/flock/flock-technical/flock-technical.service';
import { FlockBasicService } from './services/flock/flock-basic/flock-basic.service';
import { FlockOriginService } from './services/flock/flock-origin/flock-origin.service';
import { MortalityService } from './services/flock/mortality/mortality.service';
import { CoccidiostatsService } from './services/flock/coccidiostats/coccidiostats.service';
import { MedicationService } from './services/flock/medication/medication.service';
import { VaccinationService } from './services/flock/vaccination/vaccination.service';
import { SalmonellaService } from './services/flock/salmonella/salmonella.service';
import { VKIRequestService } from './services/flock/vki-request.service';
import { EmailNotificationService } from './services/setting/email-notification.service';
import { RoleSetting } from './services/setting/role-setting.service';
import { LoginService } from './services/login-service';
import { CanDeactivatedService } from './services/can-deactivated.service';
import { ErrorsService } from './services/errors.service';
import { ErrorsHandlerService } from './services/errors-handler.service';
import { ErrorsInterceptorService } from './services/errors-interceptor.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NewsService } from './services/news.service';
import { QuestionnaireService } from './services/questionnaire/questionnaire.service';
import { WorkboxService } from './services/workbox/workbox.service';
import { HttpCacheService } from './services/offline/http-cache.service';

@NgModule({})
export class SharedServiceModule {
  //to guard against lazy-loaded module re-importing services
  constructor(@Optional() @SkipSelf() parentModule: SharedServiceModule) {
    if (parentModule) {
      throw new Error('SharedServiceModule is already loaded. Import it in the AppModule only');
    }
  }
  //create singleton services
  static forRoot(): ModuleWithProviders<SharedServiceModule> {
    return {
      ngModule: SharedServiceModule,
      providers: [
        SocialAuthService,
        TranslationService,
        RouterService,
        CompanyService,
        EnvironmentService,
        CompanyTypeService,
        UserService,
        VKIAdminService,
        MyPlukonService,
        DialogService,
        DocumentationService,
        CodeService,
        VKIService,
        FlockTechnicalService,
        FlockBasicService,
        FlockOriginService,
        MortalityService,
        CoccidiostatsService,
        MedicationService,
        VaccinationService,
        SalmonellaService,
        VKIRequestService,
        EmailNotificationService,
        RoleSetting,
        LoginService,
        CanDeactivatedService,
        ErrorsService,
        {
          provide: ErrorHandler,
          useClass: ErrorsHandlerService,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: ErrorsInterceptorService,
          multi: true,
        },
        NewsService,
        QuestionnaireService,
        WorkboxService,
        HttpCacheService,
      ],
    };
  }
}
