import { ResponseStoreEntry, ResponsesDb, StorableResponse } from './responses-db';

const MAX_AGE_DAYS = 30;

class CachedResponsesService {
  private db: ResponsesDb;
  constructor() {
    this.db = new ResponsesDb('plukon-responses');
  }

  public cacheResponse(response: StorableResponse, uid = '', queryKey = ''): Promise<void> {
    return this.db.addOrUpdate({
      uid,
      url: response.url,
      queryKey,
      response,
      lastUpdated: Date.now(),
    });
  }

  public async getCachedResponse(url: string, uid = ''): Promise<ResponseStoreEntry | undefined> {
    const entry = await this.db.getByUIDAndUrl(uid, url);
    if (!entry) {
      return undefined;
    }
    return entry;
  }

  public async getCachedResponseByQueryKey(uid: string, queryKey: string): Promise<ResponseStoreEntry | undefined> {
    const entry = await this.db.getByUIDAndQueryKey(uid, queryKey);
    if (!entry) {
      return undefined;
    }
    return entry;
  }

  public async cleanupOldCachedResponses(ageDays = MAX_AGE_DAYS) {
    return this.db.removeOldEntries(ageDays);
  }
}

let serviceInstance: CachedResponsesService;

export const getCachedResponsesService = (): CachedResponsesService => {
  if (!serviceInstance) {
    serviceInstance = new CachedResponsesService();
  }
  return serviceInstance;
};

export { ResponseStoreEntry, StorableResponse };
