import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { customIBANValidator } from '../../directives/custom-ibanvalidator.directive';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CompanyService } from '../../services/admin/company.service';
import { SearchCountryField, CountryISO } from 'ngx-intl-tel-input';
import { textInputValidator } from '../../validators/text-validator';

@Component({
  selector: 'app-company-field-dialog',
  templateUrl: './company-field-dialog.component.html',
  styleUrls: ['./company-field-dialog.component.scss'],
})
export class CompanyFieldDialogComponent implements OnInit {
  checkIBANSubscription: Subscription;
  formValueChangeSubscription: Subscription;
  companyFormGroup: UntypedFormGroup;
  fieldName: string;
  fieldValue: string;
  saveEnabled = false;
  showSpinner: boolean;
  invalidIBAN: boolean;
  isPhone: boolean;
  oldValue: string;
  selectedCountry: string;

  validationError = {
    telephone: [{ type: 'pattern', message: 'MyPlukonCompany.TelephoneNumberInvalid' }],
    mobile: [{ type: 'pattern', message: 'MyPlukonCompany.MobileNumberInvalid' }],
  };

  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  onlyCountries: CountryISO[] = [
    CountryISO.Austria,
    CountryISO.Belgium,
    CountryISO.CzechRepublic,
    CountryISO.Denmark,
    CountryISO.France,
    CountryISO.Germany,
    CountryISO.Italy,
    CountryISO.Luxembourg,
    CountryISO.Netherlands,
    CountryISO.Poland,
    CountryISO.Portugal,
    CountryISO.Slovakia,
    CountryISO.Spain,
    CountryISO.Switzerland,
  ];

  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<CompanyFieldDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public translate: TranslateService,
    public snackBar: MatSnackBar,
    private companyService: CompanyService,
  ) {}

  ngOnInit() {
    switch (this.fieldName) {
      case 'bankAccFormControl':
        this.companyFormGroup = this.fb.group({
          bankAccFormControl: [
            '',
            [
              Validators.required,
              customIBANValidator(
                new RegExp('^[A-Z]{2}[0-9]{2}[A-Za-z0-9]{12,30}$'),
                new RegExp('^[A-Z]{2}[0-9]{2}[A-Z]{4}[A-Za-z0-9]{8,26}$'),
              ),
              textInputValidator(),
            ],
          ],
        });
        break;
      case 'taxNumberFormControl':
        this.companyFormGroup = this.fb.group({
          taxNumberFormControl: ['', [Validators.required, textInputValidator()]],
        });
        break;
      case 'telephoneFormControl':
      case 'mobileFormControl':
        this.companyFormGroup = this.fb.group({
          phoneFormControl: ['', [textInputValidator()]],
        });
        this.formValueChangeSubscription = this.companyFormGroup.valueChanges.subscribe((formValue) => {
          this.saveEnabled = formValue.phoneFormControl;
        });
        break;
    }
    this.companyFormGroup.get(this.fieldName).patchValue(this.fieldValue ? this.fieldValue : '');
  }

  getSelectedCountry() {
    let item;
    if (this.selectedCountry) {
      item = Object.keys(CountryISO).find((key) => CountryISO[key].toLowerCase() === this.selectedCountry.toLowerCase());
    }
    return this.selectedCountry && item ? CountryISO[item] : CountryISO.Austria;
  }

  changeDetection() {
    if (this.fieldName == 'bankAccFormControl') {
      this.invalidIBAN = false;
    }
    this.saveEnabled = true;
    this.companyFormGroup.get(this.fieldName).markAsTouched();
    this.companyFormGroup.get(this.fieldName).updateValueAndValidity();
  }

  getDescriptionTranslation() {
    const descriptionTranslation = this.translate.instant('MyPlukonCompany.IbanTaxNotion');
    const fieldLabelTranslation = this.translate.instant(
      this.fieldName == 'bankAccFormControl' ? 'MyPlukonCompany.BankAccountNumber' : 'MyPlukonCompany.TaxNumber',
    );
    return descriptionTranslation.replace('[fieldLabel]', fieldLabelTranslation);
  }

  getPhoneDescriptionTranslation() {
    return this.translate.instant(this.fieldName == 'telephoneFormControl' ? 'MyCompany.PhoneNumberText' : 'MyCompany.MobileNumberText');
  }

  save() {
    //call API for IBAN
    if (this.fieldName == 'bankAccFormControl') {
      this.showSpinner = true;

      this.checkIBANSubscription = this.companyService.checkIBAN(this.companyFormGroup.get(this.fieldName).value).subscribe(
        (result) => {
          this.showSpinner = false;
          if (result && result['response'] && result['response']['valid'] && result['response']['valid'] == true) {
            this.invalidIBAN = false;
            this.closeDialog();
          } else {
            this.companyFormGroup.get(this.fieldName).setErrors({ incorrect: true });
            this.invalidIBAN = true;
          }
        },
        (error) => {
          this.showSpinner = false;
          console.log('Company Form (onIBANCheck) : ', error);
          switch (error.status) {
            case 401:
              this.snackBar.open(this.translate.instant('Portal.UnauthorizedAPICall'), 'Close', { verticalPosition: 'top' });
              break;
            default:
              this.snackBar.open(this.translate.instant('ErrorMessage.DataRetrieveFail'), 'Close', { verticalPosition: 'top' });
              break;
          }
        },
      );
    } else {
      this.closeDialog();
    }
  }

  closeDialog() {
    this.dialogRef.close({
      fieldValue: this.companyFormGroup.get(
        this.fieldName == 'telephoneFormControl' || this.fieldName == 'mobileFormControl' ? 'phoneFormControl' : this.fieldName,
      ).value,
    });
  }

  ngOnDestroy() {
    if (this.checkIBANSubscription) {
      this.checkIBANSubscription.unsubscribe();
    }
    if (this.formValueChangeSubscription) {
      this.formValueChangeSubscription.unsubscribe();
    }
  }
}
