import { Injectable } from '@angular/core';
import { Workbox } from 'workbox-window';
import { environment } from 'src/environments/environment';
import { DialogService } from '../dialog.service';
import { TranslateService } from '@ngx-translate/core';

const DEFAULT_VERSION_CHECK_INTERVAL = 1000 * 60 * 60; // 1 hours

@Injectable({
  providedIn: 'root',
})
export class WorkboxService {
  private workbox: Workbox;
  private newUpdateOnActivated = false;
  private checkVersionTimeout: number;
  private readonly newVersionTranslationKey = 'AppUpdateVersion.NewVersionPWA';

  constructor(
    private dialogService: DialogService,
    public translate: TranslateService,
  ) {}

  public initialize() {
    if (!('serviceWorker' in navigator)) {
      return;
    }

    if (environment.serviceWorker) {
      this.initializeServiceWorker();
    }
  }

  private async initializeServiceWorker() {
    try {
      this.workbox = new Workbox('sw.js');
      const registration = await this.workbox.register();
      if (registration) {
        this.workbox.addEventListener('redundant', () => {
          this.newUpdateOnActivated = true;
        });
        this.workbox.addEventListener('activated', (event) => {
          if (this.newUpdateOnActivated || event.isUpdate) {
            this.notifyNewVersion();
          }
        });
        this.checkForVersionUpdate();
      }
    } catch (e) {
      console.log('Service worker registration failed:', e);
    }
  }

  private scheduleVersionCheck() {
    const interval = Number(environment.appVersionCheckInterval) || DEFAULT_VERSION_CHECK_INTERVAL;
    this.checkVersionTimeout = setTimeout(() => {
      this.checkForVersionUpdate();
    }, interval);
  }

  private async checkForVersionUpdate() {
    try {
      await this.workbox.update();
    } catch (e) {
      console.log('::: [APP Workbox] New service worker check failed', { e });
    } finally {
      this.scheduleVersionCheck();
    }
  }

  private async notifyNewVersion() {
    if (this.checkVersionTimeout) {
      clearTimeout(this.checkVersionTimeout);
    }
    await this.dialogService.singleAction(this.newVersionTranslationKey, null, 'Common.Reload').toPromise();
    window.location.reload();
  }
}
