import { Component, OnInit, OnDestroy } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { SocialAuthService } from 'src/app/shared/services/social-auth.service';
import { TranslationService } from 'src/app/shared/services/translation.service';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-term-of-use',
  templateUrl: './term-of-use.component.html',
  styleUrls: ['./term-of-use.component.scss'],
})
export class TermOfUseComponent implements OnInit, OnDestroy {
  plukonTranslationSub: Subscription;
  socialSubscription: Subscription;
  selectedLang: any;
  sentence: any;
  internalLink: any;
  internalLinkText: any;
  termOfUseLink: any;
  termOfUseLinkText: any;
  privacyLink: any;
  privacyLinkText: any;
  cookieStatementLink: any;
  cookieStatementLinkText: any;

  constructor(
    private socialService: SocialAuthService,
    private translationService: TranslationService,
    private router: Router,
    private translate: TranslateService,
    public snackBar: MatSnackBar,
    public sanitizer: DomSanitizer,
  ) {
    // //get current lang when switch tab
    // if (translate.currentLang) {
    //   this.selectedLang = translate.currentLang;
    // }
    // //detect change in language
    // translate.onLangChange.subscribe(result => {
    //   this.selectedLang = result.lang;
    // });
  }

  ngOnInit() {
    this.socialSubscription = this.socialService.getMyProfile().subscribe(
      (result) => {
        if (result) {
          if (result['response'].tncAgree == false || result['response'].tncAgree == null) {
            this.router.navigate(['/term-and-condition']);
          } else {
            if (result['response']['displayLanguageObj'].name) {
              this.selectedLang = result['response']['displayLanguageObj'].name;
            }
            this.plukonTranslationSub = this.translationService.getPlukonTranslation(null).subscribe();
          }
        }
      },
      (error) => {
        if (error.status == '401') {
          this.snackBar.open(this.translate.instant('Portal.UnauthorizedAPICall'), 'Close', { verticalPosition: 'top' });
          return false;
        }
        if (error.status == '404') {
          this.router.navigate(['/login']);
          return false;
        }
      },
    );

    this.internalLink = environment.linkUrl;
    this.internalLinkText = `<a href="${this.internalLink}">${environment.linkUrlText}</a>`;
    this.termOfUseLink = `${environment.linkUrl}terms-of-use`;
    this.termOfUseLinkText = `<a href="${this.termOfUseLink}">${environment.linkUrlText}/terms-of-use</a>`;
    this.privacyLink = `${environment.linkUrl}privacy-statement`;
    this.privacyLinkText = `<a href="${this.privacyLink}">${environment.linkUrlText}/privacy-statement</a>`;
    this.cookieStatementLink = `${environment.linkUrl}cookie-statement`;
    this.cookieStatementLinkText = `<a href="${this.cookieStatementLink}">${environment.linkUrlText}/cookie-statement</a>`;
  }

  ngOnDestroy() {
    if (this.socialSubscription) {
      this.socialSubscription.unsubscribe();
    }
    if (this.plukonTranslationSub) {
      this.plukonTranslationSub.unsubscribe();
    }
  }
}
