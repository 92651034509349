import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';

export const openExternalLink = (url: string | undefined | null) => {
  if (!url) {
    return;
  }
  if (Capacitor.getPlatform() === 'ios') {
    Browser.open({ url });
  } else {
    const a = document.createElement('a');
    a.href = url;
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
};
