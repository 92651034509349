import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { compute } from 'compute-scroll-into-view';

const BREAKPOINT_DESKTOP = '(min-width: 1024px)';

@Injectable({
  providedIn: 'root',
})
export class ResponsiveUIService {
  private isMobileSubject: BehaviorSubject<boolean>;

  constructor(private breakpointObserver: BreakpointObserver) {
    const isDesktop = this.breakpointObserver.isMatched(BREAKPOINT_DESKTOP);
    this.isMobileSubject = new BehaviorSubject(!isDesktop);

    this.breakpointObserver
      .observe(BREAKPOINT_DESKTOP)
      .pipe(map((result) => !result.matches))
      .subscribe(this.isMobileSubject);
  }

  public get isMobile(): boolean {
    return this.isMobileSubject.getValue();
  }

  public get isMobile$(): Observable<boolean> {
    return this.isMobileSubject.asObservable();
  }

  appHeaderHeight() {
    return document.getElementById('app-header') && this.isMobile ? document.getElementById('app-header').offsetHeight : 0;
  }

  appTopStickyHeight() {
    if (this.isMobile) {
      const appHeaderHeight = document.getElementById('app-header') ? document.getElementById('app-header').offsetHeight : 0;
      const companySelectorRow = document.getElementById('company-selector-row')
        ? document.getElementById('company-selector-row').offsetHeight
        : 0;
      return appHeaderHeight + companySelectorRow;
    }
    return 0;
  }

  optionOpened(event) {
    try {
      if (event) {
        const overlayList = document.getElementsByClassName('cdk-overlay-pane') as HTMLCollectionOf<HTMLElement>;
        const dropdown = overlayList[overlayList.length - 1];
        if (dropdown) {
          setTimeout(() => {
            if (Capacitor.getPlatform() === 'ios') {
              // FUTURE: Replace with another package/method with current config
              // try using compute-scroll-into-view package to perform scrollIntoView
              const actions = compute(dropdown, {
                scrollMode: 'always',
                block: 'center',
                inline: 'nearest',
              });
              actions.forEach(({ el, top, left }) => {
                el.scrollTop = top;
                el.scrollLeft = left;
              });
            } else {
              dropdown.scrollIntoView({ block: 'start' });
            }
          }, 700);
        }
      }
    } catch (e) {
      console.log(e);
    }
  }
}
