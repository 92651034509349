import {AbstractControl, ValidatorFn} from '@angular/forms';


export const validateStringDoIsEmoji = (text: string) => {
  const withEmojis = /\p{Extended_Pictographic}/u
  return withEmojis.test(text);
}

export const validateStringDoIsGif = (text: string) => {
  const gifRegex = /\.(gif)$/i;
  return gifRegex.test(text);
}
export function textInputValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null =>
  {
    const emojiTest = validateStringDoIsEmoji(control.value);
    const gifTest = validateStringDoIsGif(control.value);
    if(emojiTest || gifTest) {
      return { error: true }
    }
    return null;
  }
}
