import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, pairwise, switchMap, tap } from 'rxjs/operators';
import { initializeApp } from 'firebase/app';
import { Capacitor } from '@capacitor/core';
import { FirebaseAuthentication, GetIdTokenOptions, SignInResult, User } from '@capacitor-firebase/authentication';
import { environment } from 'src/environments/environment';
import { Https } from '../http.service';
import { DialogService } from '../dialog.service';
import { Router } from '@angular/router';
import { getAuth } from 'firebase/auth';
import { NetworkService } from '../network/network.service';

const initFirebaseApp = () => {
  if (Capacitor.getPlatform() === 'ios') {
    // Firebase App for iOS is created by Capacitor
    return;
  }
  // Only needed if the Firebase JavaScript SDK is used
  initializeApp({
    apiKey: environment.firebaseApiKey,
    authDomain: environment.firebaseAuthDomain,
    projectId: environment.firebaseProjectId,
    storageBucket: environment.firebaseStorageBucket,
    messagingSenderId: environment.firebaseMessagingSenderId,
    appId: environment.firebaseAppId,
  });
};

async function firebaseAuthReady() {
  if (Capacitor.getPlatform() === 'ios') {
    return;
  }
  try {
    const auth = getAuth();
    // This is needed sometimes 'authStateChange' event is fired before the listener is added in the constructor
    // this occurs on web because of async call of FirebaseAuthentication constructor here:
    // https://github.com/capawesome-team/capacitor-firebase/blob/main/packages/app-check/src/index.ts#L8
    // Without this condition there will be a bug on web:
    // - login, load the web app
    // - refresh the app
    // - Login button is visible, than redirect happens
    await auth.authStateReady();
  } catch (e) {
    console.log('::: AuthService: firebaseAuthReady() - catch(e)', { e });
    console.log(e);
  }
}
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private initializedSubject = new BehaviorSubject<boolean>(false);
  private currentUserSubject = new BehaviorSubject<User | null>(null);
  private currentUserUidSubject = new BehaviorSubject<string | null>(null);
  private loggingInSubject = new BehaviorSubject<boolean>(true);
  private initializedReplaySubject = new ReplaySubject<boolean>(1);
  private isAuthorizedSubject = new ReplaySubject<boolean>(1);
  private isLoggingOut = new BehaviorSubject(false);
  private authInitInOffline = new BehaviorSubject<boolean | undefined>(undefined);
  constructor(
    private http: Https,
    private readonly ngZone: NgZone,
    private dialogService: DialogService,
    private router: Router,
    private networkService: NetworkService,
  ) {}

  public async initializeInOnline() {
    // To ensure that initialized$ receive updates only when value was changed
    this.initializedSubject.pipe(distinctUntilChanged()).subscribe(this.initializedReplaySubject);
    combineLatest([this.initializedReplaySubject, this.currentUserSubject])
      .pipe(
        map(([initialized, user]) => {
          const isAuthenticated = initialized && !!user;
          return isAuthenticated;
        }),
        tap(() => this.loggingInSubject.next(true)),
        switchMap((isAuthenticated) => {
          if (isAuthenticated) {
            return this.verifyAuthorization();
          } else {
            return [false];
          }
        }),
        tap(() => this.loggingInSubject.next(false)),
        distinctUntilChanged(),
      )
      .subscribe(this.isAuthorizedSubject);
    // Redirect to login if was authorized and then logged out
    this.isAuthorizedSubject
      .pipe(
        pairwise(), // Compare consecutive emitted values
        filter(([prevAuthorized, currAuthorized]) => prevAuthorized && !currAuthorized), // Detect change from true to false
      )
      .subscribe(() => {
        // Redirect to the login page here
        this.router.navigate(['/login']);
      });
    initFirebaseApp();
    FirebaseAuthentication.removeAllListeners().then(() => {
      FirebaseAuthentication.addListener('authStateChange', (change) => {
        this.ngZone.run(async () => {
          this.setUser(change.user);
        });
      });
    });
    await firebaseAuthReady();
    const initializedSubjectValue = this.initializedSubject.getValue();
    if (initializedSubjectValue === false) {
      const { user } = await FirebaseAuthentication.getCurrentUser();
      this.setUser(user);
    }
  }

  public async initializeInOffline() {
    this.authInitInOffline.next(true);
    const currentUserId = localStorage.getItem('uid');
    if (currentUserId) {
      this.setCurrentUserId(currentUserId);
      this.isAuthorizedSubject.next(true);
    } else {
      this.isAuthorizedSubject.next(false);
    }
    this.initializedSubject.next(true);
    this.initializedReplaySubject.next(true);
    this.loggingInSubject.next(false);
    this.networkService.getNetworkStatus().subscribe((online) => {
      if (online) {
        window.location.reload();
      }
    });
    initFirebaseApp();
  }

  public isAuthenticated(): boolean {
    return !!this.currentUserUidSubject.getValue();
  }

  public async initialize(): Promise<void> {
    try {
      if (this.networkService.isOfflineMode) {
        await this.initializeInOffline();
      } else {
        await this.initializeInOnline();
      }
    } catch (e) {
      console.log(e);
    }
  }

  private setUser(user: User | null): void {
    this.setCurrentUserId(user ? user.uid : null);
    this.currentUserSubject.next(user);
    this.initializedSubject.next(true);
  }

  private setCurrentUserId(userId: string | null): void {
    if (userId) {
      localStorage.setItem('uid', userId);
    } else {
      localStorage.removeItem('uid');
    }
    this.currentUserUidSubject.next(userId);
  }

  public get initialized$(): Observable<boolean> {
    return this.initializedReplaySubject.asObservable();
  }

  public get isLoggingIn$(): Observable<boolean> {
    return this.loggingInSubject.asObservable();
  }

  public get isLoggedIn$(): Observable<boolean> {
    return this.isAuthorizedSubject.asObservable();
  }

  public async getIdToken(options?: GetIdTokenOptions): Promise<string | undefined> {
    try {
      if (this.networkService.isOfflineMode) {
        return undefined;
      }
      const { token } = await FirebaseAuthentication.getIdToken(options);
      return token;
    } catch (e) {
      // E.g. if logged out
      return undefined;
    }
  }

  public login(): Promise<SignInResult> {
    return this.signInWithGoogle();
  }

  private async signInWithGoogle() {
    return FirebaseAuthentication.signInWithGoogle({
      mode: 'popup',
      scopes: ['email', 'profile', 'https://www.googleapis.com/auth/plus.me'],
      customParameters: [
        {
          key: 'prompt',
          value: 'select_account',
        },
      ],
    });
  }

  public async getRedirectResult(): Promise<SignInResult | undefined> {
    if (Capacitor.isNativePlatform()) {
      return;
    }
    return FirebaseAuthentication.getRedirectResult();
  }

  public get isAuthInitInOffline(): boolean | undefined {
    return this.authInitInOffline.getValue();
  }

  public get currentUserUid$(): Observable<string | null> {
    return this.currentUserUidSubject.asObservable();
  }

  public get currentUserUid(): string | null {
    return this.currentUserUidSubject.getValue();
  }

  public get currentUser$(): Observable<User | null> {
    return this.currentUserSubject.asObservable();
  }

  public get currentUser(): User | null {
    return this.currentUserSubject.getValue();
  }

  public get isLoggingOut$(): Observable<boolean | null> {
    return this.isLoggingOut.asObservable();
  }

  private verifyAuthorization(): Observable<boolean> {
    return this.loginV3().pipe(
      map(() => {
        return true;
      }),
      catchError((error) => {
        this.logout();
        if (error.status === 401) {
          this.dialogService.context('Your account is not authorized to access this application.');
        }
        this.loggingInSubject.next(false);
        return of(false);
      }),
    );
  }

  public async logout(): Promise<void> {
    this.isLoggingOut.next(true);
    localStorage.removeItem('uid');
    const logout = FirebaseAuthentication.signOut();
    this.isLoggingOut.next(false);
    return logout;
  }

  private loginV3() {
    return this.http.get(`${environment.baseUrlV2}/auth/user`, null);
  }
}
