import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Https } from './http.service';
declare const gapi: any;

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(private http: Https) {}

  public saveTermAndCondition(payload) {
    return this.http.post(`${environment.baseUrl}/users/tnc`, payload);
  }
}
