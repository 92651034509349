import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Https } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class SocialAuthService {
  constructor(private http: Https) {
    this.firstCheck2FA = new BehaviorSubject<any>(null);
    this.is2FADialogShow = new BehaviorSubject<any>(null);
  }

  firstCheck2FA: BehaviorSubject<any>;
  is2FADialogShow: BehaviorSubject<any>;

  public getMyProfile() {
    return this.http.get(`${environment.baseUrl}/users/myProfile`, null, { noCache: true });
  }

  public getMyProfileRole() {
    return this.http.get(`${environment.baseUrl}/users/myProfile/role`, null);
  }
}
