import { EventEmitter, Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from '../../../../node_modules/rxjs';
import { Https } from './http.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MyTaskService {
  public isMyTaskOpen: BehaviorSubject<any>;
  public myTaskList: BehaviorSubject<any>;
  getMyTasksEvent = new EventEmitter();

  constructor(private http: Https) {
    this.isMyTaskOpen = new BehaviorSubject<any>(null);
    this.myTaskList = new BehaviorSubject<any>(null);
  }

  public getUserTask() {
    return this.http.get(`${environment.baseUrl}/plukonKras/userTasks`, null);
  }

  public getCompanyTask(companyId) {
    return this.http.get(`${environment.baseUrl}/plukonKras/companyTasks/${companyId}`, null);
  }
}
