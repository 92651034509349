import { Injectable } from '@angular/core';
import {HttpParams, HttpResponse} from '@angular/common/http';

import { environment } from '../../../../environments/environment';
import { Https } from '../http.service';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root',
})
export class DocumentationService {
  document: any;
  filter: any;
  currentCompanyData: any;
  params: any;
  documentNumber: BehaviorSubject<number>;

  constructor(private http: Https) {
    this.documentNumber = new BehaviorSubject<number>(0);
  }

  public getDocumentList(
    tableView,
    roundNumber,
    companyId,
    itemPerPage,
    poultryFarmerParam,
    documentParam,
    subTypeParam,
    stableParam,
    flockParam,
    uploadTime,
    currentPage = 0,
  ) {
    let params = new HttpParams();
    if (tableView) {
      params = params.append('itemPerPage', itemPerPage);
      if (currentPage.toString()) {
        params = params.append('page', currentPage.toString());
      }
      if (poultryFarmerParam) {
        poultryFarmerParam.forEach((farmer) => {
          params = params.append('poultryFarmer', farmer.poultryFarmer);
        });
      }

      if (flockParam) {
        flockParam.forEach((flock) => {
          params = params.append('flockNumber', flock.flockNumber);
        });
      }
      if (uploadTime) {
        if (uploadTime.uploadTime) {
          params = params.append('uploadTime', uploadTime.uploadTime);
        }
      }
    }

    if (documentParam) {
      documentParam.forEach((document) => {
        params = params.append('documentType', document.documentType);
      });
    }

    if (subTypeParam) {
      subTypeParam.forEach((document) => {
        params = params.append('subType', document.subType);
      });
    }

    if (stableParam) {
      stableParam.forEach((stable) => {
        params = params.append('stableId', stable.stableId);
      });
    }

    if (tableView) {
      return this.http.get(`${environment.baseUrl}/documents/${companyId}`, params);
    } else {
      return this.http.get(`${environment.baseUrl}/documents/${companyId}/folder/${roundNumber}`, params);
    }
  }

  public displayCircularDocument(payload, itemPerPage, documentParam, uploadTime, currentPage = 0) {
    let params = new HttpParams();
    if (currentPage.toString()) {
      params = params.append('page', currentPage.toString());
    }
    params = params.append('itemPerPage', itemPerPage);

    if (documentParam) {
      documentParam.forEach((document) => {
        params = params.append('subDocumentType', document);
      });
    }

    if (uploadTime) {
      if (uploadTime.uploadTime) {
        params = params.append('uploadTime', uploadTime.uploadTime);
      }
    }
    return this.http.get(`${environment.baseUrl}/circularDocuments/${payload}`, params);
  }

  public getFolder(companyId) {
    if (companyId) {
      return this.http.get(`${environment.baseUrl}/documents/${companyId}/folder`, '');
    } else {
      return of(null);
    }
  }

  public getFilter(companyId, roundNumber) {
    if (roundNumber) {
      return this.http.get(`${environment.baseUrl}/documents/filter/${companyId}/folder/${roundNumber}`, '');
    } else {
      return this.http.get(`${environment.baseUrl}/documents/filter/${companyId}`, '');
    }
  }

  public getCircularFilter(payload) {
    return this.http.get(`${environment.baseUrl}/circularDocuments/filter/${payload}`, '');
  }

  public getHomeDocumentList() {
    return this.http.get(`${environment.baseUrl}/documents/home`, '');
  }

  public getDocumentUnreadCount() {
    return this.http.get(`${environment.baseUrl}/documents/unread/all`, '');
  }

  public getMagazine() {
    return this.http.get(`${environment.baseUrl}/documents/signedURL/magazine`, '');
  }

  public getUserManual(environmentId) {
    return this.http.get(`${environment.baseUrl}/documents/signedURL/userManual/${environmentId}`, '');
  }

  public getVeterinaryUserManual() {
    return this.http.get(`${environment.baseUrl}/documents/signedURL/veterinaryUserManual`, '');
  }

  public getDocExternalSharing(companyId) {
    return this.http.get(`${environment.baseUrl}/docExternalSharing/${companyId}`, '');
  }

  public getDocExternalEmailList(companyId, docTypeId) {
    return this.http.get(`${environment.baseUrl}/docExternalSharing/${companyId}/${docTypeId}`, '');
  }

  public getAvailableDocType(companyId) {
    return this.http.get(`${environment.baseUrl}/docExternalSharing/documentType/${companyId}`, '');
  }

  public sendDocExternalEmail(companyId, documentId, payload) {
    return this.http.post(`${environment.baseUrl}/docExternalSharing/email/${companyId}/${documentId}`, payload);
  }

  public saveDocExternalSharing(payload) {
    return this.http.post(`${environment.baseUrl}/docExternalSharing`, payload);
  }

  public markDocumentStatus(payload) {
    return this.http.post(`${environment.baseUrl}/documents/isDownloaded`, payload);
  }

  public markCircularDocumentStatus(payload) {
    return this.http.post(`${environment.baseUrl}/circularDocuments/isDownloaded`, payload);
  }

  public downloadDocument(payload, companyId) {
    return this.http.get(`${environment.baseUrl}/documents/signedURL/${payload}/${companyId}`, '');
  }

  public downloadAllDocumentsAsZip(companyId: number | string, folderId?: string, documentIds?: Array<number>): Observable<{
    fileName: any;
    blob: Blob
  }> {
    let queryParams = {};
    if (folderId) {
      queryParams["folderId"] = folderId;
    }
    if (documentIds) {
      queryParams["documentIds"] = documentIds;
    }
    return this.http.getWithBlob(`${environment.baseUrl}/documents/${companyId}/download`, queryParams).pipe(
      map((response: HttpResponse<Blob>) => {
        const contentDisposition = response.headers.get('Content-Disposition');
        let fileNameToBeUsed = "Plukonnectdocuments.zip"
        if (contentDisposition) {
          const filename = contentDisposition
            .split(';')
            .find(n => n.includes('filename='))
            .replace('filename=', '')
            .trim();
          fileNameToBeUsed = filename ? filename : fileNameToBeUsed;
        }
        return {
          blob: response.body!,
          fileName: fileNameToBeUsed
        };
      })
    );
  }

  public downloadCircularsDocument(payload, companyId) {
    return this.http.get(`${environment.baseUrl}/circularDocuments/signedURL/${payload}/${companyId}`, '');
  }

  set currentDocument(value: any) {
    this.document = value;
  }

  get currentDocument(): any {
    return this.document;
  }

  set currentFilter(value: any) {
    this.filter = value;
  }

  get currentFilter(): any {
    return this.filter;
  }

  set companyData(value: any) {
    this.currentCompanyData = value;
  }

  get companyData(): any {
    return this.currentCompanyData;
  }
}
