import { Injectable } from '@angular/core';
import { HttpContext, HttpParams } from '@angular/common/http';

import { environment } from '../../../../../environments/environment';
import { Https } from '../../http.service';
import { BehaviorSubject } from 'rxjs';
import { FLOCK_BASICS_REQUEST, FLOCK_NUMBER } from 'src/app/interceptors/my-flock.interceptor';

@Injectable({
  providedIn: 'root',
})
export class FlockBasicService {
  currentFlockData: any;
  stable: any;
  currentFlockBasicData: any;
  currentFlockBasicDataOri: any;
  currentHatcheryData: any;
  currentFeedSupplierData: any;
  currentFeedType: any;
  currentVetenarian: any;
  currentStepControlStatus: any;
  stepControlStatus: BehaviorSubject<any>;

  constructor(private http: Https) {
    this.stepControlStatus = new BehaviorSubject<any>(null);
  }

  public getHatcheryList(plukonId) {
    return this.http.get(`${environment.baseUrl}/plukonKras/hatchery/company/${plukonId}`, '');
  }

  public getFeedSupplier(plukonId) {
    return this.http.get(`${environment.baseUrl}/plukonKras/feedSupplier/company/${plukonId}`, '');
  }

  public getFeedingLocation() {
    return this.http.get(`${environment.baseUrl}/plukonKras/feedSupplier/location`, '');
  }

  public getVetenarianList(plukonId) {
    return this.http.get(`${environment.baseUrl}/plukonKras/veterinarian/company/${plukonId}`, '');
  }

  public saveFlockBasic(payload) {
    const context = new HttpContext();
    context.set(FLOCK_NUMBER, payload?.flockNumber);
    context.set(FLOCK_BASICS_REQUEST, true);
    return this.http.post(`${environment.baseUrl}/plukonKras/flock/basics`, payload, undefined, context);
  }

  public saveStartingDate(payload) {
    return this.http.post(`${environment.baseUrl}/plukonKras/flock/basics/startingDate`, payload);
  }

  set flockBasicData(value: any) {
    this.currentFlockBasicData = value;
  }

  get flockBasicData(): any {
    return this.currentFlockBasicData;
  }

  set flockBasicDataOri(value: any) {
    this.currentFlockBasicDataOri = value;
  }

  get flockBasicDataOri(): any {
    return this.currentFlockBasicDataOri;
  }

  set hatcheryData(value: any) {
    this.currentHatcheryData = value;
  }

  get hatcheryData(): any {
    return this.currentHatcheryData;
  }

  set feedSupplierData(value: any) {
    this.currentFeedSupplierData = value;
  }

  get feedSupplierData(): any {
    return this.currentFeedSupplierData;
  }

  set feedTypeData(value: any) {
    this.currentFeedType = value;
  }

  get feedTypeData(): any {
    return this.currentFeedType;
  }

  set vetenarianData(value: any) {
    this.currentVetenarian = value;
  }

  get vetenarianData(): any {
    return this.currentVetenarian;
  }

  // set stepControlStatus(value:any) {
  //     this.currentStepControlStatus = value
  // }

  // get stepControlStatus():any {
  //     return this.currentStepControlStatus;
  // }
}
