import { Directive, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { ResponsiveUIService } from '../../services/responsive-ui.service';

@Directive()
export abstract class MobileDialogComponent implements OnInit, OnDestroy {
  private isMobileSubscription: Subscription;

  constructor(
    public dialogRef: MatDialogRef<any>,
    public responsiveUIService: ResponsiveUIService,
  ) {}

  ngOnInit(): void {
    this.isMobileSubscription = this.responsiveUIService.isMobile$
      .pipe(filter((isMobile) => isMobile === false))
      .subscribe(() => this.closeDialog());
  }

  closeDialog() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    if (this.isMobileSubscription) {
      this.isMobileSubscription.unsubscribe();
    }
  }
}
