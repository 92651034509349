import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { VKIService } from '../../services/flock/vki.service';
import { MyPlukonService } from '../../services/my-plukon.service';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-access-settings-dialog',
  templateUrl: './access-settings-dialog.component.html',
  styleUrls: ['./access-settings-dialog.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: environment.myDateFormat },
  ],
})
export class AccessSettingsDialogComponent implements OnInit, OnDestroy {
  companySubscription: Subscription;
  stableSubscription: Subscription;
  data: any;
  ownerCompany: string;
  isNew: boolean;
  accessSettingsFormGroup: UntypedFormGroup;
  isStableOptionAvailabale = false;
  showSpinner = false;
  // minDate = moment();
  companyAccessList = [];
  stableList = [];

  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<AccessSettingsDialogComponent>,
    private vkiService: VKIService,
    private myPlukonService: MyPlukonService,
    public translate: TranslateService,
    public snackBar: MatSnackBar,
  ) {
    this.accessSettingsFormGroup = this.fb.group({
      relationCompanyId: [null, Validators.required],
      allStable: [null, Validators.required],
      stableAccessList: [null],
      shareStartDate: [null, Validators.required],
      shareEndDate: [null],
    });
  }

  ngOnInit() {
    this.showSpinner = true;
    Promise.all([this.getCompanyAccessList(), this.getStableList(this.ownerCompany)]).then(() => {
      if (this.data) {
        this.patchFormValue(this.data);
        this.checkIsAllStable(this.accessSettingsFormGroup.get('allStable').value, false);
        // this.minDate = this.accessSettingsFormGroup.get('shareStartDate').value;
      }
      this.setFormValidation();
      this.setDateEditable();
      this.showSpinner = false;
    });
  }

  patchFormValue(content) {
    this.accessSettingsFormGroup.get('relationCompanyId').patchValue(content.relationCompanyId);
    this.accessSettingsFormGroup.get('allStable').patchValue(content.allStable);
    this.accessSettingsFormGroup
      .get('stableAccessList')
      .patchValue(
        content.stableAccessList && content.stableAccessList.length > 0 ? content.stableAccessList.map((data) => data.stable) : null,
      );
    this.accessSettingsFormGroup
      .get('shareStartDate')
      .patchValue(content.shareStartDate ? moment(content.shareStartDate, 'DD-MM-YYYY') : null);
    this.accessSettingsFormGroup.get('shareEndDate').patchValue(content.shareEndDate ? moment(content.shareEndDate, 'DD-MM-YYYY') : null);
  }

  getCompanyAccessList() {
    return new Promise<void>((resolve, reject) => {
      this.companyAccessList = [];
      this.companySubscription = this.myPlukonService.getCompanyAccessList().subscribe(
        (result) => {
          if (result && result['list'].length > 0) {
            for (const company of result['list']) {
              this.companyAccessList.push({
                companyId: company.companyId,
                name: company.name,
              });
            }
          }
          resolve();
        },
        (error) => {
          if (error.status == '401') {
            this.snackBar.open(this.translate.instant('Portal.UnauthorizedAPICall'), 'Close', { verticalPosition: 'top' });
          }
          reject(error);
        },
      );
    });
  }

  getStableList(companyId) {
    return new Promise<void>((resolve, reject) => {
      this.stableList = [];
      this.stableSubscription = this.vkiService.getStableList(companyId).subscribe(
        (result) => {
          if (result && result['list'].length > 0) {
            for (const stable of result['list']) {
              this.stableList.push({
                stableId: stable.stableId,
                name: stable.name,
              });
            }
          }
          resolve();
        },
        (error) => {
          if (error.status == '401') {
            this.snackBar.open(this.translate.instant('Portal.UnauthorizedAPICall'), 'Close', { verticalPosition: 'top' });
          }
          reject(error);
        },
      );
    });
  }

  getMultiSelected() {
    const data = _.cloneDeep(this.accessSettingsFormGroup.value);

    if (data.stableAccessList && data.stableAccessList.length > 0) {
      const selectedAnsList = data.stableAccessList.map((value) => {
        return this.stableList.filter((stable) => stable.stableId === value).length > 0
          ? this.stableList.filter((stable) => stable.stableId === value)[0].name
          : null;
      });

      return selectedAnsList.length > 0 ? selectedAnsList.join('<br/>') : null;
    }
    return '';
  }

  companySelection() {
    // this.minDate = moment();
    this.accessSettingsFormGroup.get('allStable').patchValue(null);
    this.accessSettingsFormGroup.get('stableAccessList').patchValue(null);
    this.accessSettingsFormGroup.get('shareStartDate').patchValue(null);
    this.accessSettingsFormGroup.get('shareEndDate').patchValue(null);
    this.setFormValidation();
    this.setDateEditable();
    this.isStableOptionAvailabale = false;
  }

  checkIsAllStable(isAllStable, isSelect) {
    if (isAllStable) {
      this.isStableOptionAvailabale = false;
      this.accessSettingsFormGroup.get('stableAccessList').patchValue(this.stableList.map((data) => data.stableId));
    } else {
      this.isStableOptionAvailabale = true;
      if (isSelect) {
        this.accessSettingsFormGroup.get('stableAccessList').patchValue(null);
      }
    }
    this.setFormValidation();
  }

  dateInput(control, event) {
    if (event.keyCode === 8) {
      this.accessSettingsFormGroup.get(control).patchValue(null);
      this.accessSettingsFormGroup.get(control).markAsDirty();
      this.checkDateValidity();
      this.setDateEditable();
    } else if (event.shiftKey) {
      return false;
    } else if (
      event.keyCode !== 189 &&
      event.keyCode !== 109 &&
      event.keyCode !== 9 &&
      event.keyCode !== 37 &&
      event.keyCode !== 39 &&
      (event.keyCode < 48 || event.keyCode > 57) &&
      (event.keyCode < 96 || event.keyCode > 105)
    ) {
      return false;
    }
  }

  setDate(control, event) {
    if (event.value && moment(this.accessSettingsFormGroup.get(control).value).isValid()) {
      const date = moment(event.value);
      this.accessSettingsFormGroup.get(control).patchValue(moment(date, 'DD-MM-YYYY'));
    } else {
      this.accessSettingsFormGroup.get(control).patchValue(null);
    }
    this.checkDateValidity();
    this.setDateEditable();
  }

  checkDateValidity() {
    const shareStartDate = this.accessSettingsFormGroup.get('shareStartDate').value;
    const shareEndDate = this.accessSettingsFormGroup.get('shareEndDate').value;

    if (shareStartDate && moment(shareStartDate).isValid() && shareEndDate && moment(shareEndDate).isValid()) {
      if (moment(shareStartDate).isAfter(shareEndDate, 'day')) {
        this.accessSettingsFormGroup.get('shareEndDate').patchValue(null);
      }
    }

    // TP-846 Side Note: Harm request remove minDate
    // if (shareStartDate && moment(shareStartDate).isValid() && this.minDate && moment(this.minDate).isValid()) {
    //   if (moment(shareStartDate).isBefore(this.minDate, 'day')) {
    //     this.accessSettingsFormGroup.get('shareStartDate').patchValue(null);
    //   }
    // } else {
    //   this.accessSettingsFormGroup.get('shareEndDate').patchValue(null);
    // }
  }

  setFormValidation() {
    if (this.accessSettingsFormGroup.get('allStable').value === false) {
      this.accessSettingsFormGroup.get('stableAccessList').setValidators([Validators.required]);
    } else {
      this.accessSettingsFormGroup.get('stableAccessList').clearValidators();
    }
    this.accessSettingsFormGroup.get('stableAccessList').markAsTouched();
    this.accessSettingsFormGroup.get('stableAccessList').updateValueAndValidity();
  }

  setDateEditable() {
    const shareStartDate = this.accessSettingsFormGroup.get('shareStartDate').value;

    if (shareStartDate && moment(shareStartDate).isValid()) {
      this.accessSettingsFormGroup.get('shareEndDate').enable();
    } else {
      this.accessSettingsFormGroup.get('shareEndDate').disable();
    }
  }

  addAccessSettings() {
    const data = _.cloneDeep(this.accessSettingsFormGroup.value);

    if (data.stableAccessList && data.stableAccessList.length > 0) {
      data.stableAccessList = data.stableAccessList.map((value) => ({
        stable: value,
        stableName:
          this.stableList.filter((stable) => stable.stableId === value).length > 0
            ? this.stableList.filter((stable) => stable.stableId === value)[0].name
            : null,
      }));
    }
    data.relationCompanyName = this.companyAccessList.filter((company) => company.companyId === data.relationCompanyId)[0].name;
    this.dialogRef.close(data);
  }

  ngOnDestroy() {
    if (this.companySubscription) {
      this.companySubscription.unsubscribe();
    }
    if (this.stableSubscription) {
      this.stableSubscription.unsubscribe();
    }
  }
}
