import { KEY_FLOCK_STEP_MORTALITY, KEY_FLOCK_STEP_SALMONELLA, KEY_FLOCK_STEP_TECHNICAL } from './constants';

export const updateBodyRequestWithFileData = (data: { rawBody: any; fileUploadResponse: any; flockRequestKey }) => {
  const { rawBody, fileUploadResponse, flockRequestKey } = data;
  // TODO - Add specific logic here
  switch (flockRequestKey) {
    case KEY_FLOCK_STEP_TECHNICAL:
    case KEY_FLOCK_STEP_MORTALITY:
    case KEY_FLOCK_STEP_SALMONELLA: {
      const { id: responseFileId, uuid: responseFileUUID, documentName: responseDocumentName } = fileUploadResponse.response;
      return {
        ...rawBody,
        ...(rawBody.insertUpdate
          ? {
              insertUpdate: rawBody.insertUpdate.map((item) => {
                const { uuid, ...rest } = item;
                if (uuid === responseFileUUID) {
                  return {
                    ...rest,
                    id: responseFileId,
                    documentName: responseDocumentName,
                  };
                } else {
                  return item;
                }
              }),
            }
          : undefined),
        ...(rawBody.deleted
          ? {
              deleted: rawBody.deleted.map((item) => {
                const { uuid, ...rest } = item;
                if (uuid === responseFileUUID) {
                  return {
                    ...rest,
                    id: responseFileId,
                    documentName: responseDocumentName,
                  };
                } else {
                  return item;
                }
              }),
            }
          : undefined),
      };
    }
    default:
      return rawBody;
  }
};

export const updateBodyRequestWithSaveData = (data: { rawBody: any; saveResponse: any; flockRequestKey: string }) => {
  const { rawBody, saveResponse, flockRequestKey } = data;
  // TODO - Add specific logic here
  switch (flockRequestKey) {
    case KEY_FLOCK_STEP_TECHNICAL: {
      const files = saveResponse.response.statements;
      return updateRawBodyArray(rawBody, files);
    }
    case KEY_FLOCK_STEP_MORTALITY: {
      const files = saveResponse.response.statement;
      return updateRawBodyArray(rawBody, files);
    }
    case KEY_FLOCK_STEP_SALMONELLA: {
      const files = saveResponse.response.reports;
      return updateRawBodyArray(rawBody, files);
    }
    default:
      return rawBody;
  }
};

const updateRawBodyArray = (rawBody, files): any => {
  for (let i = 0; i < files.length; i++) {
    rawBody.insertUpdate = rawBody.insertUpdate
      ? rawBody.insertUpdate.map((item) => {
          const requestFileKey = getUniqueKeyFromFilename(item.documentName);
          const responseFileKey = getUniqueKeyFromFilename(files[i].name);
          if (requestFileKey && requestFileKey === responseFileKey) {
            return {
              ...item,
              id: files[i].id,
            };
          } else {
            return item;
          }
        })
      : undefined;
    rawBody.deleted = rawBody.deleted
      ? rawBody.deleted.map((item) => {
          const requestFileKey = getUniqueKeyFromFilename(item.documentName);
          const responseFileKey = getUniqueKeyFromFilename(files[i].name);
          if (requestFileKey && requestFileKey === responseFileKey) {
            return {
              ...item,
              id: files[i].id,
            };
          } else {
            return item;
          }
        })
      : undefined;
  }
  return rawBody;
};

export const updateBodyRequestWithFailedFileUpload = (data: { rawBody: any; fileUUID: string; flockRequestKey: string }) => {
  const { rawBody, fileUUID, flockRequestKey } = data;
  switch (flockRequestKey) {
    case KEY_FLOCK_STEP_MORTALITY:
    case KEY_FLOCK_STEP_SALMONELLA:
      return {
        ...rawBody,
        ...(rawBody.insertUpdate ? { insertUpdate: rawBody.insertUpdate.filter((item) => item.uuid !== fileUUID) } : undefined),
        ...(rawBody.deleted ? { deleted: rawBody.deleted.filter((item) => item.uuid !== fileUUID) } : undefined),
      };
    default:
      return rawBody;
  }
};

const getUniqueKeyFromFilename = (filename: string): string | undefined => {
  if (filename) {
    const match = filename.match(/_(\d+)\./);
    if (match) {
      return match[1]; // return result of first match group
    }
  }
  return undefined;
};
