import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpContextToken, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable, from, of } from 'rxjs';
import { getQueryKey, KEY_FLOCK_BASICS, SourceDataHasChangedError, SourceDataNotFoundError, getMyFlockService } from 'src/lib/my-flock';
import { CACHE_QUERY_KEY, GET_CACHE_QUERY_KEY, SKIP_CACHING } from './http-cache.interceptor';
import { AUTH_TOKEN, UID } from './auth-interceptor';
import { map, switchMap } from 'rxjs/operators';
import { NetworkService } from '../shared/services/network/network.service';
import { OfflineRequestsService } from '../shared/services/offline/offline-request.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { myFlockOfflineEnabled } from '../shared/utils/offline-utils';
import { DialogService } from '../shared/services/dialog.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { KEY_FLOCK_ORIGIN, KEY_STABLE_LOG_LOSSES } from 'src/lib/my-flock/constants';

export const FLOCK_NUMBER = new HttpContextToken<string>(() => undefined);
export const FLOCK_BASICS_REQUEST = new HttpContextToken<boolean>(() => undefined);
export const FLOCK_REQUEST_KEY = new HttpContextToken<string>(() => undefined);
export const ORIGIN_LOSSES_REQUEST = new HttpContextToken<boolean>(() => undefined);
export const METADATA = new HttpContextToken<any>(() => undefined);
export const FLOCK_REQUEST_EXTRA_KEY = new HttpContextToken<any>(() => undefined);

const getCacheQueryFromFlockBasics = (request: HttpRequest<any>, response: HttpResponse<any>) => {
  const flockNumber = response.body.response.flockNumber;
  if (flockNumber) {
    return getQueryKey(flockNumber, KEY_FLOCK_BASICS);
  }
  return '';
};

@Injectable()
export class MyFlockInterceptor implements HttpInterceptor {
  constructor(
    private networkService: NetworkService,
    private offlineRequestService: OfflineRequestsService,
    public snackBar: MatSnackBar,
    private dialogService: DialogService,
    private router: Router,
    private translate: TranslateService,
  ) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (myFlockOfflineEnabled()) {
      if (req.context && req.context.get(UID) && (req.context.get(FLOCK_NUMBER) || req.context.get(FLOCK_BASICS_REQUEST))) {
        return this.handleMyFlockRequest(req, next);
      }
    }
    return next.handle(req);
  }

  private handleMyFlockRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isOffline = this.networkService.isOfflineMode;
    if (req.method === 'GET') {
      if (isOffline) {
        return from(this.handleGetOffline(req, next));
      } else {
        return from(this.handleGetOnline(req, next));
      }
    } else if (req.method === 'POST') {
      if (isOffline) {
        return from(this.handlePostOffline(req, next));
      } else {
        return from(this.handlePostOnline(req, next));
      }
    }
    return next.handle(req);
  }

  private async handleGetOnline(req: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
    const context = req.context;
    if (this.offlineRequestService.hasRequestsToSync) {
      // skipping caching to prevent new source data cached before sync completed
      console.warn('There are requests to sync, please sync them first');
      context.set(SKIP_CACHING, true);
    } else {
      if (context.get(FLOCK_BASICS_REQUEST)) {
        context.set(GET_CACHE_QUERY_KEY, getCacheQueryFromFlockBasics);
      } else {
        const flockNumber = context.get(FLOCK_NUMBER);
        const key = context.get(FLOCK_REQUEST_KEY);
        if (flockNumber && key) {
          context.set(CACHE_QUERY_KEY, `${flockNumber}-${key}`);
        }
      }
    }
    return next.handle(req).toPromise();
  }

  private async handleGetOffline(req: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
    try {
      const context = req.context;
      const uid = context.get(UID);
      const flockNumber = context.get(FLOCK_NUMBER);
      const key = context.get(FLOCK_REQUEST_KEY);
      if (flockNumber && key) {
        const offlineData = await getMyFlockService().getOfflineData({ uid, flockNumber, key });
        return new HttpResponse({
          body: offlineData,
        });
      } else {
        if (context.get(FLOCK_BASICS_REQUEST)) {
          const isOutdated = await getMyFlockService().isFlockBasicsOutdated({
            uid,
            flockBasicsGetUrl: req.urlWithParams,
            throwNotFound: true,
          });
          if (isOutdated) {
            return this.showDataOutdatedNotification(uid)
              .pipe(
                switchMap((confirm) => {
                  if (confirm) {
                    return next.handle(req);
                  } else {
                    this.router.navigate(['']);
                  }
                }),
              )
              .toPromise();
          }
        }
        return next.handle(req).toPromise();
      }
    } catch (error) {
      this.handleError(error);
    }
  }

  private async handlePostOnline(req: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
    try {
      const context = req.context;
      const uid = context.get(UID);
      if (this.offlineRequestService.hasRequestsToSync) {
        // THIS case should not happen, but prevent if a bug somewhere
        // DO NOT ALLOW TO POST WHEN SYNCING
        throw new Error('POST request is not allowed when syncing');
      }

      const flockNumber = context.get(FLOCK_NUMBER);
      const isFlockBasicsRequest = context.get(FLOCK_BASICS_REQUEST);
      const key = isFlockBasicsRequest ? KEY_FLOCK_BASICS : context.get(FLOCK_REQUEST_KEY);
      const checkSourceData = flockNumber && key && context.get(FLOCK_REQUEST_EXTRA_KEY) === undefined;
      if (checkSourceData) {
        const token = context.get(AUTH_TOKEN);
        await getMyFlockService().verifyFlockBasicsNotChanged({ uid, flockNumber, token });
        if (!isFlockBasicsRequest) {
          await getMyFlockService().verifySourceDataNotChanged({ uid, flockNumber, token, key });
        }
        return next
          .handle(req)
          .pipe(
            switchMap((response) => {
              if (response instanceof HttpResponse) {
                // First, refresh with the original key
                return from(getMyFlockService().refreshSourceData({ uid, flockNumber, token, key }))
                  .pipe(
                    // After the original refresh, perform the additional refresh with the different key
                    switchMap(() => {
                      console.log('perform additional checking for refresh');
                      // Perform additional data refresh for Origin
                      if (key === KEY_FLOCK_ORIGIN && context.get(ORIGIN_LOSSES_REQUEST)) {
                        const additionalKey = KEY_STABLE_LOG_LOSSES;
                        return from(getMyFlockService().refreshSourceData({ uid, flockNumber, token, key: additionalKey }));
                      } else {
                        return of(null);
                      }
                    }),
                    map(() => response)
                  );
              }
              return of(response);
            }),
          )
          .toPromise();
      } else {
        return next.handle(req).toPromise();
      }
    } catch (error) {
      this.handleError(error);
    }
  }

  private async handlePostOffline(req: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
    try {
      const context = req.context;
      const uid = context.get(UID);
      const flockNumber = context.get(FLOCK_NUMBER);
      const isFlockBasicsRequest = context.get(FLOCK_BASICS_REQUEST);
      const key = isFlockBasicsRequest ? KEY_FLOCK_BASICS : context.get(FLOCK_REQUEST_KEY);
      const extraKey = context.get(FLOCK_REQUEST_EXTRA_KEY);
      const storedRequestMetadata = context.get(METADATA);
      const queryKey = getQueryKey(flockNumber, key, extraKey);
      const cachedResponse = await getMyFlockService().getCachedResponse({ uid, flockNumber, key });
      await this.offlineRequestService.storeRequest({ httpRequest: req, uid, queryKey, metadata: storedRequestMetadata });
      const headers = new HttpHeaders(cachedResponse.response.headers);
      this.snackBar.open(this.translate.instant('OfflineMode.RequestStored'), 'Close', {
        verticalPosition: 'top',
        panelClass: ['orange'],
        duration: 5000,
      });
      if (extraKey) {
        return new HttpResponse({
          body: {
            response: {
              ...storedRequestMetadata,
              isOffline: true,
            },
          },
        });
      }
      return new HttpResponse({
        ...cachedResponse.response,
        body: await getMyFlockService().getOfflineData({ uid, flockNumber, key }),
        headers: headers,
      });
    } catch (error) {
      this.handleError(error);
    }
  }

  private showDataOutdatedNotification(uid: string) {
    getMyFlockService().registerOutdatedPopupShowed(uid);
    // TODO - related to Precache Service - store in sync db that user needs to get notification after next cache sync
    return this.dialogService.contextReverseButton(
      `${this.translate.instant('ErrorMessage.OldOfflineData')}`,
      null,
      'Common.Continue',
      'Header.Home',
      null,
      true,
    );
  }

  private handleError(error: any) {
    if (error instanceof SourceDataNotFoundError) {
      this.dialogService
        .contextReverseButton(`${this.translate.instant('ErrorMessage.SourceDateNotFound')}`, null, 'Common.Ok', null, null, true)
        .subscribe(() => {
          this.router.navigate(['']);
        });
    } else if (error instanceof SourceDataHasChangedError) {
      this.snackBar.open(this.translate.instant('ErrorMessage.SourceDateChanged'), 'Common.Close', {
        duration: 3000,
        verticalPosition: 'top',
      });
    } else {
      this.snackBar.open(this.translate.instant('ErrorMessage.SomethingWentWrong'), 'Common.Close', {
        duration: 3000,
        verticalPosition: 'top',
      });
      throw error();
    }
  }
}
