<div  class="login-container" fxLayout="row" fxLayoutAlign="center center">
  <button class="video-button" mat-button (click)="openVideo()">
    <i class="material-icons">movie</i> {{'WatchVideo.Text' | translate}}
  </button>
  <button *ngIf="firefoxBrowser" class="firefox-button" mat-button (click)="openFirefoxLoginProblem()">
    {{'LoginFirefoxProblem.Text' | translate}}
  </button>
  <div *ngIf=!showLoading class="login-wrapper" fxLayout="column" fxLayoutAlign="none center">
    <div class="plukon-logo-wrapper">
      <img (click)="signIn()" class='plukon-logo' src="/assets/images/plukon_logo_2021.png" alt="">
    </div>
    <div class="welcome-text">{{'WelcomeMessage.Welcome' | translate }}</div>
    <div id="googleOAuth" class="customGPlusSignIn" *ngIf="maintenanceMessage == null" layout="row"
      layout-align="center center">
      <button id="googleLoginBtn" mat-button fxLayout="row" fxLayoutAlign="center center" (click)="signIn()">Login</button>
    </div>
    <button mat-button class="clear-cache-btn" fxLayout="row" fxLayoutAlign="center center" (click)="clearCache()">Clear cache</button>
    <div *ngIf="maintenanceMessage" class="maintenance-message" layout="row" layout-align="center center">
      {{maintenanceMessage}}
    </div>
  </div>
</div>
<app-loading-spinner [show]=showLoading></app-loading-spinner>
