import { Injectable } from '@angular/core';
import { HttpContext } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { Https } from '../../http.service';
import { BehaviorSubject } from 'rxjs';
import { FLOCK_NUMBER, FLOCK_REQUEST_EXTRA_KEY, FLOCK_REQUEST_KEY, METADATA } from 'src/app/interceptors/my-flock.interceptor';
import { KEY_CANCEL_REQUEST, KEY_FLOCK_STEP_TECHNICAL, KEY_UPLOAD_TEMP_FILE } from 'src/lib/my-flock/constants';

@Injectable({
  providedIn: 'root',
})
export class FlockTechnicalService {
  currentFlockData: any;
  stable: any;
  currentFlockTechnicalData: any;
  currentFlockTechnicalDataOri: any;
  currentStepControlStatus: any;
  stepControlStatus: BehaviorSubject<any>;
  currentUploadDataOri: any;

  constructor(private http: Https) {
    this.stepControlStatus = new BehaviorSubject<any>(null);
  }

  public saveFlockTechnical(payload) {
    const flockNumber = payload?.flockNumber;
    const context = new HttpContext();
    context.set(FLOCK_NUMBER, flockNumber);
    context.set(FLOCK_REQUEST_KEY, KEY_FLOCK_STEP_TECHNICAL);
    return this.http.post(`${environment.baseUrl}/plukonKras/flock/technical`, payload, undefined, context);
  }

  set flockTechnicalData(value: any) {
    this.currentFlockTechnicalData = value;
  }

  get flockTechnicalData(): any {
    return this.currentFlockTechnicalData;
  }

  set flockTechnicalDataOri(value: any) {
    this.currentFlockTechnicalDataOri = value;
  }

  get flockTechnicalDataOri(): any {
    return this.currentFlockTechnicalDataOri;
  }

  set uploadDataOri(value: any) {
    this.currentUploadDataOri = value;
  }

  get uploadDataOri(): any {
    return this.currentUploadDataOri;
  }

  public uploadFile(data) {
    const context = new HttpContext();
    context.set(FLOCK_NUMBER, data.get('flockNumber'));
    context.set(FLOCK_REQUEST_KEY, KEY_FLOCK_STEP_TECHNICAL);
    context.set(FLOCK_REQUEST_EXTRA_KEY, KEY_UPLOAD_TEMP_FILE);
    context.set(METADATA, { uuid: data.get('uuid') });
    return this.http.upload(`${environment.baseUrl}/plukonKras/flock/technical/tempfile`, data, undefined, context);
  }

  public cancelUpload(payload) {
    const context = new HttpContext();
    context.set(FLOCK_NUMBER, payload?.flockNumber);
    context.set(FLOCK_REQUEST_KEY, KEY_FLOCK_STEP_TECHNICAL);
    context.set(FLOCK_REQUEST_EXTRA_KEY, KEY_CANCEL_REQUEST);
    return this.http.post(`${environment.baseUrl}/plukonKras/flock/technical/tempfile/cancel`, payload, undefined, context);
  }
}
