import {
  HttpContextToken,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, combineLatest, from, throwError } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';
import { AuthService } from '../shared/services/auth/auth.service';
import { NetworkService } from '../shared/services/network/network.service';

export const SKIP_AUTH = new HttpContextToken<boolean>(() => false);
export const ALLOW_WITHOUT_AUTH = new HttpContextToken<boolean>(() => false);
export const UID = new HttpContextToken<string>(() => undefined);
export const AUTH_TOKEN = new HttpContextToken<string>(() => undefined);

class TokenUndefinedError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'TokenUndefinedError';
  }
}

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private networkService: NetworkService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.context.get(SKIP_AUTH)) {
      return next.handle(request);
    }
    return combineLatest([
      from(this.authService.getIdToken()),
      this.authService.currentUserUid$,
      this.networkService.getIsOfflineMode$(),
    ]).pipe(
      take(1),
      switchMap(([token, uid, offlineMode]) => {
        const allowWithoutAuth = request.context.get(ALLOW_WITHOUT_AUTH);
        request.context.set(UID, uid);
        if (!token) {
          if (allowWithoutAuth || offlineMode) {
            return next.handle(request);
          } else {
            console.log('Non authorized, request not sent', request.urlWithParams);
            return throwError(new TokenUndefinedError('Unauthorized'));
          }
        }

        const headers = { Authorization: `Bearer ${token}` };
        request.context.set(AUTH_TOKEN, token);
        request = request.clone({
          setHeaders: headers,
        });
        return next.handle(request);
      }),
      catchError((error) => {
        if (error instanceof TokenUndefinedError) {
          this.authService.logout();
        } else if (error instanceof HttpErrorResponse) {
          if (error.status === HttpStatusCode.Unauthorized) {
            this.authService.logout();
          }
        }
        return throwError(error);
      }),
    );
  }
}
