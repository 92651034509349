<div class="coccidiostat-dialog-cont">
  <div class="close-mark" (click)="closeDialog()"></div>
  <div class="form-field-cont">
    <label>{{'VKICoccidiostats.FeedType' | translate}}</label>
    <mat-form-field>
      <mat-select [(value)]="data.coccidiostat.value.feedType"
                  (selectionChange)='data.feedType.feedTypeSelection(data.index, $event)'
                  [disabled]="(data.blockFirst === 2 ? false : (data.coccidiostat.value.blocked == 1 || data.coccidiostat.value.blocked == 2)) || data.editBlocked">
        <mat-option *ngFor="let feedType of data.feedType.feedTypeList"
                    [value]="feedType.name">
          {{feedType.translateFeedType | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="form-field-cont">
    <label>{{'VKICoccidiostats.Coccidiostats' | translate}}</label>
    <mat-form-field>
      <mat-select (mousedown)="data.coccidiostat.clearFilter(data.index)"
                  [(value)]="data.coccidiostat.value.coccidiostat"
                  (selectionChange)='data.coccidiostat.coccidiostatSelection(data.index, $event)'
                  [disabled]="(data.blockFirst == 2 ? false : (data.coccidiostat.value.blocked == 1 || data.coccidiostat.value.blocked == 2)) || data.editBlocked"
                  (openedChange)='responsiveUIService.optionOpened($event)'>
        <mat-option>
          <ngx-mat-select-search [placeholderLabel]="'Common.Search' | translate"
                                 [noEntriesFoundLabel]="'Common.NoResult' | translate"
                                 #search (input)="data.coccidiostat.filterCoccidiostat(data.index, search)">
          </ngx-mat-select-search>
        </mat-option>
        <!-- <input matInput class="search" [placeholder]="'Common.Search' | translate" #search (input)="filterCoccidiostat(i, search)"> -->
        <mat-option *ngFor="let filteredData of data.coccidiostat.filteredCoccidiostatListBigArray[data.index] | async"
                    [value]="filteredData.id">
          {{filteredData.name}}
        </mat-option>
        <!-- <mat-option *ngIf="(filteredCoccidiostatListBigArray[i] | async).length == 0" disabled>
          {{'Common.NoResult' | translate}}
      </mat-option> -->
      </mat-select>
    </mat-form-field>
  </div>
  <div class="form-field-cont">
    <label>{{'VKIMedication.RegistrationNr' | translate}}</label>
    <span class="display-text">{{data.coccidiostat.value.registrationNr || '-'}}</span>
  </div>
  <div class="form-field-cont">
    <Label>{{'VKICoccidiostats.WaitingTime' | translate}}</Label>
    <span class="display-text">{{data.coccidiostat.value.waitingTime || '-'}}</span>
  </div>
  <div class="form-field-cont">
    <Label>{{'VKICoccidiostats.StartDate' | translate}}</Label>
    <mat-form-field class="date-form">
      <input matInput
             [matDatepicker]="picker"
             [placeholder]="'Common.dd-mm-yyyy' | translate"
             #startDate
             [min]="data.dates.minDate" [max]="data.dates.maxDate"
             [(value)]="data.coccidiostat.value.startDateObj"
             (keydown)="data.dates.dateInput('startDate', $event,data.index)"
             (dateChange)="data.dates.setDate('startDate', $event,data.index)"
             [disabled]="(data.blockFirst == 2 ? false : (data.coccidiostat.value.blocked == 1 || data.coccidiostat.value.blocked == 2)) || data.editBlocked"
             maxlength="10">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </div>
  <div class="form-field-cont">
    <Label>{{'VKICoccidiostats.EndDate' | translate}}</Label>
    <mat-form-field class="date-form">
      <input
        matInput
        [matDatepicker]="picker2"
        [placeholder]="'Common.dd-mm-yyyy' | translate"
        [(value)]="data.coccidiostat.value.endDateObj" #endDate
        [min]="data.coccidiostat.value.minEndDate"
        [max]="data.dates.maxDate"
        (keydown)="data.dates.dateInput('endDate', $event, data.index)"
        (dateChange)="data.dates.setDate('endDate', $event, data.index)"
        [disabled]="!data.coccidiostat.value.endDateEditable || data.editBlocked || (data.blockFirst == 2 ? false : data.coccidiostat.value.blocked == 2)"
        maxlength="10">
      <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
      <mat-datepicker
        [startAt]="data.coccidiostat.value.endDateStartAt"
        #picker2 ></mat-datepicker>
    </mat-form-field>
  </div>
  <div class="action-buttons-cont">
    <button *ngIf="data.coccidiostat.value.icon === 'bin' && !data.editBlocked" mat-icon-button (click)="data.deleteCoccidiostats(data.index)">
      <i class="material-icons">delete_forever</i>
    </button>
    <button *ngIf="data.coccidiostat.value.icon === 'lock' && !data.editBlocked" mat-icon-button>
      <i class="material-icons">lock</i>
    </button>
    <button *ngIf="data.coccidiostat.value.icon === 'unlock' && !data.editBlocked" mat-icon-button>
      <i class="material-icons">lock_open</i>
    </button>
    <div class="close-btn">
      <button class="button-green" mat-raised-button (click)="closeDialog()" >
        {{'VKIMobile.Close' | translate}}
      </button>
    </div>
  </div>
</div>
