import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';

import { environment } from '../../../../environments/environment';
import { Https } from '../http.service';

@Injectable({
  providedIn: 'root',
})
export class VKIAdminService {
  constructor(private http: Https) {}

  public getVKIAdminRequestList(userRole, data) {
    let params = new HttpParams();
    if (userRole !== 'admin') {
      params = params.append('part', 'veterinarian');
    }
    return this.http.get(`${environment.baseUrl}/plukonKras/assessment/checks/${data}`, params);
  }

  public submitApproval(payload) {
    return this.http.post(`${environment.baseUrl}/plukonKras/assessment/approval`, payload);
  }

  public getVKIAdminRequestListId(vkiId, mainEnvironment) {
    return this.http.get(`${environment.baseUrl}/plukonKras/assessment/vki/${vkiId}/${mainEnvironment}`, null);
  }

  public updateVKIAdminRequest(payload) {
    return this.http.post(`${environment.baseUrl}/plukonKras/assessment/vki`, payload);
  }

  public testJSON(payload) {
    return this.http.posts(`https://backend-dot-plukon-dev.appspot.com/testPost`, payload);
  }

  public getPlanningEmployee() {
    return this.http.get(`${environment.baseUrl}/users/planningEmployee`, null);
  }

  public getQualityEmployee() {
    return this.http.get(`${environment.baseUrl}/users/role/Quality Employee`, null);
  }

  public getNVWAEmployee(environmentCode) {
    return this.http.get(`${environment.baseUrl}/users/nvwaVeterinarian/${environmentCode}`, null);
  }

  public getDocumentSignedUrl(payload) {
    return this.http.get(`${environment.baseUrl}/plukonKras/assessment/vki/document/signUrl/${payload}`, null);
  }

  public getPreferredLocationList(environmentCode) {
    return this.http.get(`${environment.baseUrl}/environments/preferSlaughterLocation/${environmentCode}`, null);
  }

  public getVKIFilterList(environmentCode) {
    return this.http.get(`${environment.baseUrl}/plukonKras/vkiFilters/${environmentCode}`, null);
  }

  public getUserPreferSlaughterLocation() {
    return this.http.get(`${environment.baseUrl}/slaughterlocation/user`, null);
  }

  public getFilterVKIList(
    userRole,
    environemnt,
    farmerParam,
    slaughterLocationParam,
    statusParam,
    riskParam,
    categoryParam,
    startDateParam,
    endDateParam,
  ) {
    let params = new HttpParams();

    if (userRole !== 'admin') {
      params = params.append('part', 'veterinarian');
    }

    if (farmerParam.length > 0) {
      params = params.append('farmer', farmerParam.join());
    }
    if (slaughterLocationParam.length > 0) {
      params = params.append('slaughterLocation', slaughterLocationParam.join());
    }
    if (statusParam.length > 0) {
      params = params.append('status', statusParam.join());
    }
    if (riskParam.length > 0) {
      params = params.append('risk', riskParam.join());
    }
    if (categoryParam.length > 0) {
      params = params.append('category', categoryParam.join());
    }
    if (startDateParam && endDateParam) {
      params = params.append('slaughterdate', `${startDateParam},${endDateParam}`);
    } else if (startDateParam) {
      params = params.append('slaughterdate', startDateParam);
    }

    return this.http.get(`${environment.baseUrl}/plukonKras/assessment/checks/${environemnt}`, params);
  }
}
