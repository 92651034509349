export const parseQueryKey = (queryKey: string): { flockNumber: string; flockRequestKey: string; extraRequestKey: string | undefined } => {
  const [flockNumber, flockRequestKey, extraRequestKey] = queryKey.split('-');
  return {
    flockNumber,
    flockRequestKey,
    extraRequestKey,
  };
};

export const getQueryKey = (flockNumber = '', key = '', extraParam = ''): string => {
  return `${flockNumber}-${key}` + (extraParam ? `-${extraParam}` : '');
};
