import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  constructor(public router: Router) {}

  ngOnInit() {}

  footerRouting(path) {
    switch (path) {
      case 'terms-of-use':
        this.router.navigate(['terms-of-use']);
        break;
      case 'cookie-statement':
        this.router.navigate(['cookie-statement']);
        break;
      case 'privacy-statement':
        this.router.navigate(['privacy-statement']);
        break;
    }
  }
}
