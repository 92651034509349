import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RouterService {
  private routeChange = new BehaviorSubject(null);
  dynamicRouteChange: BehaviorSubject<any>;
  public environmentData = new ReplaySubject();
  routePath = this.routeChange.asObservable();

  constructor() {
    console.log('RouterServices instance created...');
    this.environmentData = new ReplaySubject<any>(1);
    this.dynamicRouteChange = new BehaviorSubject<any>(null);
  }

  setRoute(path: string) {
    this.routeChange.next(path);
  }
}
