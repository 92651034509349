import { Injectable } from '@angular/core';
import { HttpContext } from '@angular/common/http';

import { environment } from '../../../../../environments/environment';
import { Https } from '../../http.service';
import { BehaviorSubject } from 'rxjs';
import { FLOCK_NUMBER, FLOCK_REQUEST_KEY, ORIGIN_LOSSES_REQUEST } from 'src/app/interceptors/my-flock.interceptor';
import { KEY_FLOCK_ORIGIN } from 'src/lib/my-flock/constants';

@Injectable({
  providedIn: 'root',
})
export class FlockOriginService {
  public chickenDeliver: BehaviorSubject<any>;
  currentFlockData: any;
  stable: any;
  currentFlockOriginData: any;
  currentFlockOriginDataOri: any; // store original data from previous save(if any)
  currentBreedData: any;
  currentNoOfChickTableData: any;
  currentNoOfChickTableDataOri: any; //store original list from previous save (if any)
  currentFlockNumber: any;
  currentBreedCompanyData: any;

  constructor(private http: Https) {
    this.chickenDeliver = new BehaviorSubject<any>(null);
  }

  public getFlockOrigin(flockNumber) {
    const context = new HttpContext();
    context.set(FLOCK_NUMBER, flockNumber);
    context.set(FLOCK_REQUEST_KEY, KEY_FLOCK_ORIGIN);
    return this.http.get(`${environment.baseUrl}/plukonKras/flock/origin/${flockNumber}`, undefined, undefined, context);
  }

  public getBreedList() {
    return this.http.get(`${environment.baseUrl}/plukonKras/breed`, '');
  }

  public getBreedingCompanyList() {
    return this.http.get(`${environment.baseUrl}/plukonKras/originCompany`, '');
  }

  public saveFlockOrigin(fromLosses, payload) {
    const flockNumber = payload?.flockNumber;
    const context = new HttpContext();
    context.set(FLOCK_NUMBER, flockNumber);
    context.set(FLOCK_REQUEST_KEY, KEY_FLOCK_ORIGIN);
    context.set(ORIGIN_LOSSES_REQUEST, fromLosses);
    return this.http.post(`${environment.baseUrl}/plukonKras/flock/origin`, payload, undefined, context);
  }

  set flockOriginData(value: any) {
    this.currentFlockOriginData = value;
  }

  get flockOriginData(): any {
    return this.currentFlockOriginData;
  }

  set flockOriginDataOri(value: any) {
    this.currentFlockOriginDataOri = value;
  }

  get flockOriginDataOri(): any {
    return this.currentFlockOriginDataOri;
  }

  set breedData(value: any) {
    this.currentBreedData = value;
  }

  get breedData(): any {
    return this.currentBreedData;
  }

  set breedCompanyData(value: any) {
    this.currentBreedCompanyData = value;
  }

  get breedCompanyData(): any {
    return this.currentBreedCompanyData;
  }

  set noOfChicksTableData(value: any) {
    this.currentNoOfChickTableData = value;
  }

  get noOfChicksTableData(): any {
    return this.currentNoOfChickTableData;
  }

  set noOfChicksTableDataOri(value: any) {
    this.currentNoOfChickTableDataOri = value;
  }

  get noOfChicksTableDataOri(): any {
    return this.currentNoOfChickTableDataOri;
  }
}
