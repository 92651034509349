import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {IStableCardDialogData} from '../stable-card.models';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-stable-card-dialog',
  templateUrl: './stable-card-dialog.component.html',
  styleUrls: ['./stable-card-dialog.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: environment.myDateFormat },
  ],
})
export class StableCardDialogComponent {
  twiceFormControl;
  constructor(
    public dialogRef: MatDialogRef<StableCardDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IStableCardDialogData,
  ) {
    this.twiceFormControl = this.data.stableLogForm.get('onceTwiceFormControl').value == 2;
    if (
      this.data.blockFirst !== 2 &&
      (this.data.displayStableRecordData.blocked !== 0 || this.data.isBlocked || (this.data.editBlocked && this.data.dataStatus == 2))
    ) {
      this.data.stableLogForm.disable();
    }
  }
  closeDialog() {
    this.dialogRef.close();
  }

}
