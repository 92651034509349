<div class="content-dialog-container" fxLayout="column" fxLayoutAlign="center center">
  <ng-container *ngIf="isHtml; else plain">
    <mat-dialog-content style="white-space: pre-wrap;"[innerHTML]=content></mat-dialog-content>
  </ng-container>
  <ng-template #plain>
    <mat-dialog-content>{{content}}</mat-dialog-content>
  </ng-template>
  <img *ngIf="showGif" class="img-gif" [src]="firefoxProblemUrl"/>
  <mat-dialog-actions *ngIf="primaryButtonText || secondaryButtonText">
    <button *ngIf="primaryButtonText" class="content-button-enabled" mat-button mat-dialog-close (click)="submit()">{{primaryButtonText | translate}}</button>
    <button *ngIf="secondaryButtonText" class="content-button-enabled" mat-button mat-dialog-close (click)="dialogRef.close(false)">{{secondaryButtonText | translate}}</button>
  </mat-dialog-actions>
    <i class="material-icons content-icon" *ngIf="!hideCloseIcon" (click)="dialogRef.close()">clear</i>
</div>
