import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

import { SocialAuthService } from 'src/app/shared/services/social-auth.service';
import { TranslationService } from 'src/app/shared/services/translation.service';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-cookie-statement',
  templateUrl: './cookie-statement.component.html',
  styleUrls: ['./cookie-statement.component.scss'],
})
export class CookieStatementComponent implements OnInit, OnDestroy {
  plukonTranslationSub: Subscription;
  socialSubscription: Subscription;
  selectedLang: any;

  constructor(
    private translate: TranslateService,
    private socialService: SocialAuthService,
    private translationService: TranslationService,
    private router: Router,
    public snackBar: MatSnackBar,
    public sanitizer: DomSanitizer,
  ) {
    // //get current lang when switch tab
    // if(translate.currentLang) {
    //   this.selectedLang = translate.currentLang;
    // }
    // //detect change in language
    // translate.onLangChange.subscribe(result=>{
    //   this.selectedLang = result.lang;
    // });
  }

  ngOnInit() {
    this.socialSubscription = this.socialService.getMyProfile().subscribe(
      (result) => {
        if (result) {
          if (result['response'].tncAgree == false || result['response'].tncAgree == null) {
            this.router.navigate(['/term-and-condition']);
          } else {
            if (result['response']['displayLanguageObj'].name) {
              this.selectedLang = result['response']['displayLanguageObj'].name;
            }
            this.plukonTranslationSub = this.translationService.getPlukonTranslation(null).subscribe();
          }
        }
      },
      (error) => {
        if (error.status == '401') {
          this.snackBar.open(this.translate.instant('Portal.UnauthorizedAPICall'), 'Close', { verticalPosition: 'top' });
          return false;
        }
        if (error.status == '404') {
          this.router.navigate(['/login']);
          return false;
        }
      },
    );
  }

  ngOnDestroy() {
    if (this.socialSubscription) {
      this.socialSubscription.unsubscribe();
    }
    if (this.plukonTranslationSub) {
      this.plukonTranslationSub.unsubscribe();
    }
  }
}
