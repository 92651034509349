import { Directive, ElementRef, OnInit, Optional } from '@angular/core';
import { FormControlName, NgControl, NgModel } from '@angular/forms';

@Directive({
  selector: '[formControlName]',
})
export class NativeElementInjectorDirective implements OnInit {
  constructor(
    private el: ElementRef,
    private control: NgControl,
  ) {}

  ngOnInit() {
    (this.control.control as any).nativeElement = this.el.nativeElement;
  }
}
