
<div class="header-cont">
  <div class="header-content">
    <h4>{{'Contact.Contact' | translate}}</h4>{{environmentName}}
    <span class="address">
    {{contactAddress}}
    </span>
  </div>
  <div class="header-content top-padding" fxLayoutAlign="center center">
    <div fxLayout="column" fxLayoutAlign="center center">
      <div>
        <a mat-raised-button class="button-enabled" href="https://help.plukonnect.com" target="_blank">
            {{'Contact.RemoteSupport' | translate}}
        </a>
      </div>
      <span class="windows-message">
        {{'Contact.OnlyForWindows' | translate}}
      </span>
    </div>
  </div>
</div>

<div class="contact-title" [innerHTML]="contactTranslation"></div>
<div class="contact-information" fxLayout="column" [formGroup]="contactForm">
  <div class="input-box">
    <label class="top-padding label-size">{{'Contact.Name' | translate}}</label>
    <mat-form-field class="input-size">
      <input matInput formControlName="name">
    </mat-form-field>
  </div>
  <div class="input-box">
    <label class="label-size">{{'Contact.Email' | translate}}</label>
    <mat-form-field class="input-size">
      <input matInput formControlName="email">
      <mat-error *ngIf="contactForm.get('email').hasError('pattern')">
          {{'ContactValidator.Email' | translate}}
      </mat-error>
    </mat-form-field>
  </div>
  <div class="input-box">
    <label class="label-size">{{'Contact.Telephone' | translate}}</label>
    <mat-form-field class="input-size">
      <input matInput formControlName="telephone" (keydown)="telephoneInput($event)" maxlength="50">
      <mat-error *ngIf="contactForm.get('telephone').hasError('pattern')">
        {{'ContactValidator.Telephone' | translate}}
      </mat-error>
    </mat-form-field>
  </div>
  <div class="input-box">
    <label class="label-size">{{'Contact.Subject' | translate}}</label>
    <mat-form-field class="input-size">
      <mat-select formControlName="subject" >
        <mat-option *ngFor="let subject of subjectList" [value]="subject.codeTableId">
          {{subject.translateSubject | translate}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="contactForm.get('subject').hasError('required')">
        {{'VKIValidator.Mandatory' | translate}}
      </mat-error>
    </mat-form-field>
  </div>
  <div class="input-box">
    <label class="label-size">{{'Contact.Question' | translate}}</label>
    <mat-form-field class="input-size">
      <textarea [placeholder]="'Contact.QuestionPlaceholder' | translate" matInput formControlName="question" maxlength="500" rows="3"></textarea>
      <mat-error *ngIf="contactForm.get('question').hasError('required')">
        {{'VKIValidator.Mandatory' | translate}}
      </mat-error>
    </mat-form-field>
  </div>
  <div class="top-padding" fxLayout="row" fxLayoutAlign="start center">
      <button mat-raised-button
      [ngClass]="{'button-disabled': !contactForm.dirty || !contactForm.valid,
                  'button-enabled': contactForm.dirty && contactForm.valid}"
      (click)='onSubmitContact()'
      [disabled]="!contactForm.dirty || !contactForm.valid">
      {{'Common.Submit' | translate}}
    </button>
  </div>
  <i class="material-icons" (click)="dialogRef.close()">clear</i>
  <app-loading-spinner [show]=showSpinner></app-loading-spinner>
</div>

