import { Component, OnInit, OnDestroy, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';

import { RouterService } from '../../services/router.service';
import { DialogService } from '../../services/dialog.service';
import { EnvironmentService } from '../../services/admin/environment.service';
import { CodeService } from '../../services/code.service';
import { CommonService } from '../../services/common.service';
import { DocumentationService } from '../../services/communication/documentation.service';
import { TranslationService } from '../../services/translation.service';
import { AuthGuardService } from '../../services/auth-guard.service';
import { RoleSetting } from '../../services/setting/role-setting.service';
import { VKIService } from '../../services/flock/vki.service';
import { NewsService } from '../../services/news.service';
import { Https } from '../../services/http.service';
import { NetworkService } from '../../services/network/network.service';
import { AuthService } from '../../services/auth/auth.service';
import { filter, switchMap, take } from 'rxjs/operators';
import { NotificationsService } from '../../services/notifications/notifications.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  languageSubscription: Subscription;
  documentListSubscription: Subscription;
  latestDocumentNumberSubscription: Subscription;
  headerSettingSubscription: Subscription;
  environmentSubscription: Subscription;
  maintenanceSubscription: Subscription;
  languageChangeSubscription: Subscription;
  userManualSubscription: Subscription;
  isLoggedInSubscription: Subscription;
  authInitializedSubscription: Subscription;
  @Input() url;
  @Input() companyList: string;
  @Input() notificationNumber: number;
  @Input() isAdminMyTask: boolean;
  @ViewChild('setting', { static: false, read: MatMenuTrigger }) settingMenu: MatMenuTrigger;
  @ViewChild('language', { static: false, read: MatMenuTrigger }) languageMenu: MatMenuTrigger;
  @Output() sideMenuTrigger = new EventEmitter();
  @Output() openMyTasksTrigger = new EventEmitter();
  @Output() mobileHeaderMenuTrigger = new EventEmitter();
  @Output() userManualURLEmitter = new EventEmitter();
  @Output() documentNumberEmitter = new EventEmitter();
  @Output() mobileCloseMyTasksEmitter = new EventEmitter();
  isLoggedIn: boolean;
  redirectionA: string;
  redirectionALink: string;
  language: string;
  adminOption: string;
  pictureUrl: string;
  helpText: string;
  telephone = '';
  environmentParam: string;
  languageParam: string;
  isAdmin: boolean;
  vetenarianView: boolean;
  hasPoultryOwner: boolean;
  userId: string;
  userName: string;
  documentNumber: number;
  environmentName: string;
  contactName: string;
  contactEmail: string;
  contactAddress: string;
  contactTelephone: string;
  selectedEnvironment: string;
  environmentId: string;
  environmentShortName: string;
  defaultEnvironment = '';
  isSuperAdmin = false;
  availableLanguageList = [];
  environmentList = [];
  displayEnvironmentList = [];
  userRoleList = [];
  roleSetting = null;
  userRole = null;
  userEnvironment = null;
  isGlobal = null;
  mainRole = null;
  preMaintenanceMessage: string;
  maintenanceMessage: string;
  isQualityEmployee = false;
  isInternalMail = false;
  isBlocked = false;
  addPadding = false;
  userManualURL = '';
  offlineMode = false;
  roleChecked = false;
  truncated = true;
  truncatable = false;

  constructor(
    public snackBar: MatSnackBar,
    private router: Router,
    public routerService: RouterService,
    private activatedRoute: ActivatedRoute,
    private dialogService: DialogService,
    public translate: TranslateService,
    private environmentService: EnvironmentService,
    private codeService: CodeService,
    private commonService: CommonService,
    private documentationService: DocumentationService,
    private translationService: TranslationService,
    private authGuardService: AuthGuardService,
    private roleService: RoleSetting,
    private vkiService: VKIService,
    private newsService: NewsService,
    private http: Https,
    private networkService: NetworkService,
    private authService: AuthService,
    private notifications: NotificationsService
  ) {
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationStart) {
        this.authService.isLoggedIn$.pipe(take(1)).subscribe(isLoggedIn => {
          if (isLoggedIn && this.router.url.includes('/login')) {
            this.ngOnDestroy();
            this.ngOnInit();
          }
        });
      }
    });
  }

  ngOnInit() {
    this.getMaintenanceMessage();

    this.isLoggedInSubscription = this.authService.isLoggedIn$.subscribe((isLoggedIn) => {
      this.isLoggedIn = isLoggedIn;
    });

    this.authInitializedSubscription = this.authService.initialized$.subscribe((authInitialized) => {
      if (authInitialized) {
        Promise.all([this.getRoleSetting(), this.getEnvironmentList(), this.getLanguageCode()]).then(() => {
          window.addEventListener('scroll', () => {
            if (this.settingMenu) {
              this.settingMenu.closeMenu();
            }
          });
          window.addEventListener('scroll', () => {
            if (this.languageMenu) {
              this.languageMenu.closeMenu();
            }
          });

          this.commonService.isAdmin.subscribe((admin) => {
            this.isAdmin = admin;
            try {
              this.addPadding =
                (this.roleSetting &&
                  this.roleSetting.header &&
                  this.roleSetting.header.environment &&
                  this.roleSetting.header.environment.allow &&
                  this.url !== '/login') ||
                ((this.url.startsWith('/login') || this.url.startsWith('/term-and-condition')) &&
                  !this.url.startsWith('/unauthorized') &&
                  !this.url.startsWith('/error')) ||
                this.isAdmin;
            } catch (e) {
              this.addPadding = false;
            }
            this.newsService.imagePadding.next(this.addPadding);
            // this.isAdmin =  true;
          });

          this.routerService.environmentData.subscribe((result) => {
            if (result['environment']) {
              this.defaultEnvironment = result['environment'];
              this.getEnvironmentData(result['environment'], result['language']);
            } else {
              this.defaultEnvironment = '';
              // this.getLanguageCode();
            }
          });

          this.routerService.dynamicRouteChange.subscribe((result) => {
            if (result) {
              this.adminOption = result;
            }
          });

          this.documentListSubscription = this.authService.isLoggedIn$
            .pipe(
              filter((loggedIn) => loggedIn === true),
              switchMap(() => this.documentationService.getDocumentUnreadCount()),
            )
            .subscribe((result) => {
              if (result) {
                this.documentNumber = result['response'].unread ? result['response'].unread : 0;
                this.documentNumberEmitter.emit(this.documentNumber);
              }
            });

          this.latestDocumentNumberSubscription = this.documentationService.documentNumber.subscribe((result) => {
            if (result) {
              this.documentNumber = result;
            } else {
              this.documentNumber = 0;
            }
            this.documentNumberEmitter.emit(this.documentNumber);
          });

          this.languageChangeSubscription = this.translate.onLangChange.subscribe((lang) => {
            this.getMaintenanceMessage();
          });

          this.commonService.myUserProfile.subscribe(
            (result) => {
              if (result != null && result['response']) {
                this.userRoleList = result['response']['userRoleList'] ? result['response']['userRoleList'] : [];
                this.hasPoultryOwner = this.userRoleList.filter((data) => data.roleObj.name === 'Poultry Owner').length > 0;
                this.isAdmin = result['response'].admin;
                this.mainRole = result['response'].userRoleMainCompanyObj ? result['response'].userRoleMainCompanyObj.name : null;
                this.vetenarianView = this.checkisAllowAccess(result['response'].userRoleMainCompanyObj);
                const setting = this.roleService.getRoleSetting(this.mainRole);
                this.roleSetting = setting.module;
                this.userRole = setting.role;
                this.userEnvironment = result['response'].environment;
                //TP-816 June 3, 2022, 3:26 AM Harm comment updated access control
                this.isBlocked = this.userRole === 'Planning Employee';
                this.isQualityEmployee = this.userRole === 'Quality employee';
                this.isInternalMail =
                  this.userRole === 'Other' &&
                  this.userRoleList.length > 0 &&
                  (this.userRoleList.filter((data) => data.roleObj.name === 'internalMailPlanning').length > 0 ||
                    this.userRoleList.filter((data) => data.roleObj.name === 'InternalMailAdmin').length > 0 ||
                    this.userRoleList.filter((data) => data.roleObj.name === 'internalMailquality').length > 0);
                try {
                  this.addPadding =
                    (this.roleSetting &&
                      this.roleSetting.header &&
                      this.roleSetting.header.environment &&
                      this.roleSetting.header.environment.allow &&
                      this.url !== '/login') ||
                    ((this.url.startsWith('/login') || this.url.startsWith('/term-and-condition')) &&
                      !this.url.startsWith('/unauthorized') &&
                      !this.url.startsWith('/error')) ||
                    this.isAdmin;
                } catch (e) {
                  this.addPadding = false;
                }
                this.newsService.imagePadding.next(this.addPadding);
                if (
                  !result['response'].admin &&
                  (this.userRoleList.filter((data) => data.roleObj.name === 'Ext. Advisor').length > 0 || this.mainRole === 'Ext. Advisor')
                ) {
                  this.isGlobal = false;
                } else {
                  if (result['response'].thisUserFromGlobalEnv) {
                    this.isGlobal = true;
                  } else {
                    this.isGlobal = false;
                  }
                }
                this.roleChecked = true;
              }

              if (result != null && result['response'] && result['response']['environmentObj']) {
                if (
                  !result['response'].admin &&
                  (this.userRoleList.filter((data) => data.roleObj.name === 'Ext. Advisor').length > 0 || this.mainRole === 'Ext. Advisor')
                ) {
                  this.selectedEnvironment = this.environmentList.filter(
                    (data) => data.shortName.toLowerCase() === 'kknr-be',
                  )[0].environmentId;
                  this.environmentShortName = this.environmentList.filter(
                    (data) => data.shortName.toLowerCase() === 'kknr-be',
                  )[0].shortName;
                } else {
                  this.selectedEnvironment = result['response'].environment;
                  this.environmentShortName = result['response']['environmentObj'].shortName;
                }
                this.environmentId = result['response'].environment;
                this.authGuardService.mainEnvironment.next({
                  envId: this.environmentId,
                  environment: this.selectedEnvironment,
                  environmentShortName: this.environmentShortName,
                  role: this.userRole,
                  userEnvironment: this.userEnvironment,
                });
                const environment = result['response']['environmentObj'];
                const user = result['response'];

                this.getUserManual();
                // let tempLanguageList = [];

                // if (environment.languageAvailable) {
                //   _.forEach(environment.languageAvailable, function (value) {
                //     tempLanguageList.push({
                //       'codeTable': value.codeTable.codeTable,
                //       'name': value.codeTable.name,
                //       'translateLanguage' : `${value.codeTable.codeTable}.${value.codeTable.name}`
                //     });
                //   });
                // }

                this.displayEnvironmentList = this.environmentList;
                this.userId = user.userId;
                this.userName = user.userName;
                // this.availableLanguageList = tempLanguageList;
                this.redirectionA = environment.globeMenuObj
                  ? `${environment.globeMenuObj.codeTable}.${environment.globeMenuObj.name}`
                  : '';
                this.telephone = environment.telephone;
                this.pictureUrl = environment.pictureURL;
                this.redirectionALink = environment.globeMenuObj.description;
                this.environmentName = environment.name ? environment.name : '';
                this.contactName = user.fullName ? user.fullName : '';
                this.contactEmail = user.email ? user.email : '';
                this.contactAddress = environment.address ? environment.address : '';
                this.contactTelephone = environment.telephone ? environment.telephone : '';
                if (user['displayLanguageObj'] && user['displayLanguageObj']['name']) {
                  this.language = user.displayLanguageObj.name;
                }
                this.languageSelection(this.language);
              }
            },
            (error) => {
              console.log('Header (myUserProfile) : ', error);
              switch (error.status) {
                case 401:
                  this.snackBar.open(this.translate.instant('Portal.UnauthorizedAPICall'), 'Close', { verticalPosition: 'top' });
                  break;
                default:
                  this.snackBar.open(this.translate.instant('ErrorMessage.DataRetrieveFail'), 'Close', {
                    duration: 5000,
                    verticalPosition: 'top',
                  });
                  break;
              }
            },
          );
        });
      }
    });

    this.networkService.getIsOfflineMode$().subscribe((isOffline) => {
      this.offlineMode = isOffline;
    });
  }

  truncateClass() {
    try {
      setTimeout(() => {
        const element = document.getElementById('maintenance-message');
        if (element) {
          this.truncated = true;
          if (element.offsetHeight < element.scrollHeight || element.offsetWidth < element.scrollWidth) {
            this.truncatable = true;
          } else {
            this.truncatable = false;
          }
        }
      }, 500);
    } catch (e) {
      console.log(e);
    }
  }

  truncateText(isTruncate) {
    if (isTruncate) {
      document.getElementById('maintenance-message').classList.add('truncate');
    } else {
      document.getElementById('maintenance-message').classList.remove('truncate');
    }
    this.truncated = isTruncate;
  }

  getUserManual() {
    if (this.router.url.includes('/unauthorized')) {
      return;
    }
    if (!this.isQualityEmployee && !(this.userRole === 'Veterinarian Employee') && !this.isInternalMail) {
      this.userManualURL = '';
      this.userManualURLEmitter.emit(this.userManualURL);
      if (this.userManualSubscription) {
        this.userManualSubscription.unsubscribe();
      }
      this.userManualSubscription = this.documentationService.getUserManual(this.selectedEnvironment).subscribe(
        (result) => {
          if (result && result['response'] && result['response']['signedURL']) {
            this.userManualURL = result['response']['signedURL'];
            this.userManualURLEmitter.emit(this.userManualURL);
          }
        },
        (error) => {
          console.log('Header (getUserManual) : ', error);
          switch (error.status) {
            case 401:
              this.snackBar.open(this.translate.instant('Portal.UnauthorizedAPICall'), 'Close', { verticalPosition: 'top' });
              break;
            default:
              this.snackBar.open(this.translate.instant('ErrorMessage.DataRetrieveFail'), 'Close', {
                duration: 5000,
                verticalPosition: 'top',
              });
              break;
          }
        },
      );
    }
  }

  getMaintenanceMessage() {
    this.maintenanceSubscription = this.commonService.getMaintenance().subscribe((result) => {
      this.preMaintenanceMessage = this.translate.instant('Maintenance.PreUpdateMessage');
      this.maintenanceMessage = this.translate.instant('Maintenance.UpdateMessage');

      if (result && result['response']) {
        if (result['response']['isMaintenance']) {
          this.maintenanceMessage = this.maintenanceMessage.replace('[EndDate]', result['response']['maintenanceEndDate']);
          this.preMaintenanceMessage = null;
          this.truncated = true;
          this.truncateClass();
        } else if (result['response']['isPreMaintenance']) {
          this.maintenanceMessage = null;
          this.preMaintenanceMessage = this.preMaintenanceMessage.replace('[StartDate]', result['response']['maintenanceStartDate']);
          this.preMaintenanceMessage = this.preMaintenanceMessage.replace('[EndDate]', result['response']['maintenanceEndDate']);
          this.truncated = true;
          this.truncateClass();
        } else {
          this.preMaintenanceMessage = null;
          this.maintenanceMessage = null;
        }
      } else {
        this.preMaintenanceMessage = null;
        this.maintenanceMessage = null;
      }
    });
  }

  getRoleSetting() {
    return new Promise<void>((resolve, reject) => {
      this.headerSettingSubscription = this.authGuardService.roleSetting.subscribe((result) => {
        if (result) {
          this.roleSetting = result.module;
          this.userRole = result.role;
          try {
            this.addPadding =
              (this.roleSetting &&
                this.roleSetting.header &&
                this.roleSetting.header.environment &&
                this.roleSetting.header.environment.allow &&
                this.url !== '/login') ||
              ((this.url.startsWith('/login') || this.url.startsWith('/term-and-condition')) &&
                !this.url.startsWith('/unauthorized') &&
                !this.url.startsWith('/error')) ||
              this.isAdmin;
          } catch (e) {
            this.addPadding = false;
          }
          this.newsService.imagePadding.next(this.addPadding);
          this.authGuardService.mainEnvironment.next({
            envId: this.environmentId,
            environment: this.selectedEnvironment,
            environmentShortName: this.environmentShortName,
            role: this.userRole,
            userEnvironment: this.userEnvironment,
          });
          resolve();
        } else {
          resolve();
        }
      });
    });
  }

  environmentSelection(event) {
    this.vkiService.flockStartDate.next(null);
    this.selectedEnvironment = event.value;
    this.environmentId = event.value;
    this.environmentShortName = this.environmentList.filter((data) => data.environmentId === event.value)[0].shortName;
    this.authGuardService.mainEnvironment.next({
      envId: this.environmentId,
      environment: this.selectedEnvironment,
      environmentShortName: this.environmentShortName,
      role: this.userRole,
      userEnvironment: this.userEnvironment,
    });
    this.getUserManual();
  }

  checkisAllowAccess(role) {
    if (role && role.name) {
      if (
        (role.name === 'Veterinarian Employee' && this.isAdmin === false) ||
        (role.name === 'Quality employee' && this.isAdmin === true) ||
        (role.name === 'Superadmin' && this.isAdmin === true)
      ) {
        if (role.name === 'Superadmin') {
          this.isSuperAdmin = true;
        } else {
          this.isSuperAdmin = false;
        }
        return true;
      }
      this.isSuperAdmin = false;
      return false;
    } else {
      return false;
    }
  }

  getEnvironmentList() {
    return new Promise((resolve, reject) => {
      this.environmentSubscription = this.environmentService.getEnvironmentList(this.defaultEnvironment).subscribe((result) => {
        this.environmentList = result['list'];
        this.environmentSubscription.unsubscribe();
        resolve(this.environmentList);
      },
      (error) => {
        if (error.status == '401') {
          this.snackBar.open(this.translate.instant('Portal.UnauthorizedAPICall'), 'Close', { verticalPosition: 'top' });
          reject();
        }
        if (error.status == '404') {
          this.router.navigate(['/login']);
          reject();
        }
        reject();
      });
    });
  }

  getEnvironmentData(environment, language) {
    // let tempLanguageList = [];
    this.languageSubscription = this.environmentService.getUserEnvironment(environment).subscribe(
      (result) => {
        // _.forEach(result['response'].languageAvailable, function (value) {
        //   tempLanguageList.push({
        //     'codeTable': value.codeTable.codeTable,
        //     'name': value.codeTable.name,
        //     'translateLanguage' : `${value.codeTable.codeTable}.${value.codeTable.name}`
        //   });
        // });
        this.redirectionALink = result['response'].globeMenuObj.description;
        // this.availableLanguageList = tempLanguageList;
        this.redirectionA = result['response'].globeMenuObj
          ? `${result['response'].globeMenuObj.codeTable}.${result['response'].globeMenuObj.name}`
          : '';
        this.telephone = result['response'].telephone;
        this.pictureUrl = result['response'].pictureURL;
        if (this.language == null || this.language == undefined) {
          const languageList = [
            { code: 'en', language: 'English' },
            { code: 'nl', language: 'Nederlands' },
            { code: 'de', language: 'German' },
            { code: 'fr', language: 'Français' },
          ];
          this.language = 'English';
          if (window.navigator.language) {
            const browserLang = window.navigator.language.toLowerCase().split('-')[0];
            let findLang = languageList.find((l) => l.code == browserLang);
            this.language = findLang
              ? findLang.language
              : languageList[0].language;
          }
          this.languageSelection(this.language);
        }
      },
      (error) => {
        console.log('Header (getUserEnvironment) : ', error);
        switch (error.status) {
          case 401:
            this.snackBar.open(this.translate.instant('Portal.UnauthorizedAPICall'), 'Close', { verticalPosition: 'top' });
            break;
          default:
            this.snackBar.open(this.translate.instant('ErrorMessage.DataRetrieveFail'), 'Close', {
              duration: 5000,
              verticalPosition: 'top',
            });
            break;
        }
      },
    );
  }

  getLanguageCode() {
    this.languageSubscription = this.codeService.getLanguageCodeList().subscribe((result) => {
      const tempLanguageList = [];
      _.forEach(result['list'], function (value) {
        if (value.name === 'Nederlands' || value.name === 'English' || value.name === 'German' || value.name === 'Français') {
          tempLanguageList.push({
            codeTableId: value.codeTableId,
            codeTable: value.codeTable,
            name: value.name,
            translateLanguage: `${value.codeTable}.${value.name}`,
          });
        }
      });
      this.availableLanguageList = tempLanguageList;
    },
    (error) => {
      if (error.status == '401') {
        this.snackBar.open(this.translate.instant('Portal.UnauthorizedAPICall'), 'Close', { verticalPosition: 'top' });
        return false;
      }
      if (error.status == '404') {
        this.router.navigate(['/login']);
        return false;
      }
      return false;
    });
  }

  languageSelection(selectedLanguage) {
    let languageObject;
    if (selectedLanguage || selectedLanguage !== '') {
      languageObject = this.availableLanguageList.filter((value) => {
        return value.name === selectedLanguage;
      });
    } else {
      languageObject = this.availableLanguageList.filter((value) => {
        return value.name === 'English';
      });
    }
    if (languageObject.length > 0) {
      this.translationService.languageSelectedCodeTableId.next(languageObject[0].codeTableId);
    }
    switch (selectedLanguage) {
      case 'English':
        // this.translate.use('en');
        this.translationService.languageSelected.next('English');
        break;
      case 'Nederlands':
        // this.translate.use('du');
        this.translationService.languageSelected.next('Nederlands');
        break;
      case 'German':
        // this.translate.use('ge');
        this.translationService.languageSelected.next('German');
        break;
      case 'Français':
        // this.translate.use('fr');
        this.translationService.languageSelected.next('Français');
        break;
      default:
        // this.translate.use('en');
        this.translationService.languageSelected.next('English');
    }
  }

  routeHome(toggleMobileHeader) {
    if (
      !this.offlineMode &&
      this.url !== '/login' &&
      this.url !== '/unauthorized' &&
      this.url !== '/term-and-condition' &&
      !this.isInternalMail
    ) {
      if (toggleMobileHeader) {
        this.toggleHeaderNav();
        this.mobileCloseMyTasksEmitter.emit();
      }
      this.router.navigate(['']);
      this.adminOption = null;
    }
  }

  routeSetting() {
    this.router.navigate(['/user/settings']);
    this.adminOption = null;
  }

  routeUser() {
    this.router.navigate(['/user/info']);
    this.adminOption = null;
  }

  routeMyPlukon() {
    this.router.navigate(['/my-company/company']);
    this.adminOption = null;
  }

  routeCommunication() {
    this.router.navigate(['/my-messages/documentation']);
    this.adminOption = null;
  }

  routeMyFlock() {
    this.router.navigate(['/my-flock/vki-data']);
    this.adminOption = null;
  }

  routeMyReports() {
    this.router.navigate(['/my-reports']);
    this.adminOption = null;
  }

  routeNews() {
    this.newsService.initState.next(true);
    this.router.navigate(['/news/concept']);
    this.adminOption = null;
  }

  routeMyFeedback() {
    this.router.navigate(['/my-feedback/question-entries']);
    this.adminOption = null;
  }

  routeVKIChecks() {
    if (this.userRole === 'Quality employee' && this.environmentShortName === 'kknr-be') {
      this.router.navigate(['admin/vki-check']);
      this.routerService.setRoute('vki-check');
    } else {
      this.router.navigate(['/vki-checks']);
    }
    this.adminOption = null;
  }

  openMyTasks() {
    this.openMyTasksTrigger.emit();
  }

  openHelpDialog() {
    this.dialogService
      .context(`${this.translate.instant('Environment.HelpTextLogin')} ${this.telephone}`, null, null, null, null, null, true)
      .subscribe();
  }

  openContactDialog() {
    this.dialogService
      .contactDialog(
        this.userId,
        this.selectedEnvironment,
        this.environmentName,
        this.contactName,
        this.contactEmail,
        this.contactAddress,
        this.contactTelephone,
      )
      .subscribe();
  }

  trigger() {
    this.snackBar.open('Warning!', 'Close', { duration: 2000, verticalPosition: 'top' });
  }

  toggleSideNav() {
    this.sideMenuTrigger.emit();
  }

  toggleHeaderNav(open?) {
    this.mobileHeaderMenuTrigger.emit(open);
  }

  logout() {
    Promise.all([this.notifications.removeUserDataToNotificationsList(), this.authService.logout()])
      .then(() => {
      window.location.reload();
    }).catch((e) => console.warn('Notifications disabled'));
  }

  changeAdminScreen(event) {
    switch (event.value) {
      case 'dashboard':
        this.router.navigate(['admin/dashboard']);
        this.routerService.setRoute('dashboard');
        break;
      case 'user':
        this.router.navigate(['admin/user']);
        this.routerService.setRoute('user');
        break;
      case 'company':
        this.router.navigate(['admin/company']);
        this.routerService.setRoute('company');
        break;
      case 'environment':
        this.router.navigate(['admin/environment']);
        this.routerService.setRoute('environment');
        break;
      case 'vki-check':
        this.router.navigate(['admin/vki-check']);
        this.routerService.setRoute('vki-check');
        break;
    }
  }

  ngOnDestroy() {
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe();
    }
    if (this.documentListSubscription) {
      this.documentListSubscription.unsubscribe();
    }
    if (this.latestDocumentNumberSubscription) {
      this.latestDocumentNumberSubscription.unsubscribe();
    }
    if (this.headerSettingSubscription) {
      this.headerSettingSubscription.unsubscribe();
    }
    if (this.maintenanceSubscription) {
      this.maintenanceSubscription.unsubscribe();
    }
    if (this.languageChangeSubscription) {
      this.languageChangeSubscription.unsubscribe();
    }
    if (this.userManualSubscription) {
      this.userManualSubscription.unsubscribe();
    }
    if (this.isLoggedInSubscription) {
      this.isLoggedInSubscription.unsubscribe();
    }
    if (this.authInitializedSubscription) {
      this.authInitializedSubscription.unsubscribe();
    }
  }
}
