import { TranslateLoader, TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { Https } from '../http.service';
import { map } from 'rxjs/operators';
import { Injector } from '@angular/core';
import { MISSING_TRANSLATION_RESULT } from '../../functions/missing-translation-handler';

export class TranslationsLoader implements TranslateLoader {
  constructor(
    private http: Https,
    private injector: Injector,
    private prefix: string = './assets/i18n/',
    private suffix: string = '.json',
  ) {}

  public getTranslation(lang: string): Observable<object> {
    return this.http.get(`${this.prefix}${lang}${this.suffix}`, null, { skipAuth: true }).pipe(
      map((result) => flattenObject(result)),
      map((result) => {
        const translateService = this.injector.get(TranslateService);
        // filter out values that already were added, to prevent overwriting for translations from /translate endpoint
        const filteredResult = Object.keys(result).reduce((acc, key) => {
          if (translateService.instant(key) === MISSING_TRANSLATION_RESULT) {
            acc[key] = result[key];
          }
          return acc;
        }, {});
        return filteredResult;
      }),
    );
  }
}

function flattenObject(obj: Record<string, any> | undefined, parentKey = ''): Record<string, any> {
  if (obj === undefined) {
    return {};
  }

  let result: Record<string, any> = {};

  for (const key in obj) {
    if (key in obj) {
      const newKey = parentKey ? `${parentKey}.${key}` : key;

      if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
        result = Object.assign(result, flattenObject(obj[key], newKey));
      } else {
        result[newKey] = obj[key];
      }
    }
  }

  return result;
}
