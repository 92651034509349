import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { Https } from '../../http.service';
import { HttpContext } from '@angular/common/http';
import { FLOCK_NUMBER, FLOCK_REQUEST_EXTRA_KEY, FLOCK_REQUEST_KEY, METADATA } from 'src/app/interceptors/my-flock.interceptor';
import { KEY_CANCEL_REQUEST, KEY_FLOCK_STEP_MORTALITY, KEY_UPLOAD_TEMP_FILE } from 'src/lib/my-flock';

@Injectable({
  providedIn: 'root',
})
export class MortalityService {
  currentMortalityData: any;
  currentMortalityDataOri: any;
  currentMortalityFirstWeekData: any;
  currentMortalityFirstWeekDataOri: any;
  currentTotalChicksLostData: any;
  currentTotalChicksLostDataOri: any;
  currentCumulativeData: any;
  currentCumulativeDataOri: any;
  currentUploadDataOri: any;

  constructor(private http: Https) {}

  public getMortality(flockNumber) {
    const context = new HttpContext();
    context.set(FLOCK_NUMBER, flockNumber);
    context.set(FLOCK_REQUEST_KEY, KEY_FLOCK_STEP_MORTALITY);
    return this.http.get(`${environment.baseUrl}/plukonKras/flock/mortality/${flockNumber}`, undefined, undefined, context);
  }

  public saveMortality(payload) {
    const context = new HttpContext();
    context.set(FLOCK_NUMBER, payload?.flockNumber);
    context.set(FLOCK_REQUEST_KEY, KEY_FLOCK_STEP_MORTALITY);
    return this.http.post(`${environment.baseUrl}/plukonKras/flock/mortality`, payload, undefined, context);
  }

  public uploadFile(data: FormData) {
    const context = new HttpContext();
    context.set(FLOCK_NUMBER, data.get('flockNumber'));
    context.set(FLOCK_REQUEST_KEY, KEY_FLOCK_STEP_MORTALITY);
    context.set(FLOCK_REQUEST_EXTRA_KEY, KEY_UPLOAD_TEMP_FILE);

    context.set(METADATA, { uuid: data.get('uuid') });
    return this.http.upload(`${environment.baseUrl}/plukonKras/flock/mortality/tempfile`, data, undefined, context);
  }

  public cancelUpload(payload) {
    const context = new HttpContext();
    context.set(FLOCK_NUMBER, payload?.flockNumber);
    context.set(FLOCK_REQUEST_KEY, KEY_FLOCK_STEP_MORTALITY);
    context.set(FLOCK_REQUEST_EXTRA_KEY, KEY_CANCEL_REQUEST);
    return this.http.post(`${environment.baseUrl}/plukonKras/flock/mortality/tempfile/cancel`, payload, undefined, context);
  }

  set mortalityData(value: any) {
    this.currentMortalityData = value;
  }

  get mortalityData(): any {
    return this.currentMortalityData;
  }

  set mortalityDataOri(value: any) {
    this.currentMortalityDataOri = value;
  }

  get mortalityDataOri(): any {
    return this.currentMortalityDataOri;
  }

  set mortalityFirstWeekData(value: any) {
    this.currentMortalityFirstWeekData = value;
  }

  get mortalityFirstWeekData(): any {
    return this.currentMortalityFirstWeekData;
  }

  set mortalityFirstWeekDataOri(value: any) {
    this.currentMortalityFirstWeekDataOri = value;
  }

  get mortalityFirstWeekDataOri(): any {
    return this.currentMortalityFirstWeekDataOri;
  }

  set totalChickLostData(value: any) {
    this.currentTotalChicksLostData = value;
  }

  get totalChickLostData(): any {
    return this.currentTotalChicksLostData;
  }

  set totalChickLostDataOri(value: any) {
    this.currentTotalChicksLostDataOri = value;
  }

  get totalChickLostDataOri(): any {
    return this.currentTotalChicksLostDataOri;
  }

  set cumulativeData(value: any) {
    this.currentCumulativeData = value;
  }

  get cumulativeData(): any {
    return this.currentCumulativeData;
  }

  set cumulativeDataOri(value: any) {
    this.currentCumulativeDataOri = value;
  }

  get cumulativeDataOri(): any {
    return this.currentCumulativeDataOri;
  }

  set uploadDataOri(value: any) {
    this.currentUploadDataOri = value;
  }

  get uploadDataOri(): any {
    return this.currentUploadDataOri;
  }
}
