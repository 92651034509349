<div class="vaccinations-dialog-cont">
  <div class="close-mark" (click)="closeDialog()"></div>
  <div class="form-field-cont">
    <label>{{'VKIVaccination.Illness' | translate}}</label>
    <mat-form-field class="width-80">
      <mat-select [(value)]="data.vaccination.value.illness" (selectionChange)='data.vaccination.illnessSelection(data.index , $event)'
                  [disabled]="(data.blockFirst == 2 ? false : (data.vaccination.value.blocked == 1 || data.vaccination.value.blocked == 2)) || data.editBlocked">
        <mat-option *ngFor="let illness of data.vaccination.illnessList" [value]="illness.name">
          {{illness.translateIllness | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="form-field-cont">
    <label>{{'VKIVaccination.Name' | translate}}</label>
    <mat-form-field>
      <mat-select (mousedown)="data.vaccination.clearFilter(data.index)" [(value)]="data.vaccination.value.name"
                  (selectionChange)='data.vaccination.nameSelection(data.index, $event)'
                  [disabled]="(data.blockFirst == 2 ? false : (data.vaccination.value.blocked == 1 || data.vaccination.value.blocked == 2)) || data.editBlocked"
                  (openedChange)='responsiveUIService.optionOpened($event)'>
        <mat-option>
          <ngx-mat-select-search [placeholderLabel]="'Common.Search' | translate"
                                 [noEntriesFoundLabel]="'Common.NoResult' | translate" #search (input)="data.vaccination.filterAntiBiotic(data.index, search)">
          </ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let anti of data.vaccination.filteredVaccinationAntiBioticListBigArray[data.index] | async" [value]="anti.id">
          {{anti.name}}
        </mat-option>
        <mat-option *ngIf="(data.vaccination.filteredVaccinationAntiBioticListBigArray[data.index] | async).length == 0" disabled>
          {{'Common.NoResult' | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="form-field-cont">
    <label>{{'VKIVaccination.RegistrationNr' | translate}}</label>
    <span class="display-text">{{data.vaccination.value.registrationNr  || '-'}}</span>
  </div>
  <div class="form-field-cont">
    <label>{{'VKIVaccination.WaitingTime' | translate}}</label>
    <span class="display-text">{{data.vaccination.value.legalWaitingTime || '-'}}</span>
  </div>
  <div class="form-field-cont">
    <label>{{'VKIVaccination.Method' | translate}}</label>
    <mat-form-field class="custom-form width-90">
      <mat-select [(value)]="data.vaccination.value.method" (selectionChange)='data.method.methodSelection(data.index , $event)'
                  [disabled]="(data.blockFirst == 2 ? false : (data.vaccination.value.blocked == 1 || data.vaccination.value.blocked == 2)) || data.editBlocked">
        <!-- <input matInput class="search" [placeholder]="'Common.Search' | translate" #search (input)="filterMethod(i, search)"> -->
        <mat-option *ngFor="let method of data.method.filteredMethodListBigArray[data.index] | async" [value]="method.name">
          {{method.translateMethod | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="form-field-cont">
    <label>{{'VKIVaccination.Date' | translate}}</label>
    <mat-form-field class="date-form">
      <input matInput
             [matDatepicker]="picker"
             [placeholder]="'Common.dd-mm-yyyy' | translate"
             [(value)]="data.vaccination.value.dateObj"
             #date
             [min]="data.vaccination.value.minDate"
             [max]="data.vaccination.value.maxDate"
             (keydown)="data.dates.dateInput($event, data.index)"
             (dateChange)="data.dates.setDate($event, data.index)"
             [disabled]="(data.blockFirst == 2 ? false : (data.vaccination.value.blocked == 1 || data.vaccination.value.blocked == 2)) || data.editBlocked"
             maxlength="10">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </div>
  <div class="form-field-cont" *ngIf="data.fieldConfigMap.get('administeredBy') && data.fieldConfigMap.get('administeredBy')['state'] !== 0">
    <label>{{'VKIMobile.AdministeredBy' | translate}}</label>
    <mat-form-field
      [ngClass]="{'small-error-number-form': data.vaccination.value.administeredByError}"
      class="small-custom-form text width-90">
      <input matInput #administeredBy [value]="data.vaccination.value?.administeredBy === 'null' ? '' : data.vaccination.value?.administeredBy" (input)="data.setAdministeredBy(data.index, administeredBy)"
             maxlength="150" [disabled]="(data.blockFirst == 2 ? false : (data.vaccination.value.blocked == 1 || data.vaccination.value.blocked == 2)) || data.editBlocked || data.fieldConfigMap.get('administeredBy')['state'] == 1">
    </mat-form-field>
  </div>
  <div class="form-field-cont" *ngIf="data.fieldConfigMap.get('deliveryNote') && data.fieldConfigMap.get('deliveryNote')['state'] !== 0">
    <label>{{'VKIMobile.DeliveryNote' | translate}}</label>
    <mat-form-field
      [ngClass]="{'small-error-number-form': data.vaccination.value.deliveryNoteError}"
      class="small-custom-form text width-90">
      <input matInput #deliveryNote [value]="data.vaccination.value?.deliveryNote === 'null' ? '' : data.vaccination.value?.deliveryNote" (input)="data.setDeliveryNote(data.index, deliveryNote)"
             maxlength="50" [disabled]="(data.blockFirst == 2 ? false : (data.vaccination.value.blocked == 1 || data.vaccination.value.blocked == 2)) || data.editBlocked || data.fieldConfigMap.get('deliveryNote')['state'] == 1">
    </mat-form-field>
  </div>
  <div class="action-buttons-cont">
    <button mat-icon-button *ngIf="data.vaccination.value.icon === 'bin' && !data.editBlocked" (click)="data.deleteVaccination(data.index)">
      <i class="material-icons">delete_forever</i>
    </button>
    <button *ngIf="data.vaccination.value.icon === 'lock' && !data.editBlocked" mat-icon-button>
      <i class="material-icons">lock</i>
    </button>
    <button *ngIf="data.vaccination.value.icon === 'unlock' && !data.editBlocked" mat-icon-button>
      <i class="material-icons">lock_open</i>
    </button>
    <div class="close-btn">
      <button class="button-green" mat-raised-button (click)="closeDialog()" >
        {{'VKIMobile.Close' | translate}}
      </button>
    </div>
  </div>
</div>
