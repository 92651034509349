import { Component, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ResponsiveUIService } from '../../shared/services/responsive-ui.service';

@Component({
  selector: 'app-notifications-reminder-modal',
  templateUrl: './notifications-reminder-modal.component.html',
  styleUrls: ['./notifications-reminder-modal.component.scss']
})
export class NotificationsReminderModalComponent implements OnDestroy {
  isMobileSubscription;
  isMobile;
  constructor(
    public dialogRef: MatDialogRef<NotificationsReminderModalComponent>,
    public responsiveUIService: ResponsiveUIService,
  ) {
    this.isMobileSubscription = this.responsiveUIService.isMobile$.subscribe((status: boolean) => {
      this.isMobile = status;
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.isMobileSubscription.unsubscribe();
  }
}
