/**
 * This service is needed to store and sync requests within Angular App.
 * It converts HttpRequest to Request and back.
 *
 */
import { HttpRequest } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { StoredRequestMetadata, getStorableRequestsService } from 'src/lib/requests/storable-requests.service';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { NetworkService } from '../network/network.service';
import { filter, tap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { sendMessageOfflineRequestStored } from 'src/lib/broadcast/broadcast.service';
import {
  AnotherSyncInProgressError,
  NoRequestsToSyncError,
  RequestCannotBeSynced,
  RequestSyncFailed,
  getMyFlockService,
} from 'src/lib/my-flock';
import { DialogService } from '../dialog.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { waitDelay } from 'src/lib/utils/wait-delay';
import { offlineEnabled } from '../../utils/offline-utils';
import { RequestSyncFailedDueToMaintenance } from 'src/lib/my-flock/errors';

export enum SyncStatus {
  SUCCESS = 'SUCCESS',
  SUCCESS_WITH_ERRORS = 'SUCCESS_WITH_ERRORS',
  INTERRUPTED = 'INTERRUPTED',
  INTERRUPTED_WITH_ERRORS = 'INTERRUPTED_WITH_ERRORS',
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN_PROGRESS',
  NOTHING_TO_SYNC = 'NOTHING_TO_SYNC',
}

@Injectable({
  providedIn: 'root',
})
export class OfflineRequestsService {
  public syncing$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private hasRequestsToSyncSubject = new BehaviorSubject<boolean | undefined>(undefined);
  private initialized = false;
  private wasOfflineNeedRefresh = false;

  constructor(
    private authService: AuthService,
    private networkService: NetworkService,
    private snackBar: MatSnackBar,
    private dialogService: DialogService,
    private router: Router,
    private translate: TranslateService,
    private ngZone: NgZone,
  ) {}

  public async initialize() {
    if (this.initialized) {
      return;
    }
    this.initialized = true;
    if (!offlineEnabled()) {
      return;
    }
    combineLatest([this.authService.currentUserUid$, this.networkService.getNetworkStatus()])
      .pipe(
        filter(() => !this.authService.isAuthInitInOffline),
        tap(([user, isOnline]) => {
          if (isOnline === false) {
            this.wasOfflineNeedRefresh = true;
          }
        }),
        tap(async () => {
          await this.recheckHasRequestsToSync();
        }),
      )
      .subscribe(() => this.syncRequests());
  }

  public async storeRequest(data: {
    httpRequest: HttpRequest<any>;
    uid: string;
    queryKey: string | undefined;
    metadata?: StoredRequestMetadata;
  }) {
    const { request, rawBody } = httpRequestToRequest(data.httpRequest);
    await getStorableRequestsService().storeRequest({
      request,
      rawBody,
      uid: data.uid,
      queryKey: data.queryKey,
      metadata: data.metadata,
    });
    sendMessageOfflineRequestStored({
      successMessage: this.translate.instant('SWMessage.SyncComplete'),
      failMessage: this.translate.instant('SWMessage.SyncFailed'),
      errorMessage: this.translate.instant('SWMessage.UnexpectedError'),
    });
    await this.recheckHasRequestsToSync();
  }

  public get hasRequestsToSync$(): Observable<boolean | undefined> {
    return this.hasRequestsToSyncSubject.asObservable();
  }

  public get hasRequestsToSync(): boolean | undefined {
    return this.hasRequestsToSyncSubject.getValue();
  }

  private async recheckHasRequestsToSync() {
    const currentUserUid = this.authService.currentUserUid;
    if (currentUserUid) {
      const hasRequests = await getStorableRequestsService().hasRequestsToSync(currentUserUid);
      this.hasRequestsToSyncSubject.next(hasRequests);
    } else {
      this.hasRequestsToSyncSubject.next(undefined);
    }
  }

  // use this variable to prevent multiple sync requests (e.g. quickly switch offline -> online x2 times)
  private _syncingNext = false;
  private _syncedWithErrors = false;
  private async syncRequests(notifyOnComplete = false): Promise<SyncStatus> {
    if (this._syncingNext) {
      console.log('::: sync process is already in progress');
      return SyncStatus.IN_PROGRESS;
    }

    const syncNext = () => {
      this._syncingNext = false;
      return this.syncRequests(true);
    };

    const finishSync = async (data: { complete: boolean; failed: boolean }): Promise<SyncStatus> => {
      await getMyFlockService().syncComplete(); // to release claimed sync token
      await this.recheckHasRequestsToSync();
      const { complete, failed } = data;
      const withErrors = this._syncedWithErrors;
      const syncedSomeRequests = notifyOnComplete;

      let status: SyncStatus;

      if (complete) {
        if (failed) {
          status = SyncStatus.FAILED;
        } else {
          if (syncedSomeRequests || withErrors) {
            status = withErrors ? SyncStatus.SUCCESS_WITH_ERRORS : SyncStatus.SUCCESS;
          } else {
            status = SyncStatus.NOTHING_TO_SYNC;
          }
        }
      } else {
        if (failed) {
          status = SyncStatus.FAILED;
        } else {
          if (syncedSomeRequests || withErrors) {
            status = withErrors ? SyncStatus.INTERRUPTED_WITH_ERRORS : SyncStatus.INTERRUPTED;
          } else {
            status = SyncStatus.NOTHING_TO_SYNC;
          }
        }
      }
      let messageTranslationKey = '';
      switch (status) {
        case SyncStatus.SUCCESS: {
          messageTranslationKey = 'Message.SyncCompleted';
          break;
        }
        case SyncStatus.SUCCESS_WITH_ERRORS: {
          messageTranslationKey = 'ErrorMessage.SyncCompleteWithError';
          break;
        }
        case SyncStatus.INTERRUPTED: {
          messageTranslationKey = 'ErrorMessage.SyncInterrupted';
          break;
        }
        case SyncStatus.INTERRUPTED_WITH_ERRORS: {
          messageTranslationKey = 'ErrorMessage.SyncInterruptedWithError';
          break;
        }
      }
      if (messageTranslationKey) {
        await this.dialogService.singleAction(messageTranslationKey, null, 'Common.Ok').toPromise();
      }
      // refresh if sync was done, or nothing to sync, now online and was offline before
      if (
        messageTranslationKey ||
        (status === SyncStatus.NOTHING_TO_SYNC && this.wasOfflineNeedRefresh && !this.networkService.isOfflineMode)
      ) {
        this.wasOfflineNeedRefresh = false;
        // internal reload page to update data
        // router.url is not used due to my-flock component
        const slug = document.URL.split('#').pop();
        this.router.navigateByUrl(slug);
      }
      this._syncedWithErrors = false;
      this._syncingNext = false;
      await this.recheckHasRequestsToSync();
      this.syncing = false;
      return status;
    };

    const currentUserUid = this.authService.currentUserUid;
    const isOnline = !this.networkService.isOfflineMode;
    if (!currentUserUid || !isOnline) {
      return finishSync({ complete: false, failed: false });
    }
    this._syncingNext = true;

    const uid = currentUserUid;
    const hasRequestsToSync = await getStorableRequestsService().hasRequestsToSync(uid);
    this.hasRequestsToSyncSubject.next(hasRequestsToSync);
    if (hasRequestsToSync) {
      this.syncing = true;
      this.syncing$.next(true);
      console.log('::: hasRequestsToSync', { hasRequestsToSync });
      const token = await this.authService.getIdToken();
      try {
        console.log(':::: offline-request.service.ts :::: syncNextRequest()', { uid, token });
        await getMyFlockService().syncNextRequest({ uid, token });
        console.log('::: syncNextRequest complete, call next one');
        return syncNext();
      } catch (error) {
        if (error instanceof AnotherSyncInProgressError) {
          console.info('::: another sync in progress, wait 10s and retry');
          await waitDelay(10000);
          return syncNext();
        } else if (error instanceof RequestCannotBeSynced) {
          console.warn(':::: Request cannot be synced, it was cancelled', { error });
          this._syncedWithErrors = true;
          return syncNext();
        } else if (error instanceof RequestSyncFailedDueToMaintenance) {
          this.authService.logout();
          let maintenanceMessage = this.translate.instant('Maintenance.UpdateMessage');
          maintenanceMessage = maintenanceMessage.replace('[EndDate]', error.endTime);
          this.snackBar.open(maintenanceMessage, 'Close', { verticalPosition: 'top' });
          return finishSync({ complete: false, failed: true });
        } else if (error instanceof RequestSyncFailed) {
          console.warn(':::: Request sync failed, retry or cancel?', { error });
          // This is for case when lose connection to internet while syncing
          if (this.networkService.isOfflineMode) {
            return finishSync({ complete: false, failed: false });
          }
          // Popup with Retry / Cancel buttons
          const retry = await this.dialogService
            .contextReverseButton(
              `${this.translate.instant('ErrorMessage.RequestFailsToSync')}`,
              null,
              'Common.Retry',
              'Common.Cancel',
              null,
              true,
            )
            .toPromise();
          if (retry) {
            return syncNext();
          } else {
            await getMyFlockService().cancelRequest(error.entryId);
            this._syncedWithErrors = true;
            return syncNext();
          }
        } else if (error instanceof NoRequestsToSyncError) {
          return syncNext();
        } else {
          // THIS CASE SHOULD NEVER NOT HAPPEN, AND THIS IS A FALLBACK TO PREVENT INFINITE LOOP
          console.log('Unknown error while syncing requests', error);
          this.snackBar.open(this.translate.instant('ErrorMessage.SyncRequestsError'), '', {});
          // TODO - remove all requests for uid?
          this._syncedWithErrors = true;
          return syncNext();
        }
      }
    } else {
      return finishSync({ complete: true, failed: false });
    }
  }

  private set syncing(value: boolean) {
    this.ngZone.run(() => {
      this.syncing$.next(value);
    });
  }
}

/**
 * Converts angular's HttpRequest to Request
 */
const httpRequestToRequest = (httpRequest: HttpRequest<any>): { request: Request; rawBody: any } => {
  const headers = new Headers();
  const serializedBody = httpRequest.serializeBody();
  const isRawBodyStorable = httpRequest.body instanceof Object && httpRequest.body.constructor === Object;
  httpRequest.headers.keys().forEach((key) => {
    headers.append(key, httpRequest.headers.get(key));
  });
  const request = new Request(httpRequest.url, {
    body: serializedBody,
    headers,
    method: httpRequest.method,
    mode: 'cors',
    referrer: 'no-referrer',
  });
  // stringify and parse to remove circular references and other objects that can't be stored in IndexedDB (e.g. data from moment.js)
  const rawBody = isRawBodyStorable ? JSON.parse(JSON.stringify(httpRequest.body)) : undefined;
  return { request, rawBody };
};
