/* eslint-disable prettier/prettier */
import { Injectable, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { NetworkService } from '../network/network.service';
import { environment } from 'src/environments/environment';
import { DialogService } from '../dialog.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class OfflineStatusHandlerService implements OnDestroy {
  private networkSubscription: Subscription;
  private dialogClosedSubscription: Subscription;

  constructor(
    private networkService: NetworkService,
    private dialogService: DialogService,
    public router: Router,
    private translate: TranslateService,
  ) {}

  private initialized = false;
  private offlineMessageDialogRef: MatDialogRef<any>;
  private offlineMessageTranslationKey = 'OfflineMode.OfflineMessage';
  private allowedPaths = ['/logoutsuccess', '/unauthorized', '/login', '/terms-of-use', '/cookie-statement', '/privacy-statement', '/my-flock'];

  public initialize() {
    if (this.initialized) {
      return;
    }
    this.initialized = true;
    if (!environment.enableOffline) {
      return;
    }

    this.networkSubscription = this.networkService.getIsOfflineMode$().subscribe((status: boolean) => {
      if (status) {
        if (!this.offlineMessageDialogRef) {
          this.offlineMessageDialogRef = this.dialogService.singleActionRef(this.offlineMessageTranslationKey, null, 'Common.Ok');
          this.dialogClosedSubscription = this.offlineMessageDialogRef.afterClosed().subscribe(() => {
            this.checkPath();
          });
        }
      } else {
        this.closeOfflineMessageDialog();
      }
    });
  }

  private closeOfflineMessageDialog() {
    if (this.offlineMessageDialogRef) {
      if (this.dialogClosedSubscription) {
        this.dialogClosedSubscription.unsubscribe();
      }
      this.offlineMessageDialogRef.close();
      this.offlineMessageDialogRef = null;
    }
  }

  private checkPath() {
    try {
      if (this.allowedPaths.some(path => this.router.url.includes(path)) || this.router.url === '/') {
        return;
      }
      this.router.navigate(['']);
    } catch (e) {
      console.log('::: Error while checkpath', { e });
    }
  }

  ngOnDestroy(): void {
    if (this.networkSubscription) {
      this.networkSubscription.unsubscribe();
    }
    if (this.dialogClosedSubscription) {
      this.dialogClosedSubscription.unsubscribe();
    }
  }
}
