import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-single-action-dialog',
  templateUrl: './single-action-dialog.component.html',
  styleUrls: ['./single-action-dialog.component.scss'],
})
export class SingleActionDialogComponent implements OnInit {
  public translationKey: string;
  public isHtml: boolean;
  public primaryButtonText: string;

  constructor(
    public dialogRef: MatDialogRef<SingleActionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit() {}

  submit() {
    this.dialogRef.close(true);
  }
}
