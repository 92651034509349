import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import * as moment from 'moment';

import { DocumentationService } from 'src/app/shared/services/communication/documentation.service';
import { MyPlukonService } from 'src/app/shared/services/my-plukon.service';
import { Documentation } from 'src/app/shared/models/documentation';
import { CommonService } from 'src/app/shared/services/common.service';
import { TranslateService } from '@ngx-translate/core';
import { SocialAuthService } from 'src/app/shared/services/social-auth.service';
import { trigger, style, state, transition, animate } from '@angular/animations';
import { CodeService } from 'src/app/shared/services/code.service';
import { TranslationService } from 'src/app/shared/services/translation.service';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { NewsService } from 'src/app/shared/services/news.service';
import { AuthGuardService } from 'src/app/shared/services/auth-guard.service';
import { EnvironmentService } from 'src/app/shared/services/admin/environment.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class LandingComponent implements OnInit {
  // documentListSubscription: Subscription;
  // magazineSubscription: Subscription;
  // dowonloadSubscription: Subscription;
  // pdfSubscription: Subscription;
  plukonTranslationSub: Subscription;
  newsSubscription: Subscription;
  environmentSubscription: Subscription;
  conceptLayoutSubscription: Subscription;
  languageSubscription: Subscription;
  userRoleSubscription: Subscription;
  imagePaddingSubscription: Subscription;
  // dataSource: MatTableDataSource<Documentation>;
  // columns: any;
  // displayedColumns: any;
  // documentList = [];
  showSpinner: boolean;
  adminFlag: boolean;
  newsObj: any;
  selectedLangCodeTableId: any;
  bannerURL: any;
  logoURL: any;
  userRole: any;
  userRoleList: any;
  mainEnvironment = null;
  showFeaturedNews = false;
  bannerPadding = false;

  constructor(
    private documentationService: DocumentationService,
    private myPlukonService: MyPlukonService,
    private commonService: CommonService,
    public snackBar: MatSnackBar,
    private router: Router,
    private translate: TranslateService,
    private socialService: SocialAuthService,
    private codeService: CodeService,
    public translationService: TranslationService,
    private _sanitizer: DomSanitizer,
    private newsService: NewsService,
    private authService: AuthGuardService,
    private environmentService: EnvironmentService,
  ) {
    // this.columns = [
    //   { columnDef: 'blank' },
    //   { columnDef: 'more', action: true, more: true },
    //   { columnDef: 'companyName', header: 'CommunicationDocument.CompanyName', cell: (element: Documentation) => `${element.companyName}`, status: (element: Documentation) => `${element.downloaded}` },
    //   { columnDef: 'documentType', header: 'CommunicationDocument.DocumentType', cell: (element: Documentation) => `${element.documentType}`, status: (element: Documentation) => `${element.downloaded}` },
    //   { columnDef: 'stableName', header: 'CommunicationDocument.StableName', cell: (element: Documentation) => `${element.documentCombineList}`, combineLength: (element: Documentation) => `${element.combineListLength}`, status: (element: Documentation) => `${element.downloaded}` },
    //   { columnDef: 'slaughterDate', header: 'CommunicationDocument.SlaughterDate', cell: (element: Documentation) => `${element.slaughterDate}`, status: (element: Documentation) => `${element.downloaded}` },
    //   { columnDef: 'action', header: '', action: true, more: false }
    // ];
    // this.displayedColumns = this.columns.map(c => c.columnDef);

    this.imagePaddingSubscription = this.newsService.imagePadding.subscribe((result) => {
      if (result) {
        this.bannerPadding = result;
      }
    });
  }

  ngOnInit() {
    Promise.all([this.getMyProfile()]).then(() => {
      this.languageSubscription = this.translationService.languageSelectedCodeTableId.subscribe((result) => {
        this.selectedLangCodeTableId = null;
        if (result) {
          this.selectedLangCodeTableId = result;

          if (this.mainEnvironment != null && this.selectedLangCodeTableId != null) {
            Promise.all([this.getNews()]).then(() => {});
          }
        }
      });

      this.environmentSubscription = this.authService.mainEnvironment.subscribe((result) => {
        this.mainEnvironment = null;
        if (result) {
          this.mainEnvironment = result.envId;

          if (this.mainEnvironment != null && this.selectedLangCodeTableId != null) {
            Promise.all([this.getNews()]).then(() => {});
          }
        }
      });
    });

    Promise.all([this.getMainEnvironment()]).then(() => {
      this.conceptLayoutSubscription = this.myPlukonService.conceptLayout.subscribe((result) => {
        if (result && result.response) {
          this.bannerURL = result.response.bannerURL;
          this.logoURL = result.response.logoURL;
        } else if (result || result == false) {
          this.bannerURL = '/assets/images/home.jpg';
          this.logoURL = null;
        } else {
          this.bannerURL = null;
          this.logoURL = null;
        }
      });
    });

    // this.commonService.runTestPost().subscribe((result) => {
    //   alert(result);
    // });

    this.commonService.isAdmin.subscribe((admin) => {
      this.adminFlag = admin;
    });
  }

  getMyProfile() {
    return new Promise((resolve, reject) => {
      this.socialService.getMyProfile().subscribe(
        (result) => {
          if (result) {
            this.userRoleList = result['response']['userRoleList'] ? result['response']['userRoleList'] : [];
            if (result['response'].tncAgree == false || result['response'].tncAgree == null) {
              this.router.navigate(['/term-and-condition']);
            } else {
              this.plukonTranslationSub = this.translationService.getPlukonTranslation(null).subscribe();
            }
          }
          resolve(true);
        },
        (error) => {
          if (error.status == '401') {
            this.snackBar.open(this.translate.instant('Portal.UnauthorizedAPICall'), 'Close', { verticalPosition: 'top' });
            return false;
          }
          if (error.status == '404') {
            this.router.navigate(['/login']);
            return false;
          }
        },
      );
    });
  }

  getMainEnvironment() {
    return new Promise((resolve, reject) => {
      this.userRoleSubscription = this.authService.mainEnvironment.subscribe((result) => {
        if (result) {
          this.userRole = result.role;
          //TP-830 June 8, 2022, 10:36 PM Kasim's comment allow Quality Employee to access News
          this.showFeaturedNews = !(this.userRole === 'Veterinarian Employee');
        }
        resolve(true);
      });
    });
  }

  getNews() {
    return new Promise((resolve, reject) => {
      const hideNews =
        this.userRole === 'Veterinarian Employee' ||
        (this.userRole === 'Other' &&
          this.userRoleList.length > 0 &&
          (this.userRoleList.filter((data) => data.roleObj.name === 'internalMailPlanning').length > 0 ||
            this.userRoleList.filter((data) => data.roleObj.name === 'InternalMailAdmin').length > 0 ||
            this.userRoleList.filter((data) => data.roleObj.name === 'internalMailquality').length > 0 ||
            this.userRoleList.filter((data) => data.roleObj.name === 'Veterinarian Employee').length > 0));
      if (!hideNews) {
        this.newsSubscription = this.codeService.getNews(this.mainEnvironment, this.selectedLangCodeTableId, null).subscribe(
          (result) => {
            if (result && result['response'] && result['response']['results']) {
              try {
                const self = this;
                self.newsObj = [];

                _.forEach(result['response']['results'], function (data) {
                  const conceptList = [];
                  if (data.conceptList) {
                    _.forEach(data.conceptList, function (concept) {
                      conceptList.push({
                        active: concept.active,
                        translateSubject:
                          concept.conceptCT && concept.conceptCT.name && concept.conceptCT.codeTable
                            ? `${concept.conceptCT.codeTable}.${concept.conceptCT.name}`
                            : '',
                        conceptCT: concept.conceptCT,
                        conceptLayout: concept.conceptLayout,
                      });
                    });
                  }

                  if (self.newsObj.length < 3) {
                    self.newsObj.push({
                      archived: data.archived,
                      archivedInDashboard: data.archivedInDashboard,
                      // "attachedStylesheets": data.attachedStylesheets,
                      authorName: data.authorName,
                      categoryId: data.categoryId,
                      contentGroupId: data.contentGroupId,
                      contentTypeCategory: data.contentTypeCategory,
                      created: data.created ? moment(data.created).format('DD-MM-YYYY') : '',
                      createdById: data.createdById,
                      currentState: data.currentState,
                      currentlyPublished: data.currentlyPublished,
                      deletedAt: data.deletedAt ? moment(data.deletedAt).format('DD-MM-YYYY') : '',
                      domain: data.domain,
                      enableGoogleAmpOutputOverride: data.enableGoogleAmpOutputOverride,
                      featuredImage: data.featuredImage,
                      featuredImageAltText: data.featuredImageAltText,
                      htmlTitle: data.htmlTitle,
                      id: data.id,
                      language: data.language,
                      // "layoutSections": data.layoutSections,
                      metaDescription: data.metaDescription,
                      name: data.name,
                      postBody: data.postBody,
                      postSummary: data.postSummary,
                      shortSummary: data.postSummary
                        ? data.postSummary
                            .toString()
                            .replace(/<[^>]+>/g, '')
                            .substring(0, 51) + '...read more'
                        : '',
                      // "publicAccessRules": data.publicAccessRules,
                      publicAccessRulesEnabled: data.publicAccessRulesEnabled,
                      publishDate: data.publishDate ? moment(data.publishDate).format('DD-MM-YYYY') : '',
                      slug: data.slug,
                      state: data.state,
                      tagIds: data.tagIds,
                      // "translations": data.translations,
                      updated: data.updated ? moment(data.updated).format('DD-MM-YYYY') : '',
                      updatedById: data.updatedById,
                      url: data.url,
                      useFeaturedImage: data.useFeaturedImage,
                      // "widgetContainers": data.widgetContainers,
                      // "widgets": data.widgets,
                      conceptList: conceptList,
                      hubSpotTagList: data.hubSpotTagList,
                    });
                  }
                });
                resolve(result['list']);
              } catch (e) {
                console.log('Hubspot data issue\n' + e);
                this.snackBar.open(this.translate.instant('ErrorMessage.DataRetrieveFail'), 'Close', { verticalPosition: 'top' });
              }
            } else {
              const self = this;
              self.newsObj = [];
              resolve(result['response']['results']);
            }
          },
          (error) => {
            const self = this;
            self.newsObj = [];
            console.log('Landing (getHubSpot) : ', error);
            switch (error.status) {
              case 401:
                this.snackBar.open(this.translate.instant('Portal.UnauthorizedAPICall'), 'Close', { verticalPosition: 'top' });
                break;
              default:
                this.snackBar.open(this.translate.instant('ErrorMessage.DataRetrieveFail'), 'Close', { verticalPosition: 'top' });
                break;
            }
          },
        );
      }
    });
  }

  // loadLangaugeData(lang) {
  //   this.safeURL = this.translate.instant('HomeVideo.Url') ? this._sanitizer.bypassSecurityTrustResourceUrl(this.translate.instant('HomeVideo.Url')) : null;
  //   this.videoUrl = this.safeURL;
  //   switch (lang) {
  //     case 'en':
  //       this.magazinePicture = this.magazineObj[0]['language2URL'];
  //       this.magazinePdf = this.magazineObj[1]['language2URL'];
  //       this.magazineUploadDate = this.magazineObj[1]['lastModified2URL'] ? moment(this.magazineObj[1]['lastModified2URL'], 'DD-MM-YYYY HH:mm').format('DD MMMM YYYY') : '';
  //       break;
  //     case 'du':
  //       this.magazinePicture = this.magazineObj[0]['language1URL'];
  //       this.magazinePdf = this.magazineObj[1]['language1URL'];
  //       this.magazineUploadDate = this.magazineObj[1]['lastModified1URL'] ? moment(this.magazineObj[1]['lastModified1URL'], 'DD-MM-YYYY HH:mm').format('DD MMMM YYYY') : '';
  //       break;
  //     case 'ge':
  //       this.magazinePicture = this.magazineObj[0]['language3URL'];
  //       this.magazinePdf = this.magazineObj[1]['language3URL'];
  //       this.magazineUploadDate = this.magazineObj[1]['lastModified3URL'] ? moment(this.magazineObj[1]['lastModified3URL'], 'DD-MM-YYYY HH:mm').format('DD MMMM YYYY') : '';
  //       break;
  //     default:
  //       this.magazinePicture = this.magazineObj[0]['language1URL'];
  //       this.magazinePdf = this.magazineObj[1]['language1URL'];
  //       this.magazineUploadDate = this.magazineObj[1]['lastModified1URL'] ? moment(this.magazineObj[1]['lastModified1URL'], 'DD-MM-YYYY HH:mm').format('DD MMMM YYYY') : '';
  //   }
  // }

  // displayDocument() {
  //   this.showSpinner = true;

  //   this.documentListSubscription = this.documentationService.getHomeDocumentList().subscribe(result => {
  //     let self = this;
  //     self.documentList = [];
  //     _.forEach(result['list'], function (data) {
  //       self.documentList.push({
  //         'companyId': data.documentAccessCombineList ? data.documentAccessCombineList[0].company : null,
  //         'companyName': data.documentAccessCombineList[0]['companyObj'] ? data.documentAccessCombineList[0]['companyObj'].name : '',
  //         'documentType': data.documentTypeObj ? `${data.documentTypeObj.codeTable}.${data.documentTypeObj.name}` : '',
  //         'slaughterDate': data.slaughterDate ? data.slaughterDate : '',
  //         'documentCombineList': data.documentAccessCombineList ? data.documentAccessCombineList : null,
  //         'combineListLength': data.documentAccessCombineList ? data.documentAccessCombineList.length : 0,
  //         'document': data.document ? data.document : '',
  //         'downloaded': data.downloaded ? data.downloaded : false
  //       });
  //     });

  //     this.dataSource = new MatTableDataSource(self.documentList);
  //     this.documentNumber = self.documentList.length;
  //     this.showSpinner = false;
  //   }, (error) => {
  //     console.log('Landing (displayDocument) : ', error);
  //     // this.snackBar.open(this.translate.instant('ErrorMessage.DataRetrieveFail'), 'Close', { duration: 5000, verticalPosition: 'top' });
  //     this.showSpinner = false;
  //   });
  // }

  // openMagazine(pdf) {
  //   // this.magazineSubscription = this.documentationService.getMagazine().subscribe(result => {
  //   event.preventDefault();
  //   var a = document.createElement('a');
  //   a.href = pdf;
  //   a.target = '_blank';
  //   document.body.appendChild(a);
  //   a.click();
  //   document.body.removeChild(a);
  //   // }, (error) => {
  //   //   console.log('Landing (openMagazine) : ', error);
  //   //   this.snackBar.open(this.translate.instant('ErrorMessage.DataRetrieveFail'), 'Close', { duration: 5000, verticalPosition: 'top' });
  //   // });
  // }

  openNewsDetails(news) {
    const newsId = news.id;
    this.newsService.selectedNews.next(news);
    this.router.navigate(['news/news-detail/' + newsId]);
  }

  seeAllArticles() {
    // this.newsService.seeAllFeaturedNews = true;
    this.newsService.initState.next(true);
    this.router.navigate(['news/concept']);
  }

  // download(data) {
  //   if (data.document) {
  //     let wi = window.open('about:blank', '_blank');
  //     wi.document.write("<p>Downloading pdf...</p> <progress id='download' value='100' max='100'>");

  //     this.dowonloadSubscription = this.documentationService.downloadDocument(data.document, data.companyId).subscribe((result) => {
  //       console.log("url : " + result['response'].signedURL);
  //       if (result['response'].signedURL) {
  //         wi.location.href = result['response'].signedURL;
  //         // mark the document as downloaded
  //         for (let item of this.documentList) {
  //           if (item.document === data.document) {
  //             item.downloaded = true;
  //             break;
  //           }
  //         }
  //       } else {
  //         wi.document.open().write("<p>" + this.translate.instant('CommunicationDocument.NotAvailable') + "</p>");
  //         this.snackBar.open(this.translate.instant('CommunicationDocument.NotAvailable'), 'Close', { duration: 5000, verticalPosition: 'top' });
  //       }
  //     }, (error) => {
  //       console.log('Landing (download) : ', error);
  //       if (wi != null)
  //         wi.document.open().write("<p>Error on pdf</p>");
  //       this.snackBar.open(this.translate.instant('ErrorMessage.DataRetrieveFail'), 'Close', { duration: 5000, verticalPosition: 'top' });
  //     });
  //   }
  // }

  // promptLatestNews(message) {
  //   this.snackBar.open(message, 'Close', { duration: 5000, verticalPosition: 'top' });
  // }

  // routeDocument() {
  //   this.router.navigate(['/my-messages/documentation']);
  // }

  ngOnDestroy() {
    if (this.environmentSubscription) {
      this.environmentSubscription.unsubscribe();
    }
    if (this.imagePaddingSubscription) {
      this.imagePaddingSubscription.unsubscribe();
    }
    if (this.userRoleSubscription) {
      this.userRoleSubscription.unsubscribe();
    }
    if (this.plukonTranslationSub) {
      this.plukonTranslationSub.unsubscribe();
    }
    if (this.newsSubscription) {
      this.newsSubscription.unsubscribe();
    }
    if (this.conceptLayoutSubscription) {
      this.conceptLayoutSubscription.unsubscribe();
    }
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe();
    }
  }
}
