import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from '../../services/dialog.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { sendMessage, sendMessageOfflineRequestStored } from 'src/lib/broadcast/broadcast.service';
import { OfflineRequestsService } from '../../services/offline/offline-request.service';
import { getCachedResponsesService } from 'src/lib/responses/cached-responses.service';
import { AuthService } from '../../services/auth/auth.service';
import { getSyncService } from 'src/lib/sync/sync.service';
import { PrecacheService } from '../../services/offline/precache.service';
import { IOSBgSyncService } from '../../services/offline/ios-bg-sync.service';
import { openDB, DBSchema, IDBPDatabase } from 'idb';

@Component({
  selector: 'app-dev-items',
  templateUrl: './dev-items.component.html',
  styleUrls: ['./dev-items.component.scss'],
})
export class DevItemsComponent implements OnInit {
  constructor(
    public translate: TranslateService,
    private dialogService: DialogService,
    private router: Router,
    public snackBar: MatSnackBar,
    private iosBgSyncService: IOSBgSyncService,
    private offlineRequestService: OfflineRequestsService,
    private authService: AuthService,
    private precacheService: PrecacheService,
  ) { }

  ngOnInit(): void { }

  async toggleShowPrecacheNotification() {
    const uid = this.authService.currentUserUid;
    const precacheNotificaiton = await getSyncService().getPrecacheConfig(uid);
    if (precacheNotificaiton?.showNotification) {
      await getSyncService().storePrecacheConfig(uid, false);
    } else {
      await getSyncService().storePrecacheConfig(uid, true);
    }
  }

  async cleanupCache() {
    const uid = this.authService.currentUserUid;
    await getCachedResponsesService().cleanupOldCachedResponses(-1);
    await getSyncService().resetPrecacheConfig(uid);
    await getSyncService().resetPrecacheState(uid);
  }

  iosBgSync() {
    this.iosBgSyncService.initialize();
  }

  openTwoButtonsDialog() {
    this.dialogService
      .contextReverseButton('Data is outdated', null, 'Common.Continue', 'Common.Cancel', null, true)
      .subscribe((confirm) => {
        if (!confirm) {
          this.router.navigate(['']);
        }
      });
  }

  openOKDialog() {
    this.dialogService
      .contextReverseButton('Data is not cached for offline, please go online', null, 'Common.Ok', null, null, true)
      .subscribe(() => {
        this.router.navigate(['']);
      });
  }

  openBlockingOKDialog() {
    this.dialogService.contextReverseButton('Data is changed on server', null, 'Common.Ok', null, null, true).subscribe(() => {
      window.location.reload();
    });
  }

  openRetryCancelDialog() {
    this.dialogService.contextReverseButton('Sync failed', null, 'Common.Retry', 'Common.Cancel', null, true).subscribe((retry) => {
      if (retry) {
      }
    });
  }

  openSnackbar() {
    this.snackBar.open(this.translate.instant('Portal.UnauthorizedAPICall'), 'Close', {
      verticalPosition: 'top',
      panelClass: ['yellow'],
    });
  }

  sendSwRequestStored() {
    sendMessageOfflineRequestStored({
      successMessage: 'Success',
      failMessage: 'Failed',
      errorMessage: 'Error',
    });
  }

  sendSwDebug() {
    sendMessage('debug');
  }

  async expireToken() {
    const db = await openDB('firebaseLocalStorageDb');
    const { value: result } = await db.transaction('firebaseLocalStorage').store.openCursor();
    result.value.displayName = 'THIS IS A TEST';
    result.value.stsTokenManager.expirationTime = Date.now();

    const tx = db.transaction('firebaseLocalStorage', 'readwrite', { durability: 'strict' });
    await tx.store.put(result);
    await tx.done;
  }
}
