import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../services/auth/auth.service';
import {NotificationsService} from '../../services/notifications/notifications.service';

@Component({
  selector: 'app-unauthorize',
  templateUrl: './unauthorize.component.html',
  styleUrls: ['./unauthorize.component.scss'],
})
export class UnauthorizeComponent implements OnInit {
  constructor(
    private router: Router,
    public translate: TranslateService,
    private authService: AuthService,
    private notifications: NotificationsService
  ) {
    if (translate.currentLang) {
      this.translate.use(translate.currentLang);
    } else {
      this.translate.use('en');
    }
  }

  ngOnInit() { }

  login() {
    Promise.all([this.notifications.removeUserDataToNotificationsList(), this.authService.logout()])
      .then(() => {
        window.location.reload();
      }).catch((e) => console.warn('Notifications disabled'));
  }
}
