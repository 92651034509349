<div class="cookie-statement-container" fxLayout="column" fxLayoutAlign="center center">
  <div class="cookie-statement-wrapper">
    <ng-container>
      <div [innerHTML]="sanitizer.bypassSecurityTrustHtml('CookieStatement.FullHtml' | translate)"></div>
    </ng-container>
    <!-- <ng-container *ngIf="selectedLang == 'en'">
        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
        <br>
        <br>
        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
    </ng-container>
    <ng-container *ngIf="selectedLang == 'du'">
        Lorem Ipsum is gewoon dummy tekst van de drukkerij- en zetbranche.
        <br>
        <br>
        Lorem Ipsum is de standaard dummy-tekst van de industrie sinds de jaren 1500, toen een onbekende printer een kombuis van het type in beslag nam en het tot een soort exemplaarboek maakte. Het heeft niet alleen vijf eeuwen overleefd, maar ook de sprong naar elektronisch zetwerk, en bleef in wezen onveranderd. Het werd gepopulariseerd in de jaren zestig met de uitgave van Letraset-bladen met Lorem Ipsum-passages en meer recent met desktop publishing-software zoals Aldus PageMaker inclusief versies van Lorem Ipsum.
    </ng-container> -->
    <!-- <ng-container *ngIf="selectedLang === 'German'">
      <label class="sub-title">Cookie-Erklärung Plukonnect</label>
      <br>
      Bei der Bereitstellung elektronischer Dienste verwenden wir Cookies. Ein Cookie ist eine kleine, einfache Datei,
      die zusammen mit Seiten von dieser Website gesendet und von Ihrem Browser auf der Festplatte Ihres Computers
      gespeichert wird. Die in dieser Datei gespeicherten Informationen können bei Ihrem nächsten Besuch auf unserer
      Website an unsere Server zurückgesendet werden. Sie können mehr darüber in dieser Cookie-Erklärung lesen. Wir
      verwenden nur funktionale und analytische Cookies. Wir setzen keine “Tracking”-Cookies. Die von uns gesetzten
      Cookies sind für die Verarbeitung unserer Website und die Führung von Statistiken notwendig.
      <br>
      <br>
      Wenn Sie Fragen zur Verarbeitung Ihrer persönlichen Daten haben, können Sie sich an unsere Datenschutzerklärung
      wenden.
      <br>
      <br>
      <label class="sub-title">Google Analytics</label>
      <br>
      Über unsere Website wird als Teil des „Analytics“-Diensts ein Cookie des US-Unternehmens Google Inc. („Google“)
      platziert. Wir nutzen diesen Dienst, um in Form von Berichten etwas darüber zu erfahren, wie unsere Website von
      den Besuchern genutzt wird. Google kann diese Informationen an Dritte weitergeben, wenn Google dazu gesetzlich
      verpflichtet wird, oder insoweit Dritte diese Informationen im Namen von Google verarbeiten. Darauf haben wir
      keinen Einfluss. Wir haben es Google nicht gestattet, die erhaltenen Analytics-Informationen für andere
      Google-Dienste zu verwenden.
      <br>
      <br>
      Die von Google erhobenen Daten werden so weit wie möglich anonymisiert. Diese Website verwendet Google Analytics
      unter Zuhilfenahme von „anonymizeIP ()“, einem System, mit dem die IP-Adressen verkürzt dargestellt werden. Die
      Informationen werden auf Server in den Vereinigten Staaten übertragen und dort von Google gespeichert. Google
      verpflichtet sich, die Privacy Shield-Prinzipien einzuhalten, und nimmt am Privacy Shield-Programm des
      US-Handelsministeriums teil. Dazu gehört, dass für die eventuelle Verarbeitung von personenbezogenen Daten ein
      angemessenes Schutzniveau eingehalten werden muss. Für weitere Informationen lesen Sie sich bitte zum einen die
      Datenschutzbestimmungen von Google und zum anderen die spezifischen Datenschutzbestimmungen von Google Analytics
      durch.
      <br>
      <br>
      <label class="sub-title">Recht auf Zugang und Berichtigung oder Löschung Ihrer Daten</label>
      <br>
      Sie haben das Recht auf Zugang, Berichtigung oder Löschung Ihrer Daten. Bitte beachten Sie unsere
      Datenschutzerklärung. Wenn es um den Zugriff auf personenbezogene Daten geht, die mit einem Cookie verknüpft sind,
      sollten Sie eine Kopie des betreffenden Cookie senden. Sie finden dies in den Einstellungen Ihres Browsers.
      <br>
      <br>
      <label class="sub-title">Cookies löschen, aktivieren und deaktivieren </label>
      <br>
      Weitere Informationen zum Aktivieren, Deaktivieren und Löschen von Cookies finden Sie in der Anleitung und/oder
      über die Hilfefunktion Ihres Browsers.
    </ng-container> -->
    <!-- <ng-container *ngIf="selectedLang === 'English' || selectedLang === 'Nederlands' || selectedLang === 'Français'">
      <label style="font-size: 1rem;font-weight: bold;">Cookieverklaring</label>
      <br>
      Wij maken bij het aanbieden van onze elektronische diensten gebruik van cookies. Een cookie is een eenvoudig klein
      bestandje dat met pagina’s van deze website wordt meegestuurd en door uw browser op uw harde schrijf van uw
      computer wordt opgeslagen. De daarin opgeslagen informatie kan bij een volgend bezoek weer naar onze servers
      teruggestuurd worden. Hierover leest u meer in dit cookiebeleid. Wij gebruiken uitsluitend functionele en
      analytische cookies. Wij plaatsen geen tracking cookies. De cookies die wij plaatsen zijn nodig voor het goed
      laten
      functioneren van dit portal en het bijhouden van statistieken.
      <br>
      <br>
      Indien u vragen heeft over de verwerking van uw persoonsgegevens, verwijzen wij u graag naar ons
      privacystatement.
      <br>
      <br>
      <label style="font-size: 1rem;font-weight: bold;">Google Analytics</label>
      <br>
      Via onze website wordt een cookie geplaatst van het Amerikaanse bedrijf Google, als deel van de
      “Analytics”-dienst.
      Wij gebruiken deze dienst om bij te houden en rapportages te krijgen over hoe bezoekers de website gebruiken.
      Google kan deze informatie aan derden verschaffen indien Google hiertoe wettelijk wordt verplicht, of voor zover
      derden de informatie namens Google verwerken. Wij hebben hier geen invloed op. Wij hebben Google niet
      toegestaan de verkregen analytics informatie te gebruiken voor andere Google diensten. De informatie die Google
      verzamelt wordt zo veel mogelijk geanonimiseerd. Uw IP-adres wordt nadrukkelijk niet meegegeven. De informatie
      wordt overgebracht naar en door Google opgeslagen op servers in de Verenigde Staten. Google stelt zich te houden
      aan de Safe Harbor principles en is aangesloten bij het Safe Harbor-programma van het Amerikaanse Ministerie van
      Handel. Dit houdt in dat er sprake is van een passend beschermingsniveau voor de verwerking van eventuele
      persoonsgegevens.
      <br>
      <br>
      <label style="font-size: 1rem;font-weight: bold;">Recht op inzage en correctie of verwijdering van uw gegevens</label>
      U hebt het recht om te vragen om inzage in en correctie of verwijdering van uw gegevens. Zie hiervoor onze
      privacyverklaring. Wanneer het gaat om inzage in persoonsgegevens gekoppeld aan een cookie, dient u een kopie
      van het cookie in kwestie mee te sturen. U kunt deze terug vinden in de instellingen van uw browser.
      <br>
      <br>
      <label style="font-size: 1rem;font-weight: bold;">In- en uitschakelen van cookies en verwijdering daarvan</label>
      Meer informatie omtrent het in- en uitschakelen en het verwijderen van cookies kunt u vinden in de instructies
      en/of
      met behulp van de Help-functie van uw browser.
      <br>
      <br>
      <label style="font-size: 1rem;font-weight: bold;">Meer informatie over cookies</label>
      Voor meer informatie over cookies verwijzen wij u naar de website van de Consumentenbond:
      <a href="https://www.consumentenbond.nl/internet-privacy/wat-zijn-cookies">https://www.consumentenbond.nl/internet-privacy/wat-zijn-cookies.
      </a>
    </ng-container> -->
  </div>
</div>
