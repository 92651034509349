import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, combineLatest } from 'rxjs';
import { RoleAccessService } from '../../services/role-access.service';

@Component({
  selector: 'app-bottom-navigation',
  templateUrl: './bottom-navigation.component.html',
  styleUrls: ['./bottom-navigation.component.scss'],
})
export class BottomNavigationComponent implements OnInit, OnDestroy {
  private roleAccessSubscription: Subscription;

  private roleChecked = false;
  public myFlockAllowed = false;
  public myMessagesAllowed = false;

  ruleConfigMap: any;
  ruleConfig = [];
  configList: any;

  /**
   * Hardcoded to "true" for now, more details here:
   * More details here: https://gcompany-projects.atlassian.net/browse/TP-1061?focusedCommentId=108550
   */
  private technicalAllowed = true; // same meaning as 'isTechnicalTabControl' at my-flock.component
  private losesAllowed = true; // same meaning as 'isLossesTabControl' at my-flock.component

  constructor(
    public router: Router,
    private roleAccessService: RoleAccessService,
  ) {}

  ngOnInit(): void {
    this.roleAccessSubscription = combineLatest([
      this.roleAccessService.roleChecked$,
      this.roleAccessService.myFlockAccess$,
      this.roleAccessService.myMessagesAccess$,
      this.roleAccessService.vkiConfig$,
    ]).subscribe(([roleChecked, myFlockAccess, myMessagesAccess, vkiConfig]) => {
      this.roleChecked = roleChecked;
      this.myFlockAllowed = myFlockAccess;
      this.myMessagesAllowed = myMessagesAccess;
      this.configList = vkiConfig;
      this.checkVKIConfig();
    });
  }

  checkVKIConfig() {
    if (this.configList) {
      this.ruleConfigMap = new Map();
      this.ruleConfig = this.configList ? this.configList.filter((data) => data.type === 'rule' && data.part === 'myFlock') : [];
      for (const configs of this.ruleConfig) {
        this.ruleConfigMap.set(configs.name, configs);
      }
      if (this.ruleConfigMap && this.ruleConfigMap.get('technicalTab') && this.ruleConfigMap.get('technicalTab')['state'] === 3) {
        this.technicalAllowed = true;
      } else {
        this.technicalAllowed = false;
      }
      if (this.ruleConfigMap && this.ruleConfigMap.get('lossesTab') && this.ruleConfigMap.get('lossesTab')['state'] === 3) {
        this.losesAllowed = true;
      } else {
        this.losesAllowed = false;
      }
    }
  }

  public get hasAllowedItems(): boolean {
    return (
      this.roleChecked && (this.myFlockAllowed || this.myMessagesAllowed || this.isMyFlockLossesAllowed || this.isMyFlockTechnicalAllowed)
    );
  }

  public get isMyFlockLossesAllowed(): boolean {
    return this.myFlockAllowed && this.losesAllowed;
  }

  public get isMyFlockTechnicalAllowed(): boolean {
    return this.myFlockAllowed && this.technicalAllowed;
  }

  ngOnDestroy(): void {
    if (this.roleAccessSubscription) {
      this.roleAccessSubscription.unsubscribe();
    }
  }
}
