<div fxLayout="column" fxLayoutAlign="center center">
  <ng-container *ngIf="isTranslate; else noTranslate">
    <mat-dialog-content>{{content | translate}}</mat-dialog-content>
  </ng-container>
  <ng-template #noTranslate>
    <mat-dialog-content>{{content}}</mat-dialog-content>
  </ng-template>
    <ng-container *ngIf="isConfirm; else notConfirm">
      <mat-dialog-actions>
        <button class="button-enabled" mat-button mat-dialog-close (click)="dialogRef.close()">{{'Common.No' | translate}}</button>
        <button class="button-enabled" mat-button mat-dialog-close (click)="dialogRef.close(true)">{{'Common.Yes' | translate}}</button>
      </mat-dialog-actions>
    </ng-container>
    <ng-template #notConfirm>
      <mat-dialog-actions>
        <button class="button-enabled" mat-button mat-dialog-close (click)="dialogRef.close()">{{'Common.Close' | translate}}</button>
      </mat-dialog-actions>
    </ng-template>
</div>
