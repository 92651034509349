import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-content-reverse-button-dialog',
  templateUrl: './content-reverse-button-dialog.component.html',
  styleUrls: ['./content-reverse-button-dialog.component.scss'],
})
export class ContentReverseButtonDialogComponent implements OnInit {
  public content: string;
  public isHtml: boolean;
  public primaryButtonText: string;
  public secondaryButtonText: string;
  public showGif = false;
  public firefoxProblemUrl;
  public hideCloseIcon;

  constructor(
    public dialogRef: MatDialogRef<ContentReverseButtonDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit() {
    this.firefoxProblemUrl = environment.firefoxProblemGif;
  }

  submit() {
    this.dialogRef.close(true);
  }
}
