import { EventEmitter, Injectable } from '@angular/core';
import { environment } from './../../../environments/environment';
import { BehaviorSubject } from '../../../../node_modules/rxjs';
import { Https } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class NewsService {
  public initState: BehaviorSubject<any>;
  public selectedNews: BehaviorSubject<any>;
  public routerSelectedId: BehaviorSubject<any>;
  public conceptFilterList: BehaviorSubject<any>;
  public imagePadding: BehaviorSubject<any>;
  public showSideMenu: any;
  public seeAllFeaturedNews: any;
  public seeAllHistoryNews: any;

  constructor(private http: Https) {
    this.initState = new BehaviorSubject<any>(null);
    this.selectedNews = new BehaviorSubject<any>(null);
    this.routerSelectedId = new BehaviorSubject<any>(null);
    this.conceptFilterList = new BehaviorSubject<any>(null);
    this.imagePadding = new BehaviorSubject<any>(false);
    this.showSideMenu = true;
    this.seeAllFeaturedNews = false;
    this.seeAllHistoryNews = false;
  }

  public getConceptByPlukonConceptId(environmentId, plukonConceptId) {
    return this.http.get(`${environment.baseUrl}/concept/layout/${environmentId}?plukonConceptId=${plukonConceptId}`, '');
  }
}
