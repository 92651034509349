<div class="content-container" fxLayout="column" fxLayoutAlign="center center">
  <mat-dialog-content class="content-body" [formGroup]="chickenDeliverFormGroup">
      <label>{{'StableCard.ChickenDeliverPopUp' | translate}}</label>
    <div class="field-div field-wrapper">
      <label class="input-label">{{'VKIFlockOrigin.NoOfChicksDeliver' | translate}}</label>
      <div class="input-field" fxLayout="column">
        <mat-form-field>
          <input matInput inputmode="decimal" formControlName="chickenDeliver" (keydown)="numberInput($event)" (keyup)="numberChange()" maxlength="6">
        </mat-form-field>
      </div>
    </div>
    <mat-error *ngIf="chickenDeliverFormGroup.get('chickenDeliver').hasError('max')">
      {{'VKIValidator.NumberExceed' | translate}}
    </mat-error>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button class="button-enabled" mat-button mat-dialog-close (click)="dialogRef.close()">{{'Common.Cancel' | translate}}</button>
    <button [ngClass]="{'button-disabled': !chickenDeliverFormGroup.valid, 'button-enabled': chickenDeliverFormGroup.valid}" mat-button mat-dialog-close (click)="saveChickenDeliver()" [disabled]="!chickenDeliverFormGroup.valid">{{'Common.Save' | translate}}</button>
  </mat-dialog-actions>
  <i class="material-icons" (click)="dialogRef.close()">clear</i>
</div>
