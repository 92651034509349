import { Injectable, OnDestroy } from '@angular/core';
import { CommonService } from './common.service';
import { RoleSetting } from './setting/role-setting.service';
import { BehaviorSubject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RoleAccessService implements OnDestroy {
  private userProfileSubscription: Subscription;
  private roleCheckedSubject = new BehaviorSubject<boolean>(false);
  private myFlockAccessSubject = new BehaviorSubject<boolean>(false);
  private myMessagesAccessSubject = new BehaviorSubject<boolean>(false);
  public vkiConfigSubject = new BehaviorSubject<any>(null);
  //Future improvement: VKIConfig can be refactored to only retrieved & keep track in this service instead of calling in each related component
  constructor(
    private commonService: CommonService,
    private roleService: RoleSetting,
  ) {
    this.userProfileSubscription = this.commonService.myUserProfile.subscribe((result) => {
      // similar code is duplicated in many places (should be refactored to use the service)
      // logic below is copied from header.component.ts
      if (result != null && result['response']) {
        const isAdmin = result['response'].admin;

        const role = result['response'].userRoleMainCompanyObj;
        const isSuperAdmin = isAdmin && role && role.name === 'Superadmin';
        const veterinarianView = this.checkisAllowAccess(role, isAdmin);

        const mainRole = result['response'].userRoleMainCompanyObj ? result['response'].userRoleMainCompanyObj.name : null;
        const setting = this.roleService.getRoleSetting(mainRole);
        const userRole = setting.role;
        const userRoleList = result['response']['userRoleList'] ? result['response']['userRoleList'] : [];

        const isInternalMail =
          userRole === 'Other' &&
          userRoleList.length > 0 &&
          (userRoleList.filter((data) => data.roleObj.name === 'internalMailPlanning').length > 0 ||
            userRoleList.filter((data) => data.roleObj.name === 'InternalMailAdmin').length > 0 ||
            userRoleList.filter((data) => data.roleObj.name === 'internalMailquality').length > 0);

        let isGlobal = false;

        if (!isAdmin && (userRoleList.filter((data) => data.roleObj.name === 'Ext. Advisor').length > 0 || mainRole === 'Ext. Advisor')) {
          isGlobal = false;
        } else {
          if (result['response'].thisUserFromGlobalEnv) {
            isGlobal = true;
          } else {
            isGlobal = false;
          }
        }
        // condition is taken from header.component.html
        const myFlockAllowed = (!veterinarianView || isSuperAdmin) && !isGlobal && !isInternalMail;
        this.myFlockAccessSubject.next(myFlockAllowed);

        const myMessagesAllowed = (!veterinarianView || isSuperAdmin) && !isInternalMail;
        this.myMessagesAccessSubject.next(myMessagesAllowed);

        this.roleCheckedSubject.next(true);
      } else {
        this.roleCheckedSubject.next(false);
        this.myMessagesAccessSubject.next(false);
        this.myFlockAccessSubject.next(false);
      }
    });
  }

  checkisAllowAccess(role, isAdmin) {
    if (role && role.name) {
      if (
        (role.name === 'Veterinarian Employee' && isAdmin === false) ||
        (role.name === 'Quality employee' && isAdmin === true) ||
        (role.name === 'Superadmin' && isAdmin === true)
      ) {
        return true;
      }
      return false;
    } else {
      return false;
    }
  }

  public get roleChecked$() {
    return this.roleCheckedSubject.asObservable();
  }

  public get myFlockAccess$() {
    return this.myFlockAccessSubject.asObservable();
  }

  public get myMessagesAccess$() {
    return this.myMessagesAccessSubject.asObservable();
  }

  public get vkiConfig$() {
    return this.vkiConfigSubject.asObservable();
  }

  ngOnDestroy(): void {
    if (this.userProfileSubscription) {
      this.userProfileSubscription.unsubscribe();
    }
  }
}
