import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ErrorsHandlerService implements ErrorHandler {
  constructor(private injector: Injector) {}

  handleError(error: Error | HttpErrorResponse) {
    if (!environment.production) {
      console.log('ErrorsHandlerService', error);
    }
    const router = this.injector.get(Router);
    if (error instanceof HttpErrorResponse) {
    }
  }
}
